import { Group, Progress, Stack, Text } from '@mantine/core';
import classes from './ProgressSnackbar.module.scss';
import { IconCircleCheckFilled, IconSettingsFilled } from '@tabler/icons-react';

interface ProgressSnackbarProps {
  name: string;
  progress: number;
  onCancelUpload?: () => void;
}

export const ProgressSnackbar = ({ name, progress, onCancelUpload }: ProgressSnackbarProps) => {
  return (
    <Stack mb={'md'} pt={'sm'} gap={'xs'} px={'md'} className={classes.message}>
      <Group gap={'sm'}>
        {progress === 100 ? <IconCircleCheckFilled size={16} /> : <IconSettingsFilled size={16} />}
        <Text variant={'medium'}>{name}</Text>
      </Group>
      <Progress color={'var(--text-brand)'} value={progress} />
    </Stack>
  );
};
