import { ProductOverviewDto } from '@assemblio/shared/dtos';
import { ExplorerTable, ThProps } from '../../../layout';
import { ProductRow, ProductRowSkeleton } from './ProductRow/ProductRow';
import { ReactNode } from 'react';
import { EmptyView } from '../../EmptyView/EmptyView';

interface ProductTableProps {
  products: ProductOverviewDto[];
  onOpenProduct: (product: ProductOverviewDto) => void;
  onOpenDetails: (id: string) => void;
  onToggleFavorite: (id: string, isFavorite: boolean) => void;
  isLoading?: boolean;
  emptyView?: ReactNode;
}

export const ProductTable = ({
  products,
  onOpenProduct,
  onOpenDetails,
  onToggleFavorite,
  isLoading = false,
  emptyView = <EmptyView />,
}: ProductTableProps) => {
  const items = products.map((product) => (
    <ProductRow
      onOpenProduct={() => onOpenProduct(product)}
      onOpenDetails={() => onOpenDetails(product.id)}
      onToggleFavorite={() => onToggleFavorite(product.id, product.isFavorite)}
      product={product}
    />
  ));
  const tableHeaders: ThProps[] = [
    {
      name: 'Name',
      width: '25%',
    },
    {
      name: 'Folder',
      width: '12.5%',
    },
    {
      name: 'Project',
      width: '12.5%',
    },
    {
      name: 'Owner',
      width: '12.5%',
    },
    {
      name: 'Last Edited',
      width: '12.5%',
    },
    {
      name: 'Status',
      width: '12.5%',
    },
    {
      width: '12.5%',
    },
  ];

  return (
    <ExplorerTable
      headerItems={tableHeaders}
      items={items}
      isLoading={isLoading}
      loadingPlaceholder={ProductRowSkeleton}
      emptyView={emptyView}
    />
  );
};
