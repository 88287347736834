import { useMutation, useQueryClient } from '@tanstack/react-query';
import { linkResourceToStep, unlinkResourceFromStep, updateStepResource } from '@assemblio/frontend/data-access';
import axios from 'axios';
import { createResource, updateResource } from './resource.requests';
import {
  CreateOperationalResourceDto,
  LinkResourceToStepDto,
  UnlinkResourceToStepDto,
  UpdateOperationalResourceDto,
} from '@assemblio/shared/dtos';
import { WithId } from '../types/helper.types';
import { UpdateStepResource } from './types';

export const useRemoveResourceFromStepMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((resourceFromStep: UnlinkResourceToStepDto) => unlinkResourceFromStep(resourceFromStep), {
    onSuccess: (_, values) => {
      queryClient.invalidateQueries(['resource-steps', values.stepId]).then(() => {
        queryClient.refetchQueries({
          queryKey: ['resource-steps', values.stepId],
        });
      });
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('removeResourceFromStep', error?.response?.data.message);
      }
    },
  });
};

export const useLinkResourceToStep = () => {
  const queryClient = useQueryClient();

  return useMutation((link: LinkResourceToStepDto) => linkResourceToStep(link), {
    onSuccess: (_, values) => {
      queryClient.invalidateQueries(['resource-steps', values.stepId]);
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('removeResourceFromStep', error?.response?.data.message);
      }
    },
  });
};

export const useUpdateStepResourceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((update: UpdateStepResource) => updateStepResource(update), {
    onSuccess: (_, values) => {
      queryClient.invalidateQueries(['resource-steps', values.stepId]);
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('removeResourceFromStep', error?.response?.data.message);
      }
    },
  });
};

export const useCreateOperationalResource = () => {
  const queryClient = useQueryClient();

  return useMutation((data: CreateOperationalResourceDto) => createResource(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['resources']);
    },
  });
};

export const useUpdateOperationalResource = () => {
  const queryClient = useQueryClient();

  return useMutation((data: WithId<UpdateOperationalResourceDto>) => updateResource(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['resources']);
    },
  });
};
