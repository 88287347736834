import { UserMessageType } from '@assemblio/type/socket';
import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class UserMessageDto {
  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(UserMessageType))
      .required()
  )
  @ApiProperty({ description: 'Message sub type', required: true, enum: UserMessageType })
  type!: UserMessageType;
}

export const UserMessageSchema = generateClassValidationObject(UserMessageDto);
