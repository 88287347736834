import { generateClassValidationObject, joiSchemaProperty, ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class MetaDto {
  @joiSchemaProperty(Joi.number())
  @ApiProperty({ description: 'number of records per page', type: Number })
  perPage?: number;

  @joiSchemaProperty(Joi.number())
  @ApiProperty({ description: 'total number of pages', type: Number })
  totalPages!: number;

  @joiSchemaProperty(Joi.number())
  @ApiProperty({ description: 'total number of results', type: Number })
  totalResults!: number;

  @joiSchemaProperty(Joi.number())
  @ApiProperty({ description: 'the current page count', type: Number })
  curPage!: number;
}

export class PaginatedDto<T> {
  items!: T[];
  meta!: MetaDto;
}

export const MetaSchema = generateClassValidationObject(MetaDto);
