import { ActionIcon, Paper, Tabs } from '@mantine/core';
import { UIController, useUIStore } from '@assemblio/frontend/stores';
import { useDebouncedState } from '@mantine/hooks';
import { useEffect } from 'react';
import { StepDetails } from './components/StepDetails';
import { IconCircleXFilled } from '@tabler/icons-react';
import classes from './StepDetailsDrawer.module.scss';

const OPEN_TRANSITION_DURATION = 250;

export const StepDetailsDrawer = () => {
  const opened = useUIStore((state) => state.showStepDetails);

  const step = useUIStore((state) => state.selectedStep);

  const [content, setContent] = useDebouncedState(opened, OPEN_TRANSITION_DURATION);

  const handleClose = () => {
    if (opened) UIController.setShowStepDetails(false);
    setContent(false);
  };

  useEffect(() => {
    setContent(opened);
    if (!opened) {
      handleClose();
    }
  }, [opened]);

  if (!step) return null;

  return (
    <Paper data-opened={opened} className={classes.drawer_wrapper}>
      {content && (
        <Tabs defaultValue={'resources'}>
          <Tabs.List>
            <Tabs.Tab value={'resources'}>Resources</Tabs.Tab>
            <ActionIcon
              className={classes.close_icon}
              variant={'transparent'}
              size={'xs'}
              c={'text-secondary'}
              onClick={handleClose}
            >
              <IconCircleXFilled />
            </ActionIcon>
          </Tabs.List>
          <Tabs.Panel value={'resources'}>{opened && <StepDetails id={step.id} />}</Tabs.Panel>
        </Tabs>
      )}
    </Paper>
  );
};
