import * as Sentry from '@sentry/react';
import { environment } from './environments/environment';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect } from 'react';

export const initSentry = () => {
  const url = window.location.href;
  const isLocal = url.includes('localhost');

  const isStagingEnvironment = url.includes('staging') || url.includes('testing');

  const environment_namespace = isLocal ? 'local' : isStagingEnvironment ? 'staging' : 'production';

  Sentry.init({
    dsn: 'https://efe6eb00e98c76a8acdfcecf15375cdc@o4506988670091264.ingest.us.sentry.io/4506994529992704',
    environment: environment.production ? environment_namespace : 'dev',
    enabled: environment.production,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // Performance Monitoring
    //TODO: lower value before release
    tracesSampleRate: environment.production ? 0.2 : 1.0,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/assemblio\.com\/api/],
  });
};
