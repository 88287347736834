import { useProjectMove } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { useExplorerRouteParams } from '../../../../../hooks/ExplorerRouteParams.hook';

export const useMoveItem = () => {
  const moveProject = useProjectMove();

  const { folderId } = useExplorerRouteParams();

  const handleProjectMove = (itemId: string, targetFolderId: string) => {
    if (!folderId) return;
    moveProject.mutate(
      {
        projectId: itemId,
        targetFolderId: targetFolderId,
        oldParentFolderId: folderId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'move-project-success',
            message: 'Project has been moved',
            color: 'green',
          });
        },
        onError: () => {
          notifications.show({
            id: 'move-project-error',
            message: 'Project could not be moved',
            color: 'red',
          });
        },
      }
    );
  };

  return {
    moveExplorerItem: handleProjectMove,
    isLoading: moveProject.isLoading,
  };
};
