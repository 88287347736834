import { CanvasController, UIController, useUIStore } from '@assemblio/frontend/stores';
import { CameraControlsAPI } from '@assemblio/frontend/types';
import { useThree } from '@react-three/fiber';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Orbiter } from './Orbiter';
import { ViewCube } from './viewcube/ViewCube';

const activateSelection = () => {
  _.defer(() => {
    UIController.setSelectionActive(true);
  });
};

const deactivateSelection = () => {
  UIController.setSelectionActive(false);
};

export const CameraControls = () => {
  const controlsReference = useRef<CameraControlsAPI>(null);
  const dragging = useUIStore((state) => state.isDragging);
  const cameraControlsActive = useUIStore((state) => state.cameraControlsActive);
  const { active: activeSelection } = useUIStore((state) => state.selection);
  const sidebarWidth = useUIStore((state) => state.sidebarWidth);
  const [areControlsSet, setAreControlsSet] = useState(false);
  const { size } = useThree();

  const isActive = cameraControlsActive && !activeSelection && !dragging;

  useEffect(() => {
    if (controlsReference.current && !areControlsSet) {
      CanvasController.setCameraControls(controlsReference.current);
      const reference = controlsReference.current;
      setAreControlsSet(true);
      reference.addEventListener('update', deactivateSelection);
      reference.addEventListener('end', activateSelection);
      return () => {
        reference.removeEventListener('update', deactivateSelection);
        reference.removeEventListener('end', activateSelection);
      };
    }
    return _.noop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlsReference?.current, areControlsSet]);

  return (
    <>
      <Orbiter ref={controlsReference} active={isActive} />
      <ViewCube marginX={Math.max(sidebarWidth.left, size.left) + 80 - size.left} active={cameraControlsActive} />
    </>
  );
};
