import { Group, Button } from '@mantine/core';
import { FolderCreationInput } from '../FolderCreationInput/FolderCreationInput';

interface ItemMoveModalButtonsProps {
  currentFolderId: string | undefined;
  parentFolderId: string | undefined;
  onClose: () => unknown;
  onProjectMove: () => unknown;
}

export const ExplorerMoveModalButtons = ({
  currentFolderId,
  parentFolderId,
  onClose,
  onProjectMove,
}: ItemMoveModalButtonsProps) => {
  return (
    <Group mb={'lg'} px={'lg'} justify={'space-between'}>
      <FolderCreationInput currentParentFolderId={currentFolderId} />
      <Group>
        <Button
          py={'sm'}
          px={'lg'}
          size={'sm'}
          fw={'bold'}
          variant={'secondary'}
          onClick={(e) => {
            if (currentFolderId) {
              e.preventDefault();
              e.stopPropagation();
              onClose();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          py={'sm'}
          px={'lg'}
          size={'sm'}
          fw={'bold'}
          variant={'primary'}
          disabled={parentFolderId === currentFolderId}
          onClick={(e) => {
            if (currentFolderId) {
              e.preventDefault();
              e.stopPropagation();
              onProjectMove();
            }
          }}
          data-cy="moveConfirmButton"
        >
          Move
        </Button>
      </Group>
    </Group>
  );
};
