import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class SearchInFolderQueryDto {
  @ApiProperty({
    description: 'The search query',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  query!: string;
}

export const SearchInFolderQuerySchema = generateClassValidationObject(SearchInFolderQueryDto);
