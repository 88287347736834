import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class GenerateProdUploadPathDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({
    description: 'Product name',
    required: true,
  })
  productName!: string;

  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({
    description: 'Folder id',
    required: true,
  })
  folderId!: string;

  @joiSchemaProperty(Joi.string().uuid())
  @ApiProperty({
    description: 'Project id',
    required: false,
  })
  projectId?: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({
    description: 'original file name',
    required: true,
  })
  originalFileName!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({
    description: 'token',
    required: true,
  })
  token!: string;
}

export const GenerateProdUploadPathSchema = generateClassValidationObject(GenerateProdUploadPathDto);
