import { useInstructionSync } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { SyncProfileConfig } from '@assemblio/shared/dtos';

export const useSyncProduct = () => {
  const syncMutation = useInstructionSync();
  const commitSync = (
    baseVariantId: string,
    targetVariantId: string,
    config: SyncProfileConfig,
    projectId?: string
  ) => {
    syncMutation.mutate(
      {
        sourceId: baseVariantId,
        targetId: targetVariantId,
        config: config,
        projectId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'sync-instruction-with-source-success',
            message: 'Initialized synchronization',
            color: 'green',
          });
        },
      }
    );
  };

  return {
    syncProduct: commitSync,
    isLoading: syncMutation.isLoading,
  };
};
