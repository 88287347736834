import { axiosHandler } from '../utils/axios-utils';
import {
  CreateOperationalResourceDto,
  LinkResourceToStepDto,
  OperationalResourceDto,
  QueryOperationalResourceDto,
  ResourceDetailsDto,
  StepResourceDto,
  UnlinkResourceToStepDto,
  UpdateOperationalResourceDto,
} from '@assemblio/shared/dtos';
import { FetchResourceByStep, FetchResourceProducts, UpdateStepResource } from './types';
import { WithId } from '../types/helper.types';

export const fetchOperationalResources = async (
  data?: QueryOperationalResourceDto
): Promise<OperationalResourceDto[]> => {
  return axiosHandler('get', `/v1/operational-resources`, { params: data });
};

export const fetchProductsByResource = async (data: FetchResourceProducts): Promise<ResourceDetailsDto> => {
  return typeof data.resourceId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `/v1/operational-resources/${data.resourceId}/products`, { params: data.queryData });
};

export const fetchResourcesByStep = async (data: FetchResourceByStep): Promise<StepResourceDto[]> => {
  return typeof data.stepId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `/v1/operational-resources/step/${data.stepId}`, { params: data.queryData });
};

export const unlinkResourceFromStep = async (data: UnlinkResourceToStepDto): Promise<void> => {
  return axiosHandler('delete', `/v1/operational-resources/unlink`, {
    data,
  });
};

export const linkResourceToStep = async (data: LinkResourceToStepDto): Promise<void> => {
  return axiosHandler('post', '/v1/operational-resources/link', {
    data,
  });
};

export const updateStepResource = async (data: UpdateStepResource) => {
  return axiosHandler('patch', `/v1/operational-resources/step/${data.stepId}`, {
    data: data.data,
  });
};

export const fetchResourceById = async (resourceId: string | undefined): Promise<OperationalResourceDto> => {
  return typeof resourceId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `/v1/operational-resources/${resourceId}`);
};

export const createResource = async (data: CreateOperationalResourceDto): Promise<OperationalResourceDto> => {
  return axiosHandler('post', `/v1/operational-resources`, { data });
};

export const updateResource = async (data: WithId<UpdateOperationalResourceDto>) => {
  return axiosHandler('patch', `/v1/operational-resources/${data.id}`, { data: data.data });
};
