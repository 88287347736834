import {
  MachineController,
  ModelController,
  ModelVisibilityController,
  StepController,
  UIController,
  useUIStore,
} from '@assemblio/frontend/stores';
import { Bvh } from '@react-three/drei';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import { Event, Intersection, Mesh, Object3D } from 'three';
import { MeshBVHHelper } from 'three-mesh-bvh';
import { GLTF } from 'three-stdlib';
import { ImperativeModelController } from '@assemblio/frontend/stores';
import { useSpatialQueries } from '../hooks/useSpatialQueries';

interface Props {
  gltf: GLTF;
  showBVH?: boolean;
}

export const ImperativeModel = ({ gltf, showBVH }: Props) => {
  const { scene } = gltf;

  useEffect(() => {
    let helper: MeshBVHHelper | undefined;

    if (showBVH && scene) {
      scene.traverse((child: Object3D) => {
        const mesh = child as Mesh;
        const { geometry } = mesh;
        if (mesh.isMesh && geometry && geometry.boundsTree && mesh.visible) {
          // BVH helpers need to be siblings to work, and this is only for debug purposes
          helper = new MeshBVHHelper(mesh);
          scene.add(helper);
        }
      });
    }

    return () => {
      if (helper) {
        scene.remove(helper);
      }
    };
  }, [scene, showBVH]);

  const onClick = (e: ThreeEvent<Event>) => {
    const selectionActive = useUIStore.getState().selectionActive;
    if (!selectionActive) {
      e.stopPropagation();
      return;
    }
    const gltfIndex = ImperativeModelController.findClosestGltfIndex(e.object);
    if (
      gltfIndex !== undefined &&
      ModelVisibilityController.isPartVisible(gltfIndex) &&
      !ModelController.isPartExcluded(gltfIndex) &&
      selectionActive
    ) {
      e.stopPropagation();
      //Replace with selectable Set in UI Store.
      if (!StepController.getSelectedStep()?.data.parts.some((part) => part.partGltfIndex === gltfIndex)) {
        MachineController.selectTransition(gltfIndex);
      }
    }
  };

  const collisionDetected = useUIStore((state) => state.surfaceCollisionDetected);

  const onContextMenu = (e: ThreeEvent<Event>) => {
    const selectionActive = useUIStore.getState().selectionActive;
    const gltfIndex = ImperativeModelController.findClosestGltfIndex(e.object);

    if (
      gltfIndex !== undefined &&
      ModelVisibilityController.isPartVisible(gltfIndex) &&
      !ModelController.isPartExcluded(gltfIndex) &&
      selectionActive
    ) {
      e.stopPropagation();
      UIController.setPartContext(gltfIndex);
    }
  };

  useEffect(() => {
    if (collisionDetected) {
      UIController.markCollisionOnSelectedParts();
    } else {
      ModelController.setSelectedPartsToUserDefinedColors();
      UIController.highlightSelectedParts();
    }
  }, [collisionDetected]);

  const { iterateHierarchy } = useSpatialQueries();
  const { raycaster } = useThree();
  return (
    <Bvh firstHitOnly>
      <primitive
        object={gltf.scene}
        onClick={(e: ThreeEvent<Event>) => {
          onClick(e);
        }}
        onPointerMissed={(e: ThreeEvent<Event>) => {
          const filtered: Array<Intersection> = [];
          for (const obj of iterateHierarchy(gltf.scene, true)) {
            const currentIntersections: Array<Intersection> = [];

            Mesh.prototype.raycast.call(obj, raycaster, currentIntersections);
            filtered.push(...currentIntersections);
            if (filtered.length > 0) break;
          }

          if (filtered.length === 0) {
            MachineController.deselect();
            return;
          }
          e.stopPropagation();
          e.object = filtered[0].object;
          onClick(e);
        }}
        onContextMenu={onContextMenu}
      />
    </Bvh>
  );
};
