import { UserQueryDto } from '@assemblio/shared/dtos';
import { useQuery } from '@tanstack/react-query';
import { fetchTenantUsers, fetchV1CurrentUser } from './user.requests';

export const useV1CurrentUserQuery = () => {
  return useQuery(['user-me'], () => fetchV1CurrentUser(), {
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useTenantUsersQuery = (options: UserQueryDto, enabled: boolean | undefined = true) => {
  return useQuery(
    ['tenant-users'],
    () => {
      return fetchTenantUsers(options);
    },
    {
      enabled,
    }
  );
};
