import { useV1InstructionSyncResolve } from '@assemblio/frontend/data-access';
import { SyncProfileResolvedPair } from '@assemblio/shared/next-types';

export const useEditSyncProfile = () => {
  const resolveMutation = useV1InstructionSyncResolve();

  const handleUpdateSyncProfile = (
    sourceVariantId: string,
    targetVariantId: string,
    updatedPairs: SyncProfileResolvedPair
  ) => {
    resolveMutation.mutate({
      sourceId: sourceVariantId,
      targetId: targetVariantId,
      resolve: updatedPairs,
    });
  };

  return {
    onUpdateSyncProfile: handleUpdateSyncProfile,
    isLoading: resolveMutation.isLoading,
  };
};
