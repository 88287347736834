import { ArtefactType } from '@assemblio/type/artefacts';
import { ApiProperty, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class ArtefactDto {
  @ApiProperty({
    description: 'The artefact id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The original name of the artefact',
    required: true,
  })
  originalName!: string;

  @ApiProperty({
    description: 'The creation date of the artefact',
    required: true,
  })
  createdAt!: Date;

  @ApiProperty({
    description: 'The file format of the artefact eg. .pdf or .mp4',
    required: true,
  })
  type!: ArtefactType;

  @ApiProperty({
    description: 'The size of the artefact in bytes',
    required: true,
  })
  size!: number;
}
