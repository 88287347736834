import { TextInput, Button, Group } from '@mantine/core';
import { IconCirclePlusFilled } from '@tabler/icons-react';
import { useState } from 'react';
import { useCreateFolder } from '../../../CreateExplorerItemModal/hooks/createFolder.hook';
import { useForm } from '@mantine/form';
import { useFolderDepth } from '@assemblio/frontend/explorer';

interface FolderCreationInputProps {
  currentParentFolderId: string | undefined;
}

export const FolderCreationInput = ({ currentParentFolderId }: FolderCreationInputProps) => {
  const { createFolder } = useCreateFolder();
  const currentFolderDepth = useFolderDepth(currentParentFolderId, 'modal');

  const [isCreatingFolder, setIsCreatingFolder] = useState(false);

  const form = useForm({
    initialValues: { name: '' },
    validate: {
      name: (value) => {
        if (value.trim().length < 3 || value.trim().length > 55) {
          return 'Name must be between 3 and 55 characters long';
        }
        return null;
      },
    },
  });

  const handleCreateClick = async () => {
    if (form.validate().hasErrors) {
      return;
    }
    await createFolder(form.getValues().name, currentParentFolderId, ['folder', currentParentFolderId, 'modal']);

    setIsCreatingFolder(false);
  };

  return isCreatingFolder ? (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleCreateClick();
      }}
    >
      <Group>
        <TextInput {...form.getInputProps('name')} placeholder="New folder name" autoFocus />
        <Button
          size={'sm'}
          fw={'bold'}
          py={'sm'}
          px={'lg'}
          variant={'primary'}
          onClick={handleCreateClick}
          disabled={currentFolderDepth === 2}
        >
          Create Folder
        </Button>
      </Group>
    </form>
  ) : (
    <Button
      size={'sm'}
      fw={'bold'}
      py={'sm'}
      px={'lg'}
      disabled={currentFolderDepth === 2}
      variant={'secondary'}
      leftSection={<IconCirclePlusFilled size={16} />}
      onClick={() => setIsCreatingFolder(true)}
    >
      New Folder
    </Button>
  );
};
