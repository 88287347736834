import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class PartGltfIndicesDto {
  @joiSchemaProperty(Joi.array().items(Joi.number()).required())
  @ApiProperty({
    description: 'Array of Part Gltf Indices',
    isArray: true,
    type: [Number],
    required: true,
  })
  gltfIndices!: number[];
}

export const PartGltfIndicesSchema = generateClassValidationObject(PartGltfIndicesDto);
