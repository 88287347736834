import { StepResourceDto } from '@assemblio/shared/dtos';
import { Stack, Text } from '@mantine/core';
import { StepResource } from '../StepResource/StepResource';
import { AddResourceInput } from '../StepResource/components/AddResourceInput/AddResourceInput';
import { useProjectStore, useUIStore } from '@assemblio/frontend/stores';
import { CompactStepResource } from '../StepResource/CompactStepResource';
import { useRemoveResourceFromStepMutation } from '@assemblio/frontend/data-access';

interface StepResourceListProps {
  stepId?: string;
  resources: StepResourceDto[] | undefined;
}

export const StepResourceList = ({ resources, stepId }: StepResourceListProps) => {
  const isEditor = useUIStore.getState().view === 'editor';

  const productId = useProjectStore((state) => state.instructionId);

  const removeResourceFromStepMutation = useRemoveResourceFromStepMutation();

  const handleOnDelete = (id: string) => {
    removeResourceFromStepMutation.mutate({
      id: id,
      stepId: stepId!,
      productId: productId,
    });
  };

  const isEmtpy = resources && resources.length === 0;

  return (
    <Stack p={'lg'}>
      {isEditor && <AddResourceInput />}
      <Text size={'sm'}>List of operational resources used in this step</Text>
      {isEmtpy ? (
        <Text size={'sm'} c={'text-secondary'}>
          No resources used in this step
        </Text>
      ) : (
        resources?.map((rel) =>
          isEditor ? (
            <StepResource key={rel.id} relation={rel} onDelete={handleOnDelete} />
          ) : (
            <CompactStepResource key={rel.id} relation={rel} />
          )
        )
      )}
    </Stack>
  );
};
