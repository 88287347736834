import {
  generateClassValidationObject,
  joiSchemaProperty,
} from '@assemblio/shared/decorators';
import { ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { JobState, JobType } from '@assemblio/type/job';

export class QueryJobsDTO {
  @joiSchemaProperty(Joi.string().uuid().optional())
  @ApiProperty({ description: 'Tenant Id', required: false })
  tenantId?: string;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(JobType))
      .optional()
  )
  @ApiProperty({ description: 'Job type', enum: JobType, required: false })
  type?: JobType;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(JobState))
      .optional()
  )
  @ApiProperty({ description: 'Job status', enum: JobState, required: false })
  status?: JobState;

  @joiSchemaProperty(Joi.number().min(0).optional())
  @ApiProperty({
    description: 'Page no for pagination, used only with "itemsPerPage"',
    type: Number,
    required: false,
  })
  page?: number;

  @joiSchemaProperty(Joi.number().min(1).optional())
  @ApiProperty({
    description: 'Number of items per page for pagination',
    type: Number,
    required: false,
  })
  itemsPerPage?: number;
}

export const QueryJobsSchema = generateClassValidationObject(QueryJobsDTO);
