import { RoomEvents } from '@assemblio/type/socket';
import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class RoomMessageDto {
  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(RoomEvents))
      .required()
  )
  @ApiProperty({ description: 'Room event', required: true, enum: RoomEvents })
  roomEvent!: RoomEvents;

  @joiSchemaProperty(
    Joi.string()
      .uuid()
      .when('roomEvent', {
        is: Joi.valid(RoomEvents.Job, RoomEvents.Product),
        then: Joi.optional(),
        otherwise: Joi.forbidden(),
      })
  )
  @ApiProperty({ description: 'project id', required: false })
  projectId?: string;

  @joiSchemaProperty(
    Joi.string().uuid().when('projectId', { is: Joi.exist(), then: Joi.optional(), otherwise: Joi.forbidden() })
  )
  @ApiProperty({ description: 'product id', required: false })
  productId?: string;

  @joiSchemaProperty(
    Joi.string().uuid().when('productId', { is: Joi.exist(), then: Joi.optional(), otherwise: Joi.forbidden() })
  )
  @ApiProperty({ description: 'job id', required: false })
  jobId?: string;
}

export const RoomMessageSchema = generateClassValidationObject(RoomMessageDto);
