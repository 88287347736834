import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { TextChildDto, TextChildSchema } from './text-child.dto';
import type { Alignment, HeadingLevel } from './text.types';
import { alignment, headingLevel } from './text.types';

export class ListItemDto {
  @joiSchemaProperty(Joi.string().valid('list-item').required())
  type!: 'list-item';

  @joiSchemaProperty(Joi.array().items(TextChildSchema).required())
  @ApiProperty({
    description: 'List Item Children',
    isArray: true,
    type: [TextChildDto],
    required: true,
  })
  children!: TextChildDto[];
}

export const ListItemSchema = generateClassValidationObject(ListItemDto);

export class OrderedListDto {
  @joiSchemaProperty(Joi.string().valid('ordered-list').required())
  type!: 'ordered-list';

  @joiSchemaProperty(Joi.array().items(ListItemSchema).required())
  @ApiProperty({
    description: 'Ordered List Children',
    isArray: true,
    type: [ListItemDto],
    required: true,
  })
  children!: ListItemDto[];

  @joiSchemaProperty(
    Joi.string()
      .valid(...alignment)
      .optional()
  )
  align?: Alignment;
}

export const OrderedListSchema = generateClassValidationObject(OrderedListDto);

export class UnorderedListDto {
  @joiSchemaProperty(Joi.string().valid('unordered-list').required())
  type!: 'unordered-list';

  @joiSchemaProperty(Joi.array().items(ListItemSchema).required())
  @ApiProperty({
    description: 'Unordered List Children',
    isArray: true,
    type: [ListItemDto],
    required: true,
  })
  children!: ListItemDto[];

  @joiSchemaProperty(
    Joi.string()
      .valid(...alignment)
      .optional()
  )
  align?: Alignment;
}

export const UnorderedListSchema = generateClassValidationObject(UnorderedListDto);

export class ParagraphDto {
  @joiSchemaProperty(Joi.string().valid('paragraph').required())
  type!: 'paragraph';

  @joiSchemaProperty(Joi.array().items(TextChildSchema).required())
  @ApiProperty({
    description: 'Paragraph Children',
    isArray: true,
    type: [TextChildDto],
    required: true,
  })
  children!: TextChildDto[];

  @joiSchemaProperty(
    Joi.string()
      .valid(...alignment)
      .optional()
  )
  align?: Alignment;
}

export const ParagraphSchema = generateClassValidationObject(ParagraphDto);

export class HeadingDto {
  @joiSchemaProperty(Joi.string().valid('heading').required())
  type!: 'heading';

  @joiSchemaProperty(
    Joi.number()
      .valid(...headingLevel)
      .required()
  )
  level!: HeadingLevel;

  @joiSchemaProperty(Joi.array().items(TextChildSchema).required())
  @ApiProperty({
    description: 'Heading Children',
    isArray: true,
    type: [TextChildDto],
    required: true,
  })
  children!: TextChildDto[];

  @joiSchemaProperty(
    Joi.string()
      .valid(...alignment)
      .optional()
  )
  align?: Alignment;
}

export type TextElement = ParagraphDto | HeadingDto | OrderedListDto | UnorderedListDto;
export type TextChild = TextChildDto;

export const HeadingSchema = generateClassValidationObject(HeadingDto);
