import { OperationalResourceMainCategory } from '@assemblio/type/resources';
import { DocumentVersion } from '@assemblio/type/export';

export enum DocumentExportType {
  PDF = 'pdf',
  DOCX = 'docx',
  ZIP = 'zip',
}

export type DocumentExportOptions = {
  filename: string;
  originalName: string;
  title?: string;
  version: string;
  updatedAt?: Date;
  contributor?: string;
  username?: string;
  tenantName: string;
  originalSourceFile?: string;
  status: string;
  operationalResources: OperationalResource[];
  branding: Branding;
  textBlocks: TextBlock[];
  layoutVersion?: DocumentVersion;
};

export type Branding = {
  logoPath: string | null;
  attachmentPath: string | null;
  headerColor: string;
  titleColor: string;
  textColor: string;
  footerColor: string;
  options: BrandingOptions;
};

export type OperationalResource = {
  mainCategory: string;
  secondaryCategory?: string;
  name: string;
  resourceNo?: string;
  steps: { id: string; groupId: string; value?: { amount?: number; unit?: string; info?: string }; no?: string }[];
};

export type StepResourcesMap = Record<
  string,
  Record<OperationalResourceMainCategory, { name: string; value: string }[]>
>;

export type BrandingOptions = {
  showDate: boolean;
  showContributor: boolean;
  showPartCount: boolean;
  showStepCount: boolean;
  showVersion: boolean;
};

export type DocumentExportMessage = {
  tenantId: string;
  projectId: string;
  type: DocumentExportType;
  jobId?: string;
  instructionId: string;
  path?: string;
  selection: ExportSelection;
  apiKey?: string;
  options: DocumentExportOptions;
};

export type TextBlock = {
  headline: string;
  text: string;
  tableHeaders?: string[];
};

// NEXT TYPES:
export const codecs = ['avi', 'mjpeg'] as const;
export type Codec = (typeof codecs)[number];

export const containers = ['mp4', 'avi'] as const;
export type Container = (typeof containers)[number];

export const resolutions = ['1920x1080', '1366x768', '1280x720', '1024x768', '800x600', '320x256'] as const;
export type Resolution = (typeof resolutions)[number];

export type VideoFormat = { codec: Codec; container: Container };

export type ExportSelection = Array<{
  sequenceId: string;
  stepGroupIds: string[];
}>;
