import * as Joi from 'joi';
import { joiSchemaProperty, generateClassValidationObject, ApiProperty } from '@assemblio/shared/decorators';
import { BrandingOptionsDTO, BrandingOptionsSchema } from './options.dto';
import { BrandingFileDTO, BrandingFileSchema } from './file.dto';
//TODO: Rename to BrandingDto
export class BrandingResponseDTO {
  @ApiProperty({
    description: 'options',
    type: BrandingOptionsDTO,
    required: false,
    default: new BrandingOptionsDTO(),
  })
  @joiSchemaProperty(BrandingOptionsSchema.optional())
  options?: BrandingOptionsDTO;

  @ApiProperty({
    description: 'the header branding color',
    required: false,
  })
  @joiSchemaProperty(
    Joi.string()
      .regex(/^#(?:[0-9a-fA-F]{3}){1,2}$/)
      .optional()
  )
  headerColor?: string;

  @ApiProperty({
    description: 'the title branding color',
    required: false,
  })
  @joiSchemaProperty(
    Joi.string()
      .regex(/^#(?:[0-9a-fA-F]{3}){1,2}$/)
      .optional()
  )
  titleColor?: string;

  @ApiProperty({
    description: 'the text branding color',
    required: false,
  })
  @joiSchemaProperty(
    Joi.string()
      .regex(/^#(?:[0-9a-fA-F]{3}){1,2}$/)
      .optional()
  )
  textColor?: string;

  @ApiProperty({
    description: 'the footer branding color',
    required: false,
  })
  @joiSchemaProperty(
    Joi.string()
      .regex(/^#(?:[0-9a-fA-F]{3}){1,2}$/)
      .optional()
  )
  footerColor?: string;

  @ApiProperty({
    description: 'The attached logo file',
    required: false,
  })
  @joiSchemaProperty(BrandingFileSchema)
  logo?: BrandingFileDTO;

  @ApiProperty({
    description: 'The attached file',
    required: false,
  })
  @joiSchemaProperty(BrandingFileSchema)
  attachment?: BrandingFileDTO;
}

export const BrandingResponseSchema = generateClassValidationObject(BrandingResponseDTO);
