import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class QueryProjectDto {
  @ApiProperty({
    description: 'The Project name',
    required: false,
  })
  @joiSchemaProperty(Joi.string())
  name?: string;
}

export const QueryProjectSchema = generateClassValidationObject(QueryProjectDto);
