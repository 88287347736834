import { generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { ApiProperty } from '@assemblio/shared/decorators';
import { NotificationSeverity, NotificationScope } from '@assemblio/type/notifications';
import { NotificationOptionsDto, NotificationOptionsSchema } from './notification-options.dto';
import { notificationScopeValidator } from './notification-scope.validator';

export class CreateNotificationDto {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'Tenant id', required: true })
  tenantId!: string;

  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'User id', required: true })
  userId!: string;

  @joiSchemaProperty(Joi.string().uuid().optional())
  @ApiProperty({ description: 'project id', required: false })
  projectId?: string;

  @joiSchemaProperty(Joi.string().valid(...Object.values(NotificationSeverity)))
  @ApiProperty({ description: 'Severity', required: false, default: NotificationSeverity.INFO, enum: NotificationSeverity })
  severity?: NotificationSeverity;

  @joiSchemaProperty(Joi.string().valid(...Object.values(NotificationScope)).required())
  @ApiProperty({ description: 'Notification scope', required: true, enum: NotificationScope })
  scope!: NotificationScope;

  @joiSchemaProperty(Joi.array().items(NotificationOptionsSchema).required())
  @ApiProperty({ description: 'Notification options', required: true, type: [NotificationOptionsDto] })
  options!: NotificationOptionsDto[];
}

export const CreateNotificationSchema = generateClassValidationObject(CreateNotificationDto)
  .custom(notificationScopeValidator);
