import { ItemCard, ItemCardSkeleton } from '../ItemCard/ItemCard';
import { ScrollArea, Stack } from '@mantine/core';
import { ItemRow, ItemRowSkeleton } from '../ItemRow/ItemRow';
import { ExplorerGrid, ExplorerTable, ThProps } from '../../../../layout';
import { ErrorScreen } from '@assemblio/frontend/components';
import { ExplorerItem, ExplorerItems, ExplorerItemType } from '../../types/items.types';
import { useExplorerView } from '../../../../hooks';
import { SortingOption } from '../../../../types';
import { FolderContentSortProps } from '@assemblio/type/folder';

const ITEMS_PER_ROW = 6;

interface ItemContainerProps {
  items: ExplorerItems;
  onItemClick: (id: string, type: ExplorerItemType) => void;
  isLoading: boolean;
  isError: boolean;
  sortBy?: SortingOption<FolderContentSortProps>;
  onSort?: (sortBy: string) => void;
}

/**
 * ItemContainer component
 *
 * Takes in a list of projects and folders and renders them in a grid or list view, depending on local user settings.
 */
export const ItemContainer = ({ items, sortBy, onSort, onItemClick, isLoading, isError }: ItemContainerProps) => {
  const { isListView } = useExplorerView();

  const isSplitGrid = !Array.isArray(items);

  const getListItems = () => {
    return (Array.isArray(items) ? items : items.folders.concat(items.projects)).map((item, index) =>
      toListItem(item, onItemClick, index)
    );
  };

  const tableHeaders: ThProps[] = [
    {
      name: 'Name',
      width: '45%',
      onSort: () => onSort && onSort('name'),
      reversed: sortBy?.queryValue === 'name' && !sortBy?.ascending,
      sorted: sortBy?.queryValue === 'name',
    },
    {
      name: 'Count',
      width: '40%',
    },
    {
      width: '10%',
    },
  ];

  if (isError) return <ErrorScreen />;

  return (
    <ScrollArea scrollbars={'y'} type={'hover'} mah={'100%'} h={'100%'} data-cy="itemsContainer">
      {isListView ? (
        <ExplorerTable
          headerItems={tableHeaders}
          isLoading={isLoading}
          items={getListItems()}
          loadingPlaceholder={ItemRowSkeleton}
        />
      ) : isSplitGrid ? (
        <Stack gap={'xl'}>
          <ExplorerGrid
            title={'Folders'}
            isLoading={isLoading}
            showEmptyView={items.folders.length === 0 && items.projects.length === 0}
            itemsPerRow={ITEMS_PER_ROW}
            items={items.folders.map((item, index) => toCardItem(item, onItemClick, index))}
            loadingPlaceholder={<ItemCardSkeleton />}
          />
          <ExplorerGrid
            title={'Projects'}
            isLoading={isLoading}
            showEmptyView={false}
            itemsPerRow={ITEMS_PER_ROW}
            items={items.projects.map((item, index) => toCardItem(item, onItemClick, index))}
            loadingPlaceholder={<ItemCardSkeleton />}
          />
        </Stack>
      ) : (
        <ExplorerGrid
          title={'All'}
          isLoading={isLoading}
          itemsPerRow={ITEMS_PER_ROW}
          items={items.map((item, index) => toCardItem(item, onItemClick, index))}
          loadingPlaceholder={<ItemCardSkeleton />}
        />
      )}
    </ScrollArea>
  );
};

const toCardItem = (item: ExplorerItem, onItemClick: (id: string, type: ExplorerItemType) => void, index: number) => (
  <ItemCard
    key={`item-container-card-item-${item.type}-${index}`}
    onClick={(id) => onItemClick(id, item.type)}
    item={item}
  />
);

const toListItem = (item: ExplorerItem, onItemClick: (id: string, type: ExplorerItemType) => void, index: number) => (
  <ItemRow
    key={`item-container-list-item-${item.type}-${index}`}
    onClick={(id) => onItemClick(id, item.type)}
    item={item}
  />
);
