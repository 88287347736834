import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { getAccessToken, useAuthStore } from '@assemblio/frontend/data-access';
import { useMantineTheme } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';

export const PersistLogin = () => {
  const auth0 = useAuth0();
  const theme = useMantineTheme();
  const [isLoading, setIsLoading] = useState(true);
  const accessToken = useAuthStore((state) => state.accessToken);
  const effectRan = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (effectRan.current === true || process.env['NODE_ENV'] !== 'development') {
      // React 18 Strict Mode
      const verifyRefreshToken = async () => {
        try {
          const { token, loading } = await getAccessToken(auth0);
          if (loading) return;
          setIsLoading(false);
          if (!token) {
            navigate('/login', { state: { from: location }, replace: true });
          }
        } catch (err) {
          navigate('/login', { state: { from: location }, replace: true });
        }
      };

      !accessToken ? verifyRefreshToken() : setIsLoading(false);
    }

    return () => {
      effectRan.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0.isLoading, auth0.isAuthenticated]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLoading ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: theme.other.color.editor.dark,
            position: 'absolute',
          }}
        ></div>
      ) : (
        <Outlet />
      )}
    </>
  );
};
