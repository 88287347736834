import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class PatchStepGroupDto {
  @ApiProperty({ description: 'Name', required: true })
  @joiSchemaProperty(Joi.string().required())
  name!: string;
}
export const PatchStepGroupSchema = generateClassValidationObject(PatchStepGroupDto);

export class MoveStepGroupDto {
  @ApiProperty({ description: 'new prev id', required: false })
  @joiSchemaProperty(Joi.string().uuid().required().allow(null))
  newPrev!: string | null;
}
export const MoveStepGroupSchema = generateClassValidationObject(MoveStepGroupDto);
