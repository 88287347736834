import { generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { UserRoles } from '@assemblio/type/user';
import { ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class RoleDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'tenant Id', required: true })
  tenantId!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'tenant name', required: true })
  tenant!: string;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(UserRoles))
      .required()
  )
  @ApiProperty({ description: 'role name', enum: UserRoles, required: true })
  role!: string;
}

export const RoleSchema = generateClassValidationObject(RoleDto);
