import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { InstructionFileType } from '@assemblio/type/file';
import * as Joi from 'joi';

export class InstructionFileDto {
  @ApiProperty({
    description: 'The id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({
    description: 'Name',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'file type',
    required: true,
    enum: InstructionFileType,
  })
  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(InstructionFileType))
      .required()
  )
  type!: InstructionFileType;
}

export const InstructionFileSchema = generateClassValidationObject(InstructionFileDto);
