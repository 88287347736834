import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class QueryFolderDto {
  @ApiProperty({
    description: 'The folder name',
    required: false,
  })
  @joiSchemaProperty(Joi.string())
  name?: string;
}

export const QueryFolderSchema = generateClassValidationObject(QueryFolderDto);
