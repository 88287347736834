import * as Joi from 'joi';
import { joiSchemaProperty, generateClassValidationObject, ApiProperty } from '@assemblio/shared/decorators';

export class BrandingFileDTO {
  @ApiProperty({
    description: 'The file id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The file name',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  fileName!: string;

  @ApiProperty({
    description: 'The file path',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  path!: string;

  @ApiProperty({
    description: 'The file mimeType',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  mimeType!: string;

  @ApiProperty({
    description: 'The file size',
    required: true,
  })
  @joiSchemaProperty(Joi.number().required())
  size!: number;
}

export const BrandingFileSchema = generateClassValidationObject(BrandingFileDTO);
