import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { PathType } from '@assemblio/type/path';
import * as Joi from 'joi';

export class PathElementDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({
    description: 'Element Id',
    required: true,
  })
  id!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({
    description: 'Element name',
    required: true,
  })
  name!: string;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(PathType))
      .required()
  )
  @ApiProperty({
    description: 'Element type',
    required: true,
    enum: PathType,
  })
  type!: PathType;
}

export const PathElementSchema = generateClassValidationObject(PathElementDto);
