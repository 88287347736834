import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class DuplicateInstructionDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({
    description: 'Instruction Id',
    required: true,
  })
  instructionId!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({
    description: 'Instruction name',
    required: true,
  })
  name!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({
    description: 'Folder id',
    required: true,
  })
  folderId!: string;


  @joiSchemaProperty(Joi.string())
  @ApiProperty({
    description: 'Project id',
    required: false,
  })
  projectId?: string;
  

}

export const DuplicateInstructionSchema = generateClassValidationObject(DuplicateInstructionDto);
