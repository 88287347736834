import { CSSVariablesResolver } from '@mantine/core';

export const ThemeVariablesResolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--topbar-height': theme.other.topbarHeight.toString().concat('px'),
    '--header-height': theme.other.explorerHeaderHeight.toString().concat('px'),
    '--sidebar-width': theme.other.sidebarWidth.toString().concat('px'),
    '--sidebar-width-collapsed': theme.other.sidebarWidth_collapsed.toString().concat('px'),
    'elevation-inverted': theme.colors.white[0],
    '--text-success': theme.colors.green[5],
    '--text-alert': theme.colors.yellow[5],
    '--text-danger': theme.colors.red[5],

    '--shadow-modal': '0 2px 7px 0 var(--shadow-bold), 0 5px 20px 0 var(--shadow-boldest);',
    '--shadow-panel': '9px 5px 20px 0 var(--shadow-boldest);',
    '--shadow-xs': '0px 2px 2px 0 var(--shadow-bold);',
    '--shadow-sm': '0px 2px 7px 0 var(--shadow-bold), 0 5px 17px 0 var(--shadow-bolder);',
    '--shadow-lg': '0px 4px 20px 0 var(--shadow-boldest);',
  },
  dark: {
    '--elevation-primary': theme.colors.black[0],
    '--elevation-secondary': theme.colors.neutral[9],
    '--elevation-secondary-alt': theme.colors.neutral[9],
    '--elevation-tertiary': theme.colors.neutral[8],
    '--elevation-quaternary': theme.colors.neutral[7],
    '--elevation-quinary': theme.colors.neutral[6],
    '--elevation-senary': theme.colors.neutral[4],

    '--bg-primary': theme.colors.neutral[5],
    '--bg-primary-hovered': theme.colors.neutral[4],
    '--bg-secondary': theme.colors.neutral[7],
    '--bg-secondary-hovered': theme.colors.neutral[6],
    '--bg-tertiary': theme.colors.neutral[9],
    '--bg-tertiary-hovered': theme.colors.neutral[8],
    '--bg-quinary': theme.colors.neutral[7],
    '--bg-quinary-hovered': theme.colors.neutral[6],
    '--bg-senary': theme.colors.neutral[6],

    '--bg-brand-primary': theme.colors.purple[4],
    '--bg-brand-primary-hovered': theme.colors.purple[3],
    '--bg-brand-faint': theme.colors['alpha-purple'][6],
    '--bg-brand-faintest': theme.colors['alpha-purple'][2],

    '--bg-accent-brand': theme.colors.purple[4],
    '--bg-accent-brand-green': theme.colors.green[5],
    '--bg-accent-brand-yellow': theme.colors.yellow[5],
    '--bg-accent-brand-blue': theme.colors.blue[5],
    '--bg-accent-brand-orange': theme.colors.orange[5],
    '--bg-accent-brand-magenta': theme.colors.magenta[5],
    '--bg-accent-brand-red': theme.colors.red[5],
    '--bg-accent-brand-true-blue': theme.colors['accent-blue'][5],
    '--bg-accent-white': theme.colors['alpha-white'][0],

    '--bg-loading-overlay': 'rgba(59, 59, 59, 0.75)',

    '--border-secondary-hovered-color': theme.colors.neutral[4],
    '--border-secondary-color': theme.colors.neutral[6],
    '--border-tertiary-color': theme.colors.neutral[7],
    '--border-quaternary-color': theme.colors.neutral[3],

    '--border-primary': `1px solid ${theme.colors.neutral[4]}`,
    '--border-primary-hovered': `1px solid ${theme.colors.neutral[3]}`,
    '--border-secondary': `1px solid ${theme.colors.neutral[6]}`,
    '--border-secondary-hovered': `1px solid ${theme.colors.neutral[4]}`,
    '--border-tertiary': `1px solid ${theme.colors.neutral[7]}`,
    '--border-quaternary': `1px solid ${theme.colors.neutral[3]}`,
    '--border-quaternary-hovered': `1px solid ${theme.colors.neutral[2]}`,
    '--border-brand': `1px solid ${theme.colors.purple[4]}`,
    '--border-inverted': `1px solid ${theme.colors.white[5]}`,

    '--text-primary': theme.colors.white[5],
    '--text-secondary': theme.colors['alpha-white'][8],
    '--text-tertiary': theme.colors['alpha-white'][6],
    '--text-disabled': theme.colors['alpha-white'][4],
    '--text-constant': theme.colors.white[5],
    '--text-constant-black': theme.colors.neutral[9],
    '--text-brand': theme.colors.purple[4],

    '--shadow-bold': theme.colors['alpha-black'][2],
    '--shadow-bolder': theme.colors['alpha-black'][3],
    '--shadow-boldest': theme.colors['alpha-black'][4],
  },
  light: {
    '--elevation-primary': theme.colors.white[0],
    '--elevation-secondary': theme.colors.neutral[0],
    '--elevation-secondary-alt': theme.colors.white[0],
    '--elevation-tertiary': theme.colors.neutral[1],
    '--elevation-quaternary': theme.colors.white[0],
    '--elevation-quinary': theme.colors.white[0],
    '--elevation-senary': theme.colors.neutral[3],

    '--bg-primary': theme.colors.white[0],
    '--bg-primary-hovered': theme.colors.neutral[1],
    '--bg-secondary': theme.colors.white[0],
    '--bg-secondary-hovered': theme.colors.neutral[0],
    '--bg-tertiary': theme.colors.white[0],
    '--bg-tertiary-hovered': theme.colors.neutral[2],
    '--bg-quinary': theme.colors.neutral[0],
    '--bg-quinary-hovered': theme.colors.neutral[1],
    '--bg-senary': theme.colors.neutral[2],

    '--bg-brand-primary': theme.colors.purple[5],
    '--bg-brand-primary-hovered': theme.colors.purple[3],
    '--bg-brand-faint': theme.colors['alpha-purple'][2],
    '--bg-brand-faintest': theme.colors['alpha-purple'][1],

    '--bg-accent-brand': theme.colors.purple[5],
    '--bg-accent-brand-green': theme.colors.green[5],
    '--bg-accent-brand-yellow': theme.colors.yellow[5],
    '--bg-accent-brand-blue': theme.colors.blue[5],
    '--bg-accent-brand-orange': theme.colors.orange[5],
    '--bg-accent-brand-magenta': theme.colors.magenta[5],
    '--bg-accent-brand-red': theme.colors.red[5],
    '--bg-accent-brand-true-blue': theme.colors['accent-blue'][5],
    '--bg-accent-white': theme.colors['alpha-white'][4],

    '--bg-loading-overlay': 'rgba(255, 255, 255, 0.75)',

    '--border-secondary-hovered-color': theme.colors.neutral[3],
    '--border-secondary-color': theme.colors.neutral[2],
    '--border-tertiary-color': theme.colors.neutral[1],
    '--border-quaternary-color': theme.colors.neutral[4],

    '--border-primary': `1px solid ${theme.colors.neutral[2]}`,
    '--border-primary-hovered': `1px solid ${theme.colors.neutral[3]}`,
    '--border-secondary': `1px solid ${theme.colors.neutral[2]}`,
    '--border-secondary-hovered': `1px solid ${theme.colors.neutral[4]}`,
    '--border-tertiary': `1px solid ${theme.colors.neutral[1]}`,
    '--border-quaternary': `1px solid ${theme.colors.neutral[4]}`,
    '--border-quaternary-hovered': `1px solid ${theme.colors.neutral[5]}`,
    '--border-brand': `1px solid ${theme.colors.purple[5]}`,
    '--border-inverted': `1px solid ${theme.colors.black[5]}`,

    '--text-primary': theme.colors.neutral[9],
    '--text-secondary': theme.colors['alpha-black'][8],
    '--text-tertiary': theme.colors['alpha-black'][6],
    '--text-disabled': theme.colors['alpha-black'][4],
    '--text-constant': theme.colors.white[5],
    '--text-constant-black': theme.colors.neutral[9],
    '--text-brand': theme.colors.purple[5],

    '--shadow-bold': theme.colors['alpha-black'][0],
    '--shadow-bolder': theme.colors['alpha-black'][1],
    '--shadow-boldest': theme.colors['alpha-black'][1],
  },
});
