import type { Quaternion } from '@assemblio/type/3d';
import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export type QuaternionType = [number, number, number, number];
export class QuaternionDto implements Quaternion {
  @ApiProperty({ description: 'X', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  x!: number;

  @ApiProperty({ description: 'Y', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  y!: number;

  @ApiProperty({ description: 'Z', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  z!: number;

  @ApiProperty({ description: 'W', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  w!: number;

  constructor(p: QuaternionType) {
    if (p) {
      this.x = p[0];
      this.y = p[1];
      this.z = p[2];
      this.w = p[3];
    }
  }
}

export const QuaternionSchema = generateClassValidationObject(QuaternionDto);
