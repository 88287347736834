import * as Joi from 'joi';
import { joiSchemaProperty, generateClassValidationObject, ApiProperty } from '@assemblio/shared/decorators';

export class BrandingTenantDTO {
  @ApiProperty({
    description: 'The tenant id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The tenant name',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;
}

export const BrandingTenantSchema = generateClassValidationObject(BrandingTenantDTO);
