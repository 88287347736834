import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { InstructionState } from '@assemblio/type/instruction';
import { FolderDto } from '../folder';
import { OwnerDto } from '../user';
import { SyncOverviewDto, SyncOverviewSchema } from '../instruction';
import { ProjectDto } from '../project';

export class ProductContentDto {
  @ApiProperty({
    description: 'The product id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The name of the product',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'The parent project',
    required: false,
  })
  project!: ProjectDto;

  @ApiProperty({
    description: 'The parent project',
    required: false,
  })
  folder!: FolderDto;

  @ApiProperty({
    description: 'The state of the product',
    required: true,
  })
  state!: InstructionState;

  @ApiProperty({
    description: 'When the product was last edited',
    required: false,
  })
  lastEditedAt?: Date;

  @ApiProperty({
    description: 'When the product was created',
    required: false,
  })
  createdAt!: Date;

  @ApiProperty({
    description: 'The owner of the product',
    required: false,
  })
  ownedBy!: OwnerDto;

  @ApiProperty({
    description: 'The creator of the product',
    required: false,
  })
  createdBy!: OwnerDto;

  @ApiProperty({
    description: 'The last reviewer of the product',
    required: false,
  })
  reviewer?: OwnerDto;

  @ApiProperty({
    description: 'if the product is a favorite if the current user',
    required: true,
  })
  isFavorite!: boolean;

  @ApiProperty({
    description: 'The description of the product',
    required: false,
  })
  hasSteps!: boolean;

  @ApiProperty({
    description: 'The source file of the product',
    required: false,
  })
  sourceFile!: {
    name: string;
    type: string;
  };

  @ApiProperty({
    description: 'The sync status of the product',
    required: true,
  })
  @joiSchemaProperty(SyncOverviewSchema)
  sync!: SyncOverviewDto;
}

export const ProductContentSchema = generateClassValidationObject(ProductContentDto);
