import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { StepResourceValueDto, StepResourceValueSchema } from './step-resource-value.dto';

export class UpdateStepResourceValueDto {
  @ApiProperty({
    description: 'The resource to step id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({
    description: 'The product id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  productId!: string;

  @ApiProperty({
    description: 'The set value',
    required: true,
  })
  @joiSchemaProperty(StepResourceValueSchema)
  value!: StepResourceValueDto;
}

export const UpdateStepResourceValueSchema = generateClassValidationObject(UpdateStepResourceValueDto);
