import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import '@mantine/core/styles.layer.css';
import '@mantine/notifications/styles.layer.css';
import '@uppy/core/dist/style.min.css';
import './styles.scss';
import './fonts.css';
import 'react-image-crop/dist/ReactCrop.css';
import { ComposerWithProviders } from './app/Composer';
import { initSentry } from './sentry-composer.helper';

initSentry();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <ComposerWithProviders />
  </StrictMode>
);
