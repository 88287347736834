import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { InstructionState } from '@assemblio/type/instruction';
import { OwnerDto } from '../user';
import { SyncOverviewDto } from '../instruction';
import { FolderDto } from '../folder';
import { ProjectDto } from '../project';

export class ProductOverviewDto {
  @ApiProperty({
    description: 'The product id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The name of the product',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'The state of the product',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  state!: InstructionState;

  @ApiProperty({
    description: 'The date the product was last updated',
    required: true,
  })
  updatedAt!: Date;

  @ApiProperty({
    description: 'The sync status of the product',
    required: true,
  })
  sync!: SyncOverviewDto;

  @ApiProperty({
    description: 'if the product is a favorite if the current user',
    required: true,
  })
  isFavorite!: boolean;

  @ApiProperty({
    description: 'The thumbnail path for the product',
    required: true,
  })
  owner!: OwnerDto;

  @ApiProperty({
    description: 'The folder for the product',
    required: true,
  })
  folder!: FolderDto;

  @ApiProperty({
    description: 'The project for the product',
    required: true,
  })
  project!: ProjectDto;
}

export const ProductOverviewSchema = generateClassValidationObject(ProductOverviewDto);
