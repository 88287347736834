import { useModalContext } from '@assemblio/frontend/components';
import { useSyncProfilesQuery } from '@assemblio/frontend/data-access';
import { ProductContentDto } from '@assemblio/shared/dtos';
import { FeatureFlag } from '@assemblio/type/feature-flag';
import { Box, Button, Divider, Group, Skeleton } from '@mantine/core';
import React from 'react';
import classes from './ActionBar.module.scss';
import { useProductActions } from './hooks/ProductActions.hook';
import { useOpenProduct, useHasFeatureFlag, useSyncProduct, useEditSyncProfile } from '../../../../hooks';
import { useUIStore } from '@assemblio/frontend/stores';
import { InstructionState, SyncProfileState } from '@assemblio/type/instruction';
import { useDebouncedValue } from '@mantine/hooks';
import { ActionBarButton } from './components/ActionBarButton';

interface ActionBarProps {
  product: ProductContentDto | undefined;
  isLoading?: boolean;
}

const HIDE_ACTION_BAR_STATES: InstructionState[] = [
  InstructionState.INITIALIZING_FAILED,
  InstructionState.PROCESSING_FAILED,
  InstructionState.UPLOADING,
  InstructionState.PROCESSING,
  InstructionState.INITIALIZING,
];

export const ActionBar = ({ isLoading = false, product }: ActionBarProps) => {
  const isViewer = useUIStore.getState().view === 'viewer';
  const variantsEnabled = useHasFeatureFlag(FeatureFlag.Variants);
  const { data: syncProfileData } = useSyncProfilesQuery(product?.id);
  const { openModal } = useModalContext();
  const [onOpenProduct] = useOpenProduct();
  const { syncProduct } = useSyncProduct();
  const { onUpdateSyncProfile } = useEditSyncProfile();
  const { productActions, isLoading: isMutationLoading } = useProductActions(product, syncProfileData);
  const showSyncButton = variantsEnabled && Boolean(syncProfileData?.some((profile) => profile.to.id === product?.id));

  const syncableProfiles = syncProfileData?.filter((profile) => profile.to.id === product?.id);

  const showResolveSyncButton =
    variantsEnabled && Boolean(syncableProfiles?.some((profile) => profile.state === SyncProfileState.UNRESOLVED));

  const [debouncedLoading] = useDebouncedValue(isMutationLoading || isLoading, 200);

  const handleOpenProduct = () => {
    if (!product) return;
    onOpenProduct(product, product.project.id);
  };

  const handleSync = () => {
    if (!product) return;
    openModal('execute-sync-modal', {
      productId: product.id,
      productName: product.name,
      syncableProfiles: syncableProfiles?.map((profile) => profile.from) ?? [],
      onSyncProduct: syncProduct,
    });
  };

  const handleResolveSyncIssues = () => {
    if (!product) return;
    const syncRelation = syncableProfiles?.find((profile) => profile.state === SyncProfileState.UNRESOLVED);
    if (!syncRelation) return;
    openModal('resolve-sync-issues-modal', {
      syncRelation,
      onUpdateSyncProfile,
    });
  };

  const showActionBar = () => {
    if (!product) return false;

    return !HIDE_ACTION_BAR_STATES.includes(product.state);
  };

  const SyncButtons = isViewer ? null : (
    <>
      {showSyncButton && !showResolveSyncButton && (
        <Button size={'compact-md'} onClick={handleSync} variant={'secondary'}>
          Sync product
        </Button>
      )}
      {showResolveSyncButton && (
        <Button size={'compact-md'} onClick={handleResolveSyncIssues} variant={'secondary'}>
          Resolve issues
        </Button>
      )}
    </>
  );

  if (!showActionBar()) return null;

  return (
    <Box className={classes.container} p="sm">
      <Group gap={'xs'}>
        {debouncedLoading ? (
          <ActionBarSkeleton />
        ) : (
          productActions.map((action) => <ActionBarButton action={action} key={`product-action-${action.label}`} />)
        )}
      </Group>
      <Group>
        {SyncButtons}
        <Button size={'compact-md'} onClick={handleOpenProduct} variant={'primary'}>
          Open Assembly Plan
        </Button>
      </Group>
    </Box>
  );
};

const ActionBarSkeleton = () => {
  return (
    <>
      <Skeleton h={'2rem'} w={'100px'} />
      <Divider orientation="vertical" size="xs" h="16" className={classes.verticalDivider} />
      <Skeleton h={'2rem'} w={'100px'} />
      <Divider orientation="vertical" size="xs" h="16" className={classes.verticalDivider} />
      <Skeleton h={'2rem'} w={'100px'} />
      <Divider orientation="vertical" size="xs" h="16" className={classes.verticalDivider} />
    </>
  );
};
