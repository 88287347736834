import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { ConfigService } from '@assemblio/frontend/stores';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

export function AuthWrapper({ children }: { children: ReactNode }) {
  const auth0Config = ConfigService.getAuthConfig();
  const navigate = useNavigate();
  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate((appState && appState.returnTo) || window.location.pathname, { replace: true });
  };
  if (!auth0Config.auth0) return children;
  return (
    <Auth0Provider
      domain={auth0Config.auth0.domain}
      clientId={auth0Config.auth0.clientId}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/login`,
        audience: auth0Config.auth0.audience,
      }}
    >
      {children}
    </Auth0Provider>
  );
}
