import { ApiProperty, generateClassValidationObject } from '@assemblio/shared/decorators';

export class SyncOverviewDto {
  @ApiProperty({
    description: 'Has this product been synced to a target?',
    required: true,
  })
  toTarget!: boolean;

  @ApiProperty({
    description: 'Has this product been synced from a source?',
    required: true,
  })
  fromSource!: boolean;
}

export const SyncOverviewSchema = generateClassValidationObject(SyncOverviewDto);
