import { useInstructionCreateSyncProfile } from '@assemblio/frontend/data-access';

export const useCreateSyncProfile = () => {
  const createSyncProfileMutation = useInstructionCreateSyncProfile();

  const handleCreateSyncProfile = (sourceId: string, targetId: string, projectId?: string) => {
    createSyncProfileMutation.mutate({
      from: sourceId,
      to: targetId,
      projectId,
    });
  };

  return {
    onCreateSyncProfile: handleCreateSyncProfile,
    isLoading: createSyncProfileMutation.isLoading,
  };
};
