import { UIController, useUIStore } from '@assemblio/frontend/stores';
import { ActionIcon, Tooltip } from '@mantine/core';
import { IconHammer, IconHammerOff } from '@tabler/icons-react';
import classes from './Viewport.module.scss';

export const ShowStepResourceButton = () => {
  const isViewer = useUIStore.getState().view === 'viewer';
  const showStepDrawer = useUIStore((state) => state.showStepDetails);
  const tooltipLabel = showStepDrawer ? 'Hide resources in viewport' : 'Show resources in viewport';
  if (!isViewer) return null;
  return (
    <Tooltip offset={8} position="bottom" label={tooltipLabel}>
      <ActionIcon
        style={{
          pointerEvents: 'auto',
        }}
        color={'text-secondary'}
        size="sm"
        className={classes.controls__icon}
        variant="transparent"
        onClick={() => UIController.setShowStepDetails(!showStepDrawer)}
      >
        {showStepDrawer ? <IconHammerOff /> : <IconHammer />}
      </ActionIcon>
    </Tooltip>
  );
};
