import { Button, Divider, Group, Skeleton, Stack, Text } from '@mantine/core';
import { fetchInstructionSyncReport, useInstructionSyncProfileQuery } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import saveAs from 'file-saver';
import { SyncProfilePairEditTable, UpdatedSyncPair, transformPairs } from '../../../components';
import { useDebouncedValue } from '@mantine/hooks';
import { SearchField } from '@assemblio/design-system';
import { SyncProfileDto } from '@assemblio/shared/dtos';
import { SyncProfileResolvedPair } from '@assemblio/shared/next-types';

interface EditVariantSyncProfileContentProps {
  sourceVariant: SyncProfileDto;
  targetVariant: SyncProfileDto;
  onUpdateSyncProfile: (
    sourceVariantId: string,
    targetVariantId: string,
    updatedPairs: SyncProfileResolvedPair
  ) => void;
  isLoading?: boolean;
  isError?: boolean;
  onClose: () => void;
}

export const EditSyncProfile = ({
  sourceVariant,
  targetVariant,
  onUpdateSyncProfile,
  isError = false,
  onClose,
}: EditVariantSyncProfileContentProps) => {
  const { data, isLoading, refetch } = useInstructionSyncProfileQuery({
    sourceId: sourceVariant.id,
    targetId: targetVariant.id,
  });

  const [updatedPairs, setUpdatedPairs] = useState<UpdatedSyncPair[] | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200);

  const similarPartSum = (data?.pairs.resolved.keep.length ?? 0) + (data?.pairs.resolved.replace.length ?? 0);

  const deprecatedPartSum = data?.parts.deprecated.length ? data.parts.deprecated.length + 1 : 0;

  const replacePartSum = data?.pairs.replace.length ? data.pairs.replace.length + 1 : 0;

  const updateSyncProfilePairs = () => {
    if (!updatedPairs) return;
    onUpdateSyncProfile(sourceVariant.id, targetVariant.id, {
      keep: updatedPairs.filter((pair) => pair.state === 'Keep').map((pair) => pair.pair),
      replace: updatedPairs.filter((pair) => pair.state === 'Replace').map((pair) => pair.pair),
    });
  };

  const handleCSVReportDownload = () => {
    fetchInstructionSyncReport({ sourceId: sourceVariant.id, targetId: targetVariant.id })
      .then((data) => {
        if (data === null) {
          notifications.show({
            id: 'sync-report-error',
            message: 'Could not generate sync report',
            color: 'red',
          });
          return;
        }
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'sync-report.csv');
      })
      .catch((_error) => {
        notifications.show({
          id: 'sync-report-error',
          message: 'Could not generate sync report',
          color: 'red',
        });
      });
  };

  const handleClose = () => {
    setUpdatedPairs(undefined);
    onClose();
  };

  useEffect(() => {
    if (updatedPairs) return;
    if (isLoading) return;
    if (!updatedPairs) {
      setUpdatedPairs(transformPairs(data?.pairs));
    }
  }, [data?.pairs, updatedPairs]);

  useEffect(() => {
    setUpdatedPairs(undefined);
    refetch();
  }, [targetVariant]);

  return (
    <Stack gap="lg" mb={'md'}>
      <Stack px={'lg'} gap="md">
        {isLoading ? (
          <Stack>
            <Skeleton w={'70%'} h={'24px'} />
            <Skeleton w={'70%'} h={'24px'} />
            <Skeleton w={'70%'} h={'24px'} />
          </Stack>
        ) : (
          <Stack gap={'md'}>
            <Text>
              <Text span fw={'bold'}>
                {replacePartSum}&nbsp;
                {replacePartSum === 1 ? 'Part' : 'Parts'}&nbsp;
              </Text>
              {replacePartSum === 1 ? 'has' : 'have'}
              &nbsp;been recognized as identical.
            </Text>{' '}
            <Text>
              <Text span fw={'bold'}>
                {similarPartSum}&nbsp;
                {similarPartSum === 1 ? 'Part' : 'Parts'}&nbsp;
              </Text>
              {similarPartSum === 1 ? 'has' : 'have'}
              &nbsp;been recognized as similar.
            </Text>
            <Text>
              <Text span fw={'bold'}>
                {deprecatedPartSum}&nbsp;
                {deprecatedPartSum === 1 ? 'Part' : 'Parts'}&nbsp;
              </Text>
              {deprecatedPartSum === 1 ? 'has' : 'have'}
              &nbsp;been recognized as deprecated.
            </Text>
            {similarPartSum > 0 && <Text>Choose how similar parts should be handled for future synchronizations:</Text>}
          </Stack>
        )}
      </Stack>
      <Divider />

      {similarPartSum === 0 ? null : (
        <Stack gap="lg" px={'lg'}>
          <SearchField
            placeholder={'Search'}
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.currentTarget.value)}
          />

          <SyncProfilePairEditTable
            onChange={(updatedPairs) => setUpdatedPairs(updatedPairs)}
            pairs={updatedPairs}
            searchString={debouncedSearchTerm}
          />
        </Stack>
      )}
      <Group px={'lg'} justify="space-between" pr={'md'}>
        <Button disabled={!updatedPairs || isError} onClick={handleCSVReportDownload} variant={'ghost'} size={'xs'}>
          Download CSV report
        </Button>
        <Group>
          <Button variant={'secondary'} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant={'primary'}
            disabled={!updatedPairs || isError || similarPartSum === 0}
            onClick={updateSyncProfilePairs}
          >
            Update
          </Button>
        </Group>
      </Group>
    </Stack>
  );
};
