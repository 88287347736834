import { Text, UnstyledButton, Group } from '@mantine/core';
import { IconChevronRight, IconFolderFilled } from '@tabler/icons-react';
import classes from '../../../../ExplorerFolderListView.module.scss';
import { FolderOverviewDto } from '@assemblio/shared/dtos';

interface FolderListItemProps {
  folder: FolderOverviewDto;
  onFolderNavigation: (targetId?: string) => unknown;
}

export const ExplorerFolderListItem = ({ folder, onFolderNavigation }: FolderListItemProps) => {
  return (
    <UnstyledButton
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onFolderNavigation(folder.id);
      }}
      className={classes.listItem}
      px={'lg'}
      py={'sm'}
      data-cy="folderListItem"
    >
      <Group justify={'space-between'}>
        <Group>
          <IconFolderFilled size={16} />
          <Text variant={'medium'} size={'sm'} data-cy="folderName">
            {folder.name}
          </Text>
        </Group>
        <IconChevronRight size={12} />
      </Group>
    </UnstyledButton>
  );
};
