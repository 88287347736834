import { VectorDto, VectorSchema } from '@assemblio/shared/dtos';
import { generateClassValidationObject, joiSchemaProperty, ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class PartMetaDataDto {
  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'Color', required: true, type: Number })
  color!: number;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'material', required: true })
  material!: string;

  @joiSchemaProperty(Joi.array().items(Joi.string()).required())
  @ApiProperty({ description: 'comments', required: true, type: [String] })
  comments!: string[];

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Part', required: true })
  normPart!: string;

  @joiSchemaProperty(VectorSchema.required())
  @ApiProperty({ description: 'Center of mass', required: true, type: VectorDto })
  centerOfMass!: VectorDto;

  @joiSchemaProperty(VectorSchema.required())
  @ApiProperty({ description: 'Coordinate system', required: true, type: VectorDto })
  coordinateSystem!: VectorDto;
}
export const PartMetaDataSchema = generateClassValidationObject(PartMetaDataDto);

export class DisassemblyDirectionDto {
  @joiSchemaProperty(Joi.array().items(VectorSchema).required())
  @ApiProperty({ description: 'Directions', required: true, type: [VectorDto] })
  directions!: VectorDto[];

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'Solid index', required: true, type: Number })
  solidIndex!: number;

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'Preferred direction', required: true, type: Number })
  preferredDirection!: number;
}
export const DisassemblyDirectionSchema = generateClassValidationObject(DisassemblyDirectionDto);

export class PartDto {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'Part id', required: true })
  id!: string;

  @joiSchemaProperty(Joi.string())
  @ApiProperty({ description: 'Part name', required: false })
  name!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Part original name', required: true })
  originalName!: string;

  @joiSchemaProperty(Joi.string())
  @ApiProperty({ description: 'Color', required: false })
  color!: string;

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'GLTF index', required: true, type: Number })
  gltfIndex!: number;

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'Solid index', required: true, type: Number })
  solidIndex!: number;

  @joiSchemaProperty(PartMetaDataSchema.required())
  @ApiProperty({ description: 'metaData', required: true, type: PartMetaDataDto })
  metaData!: PartMetaDataDto;

  @joiSchemaProperty(Joi.array().items(DisassemblyDirectionSchema))
  @ApiProperty({ description: 'metaData', required: false, type: [DisassemblyDirectionDto] })
  disassemblyDirections?: DisassemblyDirectionDto[];
}

export const PartSchema = generateClassValidationObject(PartDto);
