import classes from './ResourceNameDisplay.module.scss';
import { SimpleGrid, Text, Tooltip } from '@mantine/core';
import cx from 'clsx';

interface ResourceNameDisplayProps {
  name: string;
  identifier?: string;
  unit?: string;
}

export const ResourceNameDisplay = ({ name, identifier, unit }: ResourceNameDisplayProps) => {
  const isCompact = !!unit;
  return (
    <SimpleGrid
      className={cx(classes.grid_root, {
        [classes.grid_root__compact]: isCompact,
      })}
    >
      <div>
        {identifier && (
          <Tooltip openDelay={250} label={identifier}>
            <Text size={'sm'} lineClamp={1} c="text-secondary">
              {identifier}
            </Text>
          </Tooltip>
        )}
      </div>
      <div>
        <Tooltip openDelay={250} label={name}>
          <Text lineClamp={1}>{name} </Text>
        </Tooltip>
      </div>
      {isCompact && (
        <div>
          <Text size={'sm'} c={'text-secondary'}>
            {unit}
          </Text>
        </div>
      )}
    </SimpleGrid>
  );
};
