import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { ReviewStatus } from '@assemblio/type/revision';

export class QueryRevisionDto {
  @joiSchemaProperty(Joi.string().uuid().optional())
  @ApiProperty({ description: 'Instruction Id', required: false })
  instructionId?: string;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(ReviewStatus))
      .optional(),
  )
  @ApiProperty({
    description: 'Review Status',
    enum: ReviewStatus,
    required: false,
  })
  state?: ReviewStatus;

  @joiSchemaProperty(Joi.number().min(0).optional())
  @ApiProperty({
    description: 'Page no for pagination, used only with "itemsPerPage"',
    type: Number,
    required: false,
  })
  page?: number;

  @joiSchemaProperty(Joi.number().min(1).optional())
  @ApiProperty({
    description: 'Number of items per page for pagination',
    type: Number,
    required: false,
  })
  itemsPerPage?: number;
}

export const QueryRevisionsSchema = generateClassValidationObject(QueryRevisionDto);
