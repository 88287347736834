import { IconFileOpen } from '@assemblio/design-system';
import { Stack, Text } from '@mantine/core';

export const EmptyResourceProductView = () => {
  return (
    <Stack mih={500} align="center" justify="center" gap={'xl'}>
      <IconFileOpen />
      <Text size="lg" variant={'medium'} c={'text-secondary'}>
        This resource is not used by any products.
      </Text>
    </Stack>
  );
};
