import { Box, Group, Image } from '@mantine/core';
import classes from './DetailsTabContent.module.scss';
import { ProductInformationTable } from './components/ProductInformationTable/ProductInformationTable';
import { ProductContentDto } from '@assemblio/shared/dtos';
import { InstructionImageSize } from '@assemblio/type/instruction';
import { useProductImage } from '../../../../../../hooks';

interface DetailsTabContentProps {
  product: ProductContentDto | undefined;
  isLoading?: boolean;
}

export const DetailsTabContent = ({ product, isLoading }: DetailsTabContentProps) => {
  const { imageSrc } = useProductImage(product?.id, InstructionImageSize.LARGE);

  return (
    <Group px={'xl'} align="flex-start" h={'100%'} data-cy="detailsPage">
      <Box className={classes.detailsImage}>
        <Image src={imageSrc} />
      </Box>
      <ProductInformationTable product={product} isLoading={isLoading} />
    </Group>
  );
};
