import { StepResourceDto, StepResourceValueDto } from '@assemblio/shared/dtos';
import { ActionIcon, Group, NumberInput, Select, TextInput, Tooltip } from '@mantine/core';
import classes from './StepResource.module.scss';
import { IconDeviceFloppy, IconInfoCircle, IconTrashFilled } from '@tabler/icons-react';
import { useState } from 'react';
import produce from 'immer';
import { OperationalResourceUnits } from '@assemblio/type/resources';
import { useUpdateStepResourceMutation } from '@assemblio/frontend/data-access';
import { useProjectStore, useUIStore } from '@assemblio/frontend/stores';
import { ResourceNameDisplay } from './components/ResourceNameDisplay/ResourceNameDisplay';
import { notifications } from '@mantine/notifications';

interface StepResourceProps {
  relation: StepResourceDto;
  onDelete: (id: string) => void;
}

export const StepResource = ({ relation, onDelete }: StepResourceProps) => {
  const productId = useProjectStore((state) => state.instructionId);
  const stepId = useUIStore((state) => state.selectedStep?.id);

  const [resourceValue, setResourceValue] = useState<StepResourceValueDto>({
    amount: relation.value.amount ?? 0,
    unit: relation.value.unit ?? relation.resource.allowedUnits?.[0],
    info: relation.value.info,
  });

  const updateMutation = useUpdateStepResourceMutation();

  const updateLocalUnit = (newUnit: string | null) => {
    const unit = Object.values(OperationalResourceUnits).find((unit) => unit === newUnit);
    if (!unit) return;
    setResourceValue(
      produce(resourceValue, (draft) => {
        draft.unit = unit;
      })
    );
  };

  const updateLocalAmount = (newAmount: number | string) => {
    setResourceValue(
      produce(resourceValue, (draft) => {
        draft.amount = Number(newAmount);
      })
    );
  };

  const updateLocalInformationText = (newInfo: string | null) => {
    setResourceValue(
      produce(resourceValue, (draft) => {
        draft.info = newInfo ?? undefined;
      })
    );
  };

  const handleUpdateStepResource = () => {
    if (!stepId) return;
    updateMutation.mutate(
      {
        stepId: stepId,
        data: {
          id: relation.id,
          productId,
          value: resourceValue,
        },
      },
      {
        onSuccess: () => {
          notifications.show({ message: 'Resource updated' });
        },
        onError: () => {
          notifications.show({ message: 'Resource could not be updated' });
        },
      }
    );
  };

  return (
    <Group className={classes.wrapper} justify={'space-between'} p={'sm'}>
      <Group justify="start">
        <Group className={classes.resource_header} wrap={'nowrap'} w={'100%'} gap={'sm'}>
          <Tooltip
            multiline
            w={220}
            withArrow
            transitionProps={{ duration: 200 }}
            label={relation.resource.text}
            position="left"
          >
            <ActionIcon w={'10%'} size={'sm'} variant={'ghost'}>
              <IconInfoCircle />
            </ActionIcon>
          </Tooltip>

          <ResourceNameDisplay name={relation.resource.name} identifier={relation.resource.resourceNo} />

          <ActionIcon ml={'auto'} size={'xs'} variant={'ghost'} onClick={() => onDelete(relation.id)}>
            <IconTrashFilled />
          </ActionIcon>
        </Group>
        <Group wrap={'nowrap'}>
          <NumberInput
            min={0}
            style={{ flexGrow: 1 }}
            size={'sm'}
            value={resourceValue.amount}
            onChange={updateLocalAmount}
          />
          <Select
            w={'35%'}
            ml={'auto'}
            size={'sm'}
            value={resourceValue.unit}
            onChange={updateLocalUnit}
            data={relation.resource.allowedUnits}
          />
          <ActionIcon onClick={() => handleUpdateStepResource()} size={'xs'} variant={'ghost'}>
            <IconDeviceFloppy />
          </ActionIcon>
        </Group>
        {/* Disabled until info text is reflected in exports*/}
        {/*<TextInput*/}
        {/*  size={'sm'}*/}
        {/*  w={'100%'}*/}
        {/*  value={resourceValue.info}*/}
        {/*  onChange={(e) => updateLocalInformationText(e.currentTarget.value)}*/}
        {/*  placeholder={'Enter additional information...'}*/}
        {/*/>*/}
      </Group>
    </Group>
  );
};
