import { PartColor } from '@assemblio/frontend/types';
import { Step, StepData } from '@assemblio/type/step';
import { PivotTransform, Transform } from '@assemblio/type/3d';
import { Assembly, AssemblyMetaData, Part } from '@assemblio/type/input';
import produce from 'immer';
import _ from 'lodash';
import { Box3, Group, Object3D, Quaternion, Sphere, Vector3 } from 'three';
import { GLTF } from 'three-stdlib';
import { StepController, UIController } from '..';
import { dataToQuaternion, dataToVector3, matrixToTransform, transformToMatrix } from '../../helper';
import { useProjectStore, useUIStore } from '../../stores';
import { Model, ModelInformation, ModelInformationMap, useModelStore } from '../../stores/ModelStore';
import { AssemblyInformation, PartInformation } from '../../stores/types/model.types';
import { ImperativeModelController } from '../ImperativeModelController';
import { MachineController } from '../MachineController';
import { ProjectController } from '../ProjectController';
import { SequenceController } from '../SequenceController';
import { isPartExcluded } from './ModelExcludeController';

export const setModel = (model: GLTF, assemblyData: AssemblyMetaData): void => {
  const initialBounds = new Box3().setFromObject(model.scene).getBoundingSphere(new Sphere());

  const yOffset = getYOffset(model.scene);

  useModelStore.setState(
    produce<Model>(() => ({
      model,
      modelInformationMap: initModelInformationMap(model, assemblyData),
      modelInformationMapCache: new Map<number, ModelInformation>(),
      initialBounds,
      yOffset: yOffset !== undefined ? yOffset : 0.0,
    }))
  );

  useModelStore.getState().modelInformationMap.forEach((modelInformation, gltfIndex) => {
    if (modelInformation.excluded && isPartInformation(modelInformation)) {
      ImperativeModelController.excludeModel(gltfIndex);
    }
  });
};

export const setModelGltfIndex = (gltfIndex: number, model: Object3D): void => {
  ImperativeModelController.setModel(gltfIndex, model);
};

export const getModelByGltfIndex = (gltfIndex: number): Object3D | undefined => {
  return ImperativeModelController.getModel(gltfIndex);
};

export type PartColorReference = {
  partId: string;
  gltfIndex: number;
  color: number;
};

export const getColorByGltfIndex = (gltfIndices: Array<number>): Array<PartColorReference> => {
  const modelInformationMap = useModelStore.getState().modelInformationMap;
  return gltfIndices
    .map((gltfIndex) => {
      const modelInformation = modelInformationMap.get(gltfIndex);
      if (modelInformation) {
        const partInformation = modelInformation as PartInformation;
        return {
          partId: partInformation.id,
          gltfIndex,
          color: partInformation.color,
        };
      }
      return null;
    })
    .filter((item) => item !== null) as Array<PartColorReference>;
};

export const setRandomColorToParts = (gltfIndices: Array<number>, colorSet: Array<PartColor>): void => {
  const colors = gltfIndices.map((gltfIndex) => {
    const rnd = Math.floor(Math.random() * colorSet.length);
    const chosenColor = colorSet[rnd].code.dec;
    return {
      gltfIndex,
      color: chosenColor,
      partId: ProjectController.getPartByGltfIndex(gltfIndex)?.id,
    };
  });
  setColorOfParts(colors);
};

export const setSelectedPartsToUserDefinedColors = (): void => {
  const selectedParts = useUIStore.getState().selectedPartSet;
  selectedParts.forEach((gltfIndex) => {
    const partInformation = useModelStore.getState().modelInformationMap.get(gltfIndex) as PartInformation;
    if (partInformation) {
      ImperativeModelController.setColor(gltfIndex, partInformation.color);
    }
  });
};

export const resetColorOfSelectedParts = (): void => {
  const selectedParts = useUIStore.getState().selectedPartSet;
  const colors = Array.from(selectedParts)
    .map((gltfIndex) => {
      const part = ProjectController.getPartByGltfIndex(gltfIndex);
      if (part) {
        return { partId: part.id, gltfIndex, color: part.metaData.color };
      }
      return null;
    })
    .filter((element) => element !== null) as Array<PartColorReference>;
  setColorOfParts(colors);
};

export const resetNames = (): {
  resetedParts: string[];
  resetedAssemblies: string[];
} => {
  const { parts, assemblies } = useProjectStore.getState().input;

  const resetedParts = parts
    .filter((part) => setPartOrAssemblyName(part.gltfIndex, part.originalName))
    .map((part) => part.id);

  const resetedAssemblies = assemblies
    .filter((assembly) => setPartOrAssemblyName(assembly.gltfIndex, assembly.originalName))
    .map((assembly) => assembly.id);

  return { resetedParts, resetedAssemblies };
};

export const getPartAndAssemblyNames = (): string[] => {
  const parts = useProjectStore.getState().input.parts;
  const assemblies = useProjectStore.getState().input.assemblies;
  const partNames = parts.map((part) => {
    return getPartNameOverride(part);
  });

  const assemblyNames = assemblies.map((assembly) => {
    return getAssemblyNameOverride(assembly);
  });

  return [...assemblyNames.reverse(), ...partNames];
};

export const setPartOrAssemblyNames = (
  partsOrAssemblies: { gltfIndex: number; name: string }[]
): {
  renamedParts: { id: string; name: string }[];
  renamedAssemblies: { id: string; name: string }[];
} => {
  const renamedParts: { id: string; name: string }[] = [];
  const renamedAssemblies: { id: string; name: string }[] = [];

  const modelInformationMap = useModelStore.getState().modelInformationMap;

  for (const { gltfIndex, name } of partsOrAssemblies) {
    const wasRenamed = setPartOrAssemblyName(gltfIndex, name);
    if (!wasRenamed) continue;

    const modelInformation = modelInformationMap.get(gltfIndex);
    if (!modelInformation) continue;

    const { id } = modelInformation;
    if (isPartInformation(modelInformation)) {
      renamedParts.push({ id, name });
    } else {
      renamedAssemblies.push({ id, name });
    }
  }

  return {
    renamedParts,
    renamedAssemblies,
  };
};

export const setPartOrAssemblyName = (gltfIndex: number, name: string): boolean => {
  if (!name) return false;

  const originalModelInformation = useModelStore.getState().modelInformationMap.get(gltfIndex);
  if (!originalModelInformation || originalModelInformation.name === name) {
    return false;
  }

  const modelInformation = _.clone(originalModelInformation);
  modelInformation.name = name;

  useModelStore.setState(
    produce<Model>((state) => {
      state.modelInformationMap.set(gltfIndex, modelInformation);
    })
  );

  return true;
};

export const getPartsByCurrentName = (name: string): Part[] => {
  // Search in the modelInformationMap for current overridden names
  const foundEntries = Array.from(useModelStore.getState().modelInformationMap.entries()).filter(
    ([_key, value]) => isPartInformation(value) && value.name === name
  );
  // Get parts by GltfIndex and filter out undefined entries
  const parts = foundEntries
    .map((entry) => ProjectController.getPartByGltfIndex(entry[0]))
    .filter((item): item is Part => item !== undefined);

  return parts;
};

export const getAssembliesByCurrentName = (name: string): Assembly[] => {
  // Search in the modelInformationMap for current overridden names
  const foundEntries = Array.from(useModelStore.getState().modelInformationMap.entries()).filter(
    ([_key, value]) => !isPartInformation(value) && value.name === name
  );

  // Get assemblies by GltfIndex and filter out undefined entries
  const assemblies = foundEntries
    .map((entry) => ProjectController.getAssemblyByGltfIndex(entry[0]))
    .filter((item): item is Assembly => item !== undefined);

  return assemblies;
};

export const getPartNameOverrideByGltfIndex = (partGltfIndex: number): string => {
  let partName = useModelStore.getState().modelInformationMap.get(partGltfIndex)?.name;

  if (partName === undefined) {
    partName = ProjectController.getPartByGltfIndex(partGltfIndex)?.name;
  }

  return partName ? partName : 'undefined';
};

/**
 * Try to get Name Override for a Part. If undefined returns actual part name
 */
export const getPartNameOverride = (part: Part): string => {
  const overrideName = useModelStore.getState().modelInformationMap.get(part.gltfIndex)?.name;
  return overrideName ? overrideName : part.name;
};

/**
 * Try to get Name Override for an Assembly. If undefined returns actual part name
 */
export const getAssemblyNameOverride = (assembly: Assembly): string => {
  const overrideName = useModelStore.getState().modelInformationMap.get(assembly.gltfIndex)?.name;
  return overrideName ? overrideName : assembly.name;
};

export const isAssemblySelected = (assembly: Assembly): boolean => {
  const isAssemblyExcluded = useModelStore.getState().modelInformationMap.get(assembly.gltfIndex)?.excluded;
  if (isAssemblyExcluded) return false;

  const children = ProjectController.getPartsGltfIndicesOfAssemblyByGltfIndex(assembly.gltfIndex, true);
  return children.every((gltfIndex) => isPartExcluded(gltfIndex) || UIController.isPartSelected(gltfIndex));
};

// Find later step of given part and set it to this position
// If non was found set it to assembled position
export const movePartToStepState = (gltfIndex: number) => {
  const selectedStep = StepController.getSelectedStep();
  if (selectedStep) {
    const previousStep =
      selectedStep.data.parts.find((part) => part.partGltfIndex === gltfIndex) &&
      StepController.getPreviousStepForGltfIndexInAssemblyOrder(gltfIndex, selectedStep.id);
    if (previousStep) {
      movePartToStepTransform(gltfIndex, previousStep);
    } else {
      movePartByProgress(gltfIndex, 'assembled');
    }
  }
};

export const movePartByProgress = (gltfIndex: number, progress: 'assembled' | 'disassembled') => {
  if (progress === 'assembled') {
    const transform = getInitialTransformByGltfIndex(gltfIndex);
    movePartToTransform(gltfIndex, {
      ...transform,
    });
  } else {
    const lastStep = StepController.getFirstStepForGltfIndexInAssemblyOrder(gltfIndex);
    if (lastStep) movePartToStepTransform(gltfIndex, lastStep, true);
  }
};

export const movePartToStepTransform = (gltfIndex: number, step: Step, end = true) => {
  const partInStep = step.data.parts.find((part) => part.partGltfIndex === gltfIndex);
  if (partInStep) {
    movePartToTransform(gltfIndex, end ? partInStep.end : partInStep.start);
  }
};

export const movePartToTransform = (gltfIndex: number, transform: Transform) => {
  const model = ImperativeModelController.getModel(gltfIndex);
  if (model) {
    const translation = dataToVector3(transform.position);
    const rotation = dataToQuaternion(transform.rotation);
    model.position.copy(translation);
    model.quaternion.copy(rotation);
  }
};

export const selectNextStepByGltfIndex = (gltfIndex: number) => {
  const step = StepController.getSelectedStep();
  let nextStep: Step | undefined;
  if (step && step.data.parts.some((part) => part.partGltfIndex === gltfIndex)) {
    nextStep = StepController.getPreviousStepForGltfIndexInDisassemblyOrder(gltfIndex, step.id);
  }
  if (!nextStep) nextStep = StepController.getFirstStepForGltfIndexInDisassemblyOrder(gltfIndex);
  if (nextStep) MachineController.selectStep(nextStep);
};

export const reset = () => {
  useModelStore.getState().reset();
};

const getYOffset = (scene: Group): number => {
  const boundingBox = new Box3();
  boundingBox.expandByObject(scene);
  const min = boundingBox.min;
  return min.y;
};

const getInitialTransformByGltfIndex = (gltfIndex: number): PivotTransform => {
  const partInformation = useModelStore.getState().modelInformationMap.get(gltfIndex) as PartInformation;
  return partInformation
    ? partInformation.initialTransform
    : {
        position: new Vector3(),
        rotation: new Quaternion(),
        pivot: new Vector3(),
      };
};

export const setUniformColorOfParts = (gltfIndices: Array<number>, color: number) => {
  useModelStore.setState(
    produce<Model>((state) => {
      const modelInformationMap = state.modelInformationMap;
      gltfIndices.forEach((gltfIndex) => {
        const modelInformation = modelInformationMap.get(gltfIndex);
        if (modelInformation) {
          const partInformation = modelInformation as PartInformation;
          partInformation.color = color;
          ImperativeModelController.setColor(gltfIndex, color);
        }
      });
    })
  );
};

export const setColorOfParts = (partColors: Array<{ color: number; gltfIndex: number }>) => {
  useModelStore.setState(
    produce<Model>((state) => {
      const modelInformationMap = state.modelInformationMap;
      partColors.forEach(({ gltfIndex, color }) => {
        const modelInformation = modelInformationMap.get(gltfIndex);
        if (modelInformation) {
          const partInformation = modelInformation as PartInformation;
          partInformation.color = color;
          ImperativeModelController.setColor(gltfIndex, color);
        }
      });
    })
  );
};

const setColorOfPart = (gltfIndex: number, color: number): { partId: string; oldColor: number } | null => {
  const partInformation = _.clone(useModelStore.getState().modelInformationMap.get(gltfIndex) as PartInformation);

  if (partInformation) {
    if (partInformation.color === color) return null;
    const prevColor = partInformation.color;
    partInformation.color = color;
    useModelStore.setState(
      produce<Model>((state) => {
        state.modelInformationMap.set(gltfIndex, partInformation);
      })
    );
    ImperativeModelController.setColor(gltfIndex, color);

    return { partId: partInformation.id, oldColor: prevColor };
  }

  return null;
};

const resetColorOfPart = (gltfIndex: number): { partId: string; oldColor: number } | null => {
  const part = ProjectController.getPartByGltfIndex(gltfIndex);
  if (part) {
    const partIdAndColor = setColorOfPart(gltfIndex, part.metaData.color);
    if (partIdAndColor) return partIdAndColor;
  }
  return null;
};

const initModelInformationMap = (model: GLTF, assemblyData: AssemblyMetaData): Map<number, ModelInformation> => {
  const excludedParts = SequenceController.getCurrentSequence().excludedParts;

  const partInformations = assemblyData.parts.reduce<ModelInformationMap>((modelInformationMap, part) => {
    const translationArray = model.parser.json['nodes'][part.gltfIndex].translation;
    const rotationArray = model.parser.json['nodes'][part.gltfIndex].rotation;
    const translation = translationArray ? new Vector3().fromArray(translationArray) : new Vector3();
    const rotation = rotationArray ? new Quaternion().fromArray(rotationArray) : new Quaternion();
    const pivot = new Vector3();
    const color = +part.color || part.metaData.color;
    const name = part.name || part.originalName;
    const excluded = excludedParts.includes(part.gltfIndex);
    const locked = false;

    modelInformationMap.set(part.gltfIndex, {
      type: 'part',
      id: part.id,
      visible: !excluded,
      transparent: false,
      color,
      initialTransform: {
        position: translation,
        rotation,
        pivot,
      },
      name,
      excluded,
      locked,
    } as PartInformation);
    ImperativeModelController.setColor(part.gltfIndex, color);
    return modelInformationMap;
  }, new Map<number, ModelInformation>());

  const partGroupToPartsMap = generateGltfIndexMap(assemblyData);

  const assemblyInformation = assemblyData.assemblies.reduce<ModelInformationMap>((modelInformationMap, assembly) => {
    const name = assembly.name || assembly.originalName;

    const excluded = partGroupToPartsMap.get(assembly.gltfIndex)!.every((index) => excludedParts.includes(index));
    const locked = false;

    modelInformationMap.set(assembly.gltfIndex, {
      type: 'assembly',
      id: assembly.id,
      name,
      excluded,
      locked,
    } as AssemblyInformation);
    return modelInformationMap;
  }, new Map<number, ModelInformation>());

  return new Map([...partInformations, ...assemblyInformation]);
};

const generateGltfIndexMap = (assemblyData: AssemblyMetaData): Map<number, number[]> => {
  const { parts, assemblies: partGroups } = assemblyData;
  const partGroupMap = new Map<string, Assembly>();
  const result = new Map<number, number[]>();

  // Create a map of partGroup by id for quick access
  partGroups.forEach((pg) => {
    partGroupMap.set(pg.id, pg);
  });

  function collectParts(pg: Assembly, collectedParts: number[]): void {
    pg.parts.forEach((partIndex) => {
      const part = parts[partIndex];
      if (part) {
        collectedParts.push(part.gltfIndex);
      }
    });

    pg.assemblies.forEach((assemblyIndex) => {
      const childGroup = partGroupMap.get(partGroups[assemblyIndex].id);
      if (childGroup) {
        collectParts(childGroup, collectedParts);
      }
    });
  }

  partGroups.forEach((pg) => {
    const collectedParts: number[] = [];
    collectParts(pg, collectedParts);
    result.set(pg.gltfIndex, collectedParts);
  });

  return result;
};

export const isPartInformation = (modelInformation: ModelInformation): modelInformation is PartInformation => {
  return (
    (modelInformation as PartInformation).visible !== undefined &&
    (modelInformation as PartInformation).transparent !== undefined &&
    (modelInformation as PartInformation).color !== undefined &&
    (modelInformation as PartInformation).initialTransform !== undefined
  );
};

/*
  Sets the given step parts to a given end position
  Calculated end positions are returned as well
*/
export const movePartsToStepSegment = (segmentIndex: number, stepData: StepData) => {
  const transform = stepData.path.at(segmentIndex);
  if (!transform) return;

  const transformMatrix = transformToMatrix(transform);

  const newParts = stepData.parts.map((part) => {
    const firstPathElement = stepData.path.at(0);
    if (!firstPathElement) return part;
    const offsetMatrix = StepController.getPivotOffset(firstPathElement, part.start);
    const model = getModelByGltfIndex(part.partGltfIndex);
    if (!offsetMatrix || !model) return part;

    transformMatrix.clone().multiply(offsetMatrix).decompose(model.position, model.quaternion, model.scale);

    model.updateMatrix();

    return {
      ...part,
      end: matrixToTransform(model.matrix),
    };
  });

  return newParts;
};
