import { useInstructionSetFavorite, useProductByResourceQuery } from '@assemblio/frontend/data-access';
import { ProductOverviewDto } from '@assemblio/shared/dtos';
import { Group, ScrollArea, Stack, Text, Title } from '@mantine/core';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductTable } from '../../components/ProductsDisplay/components/ProductTable';
import { EmptyResourceProductView } from '../../components/ResourcesDisplay/components/EmptyResourceProductView';
import { useExplorerTitle } from '../../hooks';
import { useExplorerRouteParams } from '../../hooks/ExplorerRouteParams.hook';
import { BreadcrumbItemType } from '../../types';
import { ResourceHeader } from './ResourceHeader';

export const OperationalResource = () => {
  const navigate = useNavigate();
  const { resourceId } = useExplorerRouteParams();

  const { data, isLoading } = useProductByResourceQuery({
    resourceId,
  });

  const products = data?.products ?? [];

  const { projectId } = useExplorerRouteParams();

  const setFavoriteMutation = useInstructionSetFavorite();

  const handleOpenDetails = (id: string) => {
    navigate(`/explorer/product/${id}`);
  };

  const handleOpenProduct = (product: ProductOverviewDto) => {
    navigate(`/disassembler/${product.id}`);
  };

  const handleToggleFavorite = (id: string, isFavorite: boolean) => {
    setFavoriteMutation.mutate({
      productId: id,
      projectId,
      action: isFavorite ? 'remove' : 'add',
      invalidateQueryKey: ['resource-products', resourceId],
    });
  };

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: 'Resources',
        navigationTarget: '/explorer/resources',
      },
      {
        name: data?.name.toUpperCase() ?? 'Operational Resource',
        navigationTarget: `/explorer/resources/${resourceId}`,
      },
    ] as [BreadcrumbItemType, BreadcrumbItemType]; // Ensure it's always a tuple
  }, [data, resourceId]);

  const rightSection = useMemo(
    () => (
      <Group>
        <Text c="dimmed" fw={700}>
          #
        </Text>
        <Text tt="uppercase" fw={700}>
          {data?.resourceNo}
        </Text>
      </Group>
    ),
    [data]
  );

  const bottomSection = useMemo(() => <ResourceHeader data={data} />, [data]);

  useExplorerTitle(data?.name.toUpperCase() ?? 'Operational Resource', {
    title: {
      itemCount: products.length,
    },
    breadcrumbs,
    rightSection,
    bottomSection,
  });

  return (
    <>
      <Stack m={15} gap={'xl'} h={'100%'}>
        <Group>
          <Title order={2}>Products with this Resource:</Title>
        </Group>
        <ScrollArea scrollbars={'y'} type={'hover'} mah={'100%'} h={'100%'}>
          <ProductTable
            products={products}
            onOpenDetails={handleOpenDetails}
            onOpenProduct={handleOpenProduct}
            onToggleFavorite={handleToggleFavorite}
            isLoading={isLoading}
            emptyView={<EmptyResourceProductView />}
          />
        </ScrollArea>
      </Stack>
      );
    </>
  );
};
