import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { StepCameraSettingsDto, StepCameraSettingsSchema, StepDataDto, StepDataSchema } from './step-props.dto';
import { Step } from '@assemblio/type/step';

export class CreateStepDto {
  constructor(step: Step, prevStepId: string | null) {
    this.id = step.id;
    this.name = step.name;
    this.playWithAbove = step.playWithAbove;
    this.stepGroupId = step.stepGroupId;
    this.previousStepId = prevStepId;
    this.cameraSettings = step.cameraSettings;
    this.data = step.data;
  }
  @ApiProperty({ description: 'Id', required: false })
  @joiSchemaProperty(Joi.string().uuid())
  id?: string;

  @ApiProperty({ description: 'Group Id', required: true })
  @joiSchemaProperty(Joi.string().uuid().required())
  stepGroupId!: string;

  @ApiProperty({ description: 'Name', required: true })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({ description: 'Prev step id', required: false })
  @joiSchemaProperty(Joi.string().uuid().allow(null))
  previousStepId?: string | null;

  @ApiProperty({ description: 'camera settings', required: false, type: StepCameraSettingsDto })
  @joiSchemaProperty(StepCameraSettingsSchema.required())
  cameraSettings!: StepCameraSettingsDto;

  @ApiProperty({ description: 'The step data', required: false, type: StepDataDto })
  @joiSchemaProperty(StepDataSchema.required())
  data!: StepDataDto;

  @ApiProperty({ description: 'Prev step id', required: false, type: Boolean })
  @joiSchemaProperty(Joi.boolean().required())
  playWithAbove!: boolean;
}

export const CreateStepSchema = generateClassValidationObject(CreateStepDto);
