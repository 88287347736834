import React from 'react';
import { Card, Button, Text, Stack, RingProgress, Title } from '@mantine/core';
import classes from './UploadingProductCard.module.scss';
import { useUppy } from '@assemblio/frontend/hooks';

interface ActiveUpload {
  id: string;
  progress?: number;
  name?: string;
}

interface UploadingProductCardProps {
  item: ActiveUpload;
}

const UploadingProductCard = ({ item }: UploadingProductCardProps) => {
  const { onCancelUpload } = useUppy();

  return (
    <Card className={classes.uploadCard} shadow="sm" radius="md" h={'20rem'}>
      <Stack align="center" justify="center" style={{ height: '100%', position: 'relative' }}>
        <RingProgress
          rootColor={'var(--elevation-senary)'}
          thickness={5}
          roundCaps
          size={120}
          classNames={{
            root: classes.progress_root,
            label: classes.progress_label,
            svg: classes.progress_svg,
          }}
          sections={[{ value: item.progress || 0, color: 'var(--text-brand)' }]}
          label={<Title order={3}>{item.progress?.toFixed() || 0}%</Title>}
        />
        <Text size={'lg'} variant={'medium'}>
          Uploading product...
        </Text>
        <Button variant="secondary" onClick={onCancelUpload}>
          Cancel
        </Button>
      </Stack>
    </Card>
  );
};

export default UploadingProductCard;
