import { Group, Select, SelectProps } from '@mantine/core';
import { IconCheck, IconChevronDown } from '@tabler/icons-react';
import classes from './ReviewStateSelection.module.scss';
import { InstructionState } from '@assemblio/type/instruction';
import { IconDraft } from '@assemblio/design-system';

interface ReviewStateSelectionProps {
  onChangeState: (newState: InstructionState) => void;
  state?: InstructionState;
}

export const ReviewStateSelection = ({ onChangeState, state }: ReviewStateSelectionProps) => {
  const handleSelectChange = (value: string | null) => {
    if (value) {
      const newState = Object.values(InstructionState).find((key) => key === value) as InstructionState;
      if (newState) {
        onChangeState(newState);
      }
    }
  };

  const renderSelectOption: SelectProps['renderOption'] = ({ option }) => (
    <Group wrap={'nowrap'} gap="sm">
      {option.value === 'Approved' ? <IconCheck size={14} /> : <IconDraft size={14} />}
      {option.label}
    </Group>
  );

  return (
    <Select
      w={state === 'Approved' ? '8rem' : '6.5rem'}
      classNames={{
        input: classes.input,
        root: classes.root,
      }}
      leftSection={<IconDraft size={12} />}
      rightSection={<IconChevronDown size={12} />}
      onChange={(value) => handleSelectChange(value)}
      value={state}
      renderOption={renderSelectOption}
      comboboxProps={{ width: 150, position: 'bottom', offset: 5 }}
      data={[
        {
          value: InstructionState.APPROVED,
          label: 'Approved',
        },
        {
          value: InstructionState.DRAFT,
          label: 'Draft',
        },
      ]}
    />
  );
};
