import React, { useState } from 'react';
import { Modal, Group, Divider } from '@mantine/core';
import { LocationDisplay } from '../CreateExplorerItemModal/components/LocationDisplay';
import { ExplorerFolderListView } from './components/ExplorerFolderListView/ExplorerFolderListView';
import { ExplorerFolderListSkeleton } from './components/ExplorerFolderListSkeleton/ExplorerFolderListSkeleton';
import classes from '../MoveExplorerItemModal/MoveExplorerItemModal.module.scss';
import { ExplorerMoveModalButtons } from './components/ExplorerMoveModalButton/ExplorerMoveModalButtons';
import { useItemPathQuery, useGetFolderQuery } from '@assemblio/frontend/data-access';
import { useExplorerRouteParams } from '../../hooks/ExplorerRouteParams.hook';
import { ExplorerItem, ExplorerItemType } from '../../pages/assembly-plans/types/items.types';
import { PathType } from '@assemblio/type/path';

type MoveExplorerItemModalProps = {
  item: ExplorerItem;
  opened: boolean;
  onMoveItem: (itemId: string, targetFolderId: string) => void;
  onClose: () => void;
};

export const MoveExplorerItemModal = ({ item, onMoveItem, onClose, opened }: MoveExplorerItemModalProps) => {
  const { type } = item;
  const { folderId, rootFolderId } = useExplorerRouteParams();
  const [selectedFolderId, setSelectedFolderId] = useState(rootFolderId);

  const { data: itemPath, isLoading } = useItemPathQuery(item.id, PathType.project);
  const { data: fetchedFolder } = useGetFolderQuery({ folderId: selectedFolderId, usedBy: 'modal' });

  const handleFolderNavigation = (targetId?: string) => {
    if (targetId) {
      setSelectedFolderId(targetId);
    } else if (fetchedFolder) setSelectedFolderId(fetchedFolder?.parent?.id);
  };

  const handleProjectMove = () => {
    if (!folderId) return;
    if (type === ExplorerItemType.Project && selectedFolderId) {
      onMoveItem(item.id, selectedFolderId);
      onClose();
    }
  };

  return (
    <Modal
      title={`Move "${item.name}"`}
      opened={opened}
      onClose={onClose}
      size={600}
      classNames={{
        header: classes.custom_modal_header,
        body: classes.customModalBody,
        content: classes.custom_modal_content,
      }}
    >
      <Group className={classes.listWrapper}>
        <LocationDisplay isLoading={isLoading} path={itemPath} />
      </Group>
      <Divider my={'md'} />
      {fetchedFolder ? (
        <ExplorerFolderListView currentFolder={fetchedFolder} onFolderNavigation={handleFolderNavigation} />
      ) : (
        <ExplorerFolderListSkeleton />
      )}
      <Divider my={'md'} />
      <ExplorerMoveModalButtons
        currentFolderId={selectedFolderId}
        parentFolderId={folderId}
        onClose={onClose}
        onProjectMove={handleProjectMove}
      />
    </Modal>
  );
};
