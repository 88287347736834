import * as Joi from 'joi';
import { joiSchemaProperty, generateClassValidationObject } from '@assemblio/shared/decorators';

export class TextChildDto {
  @joiSchemaProperty(Joi.string().required().allow(''))
  text!: string;

  @joiSchemaProperty(Joi.boolean().optional())
  bold?: boolean;

  @joiSchemaProperty(Joi.boolean().optional())
  underline?: boolean;

  @joiSchemaProperty(Joi.boolean().optional())
  italic?: boolean;

  @joiSchemaProperty(Joi.string().optional())
  color?: string;
}

export const TextChildSchema = generateClassValidationObject(TextChildDto);
