import { UIController } from '@assemblio/frontend/stores';
import { useViewportSize } from '@mantine/hooks';
import React, { useImperativeHandle, useState } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import { ResizeHandle, ResizeHandleSide } from './ResizeHandle';
import { ResizableContainerRef } from '@assemblio/frontend/types';
import classes from './Resizable.module.scss';
import { useMantineTheme } from '@mantine/core';
import cx from 'clsx';
import { useMousePosition } from '@assemblio/frontend/hooks';
import { CollapseSectionButton } from './CollapseSectionButton';

const DEFAULT_CONTAINER_WIDTH = 300;

const COLLAPSED_CONTAINER_WIDTH = 5;

interface ResizableContainerProps {
  resizeHandle: ResizeHandleSide;
  maxWidthMargin: number;
  children?: React.ReactNode;
  onResize?: (collapsed: boolean) => unknown;
  defaultWidth?: number;
}

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const ResizableContainer = React.forwardRef(
  (
    {
      resizeHandle,
      maxWidthMargin,
      children,
      onResize,
      defaultWidth = DEFAULT_CONTAINER_WIDTH,
    }: ResizableContainerProps,
    ref: React.ForwardedRef<ResizableContainerRef>
  ) => {
    const { width, height } = useViewportSize();
    const [collapsed, setCollapsed] = useState(false);
    const [containerWidth, setContainerWidth] = useState<number>(defaultWidth);
    const [isDragging, setIsDragging] = useState(false);

    useImperativeHandle(ref, () => ({
      collapse() {
        if (!collapsed) {
          updateContainerWidth(COLLAPSED_CONTAINER_WIDTH);
          setCollapsed(true);
          onResize && onResize(true);
        }
      },
      expand() {
        if (collapsed) {
          updateContainerWidth(defaultWidth);
          setCollapsed(false);
          onResize && onResize(false);
        }
      },
      toggle() {
        if (collapsed) {
          this.expand();
        } else {
          this.collapse();
        }
      },
      isCollapsed: collapsed,
    }));

    const theme = useMantineTheme();

    const { getMousePosition } = useMousePosition();

    function handleResize(event: React.SyntheticEvent, { size }: ResizeCallbackData) {
      const { x } = getMousePosition();
      if (size.width <= maxWidthMargin && !collapsed) {
        updateContainerWidth(COLLAPSED_CONTAINER_WIDTH);
        setCollapsed(true);
        onResize && onResize(true);
      } else {
        let newWidth = resizeHandle === 'w' ? width - x : x;
        newWidth = clamp(newWidth, 0, width / 2 - maxWidthMargin);
        if (collapsed && newWidth < maxWidthMargin) {
          updateContainerWidth(5);
        } else {
          updateContainerWidth(newWidth);
        }
      }

      if (size.width > maxWidthMargin && collapsed) {
        setCollapsed(false);
        onResize && onResize(false);
      }
    }

    const handleResizeStart = () => {
      setIsDragging(true);
    };
    const handleResizeStop = () => {
      setIsDragging(false);
    };

    const updateContainerWidth = (width: number) => {
      UIController.setSidebarWidth(resizeHandle, width);
      setContainerWidth(width);
    };

    return (
      <Resizable
        onResizeStart={handleResizeStart}
        className={classes.sidebar}
        onResizeStop={handleResizeStop}
        height={height - theme.other.navbarHeight}
        width={containerWidth}
        onResize={handleResize}
        resizeHandles={[resizeHandle]}
        maxConstraints={[width / 2 - maxWidthMargin, Infinity]}
        handle={
          <ResizeHandle handleSide={resizeHandle} height={height - theme.other.navbarHeight} isDragging={isDragging} />
        }
      >
        <div
          style={{
            width: containerWidth >= maxWidthMargin ? containerWidth : 5,
            height: height - theme.other.navbarHeight,
          }}
          className={cx([
            classes.container,
            { [classes.container__left]: resizeHandle === 'e' },
            { [classes.container__right]: resizeHandle === 'w' },
            { [classes['container--collapsed']]: collapsed },
          ])}
        >
          <CollapseSectionButton
            right={resizeHandle === 'w'}
            collapsed={collapsed}
            onClick={() => {
              setCollapsed(!collapsed);
              updateContainerWidth(collapsed ? defaultWidth : COLLAPSED_CONTAINER_WIDTH);
            }}
          />
          {!collapsed && children}
        </div>
      </Resizable>
    );
  }
);
