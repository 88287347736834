import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { InstructionState } from '@assemblio/type/instruction';

export class QueryManagedProductsDto {
  @joiSchemaProperty(Joi.string().uuid().optional())
  @ApiProperty({ description: 'Tenant Id', required: false })
  tenantId?: string;

  @joiSchemaProperty(Joi.string().uuid().optional())
  @ApiProperty({ description: 'Product Id', required: false })
  productId?: string;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(InstructionState))
      .optional()
  )
  @ApiProperty({
    description: 'Instruction Status',
    enum: InstructionState,
    required: false,
  })
  state?: InstructionState;

  @ApiProperty({
    description: 'Show deleted instructions',
    type: Boolean,
    required: false,
  })
  @joiSchemaProperty(Joi.boolean().optional())
  showDeleted?: boolean;

  @ApiProperty({
    description: 'Show only where images are missing',
    type: Boolean,
    required: false,
  })
  @joiSchemaProperty(Joi.boolean().optional())
  showMissingImage?: boolean;

  @joiSchemaProperty(Joi.number().min(0).optional())
  @ApiProperty({
    description: 'Page no for pagination, used only with "itemsPerPage"',
    type: Number,
    required: false,
  })
  page?: number;

  @joiSchemaProperty(Joi.number().min(1).optional())
  @ApiProperty({
    description: 'Number of items per page for pagination',
    type: Number,
    required: false,
  })
  itemsPerPage?: number;
}

export const QueryManagedProductsSchema = generateClassValidationObject(QueryManagedProductsDto);
