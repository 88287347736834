import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class GetGltfFileDto {
  @joiSchemaProperty(Joi.string().uuid())
  @ApiProperty({ description: 'Tenant Id', required: true })
  tenantId!: string;

  @joiSchemaProperty(Joi.string().uuid())
  @ApiProperty({ description: 'Product Id', required: true })
  id!: string;
}

export const GetGltfFileSchema = generateClassValidationObject(GetGltfFileDto);
