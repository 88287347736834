import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, Stack } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import classes from './Unauthorized.module.scss';
import { Logo } from '../ImageMark';

interface ErrorPageProps {
  title?: string | undefined;
  message?: string | React.ReactNode;
  buttonText?: string | undefined;
  onClick?: () => unknown | undefined;
}

export const ErrorPage = ({
  title = 'Error',
  message = 'An unknown Error occured',
  buttonText = 'Go Back',
  onClick,
}: ErrorPageProps) => {
  const navigate = useNavigate();
  const goBack = () => navigate('/explorer');

  return (
    <div className={classes.wrapper}>
      <Stack gap={'xl'} align="center" h={420} my={120}>
        <Box>
          <Logo width={250} />
        </Box>
        <Alert color="red" title={title}>
          {message}
        </Alert>
        <div className={classes.goBackButton}>
          <Button type="submit" fullWidth onClick={onClick ?? goBack} leftSection={<IconArrowLeft />}>
            {buttonText}
          </Button>
        </div>
      </Stack>
    </div>
  );
};
