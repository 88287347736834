import { Box3 } from 'three';
import { ModelController, ModelVisibilityController, StepController, UIController } from '../controller';
import { useModelStore, useSequenceStore, useUIStore } from '../stores';
import { ModelInformation } from '../stores/ModelStore';

export const updateSelectionBounds = () => {
  const selectedParts = useUIStore.getState().selectedPartSet;
  const bounds = new Box3();
  selectedParts.forEach((gltfIndex) => {
    const model = ModelController.getModelByGltfIndex(gltfIndex);
    if (model) {
      bounds.expandByObject(model);
    }
  });
  UIController.setSelectionBounds(bounds);
};

export const highlightParts = () => {
  UIController.highlightSelectedParts();
};

export const unhighlightParts = () => {
  UIController.unhighlightSelectedParts();
};

export const showPartsInSelectedStep = () => {
  const selectedStep = StepController.getSelectedStep();
  if (selectedStep) {
    const gltfIndices = selectedStep.data.parts.map((part) => part.partGltfIndex);
    ModelVisibilityController.setPartsVisible(gltfIndices, true);
  }
};

export const showPartsInSelectedAndPreviousSteps = () => {
  const disassemblyOrderSteps = StepController.getSelectedAndFollowingStepsInDisassemblyOrder();
  const assemblyOrderSteps = StepController.getSelectedAndFollowingStepsInAssemblyOrder();

  if (disassemblyOrderSteps) {
    const gltfIndices = disassemblyOrderSteps.flatMap((step) => {
      return step.data.parts.map((part) => part.partGltfIndex);
    });
    ModelVisibilityController.setPartsVisible(gltfIndices, true);
  }
  // Also show parts in alignment steps
  if (assemblyOrderSteps) {
    const usedParts = new Set(
      assemblyOrderSteps
        .filter((step) => step.type === 'assembly')
        .flatMap((step) => {
          return step.data.parts.map((part) => part.partGltfIndex);
        })
    );

    const gltfIndices = assemblyOrderSteps
      .filter((step) => step.type === 'alignment')
      .flatMap((step) => {
        return step.data.parts
          .filter((part) => !usedParts.has(part.partGltfIndex))
          .map((part) => {
            return part.partGltfIndex;
          });
      });
    ModelVisibilityController.setPartsVisible(gltfIndices, true);
  }
};

export const showSelectedParts = () => {
  const selectedParts = Array.from(useUIStore.getState().selectedPartSet);
  ModelVisibilityController.setPartsVisible(selectedParts, true);
};

export const setTransparencyOff = () => {
  ModelVisibilityController.setTransparencyOffCached();
};

export const setPartsTransparent = () => {
  ModelVisibilityController.setPartsInSequenceTransparent();
};

export const restoreTransparency = () => {
  ModelVisibilityController.restoreTransparencyFromCache();
};

export const hidePartsInSteps = () => {
  const gltfIndices = useSequenceStore.getState().stepGroups.flatMap((stepGroup) => {
    return stepGroup.steps
      .filter((step) => step.type === 'assembly')
      .flatMap((step) => {
        return step.data.parts.map((part) => part.partGltfIndex);
      });
  });
  ModelVisibilityController.setPartsVisible(gltfIndices, false);
};

export const hideOtherPartsInSteps = () => {
  const selectedParts = useUIStore.getState().selectedPartSet;
  const gltfIndices = useSequenceStore.getState().stepGroups.flatMap((stepGroup) => {
    return stepGroup.steps
      .filter((step) => step.type === 'assembly')
      .flatMap((assemblyStep) => {
        return assemblyStep.data.parts
          .filter((part) => !selectedParts.has(part.partGltfIndex))
          .map((notSelectedPart) => {
            return notSelectedPart.partGltfIndex;
          });
      });
  });
  ModelVisibilityController.setPartsVisible(gltfIndices, false);
};

export const hidePartsInSelectedStepAndFollowing = () => {
  const steps = StepController.getSelectedAndPreviousStepsInDisassemblyOrder();

  if (steps) {
    const gltfIndices = steps
      .filter((step) => step.type === 'assembly')
      .flatMap((step) => step.data.parts.map((part) => part.partGltfIndex));
    ModelVisibilityController.setPartsVisible(gltfIndices, false);
  } else {
    const gltfIndices = useSequenceStore.getState().stepGroups.flatMap((stepGroup) => {
      return stepGroup.steps
        .filter((step) => step.type === 'assembly')
        .flatMap((step) => step.data.parts.map((part) => part.partGltfIndex));
    });
    ModelVisibilityController.setPartsVisible(gltfIndices, false);
  }
};

export const movePartsInPreviousStepsToAssembledPosition = () => {
  const steps = StepController.getSelectedAndFollowingStepsInDisassemblyOrder();
  if (steps) {
    steps.forEach((step, index) => {
      if (index === 0) return;
      step.data.parts.forEach((part) => {
        ModelController.movePartToStepState(part.partGltfIndex);
      });
    });
  }
};

export const movePartsInSelectedStepToDisassembledPosition = () => {
  StepController.movePartsInSelectedStepToDisassembledPosition();
};

export const movePartsToStepStartPosition = () => {
  const selectedStep = StepController.getSelectedStep();
  if (selectedStep && selectedStep.data) {
    selectedStep.data.parts.forEach((part) => {
      ModelController.movePartToStepTransform(part.partGltfIndex, selectedStep);
    });
  }
};

export const movePartsToSelectedSegment = () => {
  const { selectedStep, selectedPathSegmentMap } = useUIStore.getState();
  if (selectedStep) {
    const index = selectedPathSegmentMap.get(selectedStep.id);
    const step = StepController.getStep(selectedStep.id);
    if (index !== undefined && step) {
      ModelController.movePartsToStepSegment(index, step.data);
    }
  }
};

export const setPartsToStartPosition = () => {
  const modelInformationMap = useModelStore.getState().modelInformationMap;
  modelInformationMap.forEach((_value: ModelInformation, key: number) => {
    if (StepController.hasStep(key)) {
      ModelController.movePartByProgress(key, 'disassembled');
    }
  });
};

export const movePartsToDisassembledPosition = () => {
  useSequenceStore.getState().stepGroups.forEach((stepGroup) => {
    stepGroup.steps.forEach((step) => {
      step.data &&
        step.data.parts.forEach((part) => {
          ModelController.movePartByProgress(part.partGltfIndex, 'disassembled');
        });
    });
  });
};
