import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { InstructionFileType } from '@assemblio/type/file';
import * as Joi from 'joi';

class InstructionDataDto {
  @ApiProperty({
    description: 'The id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({
    description: 'Name',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;
}

export const InstructionDataSchema = generateClassValidationObject(InstructionDataDto);

export class InstructionFileListDto {
  @ApiProperty({
    description: 'The id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({
    description: 'Tenant id',
    required: false,
  })
  @joiSchemaProperty(Joi.string().uuid())
  tenantId?: string;

  @ApiProperty({
    description: 'original name',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  originalName!: string;

  @ApiProperty({
    description: 'file size',
    required: true,
  })
  @joiSchemaProperty(Joi.number().required())
  size!: number;

  @ApiProperty({
    description: 'file type',
    required: true,
    enum: InstructionFileType,
  })
  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(InstructionFileType))
      .required()
  )
  type!: InstructionFileType;

  @ApiProperty({
    description: 'Instruction information',
    required: true,
    type: InstructionDataDto,
  })
  @joiSchemaProperty(InstructionDataSchema.required())
  instruction!: InstructionDataDto;

  @ApiProperty({
    description: 'File path',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  path!: string;

  @ApiProperty({
    description: 'last update time',
    required: true,
  })
  @joiSchemaProperty(Joi.date())
  updatedAt!: Date;

  @ApiProperty({
    description: 'created at',
    required: true,
  })
  @joiSchemaProperty(Joi.date())
  createdAt!: Date;
}
