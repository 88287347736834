import { useExplorerRouteParams } from '../../hooks/ExplorerRouteParams.hook';
import { useInstructionSetFavorite } from '@assemblio/frontend/data-access';

import { useState } from 'react';
import { ProductOverviewDto } from '@assemblio/shared/dtos';
import { Divider, Group, Loader, ScrollArea, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useExplorerView, useOpenProduct } from '../../hooks';
import { Filter, ItemSearch, SortMenu, ViewToggleButton } from '../../components';
import { ProductGrid } from './components/ProductGrid';
import { ProductTable } from './components/ProductTable';
import { useDebouncedValue } from '@mantine/hooks';
import { useProductSearch } from './hooks/ProductSearch.hook';
import { ActiveUpload, SearchResultType, SortingOption } from '../../types';
import { useProductFilters } from './hooks/ProductFilters.hook';
import { ExplorerItemType } from '../../pages/assembly-plans/types/items.types';

interface ProductsDisplayProps<T extends string | undefined> {
  products: ProductOverviewDto[];
  isLoading?: boolean;
  isRefetching?: boolean;
  activeUpload?: ActiveUpload;
  onSort: (value: string) => void;
  sortingValue?: SortingOption<T>;
  sortingOptions?: SortingOption<T>[];
}

export const ProductsDisplay = <T extends string | undefined>({
  products,
  onSort,
  sortingValue,
  activeUpload,
  sortingOptions,
  isLoading = false,
  isRefetching = false,
}: ProductsDisplayProps<T>) => {
  const { projectId } = useExplorerRouteParams();
  const [onOpenProduct] = useOpenProduct();

  const navigate = useNavigate();
  const { isListView } = useExplorerView();
  const setFavoriteMutation = useInstructionSetFavorite();

  const { filterProps } = useProductFilters();

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500);

  const { results } = useProductSearch(debouncedSearchTerm, products);

  const handleSearchResultClick = (result: SearchResultType) => {
    handleOpenDetails(result.value);
  };

  const handleOpenDetails = (id: string) => {
    navigate(`/explorer/product/${id}`);
  };

  const handleOpenProduct = (product: ProductOverviewDto) => {
    onOpenProduct(product, product.project.id);
  };

  const handleToggleFavorite = (id: string, isFavorite: boolean) => {
    setFavoriteMutation.mutate({
      productId: id,
      projectId,
      action: isFavorite ? 'remove' : 'add',
      usedBy: projectId ? 'project' : 'favorites',
    });
  };
  return (
    <Stack gap={'xl'} h={'100%'}>
      <Group px={'xl'} wrap={'nowrap'} align={'center'} justify={'flex-start'}>
        <Filter filters={filterProps} />
        {isRefetching && <Loader size={'xs'} color={'text-primary'} />}
        <ItemSearch
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          isLoading={false}
          isEmpty={results.isEmpty}
          results={[
            {
              group: '',
              items: results.products.map((product) => {
                return {
                  label: product.name,
                  value: product.id,
                  type: ExplorerItemType.Product,
                };
              }),
            },
          ]}
          onResultClick={handleSearchResultClick}
        />
        <Divider orientation={'vertical'} />
        {sortingOptions && (
          <SortMenu<T> sortOptions={sortingOptions} onSelectSortBy={onSort} sortBy={sortingValue?.value} />
        )}
        <ViewToggleButton />
      </Group>
      <ScrollArea scrollbars={'y'} type={'hover'} mah={'100%'} h={'100%'}>
        {isListView ? (
          <ProductTable
            products={products}
            onOpenDetails={handleOpenDetails}
            onOpenProduct={handleOpenProduct}
            onToggleFavorite={handleToggleFavorite}
            isLoading={isLoading}
          />
        ) : (
          <ProductGrid
            products={products}
            activeUpload={activeUpload}
            onOpenDetails={handleOpenDetails}
            onOpenProduct={handleOpenProduct}
            isLoading={isLoading}
          />
        )}
      </ScrollArea>
    </Stack>
  );
};
