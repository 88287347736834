export const transformEnumToSelectData = (enumObject: Record<string, string>) =>
  Object.keys(enumObject).map((key) => ({ value: enumObject[key], label: enumObject[key] }));

export const getEnumKeyByValue = <T extends Record<string, string>>(
  enumObj: T,
  value: T[keyof T] | undefined
): keyof T | undefined => {
  return typeof value === 'undefined'
    ? undefined
    : (Object.keys(enumObj) as Array<keyof T>).find((key) => enumObj[key] === value);
};
