import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class CreateProductTextBlockDto {
  @ApiProperty({
    description: 'The product id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  productId!: string;

  @ApiProperty({
    description: 'The header',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  headline!: string;

  @ApiProperty({
    description: 'The content text',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  text!: string;

  @ApiProperty({
    description: 'The block order',
    required: true,
    type: Number,
    min: 0,
  })
  @joiSchemaProperty(Joi.number().min(0).required())
  order!: number;

  @ApiProperty({
    description: 'Add to the export documents?',
    required: false,
    type: Boolean,
    default: false,
  })
  @joiSchemaProperty(Joi.boolean())
  export?: boolean;

  @ApiProperty({
    description: 'Add static table with specific headers',
    required: false,
    type: [String],
    default: [],
  })
  @joiSchemaProperty(Joi.array().items(Joi.string()))
  tableHeaders?: string[];
}

export const CreateProductTextBlockSchema = generateClassValidationObject(CreateProductTextBlockDto);
