import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class QueryNotificationDto {
  @joiSchemaProperty(Joi.number())
  @ApiProperty({ description: 'Number of notifications per page', default: 10 })
  itemsPerPage?: number;

  @joiSchemaProperty(Joi.number())
  @ApiProperty({ description: 'page', default: 0 })
  page?: number;

  @joiSchemaProperty(Joi.string().uuid().optional())
  @ApiProperty({ description: 'Tenant Id', required: false })
  tenantId?: string;
}

export const QueryNotificationSchema = generateClassValidationObject(QueryNotificationDto);
