import { Modal, Stack, Text } from '@mantine/core';
import { SyncProfileRelationDto, SyncProfileResolvedPair } from '@assemblio/shared/next-types';
import { EditSyncProfile } from './components/EditSyncProfile';
import { useEffect, useState } from 'react';
import { InlineSelect, VariantNameDisplay } from '../../components';
import { IconFileFilled } from '@tabler/icons-react';
import { SyncProfileDto } from '@assemblio/shared/dtos';

interface EditSyncProfileModalProps {
  syncRelations: SyncProfileRelationDto[] | undefined;
  onUpdateSyncProfile: (
    sourceVariantId: string,
    targetVariantId: string,
    updatedPairs: SyncProfileResolvedPair
  ) => void;
  opened: boolean;
  onClose: () => void;
}

export const EditSyncProfileModal = ({
  opened,
  onUpdateSyncProfile,
  syncRelations,
  onClose,
}: EditSyncProfileModalProps) => {
  const [editTarget, setEditTarget] = useState<SyncProfileDto | undefined>(undefined);

  const handleCloseModal = () => {
    onClose();
  };

  const selectEditTarget = (id: string | null) => {
    if (!id) setEditTarget(undefined);
    const target = syncRelations?.find((relation) => relation.to.id === id)?.to;
    setEditTarget(target);
  };

  useEffect(() => {
    if (editTarget) return;
    if (syncRelations) setEditTarget(syncRelations[0].to);
  }, [syncRelations]);

  return (
    <Modal title="Edit synchronization part assigment" size={'lg'} opened={opened} onClose={handleCloseModal}>
      <Stack my={'md'} w={'100%'}>
        <Text px={'lg'} style={{ display: 'inline-block' }}>
          During past synchronizations from source product&nbsp;
          <VariantNameDisplay name={(syncRelations && syncRelations[0].from.name) || '...'} />
          &nbsp;into target product&nbsp;
          <InlineSelect
            data={syncRelations?.map((relation) => {
              return {
                value: relation.to.id,
                label: relation.to.name,
                icon: IconFileFilled,
              };
            })}
            value={editTarget?.id}
            onChange={selectEditTarget}
          />
          &nbsp; multiple parts were synchronized. <br /> Below you can review how parts have been recognized and edit
          how similar parts should be handled.
        </Text>
        {syncRelations && editTarget && (
          <EditSyncProfile
            sourceVariant={syncRelations[0].from}
            targetVariant={editTarget}
            onUpdateSyncProfile={onUpdateSyncProfile}
            onClose={handleCloseModal}
          />
        )}
      </Stack>
    </Modal>
  );
};
