enum Env {
  node = 'node',
  browser = 'browser',
}
declare const __non_webpack_require__: any;

const env = typeof window === 'undefined' ? Env.node : Env.browser;

function bootstrap(): (x: any) => any {
  if (env === Env.node) {
    return (__non_webpack_require__ || require)('@nestjs/swagger').ApiProperty;
  } else {
    return (x: any) => {
      return () => {};
    };
  }
}

export const ApiProperty = bootstrap();
