import { ExportJobDto } from '@assemblio/shared/dtos';
import { Box, Card, Group, Loader, Progress, Stack, Text, ThemeIcon } from '@mantine/core';
import classes from './JobCard.module.scss';
import { IconMP4, IconPDF, Tag } from '@assemblio/design-system';
import { IconCheck, IconFileFilled, IconFolderFilled, IconX } from '@tabler/icons-react';
import { ExportJobType, JobState } from '@assemblio/type/job';
import { JobContextMenu } from './components/JobContextMenu';

interface JobCardProps {
  job: ExportJobDto;
}

export const JobCard = ({ job }: JobCardProps) => {
  const showJobProgress = job.state === JobState.Running && job.progress;
  return (
    <Card p={'md'} className={classes.card}>
      <Group h={'100%'} justify="space-between">
        <Group h={'100%'}>
          <Box className={classes.icon}>
            <ThemeIcon variant={'transparent'} c={'text-primary'} size={'xs'}>
              {getJobIcon(job.type)}
            </ThemeIcon>
          </Box>
          <Stack gap={'xs'} justify="center">
            <Group>
              <Text fw={'bold'} c={'text-primary'}>
                Creating {job.createdFileName ?? job.type}
              </Text>
              {getState(job.state)}
            </Group>
            {showJobProgress && (
              <Group w={'100%'} h={'24px'}>
                <Progress
                  w={'100%'}
                  data-testid={'progress-bar'}
                  color={'var(--text-brand)'}
                  value={job.progress || 0}
                />
              </Group>
            )}
          </Stack>
        </Group>
        <Group>
          <JobContextMenu jobId={job.id} productId={job.productId} />
        </Group>
      </Group>
    </Card>
  );
};

const getJobIcon = (type: ExportJobType) => {
  switch (type) {
    case ExportJobType.EXPORT_VIDEO:
      return <IconMP4 data-testid={'mp4-icon'} />;
    case ExportJobType.EXPORT_PDF:
      return <IconPDF data-testid={'pdf-icon'} />;
    case ExportJobType.EXPORT_DOCX:
      return <IconFileFilled data-testid={'doc-icon'} />;
    default:
      return <IconFolderFilled size={16} />;
  }
};

const getState = (state: JobState) => {
  switch (state) {
    case JobState.Completed:
      return (
        <Tag size={'xs'} data-testid={'completed-tag'} leftSection={<IconCheck size={14} />}>
          Completed
        </Tag>
      );

    case JobState.Failed:
      return (
        <Tag size={'xs'} data-testid={'failed-tag'} leftSection={<IconX size={14} />}>
          Failed
        </Tag>
      );

    case JobState.Aborted:
      return (
        <Tag size={'xs'} data-testid={'aborted-tag'} leftSection={<IconX size={14} />}>
          Aborted
        </Tag>
      );

    case JobState.Running:
      return null;

    case JobState.Initialized:
      return (
        <Tag
          size={'xs'}
          data-testid={'initializing-tag'}
          leftSection={<Loader color={'var(--text-brand)'} size={14} />}
        >
          Initializing
        </Tag>
      );

    default:
      return null;
  }
};
