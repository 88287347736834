import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { FolderOverviewDto } from './folder-overview.dto';
import { ProjectOverviewDto } from '../project/project-overview';
import { FolderDto } from './folder.dto';

export class FolderContentDto {
  @ApiProperty({
    description: 'The folder id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The name of the folder',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'The projects in the folder',
    required: true,
  })
  @joiSchemaProperty(Joi.array().required())
  projects?: ProjectOverviewDto[];

  @ApiProperty({
    description: 'The subfolders in the folder',
    required: true,
  })
  children?: FolderOverviewDto[];

  @ApiProperty({
    description: 'The parent folder',
    required: false,
  })
  parent?: FolderDto;

  @ApiProperty({
    description: 'The depth of the folder',
    required: false,
  })
  depth!: number;
}

export const FolderContentSchema = generateClassValidationObject(FolderContentDto);
