import { SearchField } from '@assemblio/design-system';
import { useV1ProductArtefactsQuery } from '@assemblio/frontend/data-access';
import { ProductContentDto, QueryProductArtefactDto } from '@assemblio/shared/dtos';
import { ArtefactType, ProductArtefactSortProps } from '@assemblio/type/artefacts';
import { QueryOrder } from '@assemblio/type/common';
import { Group, Loader, ScrollArea, Stack } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ArtefactCard, ArtefactCardSkeleton, Filter, SortMenu, useSortingOptions } from '../../../../../../components';
import classes from './ArtefactsTabContent.module.scss';
import { ExportJobsList } from './components/ExportJobsList/ExportJobsList';
import { ARTEFACT_QUERY_SORTING_OPTIONS } from './constants/artefactQuery.sortOptions';
import { useArtefactQueryFilters } from './hooks/artefactQueryFilters.hook';

interface ArtefactsTabProps {
  product: ProductContentDto | undefined;
}

export const ArtefactsTabContent = ({ product }: ArtefactsTabProps) => {
  const { sortingValue, handleSort } = useSortingOptions<ProductArtefactSortProps>(ARTEFACT_QUERY_SORTING_OPTIONS);
  const [searchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500);

  const { filterProps } = useArtefactQueryFilters();

  const queryData: QueryProductArtefactDto = useMemo((): QueryProductArtefactDto => {
    return {
      orderBy: sortingValue?.queryValue,
      orderType: sortingValue?.ascending ? QueryOrder.ASC : QueryOrder.DESC,
      type: (searchParams.get('type') as ArtefactType) ?? undefined,
      name: debouncedSearchTerm ? debouncedSearchTerm : undefined,
    };
  }, [sortingValue, searchParams, debouncedSearchTerm]);

  const {
    data: artefacts,
    isLoading,
    refetch,
    isRefetching,
  } = useV1ProductArtefactsQuery({
    productId: product?.id,
    queryData,
  });

  useEffect(() => {
    refetch();
  }, [sortingValue, searchParams, debouncedSearchTerm]);

  return (
    <Stack pt={'xl'} h={'100%'}>
      <Group px={'xl'} h={'2.5rem'} justify="space-between" align="center">
        <Filter filters={filterProps} previewFilterOptionCount={1} />
        {isRefetching && <Loader size={'xs'} color={'text-primary'} />}
        <SearchField
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.currentTarget.value ?? undefined)}
          placeholder="Search all..."
        />
        <SortMenu<ProductArtefactSortProps>
          sortOptions={ARTEFACT_QUERY_SORTING_OPTIONS}
          onSelectSortBy={handleSort}
          sortBy={sortingValue?.value}
        />
      </Group>
      <ScrollArea px={'xl'} scrollbars={'y'} className={classes.scroll_wrapper}>
        <Stack
          flex={{
            grow: 1,
          }}
        >
          <ExportJobsList productId={product?.id} projectId={product?.project.id} />
          {isLoading ? (
            <>
              <ArtefactCardSkeleton />
              <ArtefactCardSkeleton />
            </>
          ) : (
            artefacts?.map((artefact, index) => <ArtefactCard key={index} artefact={artefact} />)
          )}
        </Stack>
      </ScrollArea>
    </Stack>
  );
};
