import { TypedFilterOption } from '../../../types';
import { IconTool } from '@tabler/icons-react';
import {
  OperationalResourceMainCategory,
  OperationalResourceSecondaryCategory,
  OperationalResourceSortProps,
} from '@assemblio/type/resources';

interface Return {
  filterProps: TypedFilterOption<OperationalResourceSortProps>[];
}

const FILTERABLE_MAIN_CATEGORIES = Object.values(OperationalResourceMainCategory);
const FILTERABLE_SECONDARY_CATEGORIES = Object.values(OperationalResourceSecondaryCategory);

export const useResourceFilters = (): Return => {
  return {
    filterProps: [
      {
        label: 'Main Category',
        value: OperationalResourceSortProps.mainCategory,
        Icon: IconTool,
        options: Object.entries(FILTERABLE_MAIN_CATEGORIES).map(([_, value]) => {
          return {
            label: value,
            value: value,
          };
        }),
        onToggleOption: () => null,
      },
      {
        label: 'Secondary Category',
        value: OperationalResourceSortProps.secondaryCategory,
        Icon: IconTool,
        options: Object.entries(FILTERABLE_SECONDARY_CATEGORIES).map(([_, value]) => {
          return {
            label: value,
            value: value,
          };
        }),
        onToggleOption: () => null,
      },
    ],
  };
};
