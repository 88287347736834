import { Card, Skeleton, Stack } from '@mantine/core';
import React from 'react';
import classes from '../ExplorerGrid.module.scss';

export const BaseCardSkeleton = () => {
  return (
    <Card className={classes.skeleton_card} h={'20rem'}>
      <Stack h={'100%'}>
        <Skeleton h={'2rem'} w={'90%'} />
        <Skeleton h={'10rem'} w={'90%'} />
        <Skeleton h={'5rem'} w={'90%'} />
      </Stack>
    </Card>
  );
};
