import 'react-resizable/css/styles.css';
import { RectAnnotation } from './RectAnnotation';
import { ShapeAnnotation } from '@assemblio/type/annotation';

interface Props {
  annotationId: string;
  annotation: ShapeAnnotation;
}

export const EllipseAnnotation = ({ annotation, annotationId }: Props) => {
  return <RectAnnotation borderRadius="100%" annotation={annotation} annotationId={annotationId} />;
};
