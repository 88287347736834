import { createContext, PropsWithChildren, ReactNode, useState } from 'react';
import { BreadcrumbItemType, TitleExtension } from '../types';
import { InstructionState } from '@assemblio/type/instruction';

interface ExplorerTitleContextType {
  title: string | undefined;
  setHeaderTitle: (title: string | undefined, extension?: TitleExtension) => void;
  versionTag: string | undefined;
  itemCount: number | undefined;
  state: InstructionState | undefined;
  breadcrumbs: [BreadcrumbItemType, BreadcrumbItemType] | undefined;
  rightSection?: ReactNode;
  bottomSection?: ReactNode;
  noDefaultRightSection?: boolean;
}

export const ExplorerTitleContext = createContext<ExplorerTitleContextType>({
  title: undefined,
  versionTag: undefined,
  itemCount: undefined,
  state: undefined,
  breadcrumbs: undefined,
  setHeaderTitle: (title: string | undefined, extension?: TitleExtension) => null,
});

export const ExplorerTitleProvider = ({ children }: PropsWithChildren) => {
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [versionTag, setVersionTag] = useState<string | undefined>(undefined);
  const [itemCount, setItemCount] = useState<number | undefined>(undefined);
  const [productState, setProductState] = useState<InstructionState | undefined>(undefined);
  const [breadcrumbs, setBreadcrumbs] = useState<[BreadcrumbItemType, BreadcrumbItemType] | undefined>(undefined);
  const [rightSection, setRightSection] = useState<ReactNode | undefined>();
  const [bottomSection, setBottomSection] = useState<ReactNode | undefined>();
  const [noDefaultRightSection, setNoDefaultRightSection] = useState<boolean | undefined>(undefined);
  const setHeaderTitle = (title: string | undefined, extension?: TitleExtension) => {
    setTitle(title);
    setVersionTag(extension?.title?.versionTag);
    setItemCount(extension?.title?.itemCount);
    setProductState(extension?.title?.state);
    setBreadcrumbs(extension?.breadcrumbs);
    setRightSection(extension?.rightSection);
    setBottomSection(extension?.bottomSection);
    setNoDefaultRightSection(extension?.noDefaultRightSection);
  };

  return (
    <ExplorerTitleContext.Provider
      value={{
        title,
        setHeaderTitle,
        versionTag,
        itemCount,
        state: productState,
        breadcrumbs,
        rightSection,
        bottomSection,
        noDefaultRightSection,
      }}
    >
      {children}
    </ExplorerTitleContext.Provider>
  );
};
