import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class PartPropertyDto {
  @joiSchemaProperty(Joi.array().items(Joi.string()))
  @ApiProperty({ description: 'part ids', required: true, type: [String] })
  partIds!: string[];

  @joiSchemaProperty(Joi.array().items(Joi.string()))
  @ApiProperty({ description: 'part group ids', required: true, type: [String] })
  partGroupIds!: string[];
}
export const PartPropertySchema = generateClassValidationObject(PartPropertyDto);

export class SequenceDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'sequence Id', required: true })
  id!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'sequence Name', required: true })
  name!: string;

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'sequence number', required: true, type: Number })
  order!: number;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'creator Id', required: true })
  creatorId!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'schema version', required: true })
  schemaVersion!: string;

  @joiSchemaProperty(PartPropertySchema.required())
  @ApiProperty({ description: 'sequence Id', required: true, type: PartPropertyDto })
  locked!: PartPropertyDto;

  @joiSchemaProperty(Joi.array().items(Joi.number()))
  @ApiProperty({ description: 'excluded parts', required: true, type: [Number] })
  excludedParts!: number[];
}
export const SequenceSchema = generateClassValidationObject(SequenceDto);
