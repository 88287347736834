import { OperationalResourceDto } from '@assemblio/shared/dtos';
import { ExplorerTable, ThProps } from '../../../layout';
import {
  OperationalResourceRow,
  OperationalResourceRowSkeleton,
} from './OperationalResourceRow/OperationalResourceRow';
import { EmptyResourceView } from './EmptyResourceView';

interface OperationalResourceTableProps {
  resources: OperationalResourceDto[];
  onEditResource: (id: string) => void;
  onViewUsage: (id: string) => void;
  isLoading?: boolean;
}

export const OperationalResourceTable = ({
  resources,
  onEditResource,
  onViewUsage,
  isLoading = false,
}: OperationalResourceTableProps) => {
  const items = resources.map((resource) => (
    <OperationalResourceRow
      onEditResource={() => onEditResource(resource.id)}
      onViewUsage={() => onViewUsage(resource.id)}
      resource={resource}
    />
  ));

  const tableHeaders: ThProps[] = [
    {
      name: '#',
      width: '5%',
    },
    {
      name: 'Name',
      width: '10%',
    },
    {
      name: 'Category',
      width: '15%',
    },
    {
      name: 'Description',
      width: '20%',
    },
    {
      name: 'Units',
      width: '10%',
    },
    {
      width: '10%',
    },
  ];

  return (
    <ExplorerTable
      headerItems={tableHeaders}
      items={items}
      isLoading={isLoading}
      loadingPlaceholder={OperationalResourceRowSkeleton}
      emptyView={<EmptyResourceView />}
    />
  );
};
