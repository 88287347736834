import { useAuthStore, useFolderCreate } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useExplorerRouteParams } from '../../../hooks/ExplorerRouteParams.hook';
import { QueryKey } from '@tanstack/react-query';

export const useCreateFolder = () => {
  const [isLoading, setIsLoading] = useState(false);

  const tenantId = useAuthStore((state) => state.tenant.id);
  const { folderId } = useExplorerRouteParams();
  const createFolderMutation = useFolderCreate();

  const createFolder = async (name: string, parentFolderId?: string, invalidateQuery?: QueryKey) => {
    setIsLoading(true);
    try {
      const folderParentId = parentFolderId || folderId;

      if (!folderParentId) {
        notifications.show({
          id: 'add-folder-error',
          message: 'Parent folder id is required',
          color: 'red',
        });
        return;
      }

      await createFolderMutation.mutateAsync({
        folderData: {
          name: name,
          tenantId: tenantId,
          parentFolderId: folderParentId,
        },
        invalidateQueryKey: invalidateQuery,
      });
      notifications.show({
        id: 'add-folder-success',
        message: 'Folder has been created',
        color: 'green',
      });
    } catch (e) {
      notifications.show({
        id: 'add-folder-error',
        message: 'Folder could not be created',
        color: 'red',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createFolder,
    isLoading,
  };
};
