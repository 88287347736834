export const capitalizeString = <T extends string>(s: T) => (s[0].toUpperCase() + s.slice(1)) as Capitalize<typeof s>;

export const capitalizeSentence = <T extends string>(s: T) =>
  s.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

/**
 * Function that returns difference between past date and now in a human readable way.
 * @param date - Past Date
 * @returns Human readable difference in days (e.g. Two days ago)
 */
export const getFormattedDateDifference = (date: Date, capitalize = true) => {
  const now = new Date();
  const pastDate = new Date(date);
  const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

  const diff = Math.round((pastDate.getTime() - now.getTime()) / (1000 * 3600 * 24));
  const formattedString = formatter.format(diff, 'day');
  return capitalize ? capitalizeString(formattedString) : formattedString;
};

export const getFormattedTimeDifference = (date: Date, capitalize = false) => {
  const now = new Date();
  const pastDate = new Date(date);

  const diffInMilliseconds = pastDate.getTime() - now.getTime();
  const timeData = getUnitAndTime(diffInMilliseconds);

  const formatter = new Intl.RelativeTimeFormat('en', { style: timeData.format, numeric: 'auto' });
  const formattedString = formatter.format(timeData.value, timeData.unit);
  return capitalize ? capitalizeString(formattedString) : formattedString;
};

const getUnitAndTime = (
  diff: number
): { unit: Intl.RelativeTimeFormatUnit; value: number; format: Intl.RelativeTimeFormatStyle } => {
  const diffInMinutes = Math.ceil(diff / (1000 * 60));
  const diffInHours = Math.ceil(diffInMinutes / 60);
  const diffInDays = Math.ceil(diffInHours / 24);

  if (Math.abs(diffInMinutes) < 60) return { unit: 'minute', value: diffInMinutes, format: 'narrow' };
  else if (Math.abs(diffInHours) < 24) return { unit: 'hour', value: diffInHours, format: 'narrow' };
  else return { unit: 'day', value: diffInDays, format: 'short' };
};

export const getFormattedDateString = (date: Date) => {
  const dateToFormat = new Date(date);
  const formatter = new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  return formatter.format(dateToFormat.getTime());
};
