export enum ExplorerItemType {
  Project = 'Project',
  Folder = 'Folder',
  Product = 'Product',
}

export interface ExplorerItem {
  name: string;
  id: string;
  subItemCount: number;
  type: ExplorerItemType;
  productIds?: string[] | never;
}

export interface ExplorerProject extends ExplorerItem {
  type: ExplorerItemType.Project;
  productIds?: string[];
}

export interface ExplorerFolder extends ExplorerItem {
  type: ExplorerItemType.Folder;
  productIds?: never;
}

export type ExplorerItems = ExplorerItem[] | { projects: ExplorerItem[]; folders: ExplorerItem[] };
