import { useMemo } from 'react';
import { ComboboxItem } from '@mantine/core';
import { OperationalResourceDto } from '@assemblio/shared/dtos';

type SelectData = {
  isEmpty: boolean;
  resources: OperationalResourceDto[];
  byValue: { [key: string]: ComboboxItem };
};

export const useResourceSearch = (searchTerm: string, products: OperationalResourceDto[]) => {
  const formattedData: SelectData = useMemo(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const filteredResources = products.filter((folder) => folder.name?.toLowerCase().includes(lowerCaseSearchTerm));

    const byValue: { [key: string]: ComboboxItem } = {};

    const isEmpty = filteredResources.length === 0;

    return { resources: filteredResources, byValue, isEmpty };
  }, [searchTerm, products]);

  return {
    results: {
      isEmpty: !!searchTerm && formattedData.isEmpty,
      products: formattedData.resources,
    },
    isLoading: false,
  };
};
