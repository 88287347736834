import { generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { ApiProperty } from '@assemblio/shared/decorators';
import { UserDto, UserSchema } from './response.dto';
import { MetaDto, MetaSchema, PaginatedDto } from '../meta';
import * as Joi from 'joi';

export class PaginatedUserDto extends PaginatedDto<UserDto> {
  @joiSchemaProperty(Joi.array().items(UserSchema).required())
  @ApiProperty({
    description: 'Users',
    isArray: true,
    type: [UserDto],
    required: true,
  })
  override items!: UserDto[];

  @joiSchemaProperty(MetaSchema.required())
  @ApiProperty({
    description: 'Pagination information',
    type: MetaDto,
    required: true,
  })
  override meta!: MetaDto;
}

export const PaginatedUserSchema = generateClassValidationObject(PaginatedUserDto);
