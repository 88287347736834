import { ComboboxItem, ComboboxItemGroup } from '@mantine/core';
import { ExplorerItemType } from '../types/items.types';
import { useFolderChildrenSearchQuery } from '@assemblio/frontend/data-access';
import { useExplorerRouteParams } from '../../../hooks/ExplorerRouteParams.hook';
import { SearchResultType } from '../../../types';
import { useDebouncedValue } from '@mantine/hooks';

export type SelectItem = ComboboxItem & {
  type: ExplorerItemType;
};

interface ItemSearchHookReturnType {
  searchResults: ComboboxItemGroup<SearchResultType>[];
  isEmpty: boolean;
  isLoading: boolean;
}

export const useItemSearch = (searchTerm: string): ItemSearchHookReturnType => {
  const { folderId } = useExplorerRouteParams();

  const { data, isLoading } = useFolderChildrenSearchQuery({
    folderId: folderId,
    searchQuery: { query: searchTerm },
  });

  const searchResults = [
    {
      group: 'Folders',
      items:
        data?.folders.map((folder) => {
          return {
            type: ExplorerItemType.Folder,
            value: folder.id,
            label: folder.name,
          };
        }) ?? [],
    },
    {
      group: 'Projects',
      items:
        data?.projects.map((project) => {
          return {
            type: ExplorerItemType.Project,
            value: project.id,
            label: project.name,
          };
        }) ?? [],
    },
    {
      group: 'Products',
      items:
        data?.products.map((product) => {
          return {
            type: ExplorerItemType.Product,
            value: product.id,
            label: product.name,
          };
        }) ?? [],
    },
  ];

  const [debouncedLoading] = useDebouncedValue(isLoading, 250);

  const isEmpty = data?.products.length === 0 && data?.projects.length === 0 && data?.folders.length === 0;

  return { searchResults, isEmpty, isLoading: debouncedLoading };
};
