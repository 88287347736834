import { ContextMenuHandler } from '@assemblio/frontend/components';
import {
  AnnotationController,
  CanvasController,
  MachineController,
  useSettingsStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { Canvas, events, LocalState, RootState } from '@react-three/fiber';
import { useState } from 'react';
import { Object3D, PCFSoftShadowMap } from 'three';
import { Annotations } from './Annotations';
import { AspectContainer } from './AspectContainer';
import { CanvasElements } from './CanvasElements';
import { AdaptiveDpr } from '@react-three/drei';

interface Props {
  onCreated?(rootState: RootState): void;
}

export const Viewport = (props: Props) => {
  const [rootState, setRootState] = useState<RootState | undefined>(undefined);

  const ratio = useUIStore((state) => state.previewRatio);
  const background = useSettingsStore((state) => state.viewport.color);
  const isEditor = useUIStore((state) => state.view === 'editor');

  const selectionActive = useUIStore((state) => state.selection.active);

  /* This div is neccessary, because the canvas grows in size indefinitely without it.
            This is a known bug: https://github.com/pmndrs/react-three-fiber/issues/1684 */
  return (
    <div
      style={{
        background: background,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 0,
        touchAction: 'none',
        cursor: isEditor && selectionActive ? 'crosshair' : 'auto',
      }}
    >
      <AspectContainer ratio={ratio} rootState={rootState}>
        <Canvas
          onPointerDown={() => {
            AnnotationController.stopEditing();
          }}
          events={(state) => {
            return {
              ...events(state),
              filter: (intersections) => {
                return intersections
                  .filter((i) => i.object.visible) // KEEP THIS AFTER TEMPORARY FIX IS REMOVED!
                  .sort((a, b) => {
                    const aPriority = getClosestPriority(a.object);
                    const bPriority = getClosestPriority(b.object);
                    return bPriority - aPriority;
                  });
              },
            };
          }}
          shadows={{ type: PCFSoftShadowMap }}
          onCreated={(state: RootState) => {
            state.camera.layers.enableAll();
            CanvasController.setCamera(state.camera);
            CanvasController.setScene(state.scene);
            // We only select objects on layer 0
            state.raycaster.layers.set(0);
            props.onCreated && props.onCreated(state);
            setRootState(state);

            state.gl.domElement.style.touchAction = 'none';

            // TODO should probably check with smaller objects to make sure we don't have precision issues and check scene size scaling
            state.raycaster.params.Mesh = {
              ...state.raycaster.params.Mesh,
              precision: 0.0001,
            };
          }}
          orthographic
          camera={{ manual: true }}
          style={{
            background: background,
            position: 'absolute',
            width: '100%',
            height: '100%',
            float: 'left',
          }}
        >
          <CanvasElements />
          <AdaptiveDpr pixelated />
        </Canvas>

        {isEditor && <ContextMenuHandler />}
        <Annotations rootState={rootState} />
      </AspectContainer>
    </div>
  );
};

// Temporary fix for View Cube click through bug.
// Bug description: https://github.com/pmndrs/react-three-fiber/issues/3338
const getClosestPriority = (o: Object3D): number => {
  if ((o as any)['__r3f'] !== undefined) {
    return ((o as any)['__r3f'] as LocalState).root.getState().events.priority;
  } else {
    return o.parent ? getClosestPriority(o.parent) : 0;
  }
};
