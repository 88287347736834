import { generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { ApiProperty } from '@assemblio/shared/decorators';

export class NotificationOptionsDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'the string key need to be replaced.', required: true })
  key!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'The id of the targeted record.', required: true })
  id!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'The value needed to be displayed in the message.', required: true })
  value!: string;
}

export const NotificationOptionsSchema = generateClassValidationObject(NotificationOptionsDto);
