import {
  MachineController,
  SelectionMode,
  SettingsController,
  StepController,
  UIController,
  useSettingsStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import _ from 'lodash';
import { useEffect } from 'react';

const onKeyDown = (event: KeyboardEvent) => {
  if (event.repeat) {
    return; // Prevent event from firing multiple times while held down
  }
  if ((event.target as HTMLElement).tagName.toLowerCase() === 'body') {
    switch (event.key) {
      case 'Control':
      case 'Meta': // For Mac Devices
        if (useSettingsStore.getState().selectionMode !== SelectionMode.ADD) {
          SettingsController.setSelectionMode(SelectionMode.ADD);
        }
        break;
      case 'Spacebar': //Intentional fallthrough for older browsers
      case ' ':
        UIController.centerCameraOnSelection();
        break;
      case 'p':
        if (event.altKey) MachineController.playOrPauseAnimation();
        break;
      case 's':
        // Use defer otherwise the state will not transition cleanly
        if (event.altKey) _.defer(() => MachineController.stopAnimation());
        break;
      case 'n':
        if (event.altKey) MachineController.skipForward();
        break;
      case 'b':
        if (event.altKey) MachineController.skipBackward();
        break;
      case 'w':
        UIController.setTransformAction('translate');
        break;
      case 'r':
        UIController.setTransformAction('rotate');
        break;
      case 'c':
        StepController.setStepCamera();
        break;
      case 'ArrowUp': {
        const view = useUIStore.getState().view;
        view === 'editor' ? StepController.selectPreviousStep() : StepController.selectNextStep();
        break;
      }
      case 'ArrowDown': {
        const view = useUIStore.getState().view;
        view === 'editor' ? StepController.selectNextStep() : StepController.selectPreviousStep();
        break;
      }
      case 'Shift': {
        const view = useUIStore.getState().view;
        if (view === 'editor') UIController.toggleSelection(true);
        break;
      }
      // Disabled until every undo redo action is working correctly
      // case 'z':
      //   if (event.ctrlKey) {
      //     event.preventDefault();
      //     UndoRedoController.applyUndoVersion();
      //   }
      //   break;
      // case 'y':
      //   if (event.ctrlKey) {
      //     event.preventDefault();
      //     UndoRedoController.applyRedoVersion();
      //   }
      //   break;
    }
  }
};

const onKeyUp = (event: KeyboardEvent) => {
  switch (event.key) {
    case 'Control':
    case 'Meta': // For Mac Devices
      SettingsController.setSelectionMode(SelectionMode.REPLACE);
      break;
    case 'Shift': {
      UIController.toggleSelection(false);
      break;
    }
  }
};

export const useShortcuts = (): void => {
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, []);
};
