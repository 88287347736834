import { generateClassValidationObject, joiSchemaProperty, ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { InstructionFileType } from '@assemblio/type/file';
import { QueryOrder } from '@assemblio/server-next/common/interfaces';
import { ProductFileSortProps } from '@assemblio/type/product-file';

export class QueryInstructionFilesDto {
  @joiSchemaProperty(Joi.string().uuid().optional())
  @ApiProperty({ description: 'Tenant Id', required: false })
  tenantId?: string;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(InstructionFileType))
      .optional()
  )
  @ApiProperty({
    description: 'File Type',
    enum: InstructionFileType,
    required: false,
  })
  type?: InstructionFileType;

  @joiSchemaProperty(Joi.string().valid(...Object.values(ProductFileSortProps)))
  @ApiProperty({
    description: 'sort prop',
    required: false,
    enum: ProductFileSortProps,
  })
  sort?: string;

  @joiSchemaProperty(Joi.string().valid(...Object.values(QueryOrder)))
  @ApiProperty({ description: 'order type', required: false, enum: QueryOrder })
  order?: QueryOrder;

  @joiSchemaProperty(Joi.number().min(0).optional())
  @ApiProperty({
    description: 'Page no for pagination, used only with "itemsPerPage"',
    type: Number,
    required: false,
  })
  page?: number;

  @joiSchemaProperty(Joi.number().min(1).optional())
  @ApiProperty({
    description: 'Number of items per page for pagination',
    type: Number,
    required: false,
  })
  itemsPerPage?: number;
}

export const QueryInstructionFilesSchema = generateClassValidationObject(QueryInstructionFilesDto);
