import { ContextMenuItem } from '@assemblio/design-system';
import { usePartGroupNameReset, usePartNameReset } from '@assemblio/frontend/data-access';
import { ModelController, useProjectStore } from '@assemblio/frontend/stores';
import { ActionIcon, Menu, Switch } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { useState } from 'react';
import { HierarchyFilters } from '../types/hierarchy.types';
import { CSVModal } from './CSVImport/CSVModal';
import { ConfirmationDialog } from './ConfirmationDialog';
import classes from './Hierarchy.module.scss';

interface HierarchyMenuProps {
  onChange: (filters: HierarchyFilters) => void;
}

export const HierarchyMenu = ({ onChange, showExcluded, showDisassembled }: HierarchyMenuProps & HierarchyFilters) => {
  const [csvModalOpened, setCSVModalOpened] = useState(false);
  const [resetDialogOpened, setResetDialogOpened] = useState(false);

  const partGroupNameResetMutation = usePartGroupNameReset();
  const partNameResetMutation = usePartNameReset();

  const handleResetNameConfirm = () => {
    const resets = ModelController.resetNames();
    partNameResetMutation.mutate({ ids: resets.resetedParts });
    partGroupNameResetMutation.mutate({ ids: resets.resetedAssemblies });
    setResetDialogOpened(false);
  };

  return (
    <>
      <Menu shadow="md" width={220} data-cy="verticalThreeDotBtn">
        <Menu.Target>
          <ActionIcon
            className={classes.menu_button}
            c="text-primary"
            mt={4}
            variant="transparent"
            size="xs"
            data-cy="icon-dots-vertical"
          >
            <IconDotsVertical />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown data-cy="verticalThreeDotBtnDropdown">
          <Menu.Label>Filter</Menu.Label>
          <ContextMenuItem
            closeMenuOnClick={false}
            label={'Show disassembled'}
            rightSection={
              <Switch
                size="xs"
                checked={showDisassembled}
                onChange={(event) => {
                  onChange({
                    showDisassembled: event.currentTarget.checked,
                    showExcluded: showExcluded,
                  });
                }}
              />
            }
          />
          <ContextMenuItem
            closeMenuOnClick={false}
            label={'Show excluded'}
            rightSection={
              <Switch
                size="xs"
                checked={showExcluded}
                onChange={(event) => {
                  onChange({
                    showDisassembled: showDisassembled,
                    showExcluded: event.currentTarget.checked,
                  });
                }}
              />
            }
          />

          <Menu.Divider />
          <Menu.Label>Part Names</Menu.Label>
          <ContextMenuItem label={'Rename by CSV'} onClick={() => setCSVModalOpened(true)} data-cy="rename-by-csv" />
          <ContextMenuItem label={'Download CSV'} onClick={handleCSVDownload} data-cy="dowdnload-csv" />
          <ContextMenuItem label={'Reset Names'} onClick={() => setResetDialogOpened(true)} data-cy="reset-option" />
        </Menu.Dropdown>
      </Menu>
      <CSVModal opened={csvModalOpened} setOpened={setCSVModalOpened} />
      <ConfirmationDialog
        opened={resetDialogOpened}
        setOpened={setResetDialogOpened}
        handleConfirm={handleResetNameConfirm}
      />
    </>
  );
};

const handleCSVDownload = () => {
  const names = [...new Set(ModelController.getPartAndAssemblyNames())];
  const projectName = useProjectStore.getState().name;
  const csv = Papa.unparse({
    fields: ['Current name', 'New name'],
    data: names.map((currentName) => [currentName, currentName]),
  });

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, `${projectName}_part_list`);
};
