// ToDo: find a proper language library that work well for us
import { en } from '@assemblio/shared/translations';
import { CreateGlobalNotificationDto } from "./create-global-notification.dto";
import { CreateNotificationDto } from "./create-notification.dto";

export const notificationScopeValidator = (obj:  CreateNotificationDto | CreateGlobalNotificationDto)=> {
  const keys = en[obj.scope]?.match(/[^{\\}]+(?=})/g) || [];
  for (const key of keys) {
    if (!obj.options.find(item => item.key === key)) throw new Error(`${key} must be specified for the scope ${obj.scope}`);
  }
}
