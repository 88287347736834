import { generateClassValidationObject, joiSchemaProperty, ApiProperty } from '@assemblio/shared/decorators';
import { UserRoles } from '@assemblio/type/user';
import * as Joi from 'joi';

export class UserDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'User Id' })
  id!: string;

  @joiSchemaProperty(
    Joi.string()
      .email({ tlds: { allow: false } })
      .required()
  )
  @ApiProperty({ description: 'User Email', required: true })
  email!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'First name', required: true })
  firstName!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Last name', required: true })
  lastName!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Full name', required: true })
  fullName!: string;

  @joiSchemaProperty(Joi.string().valid(...Object.values(UserRoles)))
  @ApiProperty({ description: 'User role', enum: UserRoles, required: false })
  role?: string;

  @joiSchemaProperty(Joi.date())
  @ApiProperty({ description: 'expires at', required: false })
  expiresAt?: Date | null;

  @joiSchemaProperty(Joi.string())
  @ApiProperty({ description: 'Tenant id', required: false })
  tenantId?: string;

  @joiSchemaProperty(Joi.string())
  @ApiProperty({ description: 'Tenant name', required: false })
  tenantName?: string;
}

export const UserSchema = generateClassValidationObject(UserDto);
