import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class FavoriteInstructionsParamDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({
    description: 'productId Id',
    required: true,
  })
  productId!: string;
}

export const FavoriteInstructionsParamSchema = generateClassValidationObject(FavoriteInstructionsParamDto);
