import { useInstructionSyncProfileDelete } from '@assemblio/frontend/data-access';

export const useDeleteSyncProfile = () => {
  const deleteSyncProfileMutation = useInstructionSyncProfileDelete();

  const deleteSyncProfile = (variantToDeleteId: string, targetVariantId: string, projectId?: string) => {
    deleteSyncProfileMutation.mutate({
      sourceId: variantToDeleteId,
      targetId: targetVariantId,
      projectId,
    });
  };

  return { onDeleteSyncProfile: deleteSyncProfile, isLoading: deleteSyncProfileMutation.isLoading };
};
