import { generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { ApiProperty } from '@assemblio/shared/decorators';
import { UserDto } from './response.dto';
import { RoleDto, RoleSchema } from './role.dto';
import * as Joi from 'joi';

export class UserPopulatedDto extends UserDto {
  @joiSchemaProperty(Joi.array().items(RoleSchema).required())
  @ApiProperty({
    description: 'list of roles',
    isArray: true,
    type: [RoleDto],
    required: true,
  })
  roles!: RoleDto[];
}

export const UserPopulatedSchema = generateClassValidationObject(UserPopulatedDto);
