import {
  TransformDto,
  TextDto,
  QuaternionDto,
  VectorDto,
  QuaternionSchema,
  VectorSchema,
  TransformSchema,
} from '@assemblio/shared/dtos';
import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class StepCameraTransformDto {
  @ApiProperty({ description: 'Rotation', required: true, type: QuaternionDto })
  @joiSchemaProperty(QuaternionSchema.required())
  rotation!: QuaternionDto;

  @ApiProperty({ description: 'Position', required: true, type: VectorDto })
  @joiSchemaProperty(VectorSchema.required())
  position!: VectorDto;

  @ApiProperty({ description: 'Pivot', required: false, type: VectorDto })
  @joiSchemaProperty(VectorSchema)
  pivot?: VectorDto;
}
export const StepCameraTransformSchema = generateClassValidationObject(StepCameraTransformDto);

export class StepCameraSettingsDto {
  @ApiProperty({ description: 'Camira id', required: false })
  @joiSchemaProperty(Joi.string().uuid())
  id?: string;

  @ApiProperty({ description: 'Near', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  near!: number;

  @ApiProperty({ description: 'Far', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  far!: number;

  @ApiProperty({ description: 'Zoom', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  zoom!: number;

  @ApiProperty({ description: 'Distance', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  distance!: number;

  @ApiProperty({ description: 'Projection', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  projection!: number;

  @ApiProperty({ description: 'Transform', required: true, type: StepCameraTransformDto })
  @joiSchemaProperty(StepCameraTransformSchema.required())
  transform!: StepCameraTransformDto;
}
export const StepCameraSettingsSchema = generateClassValidationObject(StepCameraSettingsDto);

export class PartInStepDto {
  @ApiProperty({ description: 'gltf index', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  partGltfIndex!: number;

  @ApiProperty({ description: 'Start', required: true, type: TransformDto })
  @joiSchemaProperty(TransformSchema.required())
  start!: TransformDto;

  @ApiProperty({ description: 'End', required: true, type: TransformDto })
  @joiSchemaProperty(TransformSchema.required())
  end!: TransformDto;
}
export const PartInStepSchema = generateClassValidationObject(PartInStepDto);

export class StepDataDto {
  @ApiProperty({ description: 'Path', required: true, type: [TransformDto] })
  @joiSchemaProperty(Joi.array().items(TransformSchema).required())
  path!: TransformDto[];

  @ApiProperty({ description: 'Parts', required: true, type: StepCameraTransformDto })
  @joiSchemaProperty(Joi.array().items(PartInStepSchema).required())
  parts!: PartInStepDto[];
}
export const StepDataSchema = generateClassValidationObject(StepDataDto);
