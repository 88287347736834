import { useModelStore, useSettingsStore, useUIStore } from '@assemblio/frontend/stores';
import { shallow } from 'zustand/shallow';

interface Props {
  extent?: number;
}
export const Surface = ({ extent }: Props) => {
  if (extent === undefined) extent = 2;
  const yOffset = useModelStore((state) => state.yOffset);
  const detectionEnabled = useUIStore((state) => state.enableCollisionDetection);
  const { isTableEnabled, tableColor } = useSettingsStore(
    (state) => ({
      isTableEnabled: state.viewport.table.enabled,
      tableColor: state.viewport.table.color,
    }),
    shallow
  );
  if (!detectionEnabled) return null;
  return (
    // yOffset * 1.001 to avoid z-fighting with the grid
    <mesh receiveShadow layers={31} rotation={[Math.PI / -2, 0, 0]} position={[0, yOffset * 1.001, 0]}>
      <planeGeometry args={[extent, extent, 4, 4]} />
      <shadowMaterial opacity={0.5} />
      {isTableEnabled && <meshStandardMaterial color={tableColor} />}
    </mesh>
  );
};
