import { useAuthStore } from '@assemblio/frontend/data-access';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { EventController } from '../controller';
import { useEventStore } from '../stores';
import { RoomEvents, UserState } from '@assemblio/type/socket';

/**
 * Hook that subscribes to all globally relevant Websocket events
 */
export const useWebSocket = () => {
  const socket = useEventStore((state) => state.socket);
  const { accessToken, setSessionActive } = useAuthStore((state) => state);
  const queryClient = useQueryClient();
  useEffect(() => {
    // Internal socket.io connection established
    socket.on('connect', () => {
      EventController.setConnected(true);
    });

    socket.on('exception', (res: any) => {
      // here the bad payload details will be sent
      console.error('WS Error:', res);
    });

    socket.on('reconnect', () => {
      EventController.setConnected(true);
    });

    socket.on('disconnect', () => {
      EventController.setConnected(false);
    });

    socket.on('connect_error', (err) => {
      console.error(err.message);
    });

    EventController.subscribeRoom({ roomEvent: RoomEvents.User }, (message) => {
      setSessionActive(message.payload.state === UserState.Active);
    });

    return () => {
      socket.off('connect');
      socket.off('reconnect');
      socket.off('disconnect');
      socket.off('connect_error');
      socket.off(RoomEvents.User);
      socket.off(RoomEvents.Product);
      socket.off(RoomEvents.Job);
    };
  }, [socket, queryClient]);

  useEffect(() => {
    if (socket.disconnected && accessToken !== '') {
      EventController.connectSocket();
    }
  }, [socket, accessToken]);
};
