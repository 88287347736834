import { AuthImg } from '@assemblio/frontend/components';
import { ImageAnnotationFile, ImageAnnotation as ImageAnnotationType } from '@assemblio/type/annotation';
import { Box, Center, Group, Text, useMantineTheme } from '@mantine/core';
import { IconFaceIdError } from '@tabler/icons-react';
import { useRef } from 'react';
import 'react-resizable/css/styles.css';
import { CommonTools } from './CommonTools';
import { ResizableAnnotation, ResizableInterface } from './ResizableAnnotation';
import { ToolbarGroup } from './ToolbarGroup';

interface Props {
  annotationId: string;
  annotation: ImageAnnotationType;
  file?: ImageAnnotationFile;
}

export const ImageAnnotation = ({ annotation, annotationId, file }: Props) => {
  const theme = useMantineTheme();

  const resizableRef = useRef<ResizableInterface>(null);

  return (
    <ResizableAnnotation
      initialMode="display"
      annotationId={annotationId}
      annotation={annotation}
      lockAspectRatio={true}
      ref={resizableRef}
      toolbar={
        <Group gap="sm" justify={'center'}>
          <ToolbarGroup label="Common Options">
            <CommonTools annotationId={annotationId} isImage={true} />
          </ToolbarGroup>
        </Group>
      }
    >
      {file?.id ? (
        <Box
          style={{
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            userSelect: 'none',
          }}
        >
          <AuthImg
            src={`/annotations/images/${file.id}`}
            alt={file.originalName || 'Image not found'}
            width={'100%'}
            height={'auto'}
            onResize={(size, target) => {
              if (resizableRef.current) {
                const rect = target.getBoundingClientRect();
                resizableRef.current.setSizeParameters(rect.width, rect.height);
              }
            }}
          />
        </Box>
      ) : (
        <Box style={{ background: theme.colors.dark[5] }}>
          <Center>
            <IconFaceIdError size={100} />
            <Text>Image Not Found</Text>
          </Center>
        </Box>
      )}
    </ResizableAnnotation>
  );
};
