import { createStep as createStepRequest, updateStepData } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { OrthographicCamera } from 'three';
import { assertEvent } from 'xstate';
import { SequenceController, StepController, UIController } from '../controller';
import { useCanvasStore, useSequenceStore, useUIStore } from '../stores';
import { EditorActionArgs } from './types/machine.types';
import { StepType } from '@assemblio/type/step';

export const selectStep = ({ event }: EditorActionArgs) => {
  if (event.type === 'SELECT_STEP' && event.stepId) {
    UIController.selectStepById(event.stepId);
    SequenceController.setSelectedStepGroupByStepId(event.stepId);
    UIController.expandStepGroupByStepId(event.stepId);
    UIController.setPathSegment(event.stepId);
  } else {
    if (!useUIStore.getState().selectedStep?.id) {
      // when is this not the case? !useUIStore.getState().selectedStep?.id
      // After Step was created by the machine select the step which was just created
      const selectedStepGroup = SequenceController.getCurrentStepGroup();
      const lastStep = useSequenceStore.getState().stepGroups.at(selectedStepGroup.index)?.steps.at(-1);
      if (lastStep) {
        UIController.selectStepById(lastStep.id);
        SequenceController.setSelectedStepGroupByStepId(lastStep.id);
        UIController.expandStepGroupByStepId(lastStep.id);
        UIController.setPathSegment(lastStep.id);
      }
    }
  }
};

export const deselectStep = () => {
  UIController.deselectStep();
};

export const createStep = ({ event }: EditorActionArgs) => {
  assertEvent(event, 'CREATE_STEP');
  const camera = useCanvasStore.getState().camera as OrthographicCamera;
  if (camera) {
    const newStep = StepController.createStep(event.offsets, event.transforms, camera, StepType.assembly);

    const addedStep = StepController.addStep(newStep);

    if (addedStep && addedStep.data) {
      // This is a work around
      // We need to think about saving the part id in the step.parts directly
      const stepDto = StepController.stepToDto(addedStep);
      if (stepDto.data) {
        stepDto.data = StepController.sanitizeStepData(stepDto.data);
      }
      createStepRequest(stepDto).catch((error) => {
        notifications.show({
          id: 'create-step',
          message: 'Could not add step',
          color: 'red',
        });
        StepController.removeStep(addedStep.id);
      });
    }
  }
};

export const appendStep = ({ event }: EditorActionArgs) => {
  assertEvent(event, 'APPEND_STEP');
  const step = StepController.getSelectedStep();
  const selectedSegment = StepController.getSelectedSegment();
  if (step) {
    const { currentData, newData } =
      selectedSegment !== undefined
        ? StepController.updateSegmentTransform(step.id, selectedSegment.index, event.transform)
        : StepController.appendStep(step.id, event.transform);
    if (newData) {
      updateStepData({
        id: step.id,
        data: StepController.sanitizeStepData(newData),
      }).catch((error) => {
        notifications.show({
          id: 'update-step-data',
          message: 'Could not update step data',
          color: 'red',
        });
        StepController.setStepData(step.id, currentData);
      });
    }
  }
};
