import { Combobox, Group, Text } from '@mantine/core';
import classes from '../ItemSearch.module.scss';

interface SearchResultProps {
  name: string;
  id: string;
  user?: string;
  date?: Date;
}

export const SearchResult = ({ name, id, user, date }: SearchResultProps) => {
  return (
    <Combobox.Option key={id} value={id} className={classes.option} data-cy="searchResult">
      <Group w={'100%'} justify="space-between" align="center" gap="md" px="sm" py="sm">
        <Group wrap={'nowrap'} w={'100%'}>
          <div className={classes.info}>
            <Text lineClamp={1} size="sm" variant="medium">
              {name}
            </Text>
            {user && (
              <Text c="text-secondary" size="xs" fw={400}>
                {user}
              </Text>
            )}
          </div>
        </Group>
        {date && (
          <Text c="text-secondary" size="xs" fw={400}>
            {date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
          </Text>
        )}
      </Group>
    </Combobox.Option>
  );
};
