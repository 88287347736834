import { Vector } from '@assemblio/type/3d';
import { ActionIcon, Flex, Grid, Text, Tooltip } from '@mantine/core';
import { memo } from 'react';
import classes from '../Styles/Segment.module.scss';
import { IconCircleXFilled } from '@tabler/icons-react';

interface Props {
  vector: Vector;
  disabled: boolean;
  deletable: boolean;
  onDelete: () => unknown;
}

const VectorDisplay = ({ vector, disabled, deletable, onDelete }: Props) => {
  return (
    <Grid
      columns={10}
      gutter={'xs'}
      classNames={{
        root: classes.vectorDisplay_grid__root,
        inner: classes.vectorDisplay_grid__inner,
      }}
      h={'100%'}
    >
      <Grid.Col span={3}>
        <Tooltip label={vector.x.toPrecision(2)} openDelay={250}>
          <Flex className={classes.vectorBadge}>
            <Text lineClamp={1} size={'xs'}>
              <Text span>X</Text>
              {vector.x.toPrecision(2)}
            </Text>
          </Flex>
        </Tooltip>
      </Grid.Col>

      <Grid.Col span={3}>
        <Tooltip label={vector.y.toPrecision(2)} openDelay={250}>
          <Flex className={classes.vectorBadge}>
            <Text lineClamp={1} size={'xs'}>
              <Text span>Y</Text>
              {vector.y.toPrecision(2)}
            </Text>
          </Flex>
        </Tooltip>
      </Grid.Col>
      <Grid.Col span={3}>
        <Tooltip label={vector.z.toPrecision(2)} openDelay={250}>
          <Flex className={classes.vectorBadge}>
            <Text lineClamp={1} size={'xs'}>
              <Text span>Z</Text>
              {vector.z.toPrecision(2)}
            </Text>
          </Flex>
        </Tooltip>
      </Grid.Col>
      <Grid.Col span={1} className={classes.actionIcon}>
        {deletable && (
          <ActionIcon
            size="xs"
            disabled={disabled}
            variant="transparent"
            c={'text-secondary'}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              onDelete();
            }}
          >
            <IconCircleXFilled size="16" color={'var(--text-secondary)'} />
          </ActionIcon>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default memo(VectorDisplay);
