import { Stack, Switch } from '@mantine/core';
import { useStepModification } from '../Hooks/useStepModification';
import classes from '../Styles/StepSettings.module.scss';
import { StepNumberInput } from './StepNumberInput';
import { StepType } from '@assemblio/type/step';

interface StepSettingsProps {
  stepId: string;
  selected: boolean;
  stepType: StepType;
  mtm: number;
  animationSpeed: number;
  playWithAbove: boolean;
  isInteractable: boolean;
  isAlignmentStep: boolean;
}

export const StepSettings = ({
  stepId,
  selected,
  stepType,
  mtm,
  animationSpeed,
  playWithAbove,
  isInteractable,
  isAlignmentStep,
}: StepSettingsProps) => {
  const { handleChangeType, handleChangePlayWithAbove, handleMTMChange, handleChangeAnimationSpeed } =
    useStepModification();

  return (
    <Stack px={'lg'} gap={'xs'} mb={'0'} className={classes.settings_group}>
      <StepNumberInput
        stepId={stepId}
        selected={selected}
        disabled={!isInteractable}
        value={mtm}
        onSetValue={handleMTMChange}
        label={'MTM'}
        min={1}
        max={36000}
      />
      <StepNumberInput
        stepId={stepId}
        selected={selected}
        disabled={!isInteractable}
        value={animationSpeed}
        onSetValue={handleChangeAnimationSpeed}
        label={'Animation'}
        min={0.1}
        max={10}
      />

      <Switch
        color={'text-brand'}
        labelPosition={'left'}
        className={classes.settings_group__section}
        classNames={{
          body: classes.switch_body,
          label: classes.checkboxLabel,
        }}
        data-cy="is-alignment-step-checkbox"
        size="xs"
        label={'Alignment step'}
        disabled={!isInteractable}
        checked={isAlignmentStep}
        onChange={() => handleChangeType(stepId, stepType)}
      />
      <Switch
        color={'text-brand'}
        labelPosition={'left'}
        className={classes.settings_group__section}
        classNames={{
          body: classes.switch_body,
          label: classes.checkboxLabel,
        }}
        size="xs"
        disabled={!isInteractable}
        label={'Play with above'}
        checked={playWithAbove}
        onChange={(e) => handleChangePlayWithAbove(e, stepId)}
      />
    </Stack>
  );
};
