import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class MoveFolderDto {
  @ApiProperty({
    description: 'The folder id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The new parent folder id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  parentId!: string;
}

export const MoveFolderSchema = generateClassValidationObject(MoveFolderDto);
