import * as Joi from 'joi';
import 'reflect-metadata';

const JOI_SCHEMA_PROPS = 'JOI_SCHEMA_PROPS';

export const joiSchemaProperty = (propertySchema: Joi.Schema) => {
  return (target: object, propertyKey: string) => {
    if (!Reflect.hasMetadata(JOI_SCHEMA_PROPS, target)) {
      Reflect.defineMetadata(JOI_SCHEMA_PROPS, {}, target);
    }
    const joiProps: Record<string, Joi.Schema> = Reflect.getMetadata(
      JOI_SCHEMA_PROPS,
      target
    );
    joiProps[propertyKey] = propertySchema;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function generateClassValidationObject(classType: {
  prototype: Record<string, any>;
}): Joi.Schema {
  return Joi.object().keys(
    Reflect.getMetadata(JOI_SCHEMA_PROPS, classType.prototype)
  );
}
