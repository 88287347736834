import { ModelController, useUIStore } from '@assemblio/frontend/stores';
import { Button, Divider, Text } from '@mantine/core';
import { ContextMenu, ContextMenuProps } from './ContextMenu';
import { AssemblyExIncludeButton } from './AssemblyExIncludeButton';
import { HierarchyController } from 'libs/frontend/hierarchy/src/lib/components/hierarchy.controller';

export const AssemblyContextMenu = (props: ContextMenuProps) => {
  const assemblyContext = useUIStore((state) => state.assemblyContext);
  const modelName =
    assemblyContext !== undefined ? ModelController.getPartNameOverrideByGltfIndex(assemblyContext) : '';

  return (
    <ContextMenu opened={props.opened} onClose={props.onClose}>
      <Text size="sm">{modelName}</Text>
      <>
        <Divider my="sm" />
        <Button.Group orientation="vertical">
          {assemblyContext !== undefined && <AssemblyExIncludeButton assemblyContext={assemblyContext} />}
        </Button.Group>
      </>
    </ContextMenu>
  );
};

// I commented this out for now until we have more time for testing
{
  /* <Divider my="sm" />
<Button.Group orientation="vertical">
  <Button
    variant="default"
    onClick={() => {
      assemblyContext !== undefined && HierarchyController.changeExpansionBelow(assemblyContext, true);
    }}
  >
    Expand All
  </Button>
  <Button
    variant="default"
    onClick={() => {
      assemblyContext !== undefined && HierarchyController.changeExpansionBelow(assemblyContext, false);
    }}
    style={{ marginBottom: '5px' }}
  >
    Collapse All
  </Button>
</Button.Group> */
}
