import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { deleteJob, exportToMultipleFormats, retryJob } from './job.requests';
import { MultiExportDto } from '@assemblio/shared/dtos';

export const useDeleteJob = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (options: { instructionId: string; jobId: string }) => deleteJob(options.instructionId, options.jobId),
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['export-jobs', variables.instructionId]);
        queryClient.invalidateQueries(['jobs', variables.instructionId]);
      },
      onError: () => {
        notifications.show({
          id: 'job-error',
          message: 'Job could not be restarted',
          color: 'red',
        });
      },
    }
  );
};

export const useRetryJob = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (options: { instructionId: string; jobId: string }) => retryJob(options.instructionId, options.jobId),
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['export-jobs', variables.instructionId]);
      },
      onError: () => {
        notifications.show({
          id: 'job-error',
          message: 'Job could not be restarted',
          color: 'red',
        });
      },
    }
  );
};

const handleExportError = (error: unknown) => {
  let errorMessage = undefined;
  if (error instanceof AxiosError && error.response) {
    errorMessage = error.response.data.message;
  }
  notifications.show({
    id: 'export-error',
    message: errorMessage ? errorMessage : 'Export could not be started.',
    color: 'red',
  });
};

export const useProductExport = () => {
  const queryClient = useQueryClient();

  return useMutation((data: MultiExportDto) => exportToMultipleFormats(data), {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries(['jobs', variables.instructionId]);
      queryClient.invalidateQueries(['export-jobs', variables.instructionId]);
    },
    onError: (error: unknown) => {
      handleExportError(error);
    },
  });
};
