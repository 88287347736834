import { Camera as ThreeCamera } from 'three';
import { Transform } from '@assemblio/type/3d';

export enum Projection {
  ORTHOGRAPHIC,
  PERSPECTIVE,
}

export interface Camera {
  id?: string;
  near: number;
  projection: Projection;
  far: number;
  zoom: number;
  distance: number;
  transform: Transform;
}

export interface OrthographicCamera extends Camera {
  projection: Projection.ORTHOGRAPHIC;
}

declare module 'three' {
  interface Camera {
    distance: number;
  }
}

ThreeCamera.prototype.distance = 5;
