import { Modal } from '@mantine/core';
import { ResolveSyncIssuesModalContent } from './components/ResolveSyncIssuesModalContent/ResolveSyncIssuesModalContent';
import { SyncProfileRelationDto, SyncProfileResolvedPair } from '@assemblio/shared/next-types';
import { useInstructionSyncProfileQuery } from '@assemblio/frontend/data-access';

interface ResolveSyncIssuesModalProps {
  syncRelation: SyncProfileRelationDto;
  onUpdateSyncProfile: (
    sourceVariantId: string,
    targetVariantId: string,
    updatedPairs: SyncProfileResolvedPair
  ) => void;
  opened: boolean;
  onClose: () => void;
}

export const ResolveSyncIssuesModal = ({
  opened,
  syncRelation,
  onUpdateSyncProfile,
  onClose,
}: ResolveSyncIssuesModalProps) => {
  const { data, isLoading } = useInstructionSyncProfileQuery({
    sourceId: syncRelation.from.id,
    targetId: syncRelation.to.id,
  });

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <Modal title="Resolve synchronization issues" size={'lg'} opened={opened} onClose={handleCloseModal}>
      <ResolveSyncIssuesModalContent
        syncProfilePairs={data?.pairs}
        targetId={syncRelation.to.id}
        sourceId={syncRelation.from.id}
        onUpdateSyncProfile={onUpdateSyncProfile}
        isLoading={isLoading}
        onClose={handleCloseModal}
      />
    </Modal>
  );
};
