import { ActionIcon, AppShell, Box, Group, Stack } from '@mantine/core';
import classes from './Sidebar.module.scss';
import { Logo } from '@assemblio/frontend/components';
import { AdminSidebarButton, UserButton } from '../../components';
import {
  IconCalendarMonth,
  IconChevronsLeft,
  IconChevronsRight,
  IconFileFilled,
  IconStarFilled,
  IconTools,
} from '@tabler/icons-react';
import { SidebarItem } from './components/SidebarItems/SidebarItems';
import { useNavigate } from 'react-router-dom';
import { useV1CurrentUserQuery } from '@assemblio/frontend/data-access';
import { useHasFeatureFlag } from '../../hooks/HasFeatureFlag.hook';
import { FeatureFlag } from '@assemblio/type/feature-flag';
import { useMemo } from 'react';
import { useUIStore } from '@assemblio/frontend/stores';

interface SidebarProps {
  collapsed: boolean;
  onToggleCollapse: () => void;
}

export const Sidebar = ({ collapsed, onToggleCollapse }: SidebarProps) => {
  const navigate = useNavigate();
  const isViewer = useUIStore.getState().view === 'viewer';

  const { data: userData } = useV1CurrentUserQuery();
  const appRole = userData?.role?.toLowerCase();
  const resourceFeatureEnabled = useHasFeatureFlag(FeatureFlag.BetaResources);

  const sidebarItems = useMemo(() => {
    const items = [
      {
        name: 'Assembly Plans',
        icon: <IconFileFilled />,
        navigationTarget: '/explorer',
      },
      {
        name: 'Favorites',
        icon: <IconStarFilled />,
        navigationTarget: '/explorer/favorites',
      },
      {
        name: 'Recently Opened',
        icon: <IconCalendarMonth />,
        navigationTarget: '/explorer/recent',
      },
    ];

    if (!isViewer && resourceFeatureEnabled) {
      items.push({
        name: 'Operational Resources',
        icon: <IconTools />,
        navigationTarget: '/explorer/resources',
      });
    }

    return items;
  }, [resourceFeatureEnabled]);

  const handleLogoClick = () => {
    navigate('/explorer');
  };

  return (
    <AppShell.Navbar className={classes.container}>
      <Stack pt={'xl'} mx={'md'} gap={'lg'}>
        <Group w={'100%'} h={50} px={'md'} justify={'space-between'} wrap={'nowrap'} className={classes.topSection}>
          {!collapsed && <Logo onClick={handleLogoClick} width={'auto'} />}
          <ActionIcon
            size="sm"
            color="dimmed"
            variant="transparent"
            onClick={onToggleCollapse}
            className={classes.collapseButton}
          >
            {collapsed ? <IconChevronsRight /> : <IconChevronsLeft />}
          </ActionIcon>
        </Group>
        <Stack gap={'xs'}>
          {sidebarItems.map((item) => (
            <SidebarItem
              key={item.name}
              name={item.name}
              icon={item.icon}
              navigationTarget={item.navigationTarget}
              collapsed={collapsed}
            />
          ))}
        </Stack>
      </Stack>
      <Box m={'md'} mb={'lg'}>
        <UserButton collapsed={collapsed} />
        {appRole === 'manager' && <AdminSidebarButton collapsed={collapsed} appRole={appRole} />}
      </Box>
    </AppShell.Navbar>
  );
};
