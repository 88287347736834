import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class ProductTextBlockDto {
  @ApiProperty({
    description: 'The operational resource id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({
    description: 'The product id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  productId!: string;

  @ApiProperty({
    description: 'The header',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  headline!: string;

  @ApiProperty({
    description: 'The content text',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  text!: string;

  @ApiProperty({
    description: 'The block order',
    required: true,
    type: Number,
  })
  @joiSchemaProperty(Joi.number().required())
  order!: number;

  @ApiProperty({
    description: 'should be exported in the document?',
    required: true,
    type: Boolean,
  })
  @joiSchemaProperty(Joi.boolean().required())
  export!: boolean;

  @ApiProperty({
    description: 'Static table headers',
    required: false,
    type: [String],
  })
  @joiSchemaProperty(Joi.array().items(Joi.string()))
  tableHeaders?: string[];

  @ApiProperty({
    description: 'The operational resource updated at',
    required: false,
  })
  @joiSchemaProperty(Joi.date())
  updatedAt?: Date;

  @ApiProperty({
    description: 'The operational resource created at',
    required: false,
  })
  @joiSchemaProperty(Joi.date())
  createdAt?: Date;
}

export const ProductTextBlockSchema = generateClassValidationObject(ProductTextBlockDto);
