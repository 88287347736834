import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { Group, Text, UnstyledButton } from '@mantine/core';
import classes from './SidebarItems.module.scss';
import cx from 'clsx';

interface SidebarItemProps {
  name: string;
  icon: React.ReactNode;
  navigationTarget: string;
  collapsed: boolean;
}

export const SidebarItem = ({ name, icon, navigationTarget, collapsed }: SidebarItemProps) => {
  const navigate = useNavigate();
  const matches = useMatch(navigationTarget);
  const handleClick = () => {
    navigate(navigationTarget);
  };

  return (
    <UnstyledButton
      py={'sm'}
      px={'md'}
      className={cx(classes.item, { [classes.active]: !!matches, [classes.collapsed]: collapsed })}
      onClick={handleClick}
      data-cy="sideBarItems"
    >
      <Group>
        {icon}
        {!collapsed && (
          <Text size={'sm'} variant={'medium'}>
            {name}
          </Text>
        )}
      </Group>
    </UnstyledButton>
  );
};
