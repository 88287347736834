import { ResourceDetailsDto } from '@assemblio/shared/dtos';
import { Badge, Group, rem, Stack, Text } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import classes from './OperationalResource.module.scss';

interface ResourceHeaderProps {
  data: ResourceDetailsDto | undefined;
}

export const ResourceHeader = ({ data }: ResourceHeaderProps) => {
  return (
    <>
      <Group justify="start" className={classes.header}>
        <Stack>
          <Text fz="lg" fw={500}>
            {data?.text}
          </Text>
          <Group justify="start">
            <Text size={'sm'} fw={500}>
              {data?.mainCategory}
            </Text>
            <IconChevronRight style={{ width: rem(16), height: rem(16) }} stroke={2} />{' '}
            <Text size={'sm'} fw={500}>
              {data?.secondaryCategory ? (
                <Text size={'sm'} fw={500}>
                  {data.secondaryCategory}
                </Text>
              ) : (
                <Text fs="italic" c="dimmed" size={'sm'} fw={500}>
                  No Secondary
                </Text>
              )}
            </Text>
          </Group>
          <Group mt={10}>
            <Text tt="uppercase" fw={500}>
              Allowed Units:
            </Text>
            {data?.allowedUnits && data.allowedUnits.length > 0 ? (
              data.allowedUnits.map((unit) => (
                <Badge variant="light" color="gray" m={2} key={unit}>
                  {unit}
                </Badge>
              ))
            ) : (
              <Text m={2} fs="italic" c="dimmed" size={'sm'} fw={500}>
                No Units defined
              </Text>
            )}{' '}
          </Group>
        </Stack>
      </Group>
    </>
  );
};
