import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { InstructionImageColor, InstructionImageSize } from '@assemblio/type/instruction';

export class InstructionImageQueryDto {
  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(InstructionImageSize))
      .optional()
  )
  @ApiProperty({
    description: 'Instruction Image Size',
    enum: InstructionImageSize,
    required: false,
  })
  size: InstructionImageSize = InstructionImageSize.LARGE;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(InstructionImageColor))
      .optional()
  )
  @ApiProperty({
    description: 'Instruction Image Color',
    enum: InstructionImageColor,
    required: false,
  })
  color: InstructionImageColor = InstructionImageColor.LIGHT;
}

export const InstructionImageQuerySchema = generateClassValidationObject(InstructionImageQueryDto);
