import { Skeleton } from '@mantine/core';
import { useEffect } from 'react';
import { useResourcesByStepQuery } from '@assemblio/frontend/data-access';
import { StepResourceList } from './StepResourceList/StepResourceList';

interface StepDetailsProps {
  id: string;
}

export const StepDetails = ({ id }: StepDetailsProps) => {
  const { data, isLoading, refetch } = useResourcesByStepQuery({ stepId: id });

  useEffect(() => {
    refetch();
  }, [id]);

  return isLoading ? <Skeleton m={'lg'} w={'200px'} h={'3vh'} /> : <StepResourceList stepId={id} resources={data} />;
};
