import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { OperationalResourceUnits } from '@assemblio/type/resources';
import { StepResourceValueDto, StepResourceValueSchema } from './step-resource-value.dto';

export class ResourceDto {
  @ApiProperty({
    description: 'The operational resource id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({
    description: 'The operational resource name',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'The operational resource No.',
    required: false,
  })
  @joiSchemaProperty(Joi.string())
  resourceNo?: string;

  @ApiProperty({
    description: 'The operational resource descriptive text',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  text!: string;

  @ApiProperty({
    description: 'The operational resource unit of measurement',
    required: false,
  })
  @joiSchemaProperty(Joi.array().items(Joi.string().valid(...Object.values(OperationalResourceUnits))))
  allowedUnits?: OperationalResourceUnits[];
}

export const ResourceSchema = generateClassValidationObject(ResourceDto);

export class StepResourceDto {
  @ApiProperty({
    description: 'The resource to step id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({
    description: 'The step group id',
    required: false,
  })
  @joiSchemaProperty(StepResourceValueSchema)
  value!: StepResourceValueDto;

  @ApiProperty({
    description: 'The operational resource',
    required: true,
  })
  @joiSchemaProperty(ResourceSchema)
  resource!: ResourceDto;
}

export const StepResourceSchema = generateClassValidationObject(StepResourceDto);
