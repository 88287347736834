import { Uppy, UppyFile } from '@uppy/core';
import Tus from '@uppy/tus';
import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { FileMetaData, UppyConfig } from './uppyUploader.types';
import { useUppyState } from '@uppy/react';
import { ActiveUpload } from '../../../../../explorer/src/lib/types';
import { notifications } from '@mantine/notifications';

const DEFAULT_UPPY_CONFIG: UppyConfig = {
  acceptedFileTypes: ['.step', '.stp', '.p21', '.jt'],
  minNumberOfFiles: 1,
  maxChunkSize: 10 * 1024 * 1024,
  maxFileSize: 2 * 1024 * 1024 * 1024,
};

type UppyContext = {
  uppy: Uppy<FileMetaData>;
  activeUpload?: ActiveUpload;
  onCancelUpload?: () => void;
};

const UppyContext = createContext<UppyContext | null>(null);

export const UppyProvider = ({ children }: PropsWithChildren) => {
  const { acceptedFileTypes, minNumberOfFiles, maxChunkSize, maxFileSize } = DEFAULT_UPPY_CONFIG;
  // using split and trim to use the pre-existing file types array string value
  const allowedFileTypes = acceptedFileTypes?.map((item) => item.trim());

  const [uppy] = useState(() =>
    new Uppy<FileMetaData>({
      id: 'uppyInstance',
      autoProceed: false,
      allowMultipleUploadBatches: false,
      restrictions: {
        allowedFileTypes,
        minNumberOfFiles,
        maxNumberOfFiles: null,
        maxFileSize,
      },
      onBeforeFileAdded: () => true,
    }).use(Tus<FileMetaData, Record<string, never>>, {
      endpoint: '/upload-api/files',
      onBeforeRequest: (req, file) => {
        const { parentFolderId, projectId, fileName, token } = file.meta;

        // Abort the upload if the required meta fields are not set
        if (!parentFolderId || !projectId || !fileName) {
          console.debug(`Aborting upload - required meta fields are not set`, parentFolderId, projectId, fileName);
          req.abort().then(() => {
            uppy.cancelAll();
          });
          return;
        } else {
          req.setHeader('FolderId', parentFolderId);

          req.setHeader('ProjectId', projectId);

          req.setHeader('ProductName', fileName);
        }

        req.setHeader('OriginalFileName', file.name ?? '');
        req.setHeader('Token', `Bearer ${token}`);
      },
      onError(error) {
        console.log(error);
      },
      storeFingerprintForResuming: false,
      id: 'tus',
      chunkSize: maxChunkSize,
    })
  );

  const activeFiles = useUppyState(uppy, (state) => state.files);

  const getActiveUpload = () => {
    const activeFile = Object.values(activeFiles)[0];
    return activeFile ? transformUppyFile(activeFile) : undefined;
  };

  const handleCancelActiveUpload = () => {
    uppy.cancelAll();
    uppy.clear();
    notifications.show({
      message: 'Upload canceled',
    });
  };

  return (
    <UppyContext.Provider
      value={{
        uppy,
        activeUpload: getActiveUpload(),
        onCancelUpload: handleCancelActiveUpload,
      }}
    >
      {children}
    </UppyContext.Provider>
  );
};

export const useUppy = () => {
  const context = useContext(UppyContext);
  if (!context) {
    throw new Error('useUppy must be used within a UppyProvider');
  }
  return context;
};

const transformUppyFile = (file: UppyFile<FileMetaData, Record<string, never>>): ActiveUpload | undefined => {
  if (!file) return undefined;

  const progressPercentage = getUploadPercentage(file.progress);

  return {
    progress: progressPercentage,
    id: file.id,
    name: file.name,
    affectedProjectId: file.meta.projectId,
  };
};

const getUploadPercentage = (
  progress?: UppyFile<FileMetaData, Record<string, never>>['progress']
): number | undefined => {
  if (!progress) return undefined;
  const { bytesUploaded, bytesTotal } = progress;
  if (!bytesUploaded || !bytesTotal) return undefined;
  return (bytesUploaded / bytesTotal) * 100;
};
