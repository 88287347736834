export enum OperationalResourceMainCategory {
  TOOL = 'Tool',
  RESOURCE = 'Resource',
}

export enum OperationalResourceSecondaryCategory {
  CONSUMABLE = 'Consumable',
  SAFETY_EQUIPMENT = 'Safety Equipment',
  MEASURING_INSTRUMENT = 'Measuring Instrument',
  ELECTRICAL_COMPONENT = 'Electrical Component',
  PACKAGING_MATERIAL = 'Packaging Material',
}
