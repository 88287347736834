import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class CreateFolderDto {
  @ApiProperty({
    description: 'The parent folder id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  parentFolderId!: string;

  @ApiProperty({
    description: 'The tenant id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  tenantId!: string;

  @ApiProperty({
    description: 'The name of the folder',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;
}

export const CreateFolderSchema = generateClassValidationObject(CreateFolderDto);
