import { useEffect, useState } from 'react';
import { Menu, Avatar, Button, Group, Box, Text } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconHelpHexagonFilled } from '@tabler/icons-react';
import { useAuthStore, useV1CurrentUserQuery } from '@assemblio/frontend/data-access';
import classes from './UserButton.module.scss';
import { EventController } from '@assemblio/frontend/stores';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserSettings } from '../../hooks';
import { useLogout } from '@assemblio/frontend/hooks';
import { ContextMenuItem, IconLogOut, IconUserSettings } from '@assemblio/design-system';
import { useNavigate } from 'react-router-dom';
import { BearerType } from '@assemblio/type/auth';

interface UserButtonProps {
  collapsed: boolean;
}

export const UserButton = ({ collapsed }: UserButtonProps) => {
  const { openUserSettings, closeUserSettings, settingsOpened } = useUserSettings();
  const userQuery = useV1CurrentUserQuery();
  const localeLogout = useLogout();
  const authType = useAuthStore((state) => state.type);
  const [menuOpened, setMenuOpened] = useState(false);
  const { logout: auth0Logout } = useAuth0();
  const navigate = useNavigate();

  const clearSession = useAuthStore((state) => state.clearSession);

  const email = useAuthStore((state) => state.email);

  const handleMenuToggle = () => {
    setMenuOpened((prev) => !prev);
  };

  const handleMenuClose = () => {
    setMenuOpened(false);
  };

  const logout = async () => {
    if (!authType) return;
    authType === BearerType.Auth0
      ? auth0Logout({ logoutParams: { returnTo: `${window.location.origin}/login`, full: true } })
      : await localeLogout();
    EventController.disconnectSocket();
    clearSession();
    navigate('/login');
  };

  useEffect(() => {
    if (settingsOpened) {
      openUserSettings();
    } else {
      closeUserSettings();
    }
  }, [settingsOpened]);

  return (
    <Group className={`${classes.container} ${collapsed ? classes.collapsed : ''}`}>
      <Menu
        width={200}
        opened={menuOpened}
        onClose={handleMenuClose}
        data-cy="userButtonContainer"
        classNames={{
          dropdown: classes.menu_dropdown,
          item: classes.menu_item,
          itemLabel: classes.menu_item_label,
          label: classes.menu_label,
          divider: classes.menu_divider,
        }}
      >
        <Menu.Target>
          {collapsed ? (
            <Avatar
              className={classes.avatar}
              mb={'xs'}
              onClick={handleMenuToggle}
              radius={'100%'}
              size="md"
              name={userQuery?.data?.fullName}
              color={'initials'}
              data-cy="userButtonCollapsed"
            />
          ) : (
            <Button
              variant="subtle"
              onClick={handleMenuToggle}
              h={'100%'}
              c={'text-primary'}
              py={'xs'}
              fullWidth
              justify={'space-between'}
              leftSection={
                <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Avatar
                    radius={'100%'}
                    size="md"
                    name={userQuery?.data?.fullName}
                    color={'initials'}
                    data-cy="userButtonAvatar"
                  />
                  <Text variant={'medium'} ml={'md'} data-cy="userButtonName">
                    {userQuery.data ? `${userQuery.data.firstName} ${userQuery.data.lastName}` : 'User'}
                  </Text>
                </Box>
              }
              rightSection={
                menuOpened ? (
                  <IconChevronUp size={16} color={'var(--text-primary)'} data-cy="userButtonRightSection" />
                ) : (
                  <IconChevronDown size={16} color={'var(--text-primary)'} data-cy="userButtonRightSection" />
                )
              }
              data-cy="userButton"
            ></Button>
          )}
        </Menu.Target>
        <Menu.Dropdown p={0} py={'sm'} data-cy="userButtonMenu">
          <Menu.Label p="md" data-cy="email">
            {email}
          </Menu.Label>

          <ContextMenuItem
            LeftIcon={IconUserSettings}
            label={'User Settings'}
            onClick={openUserSettings}
            data-cy="userSettingsButton"
          />
          <ContextMenuItem
            px={'xs'}
            LeftIcon={IconHelpHexagonFilled}
            label="Help center"
            data-cy="userButtonHelp"
            externalLinkUrl={process.env['NX_PUBLIC_HELP_LINK']}
          />

          <Menu.Divider my={'sm'} />
          <ContextMenuItem LeftIcon={IconLogOut} label={'Logout'} onClick={logout} data-cy="userButtonLogout" />
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};
