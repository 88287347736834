import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { FolderDto } from '../folder';
import { ProductOverviewDto } from '../product';

export class ProjectContentDto {
  @ApiProperty({
    description: 'The project id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The name of the project',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'The products in the project',
    required: true,
  })
  @joiSchemaProperty(Joi.array().required())
  products?: ProductOverviewDto[];

  @ApiProperty({
    description: 'The parent folder',
    required: false,
  })
  folder?: FolderDto;
}

export const ProjectContentSchema = generateClassValidationObject(ProjectContentDto);
