import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class ProjectOverviewDto {
  @ApiProperty({
    description: 'The project id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The name of the project',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'The number of products in the project',
    required: true,
  })
  @joiSchemaProperty(Joi.number().required())
  productCount!: number;

  @ApiProperty({
    description: 'Thee ids of the products in the project',
    required: false,
  })
  @joiSchemaProperty(Joi.array().items(Joi.string()))
  productsWithImage?: string[];

  @ApiProperty({
    description: 'the last update date',
    required: false,
  })
  @joiSchemaProperty(Joi.date())
  updatedAt?: Date;

  @ApiProperty({
    description: 'the create date',
    required: false,
  })
  @joiSchemaProperty(Joi.date())
  CreatedAt?: Date;
}

export const ProjectOverviewSchema = generateClassValidationObject(ProjectOverviewDto);
