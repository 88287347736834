import { FolderContentDto, FolderDto, SearchInFolderResponseDto } from '@assemblio/shared/dtos';
import { axiosHandler } from '../utils/axios-utils';
import { CreateFolder, FolderDelete, FolderUpdate } from './types/folder.mutation.types';
import { FetchFolder, SearchInFolder } from './types/folder.query.types';

export const createFolder = async (folder: CreateFolder): Promise<FolderDto> => {
  return axiosHandler('post', 'v1/folders', { data: folder.folderData });
};

export const getRootFolder = async (): Promise<FolderDto> => {
  return axiosHandler('get', `v1/folders/root`);
};

export const updateFolder = async (folder: FolderUpdate): Promise<FolderDto> => {
  return axiosHandler('patch', `v1/folders/${folder.folderId}`, {
    data: folder.folderData,
  });
};

export const getFolder = async (data: FetchFolder): Promise<FolderContentDto> => {
  return typeof data.folderId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler('get', `v1/folders/${data.folderId}`, { params: data.queryData });
};

export const deleteFolder = async (folder: FolderDelete) => {
  return axiosHandler('delete', `v1/folders/${folder.folderId}`);
};

export const fetchFolderChildren = async (data: SearchInFolder): Promise<SearchInFolderResponseDto> => {
  return typeof data.folderId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `v1/folders/${data.folderId}/children`, { params: data.searchQuery });
};
