import { useAuthStore } from '@assemblio/frontend/data-access';
import { io, Socket } from 'socket.io-client';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { ClientToServerEvents, ServerToClientEvents } from '@assemblio/type/websocket';
import { RoomEvents } from '@assemblio/type/socket';

export interface EventStore {
  socket: Socket<ServerToClientEvents, ClientToServerEvents>;
  connected: boolean;
  // Record of number of active room subscriptions. Used to appropriately remove event listeners for rooms.
  channels: Record<RoomEvents, number>;
}

export const useEventStore = create<EventStore>()(
  subscribeWithSelector(
    devtools(
      () => {
        const socket = io({
          auth: (cb) => {
            cb({ token: useAuthStore.getState().accessToken });
          },
          autoConnect: false,
        });
        return {
          socket,
          connected: false,
          channels: {
            [RoomEvents.Job]: 0,
            [RoomEvents.Product]: 0,
            [RoomEvents.User]: 0,
          },
        } as EventStore;
      },
      {
        name: 'Event Store',
        stateSanitizer: <EventStore>(state: EventStore) => {
          return { ...state, socket: undefined };
        },
      }
    )
  )
);
