import { AppRole } from '@assemblio/type/user';
import { fetchV1CurrentUser } from '../users/user.requests';
import { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { useAuthStore } from '../stores/AuthStore';
import { ConfigService } from '@assemblio/frontend/stores';
import { refreshTokens } from './axios-base.utils';
import { BearerType } from '@assemblio/type/auth';

const setAuth0Token = async (auth0: Auth0ContextInterface<User>): Promise<string | undefined> => {
  try {
    const accessToken = await auth0.getAccessTokenSilently();
    const userInfo = await fetchV1CurrentUser({ headers: { Authorization: `Bearer ${accessToken}` } });
    useAuthStore.getState().setSession({
      userId: userInfo.id,
      email: userInfo.email,
      accessToken,
      appRole: (userInfo.role?.toLowerCase() as AppRole) || 'viewer',
      organizationId: userInfo.tenantId || '',
      tenant: {
        id: userInfo.tenantId || '',
        name: userInfo.tenantName || '',
        role: (userInfo.role?.toLowerCase() as AppRole) || 'viewer',
      },
      type: BearerType.Auth0,
    });
    return accessToken;
  } catch {
    auth0.logout({ openUrl: false });
    auth0.loginWithRedirect();
  }
  return undefined;
};

const setLocaleToken = async (): Promise<string | undefined> => {
  try {
    const token = await refreshTokens();
    if (token) return token;
    return undefined;
  } catch {
    return undefined;
  }
};

export const getAccessToken = async (
  auth0?: Auth0ContextInterface<User>
): Promise<{ token?: string; loading: boolean }> => {
  const authConfig = ConfigService.getAuthConfig();
  if (!authConfig.auth0 || !auth0) return { loading: false, token: await setLocaleToken() };
  else if (auth0.isLoading === true) return { loading: true };
  else if (auth0.isAuthenticated) return { loading: false, token: await setAuth0Token(auth0) };
  return { loading: false, token: await setLocaleToken() };
};
