import React, { forwardRef } from 'react';
import { TextInput, TextInputProps } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import classes from './SearchField.module.scss';

interface SearchFieldProps extends TextInputProps {
  variant?: 'default' | 'filled';
}

export const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>((props, ref) => {
  return (
    <TextInput
      classNames={classes}
      leftSection={<IconSearch size={16} color={'var(--text-primary)'} />}
      data-variant={props.variant || 'default'}
      {...props}
      ref={ref}
      data-cy="searchField"
    />
  );
});
