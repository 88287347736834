import * as Joi from 'joi';
import { joiSchemaProperty, generateClassValidationObject, ApiProperty } from '@assemblio/shared/decorators';
import { BrandingFileType } from '@assemblio/type/branding';

export class BrandingParamTypeDTO {
  @ApiProperty({
    description: 'File type',
    enum: BrandingFileType,
    name: 'type',
    required: true,
  })
  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(BrandingFileType))
      .required()
  )
  type!: BrandingFileType;
}

export const BrandingParamTypeSchema = generateClassValidationObject(BrandingParamTypeDTO);
