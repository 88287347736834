import { Card, Group, LoadingOverlay, Skeleton, Stack, Text } from '@mantine/core';
import classes from './ItemCard.module.scss';
import { ExplorerItem, ExplorerItemType, ExplorerProject } from '../../types/items.types';
import { useState } from 'react';
import { ItemContextMenu } from '../ItemContextMenu/ItemContextMenu';
import cx from 'clsx';
import { FolderShape } from './components/FolderShape/FolderShape';
import { useElementSize } from '@mantine/hooks';
import { ProjectThumbnail } from './components/ProjectThumbnail/ProjectThumbnail';
import { useUIStore } from '@assemblio/frontend/stores';

export interface ItemCardProps {
  item: ExplorerItem;
  onClick: (id: string) => void;
  isLoading?: boolean;
}
const BASE_WIDTH = 250;

export const ItemCard = ({ item, onClick, isLoading = false }: ItemCardProps) => {
  const isViewer = useUIStore.getState().view === 'viewer';
  const { id, name, subItemCount, type } = item;

  const [itemIsLoading, setItemIsLoading] = useState(false);

  const { ref, width } = useElementSize();

  return (
    <Card
      ref={ref}
      className={cx(classes.container, {
        [classes['container--project']]: type === 'Project',
        [classes['container--folder']]: type === 'Folder',
      })}
      onClick={() => onClick(id)}
      data-cy="itemCard"
      data-type={type.toLowerCase()}
    >
      {type === ExplorerItemType.Folder ? (
        <FolderShape isLoading={isLoading || itemIsLoading} scale={width / BASE_WIDTH} />
      ) : (
        <LoadingOverlay
          overlayProps={{
            radius: 'xl',
          }}
          visible={isLoading || itemIsLoading}
        />
      )}

      <Stack className={classes.content} justify={'end'} gap={'sm'}>
        {type === ExplorerItemType.Project && (
          <ProjectThumbnail subItemCount={item.subItemCount} productIds={item.productIds || []} />
        )}

        <Group px={'md'} wrap={'nowrap'} justify={'space-between'}>
          <Text size={'md'} fw={'bold'} lineClamp={2} data-cy="itemCardName">
            {name}
          </Text>
          {!isViewer && <ItemContextMenu item={item} setLoading={setItemIsLoading} />}
        </Group>

        <Text px={'md'} c={'text-tertiary'} size={'sm'} data-cy="itemCount">
          {subItemCount} {type === 'Project' ? 'products' : 'projects'}
        </Text>
      </Stack>
    </Card>
  );
};

export const ItemCardSkeleton = () => (
  <Card pl={'10%'} className={cx(classes.container, classes['container--project'])}>
    <Stack w={'100%'}>
      <Skeleton w={'80%'} h={'1.5rem'} />
      <Skeleton w={'40%'} h={'1rem'} />
    </Stack>
  </Card>
);
