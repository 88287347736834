import { OperationalResourceDto } from '@assemblio/shared/dtos';
import { Group, Loader, ScrollArea, Stack } from '@mantine/core';
import { OperationalResourceTable } from './components/OperationalResourceTable';
import { SearchResultType, SortingOption } from '../../types';
import { useResourceFilters } from './hooks/ResourceFilter.hook';
import { useResourceSearch } from './hooks/ResourceSearch.hook';
import { useDebouncedValue } from '@mantine/hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Filter } from '../Filter/Filter';
import { ItemSearch } from '../ItemSearch/ItemSearch';
import { SortMenu } from '../SortMenu';
import { useModalContext } from '@assemblio/frontend/components';
import { ExplorerItemType } from '../../pages/assembly-plans/types/items.types';

interface ProductsDisplayProps<T extends string | undefined> {
  resources: OperationalResourceDto[];
  isLoading?: boolean;
  isRefetching?: boolean;
  onSort: (value: string) => void;
  sortingValue?: SortingOption<T>;
  sortingOptions?: SortingOption<T>[];
}

export const ResourceDisplay = <T extends string | undefined>({
  resources,
  onSort,
  sortingValue,
  sortingOptions,
  isLoading = false,
  isRefetching = false,
}: ProductsDisplayProps<T>) => {
  const navigate = useNavigate();

  const { openModal } = useModalContext();
  const { filterProps } = useResourceFilters();

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500);

  const { results } = useResourceSearch(debouncedSearchTerm, resources);

  const handleSearchResultClick = (result: SearchResultType) => {
    navigate(`/explorer/resources/${result.value}`);
  };

  const handleEditResource = (id: string) => {
    openModal('upsert-operational-resource', { resourceId: id });
  };

  const handleViewUsage = (id: string) => {
    navigate(`/explorer/resources/${id}`);
  };

  return (
    <Stack gap={'xl'} h={'100%'}>
      <Group px={'xl'} wrap={'nowrap'} align={'center'} justify={'flex-start'}>
        <Filter filters={filterProps} />
        {isRefetching && <Loader size={'xs'} color={'text-primary'} />}
        <ItemSearch
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          isLoading={false}
          isEmpty={results.isEmpty}
          results={[
            {
              group: '',
              items: results.products.map((product) => {
                return {
                  label: product.name,
                  value: product.id,
                  type: ExplorerItemType.Product,
                };
              }),
            },
          ]}
          onResultClick={handleSearchResultClick}
        />
        {sortingOptions && (
          <SortMenu<T> sortOptions={sortingOptions} onSelectSortBy={onSort} sortBy={sortingValue?.value} />
        )}
      </Group>
      <ScrollArea scrollbars={'y'} type={'hover'} mah={'100%'} h={'100%'}>
        <OperationalResourceTable
          resources={resources}
          onEditResource={handleEditResource}
          onViewUsage={handleViewUsage}
          isLoading={isLoading}
        />
      </ScrollArea>
    </Stack>
  );
};
