import { Suspense, useMemo } from 'react';
import { useHierarchyStore } from './hierarchy.store';
import { HierarchyBranch } from './HierarchyBranch';
import { HierarchyLeaf } from './HierarchyLeaf';

export const HierarchyTree = () => {
  const tree = useHierarchyStore((state) => state.tree);

  const hierarchy = useMemo(() => {
    if (tree.isLeaf) {
      return <HierarchyLeaf tree={tree} />;
    } else {
      return <HierarchyBranch tree={tree} />;
    }
  }, [tree]);
  return <Suspense fallback={<h1>Rendering...</h1>}>{hierarchy}</Suspense>;
};
