import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import {
  createEmptyProject,
  deleteNextProject,
  deleteProject,
  moveProject,
  updateNextProject,
} from './project.requests';
import { NextProjectDelete, NextProjectMove, NextProjectUpdate } from './types/project.mutation.types';
import { CreateProjectDto } from '@assemblio/shared/next-types';

export const useProjectDelete = () => {
  const queryClient = useQueryClient();

  return useMutation((id: string) => deleteProject(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['projects']);
    },
  });
};

export const useCreateEmptyProject = () => {
  const queryClient = useQueryClient();

  return useMutation((project: CreateProjectDto) => createEmptyProject(project), {
    onSuccess: (data, values) => {
      queryClient.invalidateQueries(['folder', values.folderId]);
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('useCreateEmptyProject', error?.response?.data.message);
      }
    },
  });
};

export const useNextProjectDelete = () => {
  const queryClient = useQueryClient();

  return useMutation((project: NextProjectDelete) => deleteNextProject(project), {
    onSuccess: (data, values) => {
      if (values.parentFolderId) {
        queryClient.invalidateQueries(['folder', values.parentFolderId]);
      }
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('useProjectDelete', error?.response?.data.message);
      }
    },
  });
};

export const useNextProjectUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation((update: NextProjectUpdate) => updateNextProject(update), {
    onSuccess: (_data, values) => {
      const invalidateFolder = values.projectData.folderId;
      queryClient.invalidateQueries(['folder', invalidateFolder]).then(() => {
        queryClient.refetchQueries({
          queryKey: ['folder', invalidateFolder],
        });
      });
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('useNextProjectUpdate', error?.response?.data.message);
      }
    },
  });
};

export const useProjectMove = () => {
  const queryClient = useQueryClient();

  return useMutation((options: NextProjectMove) => moveProject(options), {
    onSuccess: (data, values) => {
      queryClient.invalidateQueries(['folder', values.oldParentFolderId]);
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('useProjectMove', error?.response?.data.message);
      }
    },
  });
};
