import { generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { UserDto, UserSchema } from './response.dto';

export class UserWithPasswordDto {
  @joiSchemaProperty(UserSchema.required())
  @ApiProperty({ description: 'User', type: UserDto, required: true })
  user!: UserDto;

  @joiSchemaProperty(Joi.string().min(8))
  @ApiProperty({ description: 'User password', required: false })
  password?: string;
}

export const UserWithPasswordSchema = generateClassValidationObject(UserWithPasswordDto);
