import { generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class UpdatePasswordDto {
  @joiSchemaProperty(Joi.string().min(8).required())
  @ApiProperty({ description: 'user password', required: true })
  password!: string;
}

export const UpdatePasswordSchema = generateClassValidationObject(UpdatePasswordDto);
