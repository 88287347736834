import { generateClassValidationObject, joiSchemaProperty, ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class PartsColorDto {
  @joiSchemaProperty(Joi.array().items(Joi.string().uuid()).required())
  @ApiProperty({ description: 'Part Ids', required: true, type: [String] })
  partIds!: string[];

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Color', required: true })
  color!: string;
}
export const PartsColorSchema = generateClassValidationObject(PartsColorDto);

export class PartColorDto {
  @joiSchemaProperty(Joi.string().uuid())
  @ApiProperty({ description: 'Part Id', required: true })
  partId!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Color', required: true })
  color!: string;
}
export const PartColorSchema = generateClassValidationObject(PartColorDto);
