import { useAuthStore } from '@assemblio/frontend/data-access';
import { NotificationData, notifications, useNotifications } from '@mantine/notifications';
import { IconCircleXFilled } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { useUppyEvent, useUppyState } from '@uppy/react';
import { useEffect } from 'react';
import { ProgressSnackbar } from './components/ProgressSnackbar/ProgressSnackbar';
import classes from './UploadNotification.module.scss';
import { useUppy } from './UppyContextProvider';
import { CustomUppyFile, FileMetaData, FileProgressBase } from './uppyUploader.types';
import { useExplorerRouteParams } from '../../../../../explorer/src/lib/hooks/ExplorerRouteParams.hook';
import { RestrictionError } from '@uppy/core/lib/Restricter';

interface Return {
  uploadFile: (file: File, meta: FileMetaData) => Promise<boolean>;
  removeFile: () => void;
  uploadReady: boolean;
  isUploadInProgress: boolean;
}

const PROGRESS_NOTIFICATION_DATA: Partial<NotificationData> = {
  icon: null,
  title: 'File uploading',
  classNames: classes,
  closeButtonProps: {
    classNames: {
      root: classes['close_button'],
    },
    icon: <IconCircleXFilled size={16} color={'var(--text-secondary)'} />,
  },
};

export const useUppyUploader = (): Return => {
  const queryClient = useQueryClient();
  const token = useAuthStore((state) => state.accessToken);
  const { uppy, onCancelUpload } = useUppy();

  const { projectId } = useExplorerRouteParams();
  const { notifications: activeNotifications } = useNotifications();

  const handleUploadStarted = (files: CustomUppyFile[]) => {
    const activeUpload = files[0];
    if (projectId === activeUpload.meta.projectId) {
      return;
    }
    showProgressNotification(activeUpload.id, activeUpload.meta.fileName);
  };

  const handleUploadProgress = (file: CustomUppyFile | undefined, progress: FileProgressBase) => {
    if (!file) return;
    if (projectId === file.meta.projectId) {
      const activeNotification = activeNotifications.find((notification) => notification.id === file.id);
      if (activeNotification) notifications.hide(activeNotification.id || file.id);
      return;
    }
    const { bytesUploaded, bytesTotal } = progress;
    if (!bytesUploaded || !bytesTotal) return;
    const percentage = (bytesUploaded / bytesTotal) * 100;

    updateProgressNotification(file.id, file.meta.fileName, percentage);
  };

  const onUploadSuccess = (file: CustomUppyFile | undefined) => {
    file && uppy.removeFile(file.id);
    const projectId = file?.meta.projectId;
    queryClient.invalidateQueries(['project', projectId]);
    uppy.cancelAll();
    uppy.clear();
  };

  useUppyEvent(uppy, 'upload-start', handleUploadStarted);
  useUppyEvent(uppy, 'upload-progress', handleUploadProgress);
  useUppyEvent(uppy, 'upload-success', onUploadSuccess);
  useUppyEvent(uppy, 'upload-error', () => {
    notifications.show({
      title: 'Error',
      message: 'An error occurred while uploading the file',
    });
  });

  const showProgressNotification = (id: string, name: string | undefined) => {
    notifications.show({
      id,
      autoClose: false,
      message: ProgressSnackbar({
        name: name ?? '',
        progress: 0,
        onCancelUpload,
      }),
      ...PROGRESS_NOTIFICATION_DATA,
    });
  };

  const updateProgressNotification = (id: string, name: string | undefined, progress: number) => {
    const activeNotification = activeNotifications.find((notification) => notification.id === id);

    if (!activeNotification) {
      showProgressNotification(id, name);
    }
    notifications.update({
      id: id,
      title: 'Upload successful',
      message: ProgressSnackbar({
        name: name ?? '',
        progress,
      }),
      autoClose: progress === 100 ? 2000 : false,
      ...PROGRESS_NOTIFICATION_DATA,
    });
  };

  const isUploadInProgress = useUppyState(uppy, (state) => {
    return Object.values(state.currentUploads).some((upload) => {
      return upload.fileIDs.length > 0;
    });
  });

  const handleAddFile = (file: File, meta: FileMetaData) => {
    let addedId: string;
    try {
      addedId = uppy.addFile({
        source: 'file input',
        name: file.name,
        type: '.STEP',
        data: file,
      });
    } catch (e: unknown) {
      if (!isRestrictionError(e)) {
        console.error('Error while adding file to uppy', e);
        return;
      }

      const error = e as RestrictionError<FileMetaData, Record<string, unknown>>;
      notifications.show({
        message: error.message,
      });

      return;
    }
    uppy.setFileMeta(addedId, meta);
    return;
  };

  const isRestrictionError = (e: unknown): e is RestrictionError<FileMetaData, Record<string, unknown>> => {
    return (e as RestrictionError<FileMetaData, Record<string, unknown>>).isUserFacing !== undefined;
  };

  /**
   * Uploads a file to the upload service.
   * @param file
   * @param meta
   * @return Promise<boolean> - Returns a promise that resolves to true if the upload was started successfully, false if the upload could not be started.
   */
  const handleUploadFile = async (file: File, meta: FileMetaData) => {
    handleAddFile(file, meta);
    return uppy
      .upload()
      .then(() => true)
      .catch(() => false);
  };

  const handleFileRemoval = () => {
    uppy.cancelAll();
    uppy.clear();
  };

  useEffect(() => {
    if (token) {
      uppy.setMeta({ token });
    }
  }, [token]);

  return {
    uploadFile: handleUploadFile,
    removeFile: handleFileRemoval,
    uploadReady: useUppyState(uppy, (state) => state.allowNewUpload),
    isUploadInProgress,
  };
};
