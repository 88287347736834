import { PropsWithChildren } from 'react';
import { Box, Group, Text } from '@mantine/core';

interface StepButtonInputProps {
  label: string;
}

export const StepButtonInput = ({ label, children }: PropsWithChildren<StepButtonInputProps>) => {
  return (
    <Group px={'lg'} mb={'md'} justify={'space-between'} gap={'0'}>
      <Text w={'40%'} c={'text-secondary'} size="sm">
        {label}
      </Text>
      <Box w={'50%'} mah={'2rem'} h={'2rem'}>
        {children}
      </Box>
    </Group>
  );
};
