const exportPDFCanceled = {
  id: 'export-pdf-canceled',
  title: 'Warning: Export canceled',
  message: 'This project does not contain any valid steps. Please switch to the editor to create assembly steps.',
};

const projectIsBeingInitialized = {
  title: 'Project is being initialized',
  message: 'Please wait until the initialization is complete.',
  autoClose: 5000,
  loading: true,
  color: 'red',
};

const instructionIsBeingInitialized = {
  title: 'Product is being initialized',
  message: 'Please wait until the initialization is complete.',
  autoClose: 5000,
  loading: true,
  color: 'red',
};

const instructionIsBeingProcessed = {
  title: 'Product is being processed',
  message: 'Please wait until the product finished processing.',
  autoClose: 5000,
  loading: true,
  color: 'red',
};

const instructionUploadInProgress = {
  title: 'Product is being uploaded',
  message: 'Please wait until the upload finished or cancel it.',
  autoClose: 5000,
  loading: true,
  color: 'red',
};

export const ErrorNotification = {
  exportPDFCanceled,
  projectIsBeingInitialized,
  instructionIsBeingInitialized,
  instructionIsBeingProcessed,
  instructionUploadInProgress,
};
