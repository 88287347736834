import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import {
  HeadingSchema,
  OrderedListSchema,
  ParagraphSchema,
  TextElement,
  UnorderedListSchema
} from './text-elements.dto';

export class TextDto {
  @joiSchemaProperty(
    Joi.array()
      .items(Joi.alternatives().try(ParagraphSchema, HeadingSchema, OrderedListSchema, UnorderedListSchema))
      .required()
  )
  @ApiProperty({
    description: 'Text Elements',
    isArray: true,
    required: true,
    type: Array<TextElement>
  })
  elements!: TextElement[];
}

export const TextSchema = generateClassValidationObject(TextDto);

export class SetTextDto {
  @joiSchemaProperty(TextSchema.required())
  @ApiProperty({
    description: 'TextDto',
    type: TextDto,
  })
  text!: TextDto;
}

export const SetTextSchema = generateClassValidationObject(SetTextDto);
