import { useCopyPasteAnnotations } from '@assemblio/frontend/hooks';
import { UIController, useAnnotationStore } from '@assemblio/frontend/stores';
import {
  ArrowAnnotation as ArrowAnnotationType,
  ImageAnnotation as ImageAnnotationType,
  ShapeAnnotation,
} from '@assemblio/type/annotation';
import { RootState } from '@react-three/fiber';
import { ReactNode, useEffect } from 'react';
import { Arrow } from './ArrowAnnotation';
import { EllipseAnnotation } from './EllipseAnnotation';
import { ImageAnnotation } from './ImageAnnotation';
import { RectAnnotation } from './RectAnnotation';
import { AnnotationDto } from '@assemblio/shared/dtos';

const getAnnotationComponent = (annotation: AnnotationDto, key: string): ReactNode => {
  switch (annotation.type) {
    case 'arrow':
      return <Arrow annotationId={annotation.id} annotation={annotation.meta as ArrowAnnotationType} key={key} />;
    case 'ellipse':
      return (
        <EllipseAnnotation annotationId={annotation.id} annotation={annotation.meta as ShapeAnnotation} key={key} />
      );
    case 'rect':
      return <RectAnnotation annotationId={annotation.id} annotation={annotation.meta as ShapeAnnotation} key={key} />;
    case 'image':
      return (
        <ImageAnnotation
          annotationId={annotation.id}
          annotation={annotation.meta as ImageAnnotationType}
          file={annotation.file}
          key={key}
        />
      );
    // case 'icon':
    //   return (
    //     <IconAnnotation
    //       annotation={annotation as IconAnnotationType & { id: string }}
    //       key={key}
    //     />
    //   );
    default:
      return <div />;
  }
};
interface Props {
  rootState?: RootState;
}

export const Annotations = ({ rootState }: Props) => {
  const annotationMap = useAnnotationStore((state) => state.annotationMap);

  useCopyPasteAnnotations();

  // Check if there are any image annotations, if not,
  // set assets loaded to true, otherwise wait for the
  // onLoad function of the div below to fire
  useEffect(() => {
    const hasImageAnnotations = Array.from(annotationMap.values()).some(
      (annotation) => annotation.data.type === 'image'
    );

    if (!hasImageAnnotations) {
      UIController.setAnnotationAssetsLoaded(true);
    }
  }, [annotationMap]);

  const annotations = Array.from(annotationMap).map(([id, annotationState], index) => {
    return (
      <div
        key={`annotation-${id}`}
        id={`${id}-annotation`}
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        {getAnnotationComponent(annotationState.data, `annotation-${annotationState.data.type}-${index}`)}
      </div>
    );
  });

  return (
    <div
      style={{
        position: 'absolute',
        float: 'left',
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      }}
      className="annotations-container"
      onLoad={() => {
        UIController.setAnnotationAssetsLoaded(true);
      }}
    >
      {annotations}
    </div>
  );
};
