import { axiosHandler } from '../utils/axios-utils';
import { ExportJobDto, MultiExportDto } from '@assemblio/shared/dtos';

export const deleteJob = async (instructionId: string, jobId: string) => {
  return axiosHandler('delete', `/instructions/${instructionId}/jobs/${jobId}`);
};

export const retryJob = async (instructionId: string, jobId: string) => {
  return axiosHandler('post', `/instructions/${instructionId}/jobs/${jobId}/retry`);
};

export const fetchExportJobs = async (productId: string | undefined): Promise<ExportJobDto[]> => {
  return typeof productId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `/v1/instructions/${productId}/export/jobs`);
};

export const exportToMultipleFormats = async (exportDto: MultiExportDto) => {
  return axiosHandler('post', `/v1/instructions/${exportDto.instructionId}/export`, { data: exportDto });
};
