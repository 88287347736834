import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class OwnerDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'User Id' })
  id!: string;

  @joiSchemaProperty(
    Joi.string()
      .email({ tlds: { allow: false } })
      .required()
  )
  @ApiProperty({ description: 'User Email', required: true })
  email!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Full name', required: true })
  fullName!: string;
}

export const OwnerSchema = generateClassValidationObject(OwnerDto);
