import { useExplorerRouteParams } from '../../hooks/ExplorerRouteParams.hook';
import { useAuthStore, useProjectContentQuery } from '@assemblio/frontend/data-access';

import { useCallback, useEffect, useMemo } from 'react';
import { ProjectContentDto, ProjectContentQueryDto } from '@assemblio/shared/dtos';
import { ErrorScreen } from '../../components/ErrorScreen/ErrorScreen';
import { useExplorerTitle } from '../../hooks';
import { ProductsDisplay } from '../../components/ProductsDisplay/ProductsDisplay';
import { useSortingOptions } from '../../components';
import { ProjectContentSortProps } from '@assemblio/type/project';
import { PROJECT_QUERY_SORTING_OPTIONS } from './constants/projectQuerySortingOptions';
import { QueryOrder } from '@assemblio/type/common';
import { useSearchParams } from 'react-router-dom';
import { InstructionState } from '@assemblio/type/instruction';
import { useProjectWebsocketListeners } from './hooks/projectWebSocket.listener';
import { useUppy } from '@assemblio/frontend/hooks';

export const Project = () => {
  useProjectWebsocketListeners();
  const { projectId } = useExplorerRouteParams();
  const [searchParams] = useSearchParams();
  const { activeUpload } = useUppy();
  const userId = useAuthStore((state) => state.userId);

  const { sortingValue, handleSort } = useSortingOptions<ProjectContentSortProps>(PROJECT_QUERY_SORTING_OPTIONS);

  const ownerOnly = false;

  const ownerFilter = useCallback(
    (project: ProjectContentDto) => {
      if (!ownerOnly) return project;
      return {
        ...project,
        instructions: project.products?.filter((product) => product.owner.id === userId),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ownerOnly]
  );

  const queryData: ProjectContentQueryDto = useMemo((): ProjectContentQueryDto => {
    return {
      orderBy: sortingValue?.queryValue,
      orderType: sortingValue?.ascending ? QueryOrder.ASC : QueryOrder.DESC,
      owners: searchParams.get('owner') ?? undefined,
      states: (searchParams.get('state') as InstructionState) ?? undefined,
    };
  }, [sortingValue, searchParams]);

  const {
    data: projectData,
    isLoading,
    isRefetching,
    isError,
    refetch,
  } = useProjectContentQuery({
    projectId,
    queryData,
    ownerFilter,
  });

  useExplorerTitle(projectData?.name, {
    title: {
      itemCount: projectData?.products?.length,
    },
  });

  const products = projectData?.products ?? [];

  const displayedUpload = activeUpload?.affectedProjectId === projectId ? activeUpload : undefined;

  useEffect(() => {
    refetch();
  }, [sortingValue, searchParams]);

  if (isError) {
    return <ErrorScreen />;
  }

  return (
    <ProductsDisplay<ProjectContentSortProps>
      products={products}
      isLoading={isLoading}
      activeUpload={displayedUpload}
      isRefetching={isRefetching}
      sortingValue={sortingValue}
      onSort={handleSort}
      sortingOptions={PROJECT_QUERY_SORTING_OPTIONS}
    />
  );
};
