import {
  MachineController,
  ModelController,
  SelectionMode,
  StepController,
  TransformMode,
  UIController,
  dataToQuaternion,
  dataToVector3,
  useModelStore,
  useSettingsStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { ThreeTransform } from '@assemblio/type/3d';
import { notifications } from '@mantine/notifications';
import { TransformControls } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box3, Event, Group, Matrix4, Plane, Quaternion, Vector3 } from 'three';

const exceedsMovementThreshold = (previousTransform: ThreeTransform, currentTransform: ThreeTransform): boolean => {
  const selectedParts = UIController.getSelectedParts();
  const bounds = new Box3();
  selectedParts.forEach((part) => bounds.expandByObject(part));
  const extent = bounds.getSize(new Vector3()).length();
  return (
    previousTransform.position.distanceTo(currentTransform.position) >= extent / 1000 || // 1/1000 of the extent of the selected parts
    previousTransform.rotation.angleTo(currentTransform.rotation) >= (Math.PI * 2) / 360 // Min 1°
  );
};

export const ObjectControls = () => {
  const selectedStep = useUIStore((state) => state.selectedStep);
  const transformMode = useSettingsStore((state) => state.transformMode);
  const selectedParts = useUIStore((state) => state.selectedPartSet);
  const selectedSegment = useUIStore(
    useCallback((state) => selectedStep && state.selectedPathSegmentMap.get(selectedStep.id), [selectedStep])
  );
  const offsets = useRef(new Map<number, Matrix4>());
  const globalTransform = transformMode === TransformMode.GLOBAL;
  const gizmoState = useUIStore((state) => state.transformGizmo);
  const selectionMode = useSettingsStore((state) => state.selectionMode);
  const transformAction = useUIStore((state) => state.transformGizmo.action);
  const selectionGroup = useRef<Group>(null);
  const dragging = useUIStore((state) => state.isDragging);
  const { camera } = useThree();
  const yOffset = useModelStore((state) => state.yOffset);
  //const [initialTransform, setInitialTransform] = useState<ThreeTransform | null>();
  const initialTransform = useRef({ position: new Vector3(), rotation: new Quaternion() });

  useEffect(() => {
    const movePivotToMostAdvancedStepState = () => {
      if (selectedStep && selectionGroup.current) {
        const step = StepController.getStep(selectedStep.id);
        if (step) {
          const lastElement = step.data.path.at(-1);
          if (lastElement) {
            selectionGroup.current.position.copy(dataToVector3(lastElement.position));
            selectionGroup.current.quaternion.copy(dataToQuaternion(lastElement.rotation));
          }
        }
      }
    };
    if (selectedStep) {
      if (selectedSegment) {
        const step = StepController.getStep(selectedStep.id);
        const segment = step?.data.path.at(selectedSegment);
        if (segment) {
          selectionGroup.current?.position.copy(dataToVector3(segment.position));
          selectionGroup.current?.quaternion.copy(dataToQuaternion(segment.rotation));
        }
      } else {
        movePivotToMostAdvancedStepState();
      }
    } else {
      if (selectedParts.size > 0 && selectionGroup.current) {
        const selectedParts = UIController.getSelectedParts();
        const bounds = new Box3();
        selectedParts.forEach((part) => bounds.expandByObject(part));
        selectionGroup.current.position.copy(bounds.getCenter(new Vector3()));
        selectionGroup.current.rotation.set(0, 0, 0);
      }
    }
  }, [selectedParts, selectedStep, gizmoState.refresh, selectedSegment]);

  const collidesWithSurface = (): boolean => {
    const selectedParts = UIController.getSelectedParts();
    const bounds = new Box3();
    selectedParts.forEach((part) => bounds.expandByObject(part));
    const size = new Vector3();
    const fraction = bounds.getSize(size).y / 100; // base tollerance on height of the model
    const surface = new Plane(new Vector3(0, -1, 0), yOffset - fraction);
    const result = bounds.intersectsPlane(surface);
    UIController.setSurfaceCollisionDetected(result);
    return result;
  };

  const startTransform = (e?: Event) => {
    offsets.current.clear();
    if (selectionGroup.current) {
      UIController.setDragging(true);
      UIController.setSelectionActive(false);
      const { quaternion, position } = selectionGroup.current;
      //setInitialTransform({ rotation: quaternion.clone(), position: position.clone() });
      initialTransform.current = { rotation: quaternion.clone(), position: position.clone() };

      const pathMatrix = selectionGroup.current.matrix.clone().invert();
      selectedParts.forEach((gltfIndex) => {
        const model = ModelController.getModelByGltfIndex(gltfIndex);
        if (model && selectionGroup.current) {
          offsets.current.set(gltfIndex, pathMatrix.clone().multiply(model.matrix));
        }
      });
    }
  };

  const movePartsToProxy = () => {
    // Updating the matrix is important, because it hasn't been updated yet at this point
    selectionGroup.current && selectionGroup.current.updateMatrix();
    selectedParts.forEach((gltfIndex) => {
      const model = ModelController.getModelByGltfIndex(gltfIndex);
      const offset = offsets.current.get(gltfIndex);
      if (selectionGroup.current && offset && model) {
        selectionGroup.current.matrix.clone().multiply(offset).decompose(model.position, model.quaternion, model.scale);
      }
    });
  };

  const resetProxy = () => {
    if (selectionGroup.current) {
      selectionGroup.current.position.copy(dataToVector3(initialTransform.current.position));
      selectionGroup.current.rotation.set(0, 0, 0);
      movePartsToProxy();
    }
  };

  const changeTransform = () => {
    collidesWithSurface();
    if (useUIStore.getState().isDragging) {
      movePartsToProxy();
    }
  };

  const commitTransform = () => {
    // Get fresh isDragging state from store, because the component
    // dragging state doesn't update until a movement occurs.
    if (useUIStore.getState().isDragging && selectionGroup.current) {
      UIController.setDragging(false);
      // Deferring is important here, otherwise the UI can select another part before the action is complete
      _.defer(() => UIController.setSelectionActive(true));
      const { quaternion, position } = selectionGroup.current;
      const currentTransform = {
        rotation: quaternion.clone(),
        position: position.clone(),
      };
      if (exceedsMovementThreshold(initialTransform.current, currentTransform)) {
        const selectedStep = useUIStore.getState().selectedStep;
        if (!selectedStep) {
          if (StepController.canStepBeCreated()) {
            MachineController.createStep(offsets.current, [initialTransform.current, currentTransform], camera);
          } else {
            notifications.show({
              id: 'create-step-forbidden',
              message: 'Could not create the step, because parts it contains are used in subsequent steps.',
              color: 'red',
            });
            resetProxy();
          }
        } else {
          if (StepController.canStepBeAppended(selectedStep.id)) {
            MachineController.appendStep(currentTransform, camera);
          } else {
            notifications.show({
              id: 'append-alignment-step-forbidden',
              message: 'Could not append the step, because parts it contains are used in subsequent steps.',
              color: 'red',
            });
            resetProxy();
            StepController.movePartsInSelectedStepToDisassembledPosition();
          }
        }
      } else {
        // Reset the part positions
        resetProxy();
      }
      selectionGroup.current.rotation.set(0, 0, 0);
    }
  };

  const gizmoReady = gizmoState.show && selectionGroup;
  const hideCondition = selectionMode !== SelectionMode.ADD || dragging;
  const showGizmo = hideCondition && gizmoReady && selectedParts.size > 0 && selectedSegment !== 0;

  return (
    <>
      <group ref={selectionGroup} />
      {showGizmo && selectionGroup.current && (
        <TransformControls
          showX={globalTransform}
          showY={globalTransform}
          //translationSnap={0.1}
          rotationSnap={(gizmoState.snappingAngle * Math.PI) / 180}
          mode={transformAction}
          showZ={true}
          castShadow={false}
          space={globalTransform ? 'world' : 'local'}
          key={`transform-controls-${transformAction}`}
          onChange={changeTransform}
          onMouseDown={startTransform}
          onMouseUp={commitTransform}
          object={selectionGroup.current}
        />
      )}
    </>
  );
};
