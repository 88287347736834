import {
  PlaybackControls,
  ResizableContainer,
  SidebarTextRenderer,
  StepDetailsDrawer,
} from '@assemblio/frontend/components';
import { Hierarchy } from '@assemblio/frontend/hierarchy';
import { useEditorShortcuts, useShortcuts } from '@assemblio/frontend/hooks';
import { Sequencer } from '@assemblio/frontend/sequencer';
import { CanvasController, useUIStore } from '@assemblio/frontend/stores';
import { ResizableContainerRef } from '@assemblio/frontend/types';
import { Viewport, ViewportControls } from '@assemblio/frontend/viewport';
import { LoadingOverlay } from '@mantine/core';
import { RootState } from '@react-three/fiber';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import classes from './Disassembler.module.scss';

export function Disassembler() {
  const [initialized, setInitialized] = useState(false);

  const machine = useUIStore((state) => state.machine);
  const showSidebarTextRenderer = useUIStore((state) => state.showSidebarTextRenderer);

  useEffect(() => {
    if (machine) {
      machine.start();
    }
  }, [machine]);

  const [rootState, setRootState] = useState<RootState | undefined>();

  const hierarchyContainer = useRef<ResizableContainerRef>(null);
  const sequencerContainer = useRef<ResizableContainerRef>(null);

  useShortcuts();
  useEditorShortcuts(hierarchyContainer, sequencerContainer);
  //useDisassemblerShortcuts(); // currently empty

  useLayoutEffect(() => {
    if (rootState) {
      if (!initialized) {
        setInitialized(true);
      }
    }
  }, [rootState, initialized]);

  return (
    <div className={classes.viewportContainer}>
      <LoadingOverlay overlayProps={{ blur: 2 }} loaderProps={{ color: 'var(--text-brand)' }} visible={!initialized} />
      <Viewport
        onCreated={(rootState) => {
          setRootState(rootState);
          CanvasController.setCamera(rootState.camera);
        }}
      />
      <div className={classes.controlsAnchor} style={{ zIndex: 5 }}>
        <ResizableContainer maxWidthMargin={100} resizeHandle={'e'} ref={hierarchyContainer}>
          <Hierarchy />
        </ResizableContainer>

        <div className={classes.controlsContainer}>
          <div className={classes.viewportControls}>
            <ViewportControls />
          </div>
          <PlaybackControls />
          {showSidebarTextRenderer && <SidebarTextRenderer />}
          <StepDetailsDrawer />
        </div>
        <ResizableContainer maxWidthMargin={300} resizeHandle={'w'} ref={sequencerContainer}>
          <Sequencer />
        </ResizableContainer>
      </div>
    </div>
  );
}
