import { useBulkSetPartColor, useResetPartColor, useSetPartColor } from '@assemblio/frontend/data-access';
import { ModelController, useUIStore } from '@assemblio/frontend/stores';
import { ASSEMBLIO_PART_COLORS, COLOR_REEL, ColorCode } from '@assemblio/frontend/types';
import { ActionIcon, Button, ColorSwatch, Divider, Group, Menu, SimpleGrid, Stack, Tooltip } from '@mantine/core';
import { IconDice6, IconPalette, IconRotate } from '@tabler/icons-react';
import classes from '../Viewport.module.scss';
import { shallow } from 'zustand/shallow';

export const ColorPicker = () => {
  const { toolBarActive } = useUIStore(
    (state) => ({
      toolBarActive: state.toolbarActive,
    }),
    shallow
  );

  const setPartColorMutation = useSetPartColor();
  const resetPartColorMutation = useResetPartColor();
  const bulkSetParColorMutation = useBulkSetPartColor();

  const handleColorChangeOnSelectedPart = (colorCode: ColorCode) => {
    const selectedParts = Array.from(useUIStore.getState().selectedPartSet);
    const previousColors = ModelController.getColorByGltfIndex(selectedParts);
    ModelController.setUniformColorOfParts(selectedParts, colorCode.dec);
    const newColors = ModelController.getColorByGltfIndex(selectedParts);
    if (newColors.length > 0) {
      setPartColorMutation.mutate(
        {
          partIds: newColors.map((partAndColor) => partAndColor.partId),
          color: colorCode.dec.toString(),
        },
        {
          onError: () => {
            ModelController.setColorOfParts(previousColors);
          },
        }
      );
    }
  };

  const handleReset = () => {
    const selectedParts = Array.from(useUIStore.getState().selectedPartSet);
    const previousColors = ModelController.getColorByGltfIndex(selectedParts);
    ModelController.resetColorOfSelectedParts();
    const newColors = ModelController.getColorByGltfIndex(selectedParts);
    if (newColors.length > 0)
      resetPartColorMutation.mutate(
        {
          ids: newColors.map((partAndColor) => partAndColor.partId),
        },
        {
          onError: () => {
            ModelController.setColorOfParts(previousColors);
          },
        }
      );
  };

  const handleRandomize = () => {
    const selectedParts = Array.from(useUIStore.getState().selectedPartSet);
    const previousColors = ModelController.getColorByGltfIndex(selectedParts);
    ModelController.setRandomColorToParts(selectedParts, ASSEMBLIO_PART_COLORS);
    const newColors = ModelController.getColorByGltfIndex(selectedParts);
    if (newColors.length > 0) {
      const processedColors = newColors.map((partColor) => ({
        partId: partColor.partId,
        color: partColor.color.toString(),
      }));
      bulkSetParColorMutation.mutate(processedColors, {
        onError: () => {
          ModelController.setColorOfParts(previousColors);
        },
      });
    }
  };

  const swatches = ASSEMBLIO_PART_COLORS.map((color) => (
    <ColorSwatch
      withShadow={false}
      size={20}
      key={`swatch-color-${color.code.hex}-assemblio`}
      color={color.code.hex}
      radius="100%"
      onClick={() => handleColorChangeOnSelectedPart(color.code)}
      className={classes.colorPicker__colorButton}
    />
  ));

  const colorReelSwatches = COLOR_REEL.flatMap((colors, index) => {
    const swatches = colors.map((color) => (
      <ColorSwatch
        withShadow={false}
        size={20}
        key={`swatch-color-${color.code.hex}-reel`}
        color={color.code.hex}
        radius="100%"
        onClick={() => handleColorChangeOnSelectedPart(color.code)}
        className={classes.colorPicker__colorButton}
      />
    ));

    return (
      <Stack gap={'sm'} key={`color-stack-${index}`}>
        {swatches}
      </Stack>
    );
  });

  return (
    <Menu closeOnItemClick={false} disabled={!toolBarActive}>
      <Menu.Target>
        <Tooltip position="bottom" label="Set part color">
          <ActionIcon
            className={classes.controls__icon}
            c={toolBarActive ? 'text-secondary' : 'text-disabled'}
            size="sm"
            disabled={!toolBarActive}
            variant="transparent"
          >
            <IconPalette />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        <Stack justify="center" gap="sm" style={{ flexWrap: 'nowrap' }}>
          <Group gap={'sm'}>
            <Button
              className={classes.colorPicker__button}
              leftSection={<IconDice6 size={18} />}
              size={'compact-sm'}
              c={'text-primary'}
              variant="transparent"
              onClick={() => handleRandomize()}
            >
              Randomize
            </Button>
            <Button
              className={classes.colorPicker__button}
              leftSection={<IconRotate size={18} />}
              size={'compact-sm'}
              c={'text-primary'}
              variant="transparent"
              onClick={handleReset}
            >
              Reset
            </Button>
          </Group>
          <Divider size="sm" />
          <SimpleGrid spacing={'sm'} cols={6}>
            {[...swatches, ...colorReelSwatches]}
          </SimpleGrid>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
};
