import { ProductAction } from '../types/productActions.types';
import React from 'react';
import { Button, Divider, Text, Tooltip } from '@mantine/core';
import classes from '../ActionBar.module.scss';

interface ActionBarButtonProps {
  action: ProductAction;
}

export const ActionBarButton = ({ action }: ActionBarButtonProps) => {
  return (
    <React.Fragment>
      {action.component ? (
        action.component
      ) : (
        <Tooltip openDelay={250} disabled={!action.tooltipText || !action.disabled} label={action.tooltipText}>
          <Button
            classNames={{
              section: classes.action_button_icon,
            }}
            disabled={action.disabled}
            onClick={action.onClick}
            leftSection={action.Icon && <action.Icon />}
            variant="ghost"
            size={'compact-md'}
            px={'sm'}
            py={'xs'}
          >
            <Text size="sm" c={action.disabled ? 'text-disabled' : 'text-primary'} fw="bold">
              {action.label}
            </Text>
          </Button>
        </Tooltip>
      )}
      <Divider orientation="vertical" size="xs" h="16" className={classes.verticalDivider} />
    </React.Fragment>
  );
};
