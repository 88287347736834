import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getFolder, getRootFolder, fetchFolderChildren } from './folder.requests';
import { FetchFolder, SearchInFolder } from './types/folder.query.types';

export const useGetRootFolderQuery = () => {
  return useQuery(['rootFolder'], () => getRootFolder());
};

export const useGetFolderQuery = (data: FetchFolder) => {
  const queryClient = useQueryClient();
  return useQuery(
    ['folder', data.folderId, data.usedBy],
    () => {
      return getFolder(data);
    },
    {
      select: data.ownerFilter,
      enabled: Boolean(data.folderId),
      placeholderData: () => {
        return queryClient.getQueryData(['folder', data.folderId]);
      },
    }
  );
};



export const useFolderChildrenSearchQuery = (data: SearchInFolder) => {
  return useQuery(
    ['folderChildren', data],
    () => {
      return fetchFolderChildren(data);
    },
    {
      enabled: data.searchQuery.query.length > 0,
    }
  );
};
