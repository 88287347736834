import { Drawer, Group, Text, Stack, Divider } from '@mantine/core';
import classes from './ShortcutDrawer.module.scss';
import { IconCircleXFilled } from '@tabler/icons-react';
import { SHORTCUT_DATA } from './constants/Shortcuts';
import cx from 'clsx';
import { Fragment } from 'react/jsx-runtime';

interface ShortcutDrawerProps {
  opened: boolean;
  onClose: () => void;
}

export const ShortcutDrawer = ({ opened, onClose }: ShortcutDrawerProps) => {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="bottom"
      withOverlay={false}
      overlayProps={{ opacity: 0 }}
      title={'Keyboard Shortcuts'}
      closeButtonProps={{
        icon: <IconCircleXFilled size={16} color={'var(--text-secondary)'} onClick={onClose} />,
      }}
      classNames={{
        content: classes.drawer,
        body: classes.body,
        header: classes.header,
        title: classes.title,
      }}
    >
      <Group w={'100%'} gap={0} justify={'space-between'} align={'flex-start'}>
        {SHORTCUT_DATA.map((shortCutGroup, index) => (
          <Fragment key={`shortcut-group-${index}`}>
            <Stack gap={'lg'} className={classes.drawerColumn}>
              <Text fw={'bold'} size={'xs'}>
                {shortCutGroup.title}
              </Text>
              {shortCutGroup.shortcuts.map((shortCut, index) => (
                <Group justify={'space-between'} key={`shortcut-${index}`}>
                  <Text size={'sm'} variant={'medium'}>
                    {shortCut.label}
                  </Text>
                  <Group gap={'sm'} w={'30%'}>
                    <Text className={cx(classes.shortcut, classes.shortcut__key)} fw={'bold'} size={'xs'}>
                      {shortCut.keys[0]}
                    </Text>
                    <Text
                      className={cx(classes.shortcut, {
                        [classes.shortcut__key]: shortCut.keys[1] && shortCut.keys[1].split(' ').length === 1,
                        [classes.shortcut__label]: shortCut.keys[1] && shortCut.keys[1].split(' ').length > 1,
                      })}
                      fw={'bold'}
                      size={'xs'}
                    >
                      {shortCut.keys[1]}
                    </Text>
                  </Group>
                </Group>
              ))}
            </Stack>
            {index < SHORTCUT_DATA.length - 1 && <Divider orientation={'vertical'} />}
          </Fragment>
        ))}
      </Group>
    </Drawer>
  );
};
