import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class UpdateProductTextBlockDto {
  @ApiProperty({
    description: 'The header',
    required: false,
  })
  @joiSchemaProperty(Joi.string())
  headline?: string;

  @ApiProperty({
    description: 'The content text',
    required: false,
  })
  @joiSchemaProperty(Joi.string())
  text?: string;

  @ApiProperty({
    description: 'The block order',
    required: false,
    type: Number,
    min: 0,
  })
  @joiSchemaProperty(Joi.number().min(0))
  order?: number;

  @ApiProperty({
    description: 'Add to the export documents?',
    required: false,
    type: Boolean,
  })
  @joiSchemaProperty(Joi.boolean())
  export?: boolean;

  @ApiProperty({
    description: 'Add static table with specific headers',
    required: false,
    type: [String],
    default: [],
  })
  @joiSchemaProperty(Joi.array().items(Joi.string()))
  tableHeaders?: string[];
}

export const UpdateProductTextBlockSchema = generateClassValidationObject(UpdateProductTextBlockDto);
