import { Button, Divider, FileButton, Flex, Group, Modal, Stack, Text } from '@mantine/core';
import { IconUpload } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { ImageCropper } from '../components/ImageCropper/ImageCropper';

interface UploadImageModalProps {
  opened: boolean;
  onClose: () => void;
  imageUrl: string;
  onUploadImage: (file: File | Blob | undefined) => void;
}

// 5 MB
const MAX_FILE_SIZE = 5 * 1024 * 1024;

export const UploadImageModal = ({ opened, onClose, imageUrl, onUploadImage }: UploadImageModalProps) => {
  const handleClose = () => {
    setIsFileTooBig(false);
    onClose();
  };

  const [tempImage, setTempImage] = useState(imageUrl);

  const [isFileTooBig, setIsFileTooBig] = useState(false);

  const [tempImageCropped, setTempImageCropped] = useState<Blob | undefined>(undefined);

  const handleFileUpload = (file: File | null) => {
    if (!file) return;
    if (file.size > MAX_FILE_SIZE) {
      setIsFileTooBig(true);
      return;
    }
    const localUrl = URL.createObjectURL(file);
    setTempImage(localUrl);
    setIsFileTooBig(false);
  };

  useEffect(() => {
    setTempImage(imageUrl);
  }, [opened]);

  return (
    <Modal title={'Edit Logo'} size={'xl'} opened={opened} onClose={handleClose}>
      <Stack gap={0} ml={'xl'} mb={'xl'}>
        <Text>Upload a new Logo to be used in your exported assets.</Text>
      </Stack>
      <Stack align="center" gap={'md'}>
        <Flex bg={'white'} mx={'10%'}>
          <ImageCropper imageUrl={tempImage} onCropImage={(dataUrl) => setTempImageCropped(dataUrl)} />
        </Flex>
        <FileButton onChange={handleFileUpload} accept="image/png,image/jpeg,image/svg+xml">
          {(props) => (
            <Stack align={'center'} gap={'xs'}>
              {isFileTooBig && (
                <Text size={'xs'} c={'red'}>
                  The uploaded File is too large. File size should be less than 5MB.
                </Text>
              )}
              <Button variant="primary" rightSection={<IconUpload />} w={'350px'} {...props}>
                Upload new image
              </Button>
            </Stack>
          )}
        </FileButton>
        <Divider w={'100%'} />
        <Group
          w={'100%'}
          p={'lg'}
          pt={'xs'}
          style={{
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant={'secondary'}
            onClick={(e) => {
              handleClose();
            }}
          >
            Cancel
          </Button>

          <Button
            variant={'primary'}
            onClick={(e) => {
              onUploadImage(tempImageCropped);
            }}
          >
            Save
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
