import { useStepGroupCreate } from '@assemblio/frontend/data-access';
import {
  SequenceController,
  StepGroupController,
  UIController,
  useSequenceStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { StepGroup } from '@assemblio/type/step';
import type { DroppableProvided } from '@hello-pangea/dnd';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { Box, Button } from '@mantine/core';
import { IconCirclePlusFilled } from '@tabler/icons-react';
import _ from 'lodash';
import { useMemo } from 'react';
import { DroppableType, useStepDrop } from './Hooks/useStepDrop';
import { StepGroup as StepGroupComponent } from './StepGroup';

interface StepBoardProps {
  searchTerm: string;
  //scrollToBottom: () => void;
}

export const StepBoard = ({ searchTerm }: StepBoardProps) => {
  const isEditor = useUIStore((state) => state.view === 'editor');
  const isInteractable = useUIStore((state) => !state.isAnimating);
  let stepGroups = useSequenceStore((state) => state.stepGroups);

  const createStepGroupMutation = useStepGroupCreate();
  const { onDragEnd } = useStepDrop();

  const handleAddStepGroup = () => {
    const { stepGroup, prevStepGroupId } = SequenceController.addStepGroupAtEnd();
    if (stepGroup && prevStepGroupId) {
      createStepGroupMutation.mutate(
        {
          id: stepGroup.id,
          name: stepGroup.name,
          instructionSequenceId: stepGroup.instructionSequenceId,
          previousStepGroupId: prevStepGroupId,
        },
        {
          onError: () => {
            StepGroupController.deleteStepGroup(stepGroup.id);
            UIController.selectLastStepGroup();
          },
        }
      );
    }
    UIController.selectLastStepGroup();
  };

  stepGroups = useMemo(() => {
    if (!isEditor) {
      const clone = _.cloneDeep(stepGroups);
      clone.reverse().forEach((group) => {
        group.steps = _.cloneDeep(group.steps).reverse();
      });
      return clone;
    }

    return stepGroups;
  }, [stepGroups, isEditor]);

  stepGroups = useMemo(() => {
    return getFilteredStepList(stepGroups, searchTerm);
  }, [stepGroups, searchTerm]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <DragDropContext enableDefaultSensors={isEditor} onDragEnd={onDragEnd}>
        <Droppable droppableId="board" type={DroppableType.StepGroup} direction="vertical">
          {(provided: DroppableProvided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {stepGroups.map((stepGroup: StepGroup, index: number) => (
                <StepGroupComponent
                  key={stepGroup.id}
                  index={index}
                  stepGroup={stepGroup}
                  stepGroupsLength={stepGroups.length}
                />
              ))}
              {provided.placeholder}
              {isEditor && (
                <Box py={'sm'} px={'sm'}>
                  <Button
                    variant={'secondary'}
                    leftSection={<IconCirclePlusFilled size={16} color={'white'} />}
                    justify={'center'}
                    onClick={() => handleAddStepGroup()}
                    fullWidth
                    disabled={!isInteractable}
                  >
                    Add group
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const getFilteredStepList = (stepGroups: StepGroup[], searchTerm: string): StepGroup[] => {
  if (searchTerm.length === 0) return stepGroups;
  const trimmedSearchTerm = searchTerm.trim().toLowerCase();
  return stepGroups.map((stepGroup) => ({
    ...stepGroup,
    steps: stepGroup.steps.filter((step) => step.name.toLowerCase().includes(trimmedSearchTerm)),
  }));
};
