import { UIController, useUIStore } from '@assemblio/frontend/stores';
import { Button, Menu, Tooltip } from '@mantine/core';
import { ContextMenuItem } from '@assemblio/design-system';
import { IconLassoPolygon, IconSquare, IconChevronDown, IconCheck } from '@tabler/icons-react';
import classes from './Viewport.module.scss';
import { useState } from 'react';
import cx from 'clsx';

export const SelectionSwitch = () => {
  const { mode, active } = useUIStore((state) => state.selection);
  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  const toggleSelectionMode = (isActive: boolean) => {
    UIController.toggleSelection(isActive);
    setMenuOpened(false);
  };

  const handleToggleClick = (mode: 'lasso' | 'box') => {
    UIController.setSelectionMode(mode);
    toggleSelectionMode(true);
  };

  return (
    <Menu position="bottom" width={190} offset={8} opened={menuOpened}>
      <Menu.Target>
        <Tooltip offset={8} position="top" label="Choose Selection Mode">
          <Button.Group
            className={cx(classes.selection_switch_controls, {
              [classes.selection_switch_controls__active]: active,
            })}
          >
            <Button
              variant={'ghost'}
              size={'compact-sm'}
              c={'text-secondary'}
              onClick={() => toggleSelectionMode(!active)}
            >
              {mode === 'lasso' ? <IconLassoPolygon size={16} /> : <IconSquare size={16} />}
            </Button>
            <Button
              variant={'ghost'}
              size={'compact-xs'}
              pl={0}
              c={'text-secondary'}
              onClick={() => setMenuOpened(!menuOpened)}
            >
              <IconChevronDown size={16} />
            </Button>
          </Button.Group>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        <ContextMenuItem
          onClick={() => {
            handleToggleClick('box');
          }}
          label={'Box'}
          LeftIcon={IconSquare}
          RightIcon={mode === 'box' ? IconCheck : undefined}
        />
        <ContextMenuItem
          onClick={() => {
            handleToggleClick('lasso');
          }}
          label={'Lasso'}
          LeftIcon={IconLassoPolygon}
          RightIcon={mode === 'lasso' ? IconCheck : undefined}
        />
      </Menu.Dropdown>
    </Menu>
  );
};
