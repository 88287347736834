import { ProductContentDto } from '@assemblio/shared/dtos';

import { useAuthStore } from '@assemblio/frontend/data-access';
import { SyncProfileRelationDto } from '@assemblio/shared/next-types';
import { InstructionState } from '@assemblio/type/instruction';
import { useUIStore } from '@assemblio/frontend/stores';
import { AppRole } from '@assemblio/type/user';
import { useBaseProductAction } from './productActions.base';
import { useProductSyncActions } from './productActions.sync';
import { ProductAction, UserState } from '../types/productActions.types';
import { useIsOwner } from '@assemblio/frontend/hooks';

export const useProductActions = (product: ProductContentDto | undefined, syncProfiles?: SyncProfileRelationDto[]) => {
  const isViewer = useUIStore.getState().view === 'viewer';
  const isOwner = useIsOwner(product?.ownedBy.id);

  const { userId: currentUserId, appRole } = useAuthStore((state) => state);

  const filterProductState = (action: ProductAction): boolean => {
    if (action.allowedProductStates) {
      const allowedStates: (InstructionState | undefined)[] = action.allowedProductStates;
      return allowedStates.includes(product?.state);
    }
    return true;
  };
  const filterUserRole = (action: ProductAction): boolean => {
    if (action.allowedUserRoles) {
      const allowedRoles: (AppRole | undefined)[] = action.allowedUserRoles;
      return allowedRoles.includes(appRole);
    }
    return true;
  };

  const userState: UserState = {
    isOwner,
    currentUserId,
    isViewer,
  };

  const { productActions: baseActions, isLoading: isBaseActionsLoading } = useBaseProductAction({ product, userState });

  const { productActions: syncActions } = useProductSyncActions({ product, userState, syncProfiles });

  const sortedActions = [...baseActions, ...syncActions].sort((a, b) => {
    return (a.order || 100) - (b.order || 100);
  });

  const filteredActions = sortedActions.filter(filterProductState).filter(filterUserRole);

  return {
    productActions: filteredActions,
    isLoading: isBaseActionsLoading,
  };
};
