import { ProjectContentDto } from '@assemblio/shared/dtos';
import { useQuery } from '@tanstack/react-query';
import { fetchProjectContent } from './project.requests';
import { FetchProject } from './types/project.queries.types';

export const useProjectContentQuery = (data: FetchProject) => {
  return useQuery(
    ['project', data.projectId],
    () => {
      return fetchProjectContent(data);
    },
    {
      select: (project: ProjectContentDto) => {
        project.products = project.products?.filter((product) => product.state !== 'Uploading');
        return data.ownerFilter ? data.ownerFilter(project) : project;
      },
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};
