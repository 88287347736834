import * as Joi from 'joi';
import { joiSchemaProperty, generateClassValidationObject, ApiProperty } from '@assemblio/shared/decorators';
import { BrandingOptionsDTO, BrandingOptionsSchema } from './options.dto';

export class UpdateBrandingDTO {
  @ApiProperty({
    description: 'options',
    type: BrandingOptionsDTO,
    required: false,
  })
  @joiSchemaProperty(BrandingOptionsSchema.optional())
  options?: BrandingOptionsDTO;

  @ApiProperty({
    description: 'the primary branding color',
    required: false,
  })
  @joiSchemaProperty(
    Joi.string()
      .regex(/^#(?:[0-9a-fA-F]{3}){1,2}$/)
      .optional()
  )
  headerColor?: string;

  @ApiProperty({
    description: 'the primary branding color',
    required: false,
  })
  @joiSchemaProperty(
    Joi.string()
      .regex(/^#(?:[0-9a-fA-F]{3}){1,2}$/)
      .optional()
  )
  titleColor?: string;

  @ApiProperty({
    description: 'the primary branding color',
    required: false,
  })
  @joiSchemaProperty(
    Joi.string()
      .regex(/^#(?:[0-9a-fA-F]{3}){1,2}$/)
      .optional()
  )
  textColor?: string;

  @ApiProperty({
    description: 'the primary branding color',
    required: false,
  })
  @joiSchemaProperty(
    Joi.string()
      .regex(/^#(?:[0-9a-fA-F]{3}){1,2}$/)
      .optional()
  )
  footerColor?: string;
}

export const UpdateBrandingSchema = generateClassValidationObject(UpdateBrandingDTO);
