import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { StepResourceValueDto, StepResourceValueSchema } from './step-resource-value.dto';

export class LinkResourceToStepDto {
  @ApiProperty({
    description: 'The operational resource id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  resourceId!: string;

  @ApiProperty({
    description: 'The step id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  stepId!: string;

  @ApiProperty({
    description: 'The product id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  productId!: string;

  @ApiProperty({
    description: 'additional value info',
    required: false,
  })
  @joiSchemaProperty(StepResourceValueSchema)
  value?: StepResourceValueDto;
}

export const LinkResourceToStepSchema = generateClassValidationObject(LinkResourceToStepDto);
