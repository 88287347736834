import * as Joi from 'joi';
import { joiSchemaProperty, generateClassValidationObject, ApiProperty } from '@assemblio/shared/decorators';

export class BrandingOptionsDTO {
  @ApiProperty({
    description: 'Show the date',
    type: Boolean,
    required: false,
    default: true,
  })
  @joiSchemaProperty(Joi.boolean().optional())
  showDate? = true;

  @ApiProperty({
    description: 'Show contributor',
    type: Boolean,
    required: false,
    default: true,
  })
  @joiSchemaProperty(Joi.boolean().optional())
  showContributor? = true;

  @ApiProperty({
    description: 'Show part count',
    type: Boolean,
    required: false,
    default: true,
  })
  @joiSchemaProperty(Joi.boolean().optional())
  showPartCount = true;

  @ApiProperty({
    description: 'Show step count',
    type: Boolean,
    required: false,
    default: true,
  })
  @joiSchemaProperty(Joi.boolean().optional())
  showStepCount? = true;

  @ApiProperty({
    description: 'Show version',
    type: Boolean,
    required: false,
    default: true,
  })
  @joiSchemaProperty(Joi.boolean().optional())
  showVersion? = true;
}

export const BrandingOptionsSchema = generateClassValidationObject(BrandingOptionsDTO);
