import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { PathType } from '@assemblio/type/path';
import * as Joi from 'joi';

export class PathParamDto {
  @ApiProperty({
    description: 'Id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({
    description: 'type',
    required: true,
    enum: PathType,
  })
  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(PathType))
      .required()
  )
  type!: PathType;
}

export const PathParamSchema = generateClassValidationObject(PathParamDto);
