export enum InstructionFileType {
  GLTF = 'gltf',
  GLTF_MAP = 'gltf-map',
  STEP = 'step',
  JT = 'jt',
  ANALYSIS = 'analysis',
  REPORT = 'report',
  UNKNOWN = 'unknown',
}

export enum CADFileType {
  STEP = 'step',
  JT = 'jt',
}

export enum FileType {
  'image/jpeg' = 'image/jpeg',
  'image/png' = 'image/png',
  'application/pdf' = 'application/pdf',
}
