import React from 'react';
import { Avatar, Box, Group, Stack, Text } from '@mantine/core';
import { Mock_VersionInformation } from '../types/utils.types';
import classes from '../VersionHistoryTab.module.scss';
import { IconCheck, IconX, IconAlertCircle } from '@tabler/icons-react';

interface VersionItemProps {
  versionInfo: Mock_VersionInformation;
}

export const VersionItem = ({ versionInfo }: VersionItemProps) => {
  const { version, timestamp, state, action, issuer } = versionInfo;

  const getUserInitials = () => {
    if (!issuer) return '';
    const { firstName, lastName } = issuer;
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  const formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const iconMap = {
    Approved: <IconCheck size={16} color="green" />,
    'In Review': <IconAlertCircle size={16} color="orange" />,
    'Changes needed': <IconX size={16} color="red" />,
    Draft: <IconAlertCircle size={16} color="gray" />,
  };

  return (
    <Stack>
      <Group className={classes.versionInfo} gap={'lg'}>
        <Box w={'3%'} className={classes.versionText}>
          <Text size="sm" variant="medium" c={'var(--text-constant)'} py={'xs'} px={'sm'}>{`V${version}`}</Text>
        </Box>
        <Group className={classes.versionItem} w={'95%'} justify="flex-end">
          <Group w="60%" px={'md'} gap={'sm'} align="flex-start">
            <Avatar mb={'xs'} radius={'100%'} size="md" variant="medium" color={'var(--text-constant)'} bg={'magenta'}>
              {getUserInitials()}
            </Avatar>
            <Stack gap={'xs'}>
              <Text size="md" fw={'bold'}>{`${issuer.firstName} ${issuer.lastName}`}</Text>
              <Text size="md" lineClamp={1}>
                {action.toLowerCase()}
              </Text>
            </Stack>
          </Group>
          <Group w="fit-content">
            <Group className={classes.stateText} bg="var(--elevation-quinary)" px={'sm'} py={'xs'} mr={'xl'} gap={'xs'}>
              <Box className={classes.icons}>{iconMap[state]}</Box>
              <Text>{state}</Text>
            </Group>
            <Box>
              <Text size="sm" className={classes.dateText}>
                {formatDate(timestamp)}
              </Text>
            </Box>
          </Group>
        </Group>
      </Group>
    </Stack>
  );
};
