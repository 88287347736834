import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { FolderDto } from './folder.dto';

export class FolderOverviewDto {
  @ApiProperty({
    description: 'The folder id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The name of the folder',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'The number of projects in the folder',
    required: true,
  })
  @joiSchemaProperty(Joi.number().required())
  projectCount!: number;

  @ApiProperty({
    description: 'The parent folder',
    required: false,
  })
  parent?: FolderDto;

  @ApiProperty({
    description: 'the last update date',
    required: false,
  })
  @joiSchemaProperty(Joi.date())
  updatedAt?: Date;

  @ApiProperty({
    description: 'the create date',
    required: false,
  })
  @joiSchemaProperty(Joi.date())
  CreatedAt?: Date;
}

export const FolderOverviewSchema = generateClassValidationObject(FolderOverviewDto);
