import { useEffect, useState } from 'react';
import { JobMessage, RoomEvents } from '@assemblio/type/socket';
import { notifications, useNotifications } from '@mantine/notifications';
import { ExportJobDto } from '@assemblio/shared/dtos';
import produce from 'immer';
import { JobState } from '@assemblio/type/job';
import { EventController } from '@assemblio/frontend/stores';
import { useQueryClient } from '@tanstack/react-query';

export const useExportJobWebSocketListener = (
  productId: string | undefined,
  projectId: string | undefined,
  exportJobCount?: number
) => {
  const [tempEvents, setTempEvents] = useState<JobMessage[]>([]);
  const { notifications: activeNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const showSuccessNotification = () => {
    const activeSuccessNotification = activeNotifications.find(
      (notification) => notification.id === 'export-job-success'
    );
    if (activeSuccessNotification) return;
    notifications.show({
      id: 'export-job-success',
      message: 'Your download is ready',
    });
  };

  const handleUpdate = (event: JobMessage) => {
    let saveTemp = true;
    let invalidate = false;
    queryClient.setQueryData<ExportJobDto[]>(['export-jobs', productId], (jobs = []) =>
      produce(jobs, (draft) => {
        for (let i = 0; i < jobs.length; i++) {
          if (draft[i].productId === productId && draft[i].id === event.target.jobId) {
            draft[i] = {
              ...draft[i],
              progress: (event.payload.progress || 0) * 100,
              ...(event.payload.state && { state: event.payload.state }),
            };
            if (event.payload.state === JobState.Completed) {
              invalidate = true;
              showSuccessNotification();
            }
            if (event.payload.state === JobState.Failed) {
              notifications.show({
                title: 'Job failed',
                message: 'Your job has failed',
              });
            }
            saveTemp = false;
            break;
          }
        }
        return draft;
      })
    );

    if (invalidate) {
      queryClient.invalidateQueries(['artefacts', productId]);
      queryClient.invalidateQueries(['export-jobs', productId]);
    }
    return saveTemp;
  };

  useEffect(() => {
    setTempEvents(tempEvents.filter(handleUpdate));
  }, [exportJobCount]);

  useEffect(() => {
    if (!productId) return;

    EventController.subscribeRoom(
      {
        roomEvent: RoomEvents.Job,
        projectId,
        productId,
      },
      handleUpdate
    );
    return () => {
      EventController.unsubscribeRoom({
        roomEvent: RoomEvents.Job,
        projectId,
        productId,
      });
    };
  }, [productId]);
};
