import { useInstructionSetFavorite } from '@assemblio/frontend/data-access';
import { ProductContentDto, ProductOverviewDto } from '@assemblio/shared/dtos';

export const useToggleFavorite = () => {
  const setFavoriteMutation = useInstructionSetFavorite();

  const handleToggleFavorite = (product: ProductOverviewDto | ProductContentDto, projectId: string) => {
    setFavoriteMutation.mutate({
      productId: product.id,
      projectId,
      action: product.isFavorite ? 'remove' : 'add',
      usedBy: 'details',
    });
  };
  return { toggleFavorite: handleToggleFavorite, isLoading: setFavoriteMutation.isLoading };
};
