import { SearchField } from '@assemblio/design-system';
import { Box, Combobox, ComboboxItemGroup, Loader, Center, useCombobox } from '@mantine/core';
import classes from './ItemSearch.module.scss';
import { SearchResult } from './components/SearchResult';
import { SearchResultType } from '../../types';

interface ItemSearchProps {
  onResultClick: (result: SearchResultType) => void;
  searchTerm?: string;
  setSearchTerm?: (value: string) => void;
  isLoading?: boolean;
  results?: ComboboxItemGroup<SearchResultType>[];
  isEmpty?: boolean;
}

export const ItemSearch = ({
  onResultClick,
  results,
  searchTerm,
  setSearchTerm,
  isLoading,
  isEmpty,
}: ItemSearchProps) => {
  const combobox = useCombobox();

  const hideResultDropdown = !searchTerm;

  const handleChangeSearchTerm = (value: string) => {
    combobox.openDropdown();
    combobox.updateSelectedOptionIndex();
    setSearchTerm && setSearchTerm(value);
  };

  const getResultByValue = (value: string) => {
    return results?.flatMap((group) => group.items).find((item) => item.value === value);
  };

  const handleSubmitOption = (value: string) => {
    const option = getResultByValue(value);
    if (!option) {
      console.error('Could not find result');
      return;
    }
    onResultClick(option);
    setSearchTerm && setSearchTerm('');
    combobox.closeDropdown();
  };

  return (
    <Box className={classes.container} data-cy="itemSearch">
      <Combobox onOptionSubmit={handleSubmitOption} store={combobox}>
        <Combobox.Target>
          <SearchField
            maw={'350px'}
            placeholder="Search"
            value={searchTerm}
            onChange={(event) => handleChangeSearchTerm(event.currentTarget.value)}
            onClick={() => combobox.openDropdown()}
            onFocus={() => combobox.openDropdown()}
            onBlur={() => combobox.closeDropdown()}
          />
        </Combobox.Target>

        <Combobox.Dropdown hidden={hideResultDropdown} data-cy="searchOptions">
          {isLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <Combobox.Options>
              {isEmpty ? (
                <Combobox.Empty data-cy="searchOptions">Nothing found</Combobox.Empty>
              ) : (
                results?.map((result) => {
                  return (
                    <Combobox.Group key={result.group} label={result.group}>
                      {result.items.map((item) => (
                        <SearchResult
                          key={item.value}
                          name={item.label}
                          id={item.value}
                          date={item.createdAt}
                          user={item.owner}
                        />
                      ))}
                    </Combobox.Group>
                  );
                })
              )}
            </Combobox.Options>
          )}
        </Combobox.Dropdown>
      </Combobox>
    </Box>
  );
};
