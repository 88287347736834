import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { ExportSelectionDto } from './selection-steps.dto';
import { DocumentExportType } from '@assemblio/type/export';

export class DocumentExportDto {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'The Instruction ID', required: true })
  instructionId!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'The file name', required: true })
  fileName!: string;

  @joiSchemaProperty(Joi.array().items().required())
  @ApiProperty({ description: 'The selection steps', required: true })
  selection!: ExportSelectionDto[];

  @joiSchemaProperty(Joi.string().valid(...Object.values(DocumentExportType)))
  @ApiProperty({ description: 'The exported type', required: true, enum: DocumentExportType })
  type?: DocumentExportType = DocumentExportType.PDF;
}

export const DocumentExportSchema = generateClassValidationObject(DocumentExportDto);
