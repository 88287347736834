import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { ExportSelectionDto } from './selection-steps.dto';
import { VideoExportTypes } from '@assemblio/type/export';
import type { VideoFormat } from '@assemblio/type/export';
export class ViewportOptionsDto {
  showGrid?: boolean;
  backgroundColor?: number;
  resolution!: {
    width: number;
    height: number;
  };
}

export class VideoExportDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'The file name', required: true })
  fileName!: string;

  @joiSchemaProperty(Joi.array().items().required())
  @ApiProperty({ description: 'The selection steps', required: true })
  selection!: ExportSelectionDto[];

  @joiSchemaProperty(Joi.string().valid(...Object.values(VideoExportTypes)))
  @ApiProperty({ description: 'The exported type', required: true, enum: VideoExportTypes })
  type?: VideoExportTypes = VideoExportTypes.AVI;

  @joiSchemaProperty(Joi.object().required())
  @ApiProperty({ description: 'The video format', required: true })
  fileType!: VideoFormat;

  @joiSchemaProperty(Joi.object().required())
  @ApiProperty({ description: 'The viewport options', required: true })
  viewport!: ViewportOptionsDto;
}

export const VideoMediaExportSchema = generateClassValidationObject(VideoExportDto);
