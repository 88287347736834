import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { arrayQueryString } from '@assemblio/shared/helpers';
import * as Joi from 'joi';
import { InstructionState } from '@assemblio/type/instruction';
import { QueryOrder } from '@assemblio/server-next/common/interfaces';
import { ProjectContentSortProps } from '@assemblio/type/project';

export class ProjectContentQueryDto {
  @ApiProperty({
    description: 'The content owners separated by "," separator',
    required: false,
    isArray: true,
    exploded: false,
    format: 'form',
    type: [String],
  })
  @joiSchemaProperty(arrayQueryString('uuid'))
  owners?: string;

  @ApiProperty({
    description: 'The content states separated by "," separator',
    required: false,
    isArray: true,
    exploded: false,
    format: 'form',
    enum: InstructionState,
    type: [InstructionState],
  })
  @joiSchemaProperty(arrayQueryString(InstructionState))
  states?: string;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(QueryOrder))
      .optional()
  )
  @ApiProperty({
    description: 'order type',
    required: false,
    enum: QueryOrder,
  })
  orderType?: QueryOrder;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(ProjectContentSortProps))
      .optional()
  )
  @ApiProperty({
    description: 'order items in the project by',
    required: false,
    enum: ProjectContentSortProps,
  })
  orderBy?: ProjectContentSortProps;
}

export const ProjectContentQuerySchema = generateClassValidationObject(ProjectContentQueryDto);
