import { generateClassValidationObject, joiSchemaProperty, ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class PartGroupDto {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'Part group id', required: true })
  id!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Part group name', required: true })
  name!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Part group original name', required: true })
  originalName!: string;

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'The GLTF index', required: true, type: Number })
  gltfIndex!: number;

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'The solid index', required: true, type: Number })
  solidIndex!: number;

  @joiSchemaProperty(Joi.array().items(Joi.number()).required())
  @ApiProperty({ description: 'Assemblies', required: true, type: [Number] })
  assemblies!: number[];

  @joiSchemaProperty(Joi.array().items(Joi.number()).required())
  @ApiProperty({ description: 'Parts', required: true, type: [Number] })
  parts!: number[];

  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'Parent id', required: true })
  parentGroupId?: string;
}

export const PartGroupSchema = generateClassValidationObject(PartGroupDto);
