export enum UserTypes {
  INTERNAL,
  USER,
}

export enum UserRoles {
  editor = 'editor',
  viewer = 'viewer',
  manager = 'manager',
}

export enum UserSortProps {
  firstName = 'firstName',
  lastName = 'lastName',
  fullName = 'fullName',
  email = 'email',
  role = 'role',
  created_at = 'created_at',
  updated_at = 'updated_at',
}
