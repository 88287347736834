import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class CreateRevisionDto {
  @ApiProperty({
    description: 'The id of the instruction',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  instructionId!: string;

  @ApiProperty({
    description: 'The id of the reviewer',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  reviewerId!: string;

  @ApiProperty({
    description: 'The id of the requestee',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  requesteeId!: string;
}

export const createCreateRevisionSchema = generateClassValidationObject(CreateRevisionDto);
