import { getConvexHull, pointRayCrossesSegments, lineCrossesLine, ImperativeModelController, ModelController } from '@assemblio/frontend/stores';
import { useThree } from '@react-three/fiber';
import { Mesh, Matrix4, Vector3, Line3, Box3, Scene, Object3D, Group, Raycaster, Triangle, Intersection, Object3DEventMap } from 'three';
import { MeshBVH, INTERSECTED, NOT_INTERSECTED, CONTAINED } from 'three-mesh-bvh';
import { Point2D } from '../CoordinateSystems';
import { useViewableRaycast } from './useViewableRaycast';

type ExtendedMesh = Mesh & { geometry: { boundsTree: MeshBVH } };

export function useSpatialQueries() {
  const { camera } = useThree();

  const mainSharedVector = new Vector3();
  const auxSharedVector = new Vector3();
  const invWorldMatrix: Matrix4 = new Matrix4();


  const { intersectObjects } = useViewableRaycast({useFirstIntersection: true, debug: false});

  function getCentroid(tri: Triangle, centroid: Vector3) {
    return centroid
    .copy(tri.a)
    .add(tri.b)
    .add(tri.c)
    .multiplyScalar(1 / 3);
  }
  function* iterateHierarchy(scene: Group, onlyVisible = true) {
    const stack: Object3D[] = [scene];

    while (stack.length > 0) {
      const child = stack.pop();

      if (child && (!onlyVisible || child.visible)) {
        if (child instanceof Mesh) {
          yield child;
        }
        stack.push(...child.children);
      }
    }
  }

  // Max raycasts is a heuristic that should be adjusted. Right now 50 seemed like a good number
  function isMeshOccluded(meshToCheck: ExtendedMesh, hierarchyGroup: Group | Array<Object3D>, BVHDepthToCheck = 4, maxRaycasts = 50): boolean {
    let isViewableFromCamera = false;
    invWorldMatrix.copy(meshToCheck.matrixWorld).invert();
    let amountOfRaysShot = 0;
    const cameraPosition = camera.getWorldPosition(auxSharedVector);



    const isFirstObjectInRay = (direction: Vector3) => {
      const intersections = intersectObjects(
        cameraPosition,
        direction.clone().sub(camera.position).normalize(),
        hierarchyGroup,
        true
      );
      const isFirstHit = intersections.length > 0 && intersections[0].object === meshToCheck;
      amountOfRaysShot++;

      return isFirstHit;
    };

    meshToCheck.geometry.boundsTree.shapecast({
      boundsTraverseOrder: (box) => {
        return cameraPosition.distanceTo(box.getCenter(mainSharedVector));
      },
      intersectsBounds: (box, isLeaf, score, depth) => {
        if(depth < 2) return INTERSECTED;
        if(amountOfRaysShot > maxRaycasts) return NOT_INTERSECTED;
        box.getCenter(mainSharedVector);
        mainSharedVector.applyMatrix4(meshToCheck.matrixWorld);
        const isFirstHit = isFirstObjectInRay(mainSharedVector);
        isViewableFromCamera = isFirstHit;
        if(isViewableFromCamera) return CONTAINED;
        return depth <= BVHDepthToCheck ? INTERSECTED : NOT_INTERSECTED;
      },
      intersectsTriangle: (triangle, triangleIndex, contained) => {
        if(contained) return true;
        if(amountOfRaysShot > maxRaycasts) return false;

        getCentroid(triangle, mainSharedVector);
        mainSharedVector.applyMatrix4(meshToCheck.matrixWorld);
        const isFirstHit = isFirstObjectInRay(mainSharedVector);
        isViewableFromCamera = isFirstHit;
        return isViewableFromCamera;
      },
    });


    // const getMeshName = (mesh: ExtendedMesh) => {
    //   const closestGLTFId = ImperativeModelController.findClosestGltfIndex(mesh) ?? 0;
    //   return ModelController.getPartNameOverrideByGltfIndex(closestGLTFId);
    // }
    // const partName = getMeshName(meshToCheck);
    // console.log(`${partName}: ${amountOfRaysShot} ${isViewableFromCamera}`);


    return !isViewableFromCamera;
  }
  // Almost identical to https://github.com/gkjohnson/three-mesh-bvh/blob/e44a9188adf476bdc75f11b272b035bc2a79531c/example/selection.js
  function isMeshInsidePolygon(mesh: ExtendedMesh, selectionPolygonPoints: Array<Point2D>): boolean {
    // Lazy initialization for function-specific cached objects
    const cache = (() => {
      let toScreenSpaceMatrix: Matrix4 | null = null;
      let boxPoints: Vector3[] | null = null;
      let boxLines: Line3[] | null = null;
      let lassoSegments: Line3[] | null = null;
      let perBoundsSegments: Line3[][] | null = null;

      return {
        getToScreenSpaceMatrix: () => toScreenSpaceMatrix ?? (toScreenSpaceMatrix = new Matrix4()),
        getBoxPoints: () => boxPoints ?? (boxPoints = new Array(8).fill(null).map(() => new Vector3())),
        getBoxLines: () => boxLines ?? (boxLines = new Array(12).fill(null).map(() => new Line3())),
        getLassoSegments: () => lassoSegments ?? (lassoSegments = []),
        getPerBoundsSegments: () => perBoundsSegments ?? (perBoundsSegments = []),
      };
    })();

    const toScreenSpaceMatrix = cache.getToScreenSpaceMatrix();

    const boxPoints = cache.getBoxPoints();
    const boxLines = cache.getBoxLines();
    const lassoSegments = cache.getLassoSegments();
    const perBoundsSegments = cache.getPerBoundsSegments();
    // Prepare matrix for transforming mesh points into screen space
    toScreenSpaceMatrix
      .copy(mesh.matrixWorld)
      .premultiply(camera.matrixWorldInverse)
      .premultiply(camera.projectionMatrix);

    while (lassoSegments.length < selectionPolygonPoints.length) {
      lassoSegments.push(new Line3());
    }
    lassoSegments.length = selectionPolygonPoints.length;

    // Fill the segments based on selection points
    for (let s = 0, l = selectionPolygonPoints.length; s < l; s++) {
      const line = lassoSegments[s];

      // Wrap around for the last point
      const next = (s + 1) % l;

      line.start.x = selectionPolygonPoints[s].x;
      line.start.y = selectionPolygonPoints[s].y;
      line.end.x = selectionPolygonPoints[next].x;
      line.end.y = selectionPolygonPoints[next].y;
    }

    // Transform the camera and mesh coordinates into local space
    invWorldMatrix.copy(mesh.matrixWorld).invert();
    mainSharedVector.set(0, 0, 0).applyMatrix4(camera.matrixWorld).applyMatrix4(invWorldMatrix);

    return mesh.geometry.boundsTree.shapecast({
      intersectsBounds: (box, isLeaf, score, depth) => {
        const { min, max } = box;
        let minX = Infinity,
          minY = Infinity,
          maxY = -Infinity;

        // Calculate bounding box points in screen space
        let index = 0;
        for (let x = 0; x <= 1; x++) {
          for (let y = 0; y <= 1; y++) {
            for (let z = 0; z <= 1; z++) {
              const v = boxPoints[index];
              v.set(x === 0 ? min.x : max.x, y === 0 ? min.y : max.y, z === 0 ? min.z : max.z);
              v.applyMatrix4(toScreenSpaceMatrix);
              index++;
              if (v.y < minY) minY = v.y;
              if (v.y > maxY) maxY = v.y;
              if (v.x < minX) minX = v.x;
            }
          }
        }

        // Prepare segments for bounds checking
        const parentSegments = perBoundsSegments[depth - 1] || lassoSegments;
        const segmentsToCheck = perBoundsSegments[depth] || [];
        segmentsToCheck.length = 0;
        perBoundsSegments[depth] = segmentsToCheck;

        for (let i = 0, l = parentSegments.length; i < l; i++) {
          const line = parentSegments[i];
          const sx = line.start.x;
          const sy = line.start.y;
          const ex = line.end.x;
          const ey = line.end.y;
          if (sx < minX && ex < minX) continue;

          const startAbove = sy > maxY;
          const endAbove = ey > maxY;
          if (startAbove && endAbove) continue;

          const startBelow = sy < minY;
          const endBelow = ey < minY;
          if (startBelow && endBelow) continue;

          segmentsToCheck.push(line);
        }
        if (segmentsToCheck.length === 0) return false;

        // Get the convex hull and check intersection with the selection lasso
        const hull = getConvexHull(boxPoints);

        let crossings = 0;

        if (hull) {
          const lines = hull.map((p, i) => {
            const nextP = hull[(i + 1) % hull.length];
            const line = boxLines[i];
            line.start.copy(p);
            line.end.copy(nextP);
            return line;
          });

          // If a lasso point is inside the hull then it's intersected and cannot be contained
          if (pointRayCrossesSegments(segmentsToCheck[0].start, lines) % 2 === 1) {
            return INTERSECTED;
          }

          // check if the screen space hull is in the lasso
          for (let i = 0, l = hull.length; i < l; i++) {
            const v = hull[i];
            const pCrossings = pointRayCrossesSegments(v, segmentsToCheck);

            if (i === 0) {
              crossings = pCrossings;
            }

            // if two points on the hull have different amounts of crossings then
            // it can only be intersected
            if (crossings !== pCrossings) {
              return INTERSECTED;
            }
          }

          // check if there are any intersections
          for (let i = 0, l = lines.length; i < l; i++) {
            const boxLine = lines[i];
            for (let s = 0, ls = segmentsToCheck.length; s < ls; s++) {
              if (lineCrossesLine(boxLine, segmentsToCheck[s])) {
                return INTERSECTED;
              }
            }
          }
        }
        return crossings % 2 === 0 ? NOT_INTERSECTED : CONTAINED;
      },

      intersectsTriangle: (tri, index, contained, depth) => {
        const segmentsToCheck = perBoundsSegments[depth] || lassoSegments;

        getCentroid(tri, mainSharedVector);
        // Screen space centroid
        mainSharedVector.applyMatrix4(toScreenSpaceMatrix);

        const crossesOrIsInsideShape = contained || pointRayCrossesSegments(mainSharedVector, segmentsToCheck) % 2 === 1;
        return crossesOrIsInsideShape;
      },
    });
  }
  return { isMeshInsidePolygon, iterateHierarchy, isMeshOccluded };
}
