import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class SwitchProductTextOrderBlockDto {
  @ApiProperty({
    description: 'Product text to switch order from',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  from!: string;

  @ApiProperty({
    description: 'Product text to switch order to',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  to!: string;

  @ApiProperty({
    description: 'The product id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  productId!: string;
}

export const SwitchProductTextOrderBlockSchema = generateClassValidationObject(SwitchProductTextOrderBlockDto);
