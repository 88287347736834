import { useQuery } from '@tanstack/react-query';
import { QueryOperationalResourceDto } from '@assemblio/shared/dtos';
import {
  fetchOperationalResources,
  fetchProductsByResource,
  fetchResourceById,
  fetchResourcesByStep,
} from './resource.requests';
import { FetchResourceByStep, FetchResourceProducts } from './types';

export const useOperationalResourceQuery = (data?: QueryOperationalResourceDto) => {
  return useQuery(
    ['resources'],
    () => {
      return fetchOperationalResources(data);
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useProductByResourceQuery = (data: FetchResourceProducts) => {
  return useQuery(
    ['resource-products', data.resourceId],
    () => {
      return fetchProductsByResource(data);
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};

export const useResourcesByStepQuery = (data: FetchResourceByStep) => {
  return useQuery(
    ['resource-steps', data.stepId],
    () => {
      return fetchResourcesByStep(data);
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};

export const useResourceByIdQuery = (id: string | undefined) => {
  return useQuery(
    ['resource', id],
    () => {
      return fetchResourceById(id);
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
};
