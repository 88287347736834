import { getAuthType } from '@assemblio/shared/helpers';
import { BearerType } from '@assemblio/type/auth';

const getNodeEnv = (): string => {
  const nodeEnv = process.env['NODE_ENV'];
  if (nodeEnv === undefined) return 'development';
  else return nodeEnv;
};

const getServerURL = (): string | undefined => {
  return process.env['NX_THE_SERVER_URL'];
};

const getApplicationType = (): string | undefined => {
  return process.env['NX_PUBLIC_APPLICATION'];
};

const getAuthConfig = (): {
  types: BearerType[];
  auth0?: {
    domain: string;
    clientId: string;
    audience: string;
  };
} => {
  const types = getAuthType(process.env['NX_PUBLIC_AUTH_TYPE']);
  return {
    types,
    auth0: types.find((type) => type === BearerType.Auth0)
      ? {
          domain: `${process.env['NX_PUBLIC_AUTH0_TENANT_NAME']}.${process.env['NX_PUBLIC_AUTH0_REGION']}.auth0.com`,
          clientId: process.env['NX_PUBLIC_AUTH0_CLIENT_ID'] || '',
          audience: process.env['NX_PUBLIC_AUTH0_TOKEN_AUDIENCE'] || '',
        }
      : undefined,
  };
};

export const ConfigService = {
  getNodeEnv,
  getServerURL,
  getApplicationType,
  getAuthConfig,
};
