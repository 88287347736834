import { ProductOverviewDto } from '@assemblio/shared/dtos';
import { ExplorerGrid } from '../../../layout';
import { ProductCard, ProductCardSkeleton } from './ProductCard';
import UploadingProductCard from '../../UploadProductCard/UploadingProductCard';
import { ActiveUpload } from '../../../types';

interface ProductGridProps {
  products: ProductOverviewDto[];
  activeUpload?: ActiveUpload;
  onOpenProduct: (product: ProductOverviewDto) => void;
  onOpenDetails: (id: string) => void;
  isLoading?: boolean;
}

export const ProductGrid = ({
  products,
  activeUpload,
  onOpenProduct,
  onOpenDetails,
  isLoading = false,
}: ProductGridProps) => {
  const productCards = products.map((product) => (
    <ProductCard
      onOpenProduct={() => onOpenProduct(product)}
      onOpenDetails={() => onOpenDetails(product.id)}
      item={product}
    />
  ));

  const activeUploadComponent = activeUpload ? <UploadingProductCard item={activeUpload} /> : null;

  const items = activeUploadComponent ? [...productCards, activeUploadComponent] : productCards;
  return <ExplorerGrid items={items} isLoading={isLoading} loadingPlaceholder={<ProductCardSkeleton />} />;
};
