import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import type { Vector as VectorInterface, VectorType } from '@assemblio/type/3d';
import * as Joi from 'joi';

export class VectorDto implements VectorInterface {
  @ApiProperty({ description: 'Y dimensional', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  y!: number;

  @ApiProperty({ description: 'X dimensional', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  x!: number;

  @ApiProperty({ description: 'Z dimensional', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  z!: number;

  constructor(p: VectorType) {
    // I can't find where this is called without parameter but it happens
    if (p) {
      this.x = p[0];
      this.y = p[1];
      this.z = p[2];
    }
  }

  public static toArray(data: VectorDto): VectorType {
    return [data.x, data.y, data.z];
  }

  public static fromArray(array: VectorType): VectorDto {
    return new VectorDto(array);
  }
}

export const VectorSchema = generateClassValidationObject(VectorDto);
