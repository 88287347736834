import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class UpdateReviewerDto {
  @ApiProperty({
    description: 'The id of the revision',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  revisionId!: string;

  @ApiProperty({
    description: 'The id of the new reviewer',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  reviewerId!: string;
}

export const UpdateReviewerSchema = generateClassValidationObject(UpdateReviewerDto);
