import { generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { ExportJobType, JobState } from '@assemblio/type/job';

export class ExportJobDto {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'Job id', required: true })
  id!: string;

  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'Product id', required: true })
  productId!: string;

  @joiSchemaProperty(Joi.string().optional())
  @ApiProperty({ description: 'Created file name', required: false })
  createdFileName?: string;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(JobState))
      .required()
  )
  @ApiProperty({ description: 'Job state', required: true, enum: JobState })
  state!: JobState;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(ExportJobType))
      .required()
  )
  @ApiProperty({ description: 'Job type', required: true, enum: ExportJobType })
  type!: ExportJobType;

  @joiSchemaProperty(Joi.number().optional())
  @ApiProperty({ description: 'Job progress', required: false })
  progress?: number;
}

export const ExportJobSchema = generateClassValidationObject(ExportJobDto);
