import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class CreateProductDto {
  @joiSchemaProperty(Joi.string())
  @ApiProperty({
    description: 'Product name',
    required: false,
  })
  productName?: string;

  @joiSchemaProperty(Joi.string().uuid())
  @ApiProperty({
    description: 'Folder id',
    required: false,
  })
  folderId?: string;

  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({
    description: 'Project id',
    required: true,
  })
  projectId!: string;

  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({
    description: 'product id',
    required: true,
  })
  productId!: string;

  @joiSchemaProperty(Joi.string().min(5).required())
  @ApiProperty({
    description: 'The file path',
    required: true,
  })
  path!: string;

  @joiSchemaProperty(Joi.string())
  @ApiProperty({
    description: 'original file name',
    required: false,
  })
  originalFileName?: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({
    description: 'token',
    required: true,
  })
  token!: string;
}

export const CreateProductSchema = generateClassValidationObject(CreateProductDto);
