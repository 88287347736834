export enum ExportMediaActionType {
  COVER = 'cover',
  SEQUENCE = 'sequence',
  VIDEO = 'video',
}

export enum ExportImageFormat {
  JPEG = 'jpg',
  PNG = 'png',
  WEBP = 'webp',
}
