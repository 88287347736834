import { DisassemblyDirectionDto, DisassemblyDirectionSchema, PartMetaDataDto, PartMetaDataSchema } from './part.dto';
import { generateClassValidationObject, joiSchemaProperty, ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class Part {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'Part id', required: true })
  id!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Part name', required: true })
  name!: string;

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'GLTF index', required: true, type: Number })
  gltfIndex!: number;

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'Solid index', required: true, type: Number })
  solidIndex!: number;

  @joiSchemaProperty(PartMetaDataSchema.required())
  @ApiProperty({ description: 'metaData', required: true, type: PartMetaDataDto })
  metaData!: PartMetaDataDto;

  @joiSchemaProperty(Joi.array().items(DisassemblyDirectionSchema))
  @ApiProperty({ description: 'metaData', required: false, type: [DisassemblyDirectionDto] })
  disassemblyDirections?: DisassemblyDirectionDto[];
}

export const GltfPartSchema = generateClassValidationObject(Part);

export class PartGroup {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'Part id', required: true })
  id!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Part name', required: true })
  name!: string;

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'GLTF index', required: true, type: Number })
  gltfIndex!: number;

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'Solid index', required: true, type: Number })
  solidIndex!: number;

  @joiSchemaProperty(Joi.array().items(Joi.number()).required())
  @ApiProperty({ description: 'parts', required: true, type: [Number] })
  parts!: number[];

  @joiSchemaProperty(Joi.array().items(Joi.number()).required())
  @ApiProperty({ description: 'groups', required: true, type: [Number] })
  partGroups!: number[];
}

export const GltfPartGroupSchema = generateClassValidationObject(PartGroup);

export class GltfParsingFileContentDto {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'product Id', required: true })
  instructionId!: string;

  @joiSchemaProperty(Joi.array().items(GltfPartGroupSchema).required())
  @ApiProperty({ description: 'groups', required: true, type: [PartGroup] })
  partGroups!: PartGroup[];

  @joiSchemaProperty(Joi.array().items(GltfPartSchema).required())
  @ApiProperty({ description: 'parts', required: true, type: [Part] })
  parts!: Part[];
}

export const GltfParsingFileContentSchema = generateClassValidationObject(GltfParsingFileContentDto);
