import { ProjectOverviewDto, FolderOverviewDto } from '@assemblio/shared/dtos';
import { ExplorerItem, ExplorerItems, ExplorerItemType } from '../types/items.types';
import { useFolderDisplayMode } from '../../../hooks/folderDisplayMode.hook';
import { QueryOrder } from '@assemblio/type/common';

interface ExplorerItemHookProps {
  projects: ProjectOverviewDto[];
  folders: FolderOverviewDto[];
  sortValue?: string;
  sort?: QueryOrder;
}

interface ExplorerItemHookReturnType {
  items: ExplorerItems;
}

type CombinedKeys = keyof ProjectOverviewDto & keyof FolderOverviewDto;

export const useExplorerItems = ({
  projects,
  folders,
  sortValue,
  sort,
}: ExplorerItemHookProps): ExplorerItemHookReturnType => {
  const { view } = useFolderDisplayMode();

  const getMixedItems = () => {
    return sortValue
      ? mixSortedArrays({
          projects,
          folders,
          prop: sortValue as CombinedKeys,
          sort,
        })
      : [...projects, ...folders];
  };

  return {
    items:
      view === 'mixed'
        ? getMixedItems().map(transformItem)
        : { projects: projects.map(transformItem), folders: folders.map(transformItem) },
  };
};

const transformItem = (item: ProjectOverviewDto | FolderOverviewDto): ExplorerItem => {
  return 'productCount' in item
    ? {
        id: item.id,
        name: item.name,
        subItemCount: item.productCount,
        type: ExplorerItemType.Project,
        productIds: item.productsWithImage,
      }
    : {
        id: item.id,
        name: item.name,
        subItemCount: item.projectCount,
        type: ExplorerItemType.Folder,
      };
};

interface MixInput {
  projects: ProjectOverviewDto[];
  folders: FolderOverviewDto[];
  prop: CombinedKeys;
  sort?: QueryOrder;
}

const mixSortedArrays = ({ projects, folders, prop, sort = QueryOrder.ASC }: MixInput) => {
  let currentProjectIndex = 0;

  const res = folders.reduce(
    (arr, folder) => {
      while (
        projects[currentProjectIndex] &&
        ((sort === QueryOrder.ASC && !isPropertyInProjectLarger(projects[currentProjectIndex], folder, prop)) ||
          (sort === QueryOrder.DESC && isPropertyInProjectLarger(projects[currentProjectIndex], folder, prop)))
      ) {
        arr.push(projects[currentProjectIndex]);
        currentProjectIndex++;
      }
      arr.push(folder);
      return arr;
    },
    [] as (ProjectOverviewDto | FolderOverviewDto)[]
  );
  if (projects[currentProjectIndex]) res.push(...projects.slice(currentProjectIndex));
  return res;
};

function isPropertyInProjectLarger(
  project: ProjectOverviewDto,
  folder: FolderOverviewDto,
  propName: CombinedKeys
): boolean {
  if (!(propName in project) || !(propName in folder)) {
    console.debug(`Error while mixing sorted explorer items - Property ${propName} does not exist in either object`);
    return false;
  }

  return (project[propName] || 0) > (folder[propName] || 0);
}
