import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { StepDto, StepSchema } from '../step';

export class StepGroupDto {
  @ApiProperty({ description: 'Id', required: true })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({ description: 'Name', required: true })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({ description: 'Seq Id', required: true })
  @joiSchemaProperty(Joi.string().uuid().required())
  instructionSequenceId!: string;

  @ApiProperty({ description: 'next Group Id', required: true })
  @joiSchemaProperty(Joi.string().uuid().allow(null))
  next!: string | null;

  @ApiProperty({ description: 'prev Group Id', required: true })
  @joiSchemaProperty(Joi.string().uuid().allow(null))
  prev!: string | null;

  @ApiProperty({ description: 'the steps information', required: true, type: [StepDto] })
  @joiSchemaProperty(Joi.array().items(StepSchema).required())
  steps!: StepDto[];
}
export const StepGroupSchema = generateClassValidationObject(StepGroupDto);
