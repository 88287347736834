import { IconFileOpen } from '@assemblio/design-system';
import { Stack, Text } from '@mantine/core';

export const EmptyResourceView = () => {
  return (
    <Stack mih={500} align="center" justify="center" gap={'xl'}>
      <IconFileOpen />
      <Text size="lg" variant={'medium'} c={'text-secondary'}>
        Nothing is here yet. Start by creating a resource.
      </Text>
    </Stack>
  );
};
