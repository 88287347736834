import { Line3, Matrix4, Quaternion, Vector3 } from 'three';

export const pivotTransform = (pivot: Vector3, rotation: Quaternion, translation: Vector3): Matrix4 => {
  const rotationMatrix = new Matrix4().makeRotationFromQuaternion(
    new Quaternion(rotation.x, rotation.y, rotation.z, rotation.w)
  );
  const translationMatrix = new Matrix4().makeTranslation(translation.x, translation.y, translation.z);
  const pivotTranslationMatrix = new Matrix4()
    .makeTranslation(pivot.x, pivot.y, pivot.z)
    .multiply(translationMatrix.clone());

  return pivotTranslationMatrix
    .clone()
    .multiply(rotationMatrix.clone())
    .multiply(pivotTranslationMatrix.clone().invert())
    .multiply(translationMatrix.clone());
};

/**
 * Computes the convex hull of a set of 2D points using the Graham scan algorithm.
 * https://www.geeksforgeeks.org/convex-hull-set-2-graham-scan/
 * @param points - An array of Vector3 representing the points.
 * @returns The convex hull as an array of Vector3 points, or null if fewer than 3 points are provided.
 */
export function getConvexHull(points: Vector3[]): Vector3[] | null {

  function orientation(p: Vector3, q: Vector3, r: Vector3): number {
    const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
    if (val == 0) {
      return 0; // collinear
    }
    return (val > 0) ? 1 : 2; // clockwise or counterclockwise
  }

  function distSq(p1: Vector3, p2: Vector3): number {
    return (p1.x - p2.x) * (p1.x - p2.x) +
      (p1.y - p2.y) * (p1.y - p2.y);
  }

  function compare(p1: Vector3, p2: Vector3): number {
    // Find orientation
    const o = orientation(p0, p1, p2);
    if (o == 0)
      return (distSq(p0, p2) >= distSq(p0, p1)) ? -1 : 1;

    return (o == 2) ? -1 : 1;
  }

  // find the lowest point in 2D
  let lowestY = Infinity;
  let lowestIndex = -1;
  for (let i = 0, l = points.length; i < l; i++) {

    const p = points[i];
    if (p.y < lowestY) {

      lowestIndex = i;
      lowestY = p.y;

    }

  }

  // sort the points
  const p0 = points[lowestIndex];
  points[lowestIndex] = points[0];
  points[0] = p0;

  points = points.sort(compare);

  // filter the points
  let m = 1;
  const n = points.length;
  for (let i = 1; i < n; i++) {

    while (i < n - 1 && orientation(p0, points[i], points[i + 1]) == 0) {
      i++;
    }

    points[m] = points[i];
    m++;

  }

  // early out if we don't have enough points for a hull
  if (m < 3) return null;

  // generate the hull
  const hull = [points[0], points[1], points[2]];
  for (let i = 3; i < m; i++) {

    while (orientation(hull[hull.length - 2], hull[hull.length - 1], points[i]) !== 2) {
      hull.pop();
    }

    hull.push(points[i]);

  }

  return hull;
}

/**
 * Checks if a ray originating from a point crosses a given line in 2D space.
 * @param point - The origin point of the ray.
 * @param line - The line segment to check for intersection.
 * @param prevDirection - The previous direction of the ray.
 * @param thisDirection - The current direction of the ray.
 * @returns A boolean indicating whether the ray crosses the line.
 */
function pointRayCrossesLine(
  point: Vector3,
  line: Line3,
  prevDirection: boolean,
  thisDirection: boolean
): boolean {

  const { start, end } = line;
  const px = point.x;
  const py = point.y;

  const sy = start.y;
  const ey = end.y;

  if (sy === ey) return false;

  if (py > sy && py > ey) return false; // above
  if (py < sy && py < ey) return false; // below

  const sx = start.x;
  const ex = end.x;
  if (px > sx && px > ex) return false; // right
  if (px < sx && px < ex) { // left

    if (py === sy && prevDirection !== thisDirection) {
      return false;
    }

    return true;

  }

  // check the side
  const dx = ex - sx;
  const dy = ey - sy;
  const perpx = dy;
  const perpy = -dx;

  const pdx = px - sx;
  const pdy = py - sy;

  const dot = perpx * pdx + perpy * pdy;

  if (Math.sign(dot) !== Math.sign(perpx)) {
    return true;
  }

  return false;
}

/**
 * Checks how many times a ray originating from a point crosses the given set of line segments.
 * @param point - The origin point of the ray.
 * @param segments - An array of line segments.
 * @returns The number of crossings.
 */
export function pointRayCrossesSegments(point: Vector3, segments: Line3[]): number {

  let crossings = 0;
  const firstSeg = segments[segments.length - 1];
  let prevDirection = firstSeg.start.y > firstSeg.end.y;
  for (let s = 0, l = segments.length; s < l; s++) {

    const line = segments[s];
    const thisDirection = line.start.y > line.end.y;
    if (pointRayCrossesLine(point, line, prevDirection, thisDirection)) {

      crossings++;

    }

    prevDirection = thisDirection;

  }

  return crossings;
}

/**
 * Checks if two line segments in 3D space intersect.
 * https://stackoverflow.com/questions/3838329/how-can-i-check-if-two-segments-intersect
 * @param l1 - The first line segment.
 * @param l2 - The second line segment.
 * @returns A boolean indicating whether the two line segments intersect.
 */
export function lineCrossesLine(l1: Line3, l2: Line3): boolean {

  function ccw(A: Vector3, B: Vector3, C: Vector3): boolean {
    return (C.y - A.y) * (B.x - A.x) > (B.y - A.y) * (C.x - A.x);
  }

  const A = l1.start;
  const B = l1.end;

  const C = l2.start;
  const D = l2.end;

  return ccw(A, C, D) !== ccw(B, C, D) && ccw(A, B, C) !== ccw(A, B, D);
}
