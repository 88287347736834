import classes from './FolderShape.module.scss';
import { Box, Loader } from '@mantine/core';

interface FolderShapeProps {
  scale: number;
  isLoading?: boolean;
}

export const FolderShape = ({ scale, isLoading = true }: FolderShapeProps) => {
  return (
    <>
      {isLoading && <FolderLoadingOverlay scale={scale} />}
      <svg
        data-cy="itemFolder"
        height={225}
        viewBox="0 0 248 219"
        fill="none"
        style={{
          transform: `scale(${scale}, 1.02)`,
          transformOrigin: 'left top 0px',
        }}
        xmlns="http://www.w3.org/2000/svg"
        className={classes.root}
      >
        <g filter="url(#filter0_d_347_98000)">
          <path
            className={classes.path}
            d="M2 12C2 5.37259 7.37258 0 14 0H68.5674C71.3177 0 73.9845 0.944715 76.1214 2.67601L92.4642 15.9165C94.6012 17.6478 97.268 18.5925 100.018 18.5925H234C240.627 18.5925 246 23.9651 246 30.5925V203C246 209.627 240.627 215 234 215H14C7.37258 215 2 209.627 2 203L2 12Z"
          />
          <path
            className={classes.path}
            d="M2.5 12C2.5 5.64873 7.64873 0.5 14 0.5H68.5674C71.2031 0.5 73.7588 1.40535 75.8067 3.06451L92.1495 16.305C94.3755 18.1084 97.1534 19.0925 100.018 19.0925H234C240.351 19.0925 245.5 24.2412 245.5 30.5925V203C245.5 209.351 240.351 214.5 234 214.5H14C7.64873 214.5 2.5 209.351 2.5 203V12Z"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_347_98000"
            x="0"
            y="0"
            width="248"
            height="219"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters={'sRGB'}
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation={1} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.0980392 0 0 0 0 0.145098 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_347_98000" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_347_98000" result="shape" />
          </filter>
        </defs>
      </svg>
    </>
  );
};

const FolderLoadingOverlay = ({ scale }: FolderShapeProps) => {
  return (
    <>
      <Box className={classes.loader}>
        <Loader />
      </Box>
      <svg
        height={225}
        viewBox="0 0 248 219"
        style={{
          transform: `scale(${scale}, 1.02)`,
          transformOrigin: 'left top 0px',
          zIndex: 5,
        }}
        xmlns="http://www.w3.org/2000/svg"
        className={classes.root}
        fill={'none'}
      >
        <path
          className={classes.loading}
          d="M2 12C2 5.37259 7.37258 0 14 0H68.5674C71.3177 0 73.9845 0.944715 76.1214 2.67601L92.4642 15.9165C94.6012 17.6478 97.268 18.5925 100.018 18.5925H234C240.627 18.5925 246 23.9651 246 30.5925V203C246 209.627 240.627 215 234 215H14C7.37258 215 2 209.627 2 203L2 12Z"
        />
      </svg>
    </>
  );
};
