import { Assembly } from '@assemblio/type/input';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';
import { HierarchyElement, HierarchyFilters, SearchTerm, Tree } from '../types/hierarchy.types';

export const hierarchyEqualityFunction = (a?: HierarchyElement, b?: HierarchyElement) => {
  return (
    a?.assembled === b?.assembled &&
    a?.excluded === b?.excluded &&
    a?.expanded === b?.expanded &&
    a?.filtered === b?.filtered &&
    a?.isLeaf === b?.isLeaf &&
    a?.name === b?.name &&
    a?.selected === b?.selected &&
    a?.transparent === b?.transparent &&
    a?.visible === b?.visible &&
    a?.parent === b?.parent
  );
};

export interface HierarchyStore {
  rootIndex: number;
  elements: Map<number, HierarchyElement>;
  tree: Tree;
  hierarchyFilter: HierarchyFilters & SearchTerm;
  reset: () => void;
}

const init = () => ({
  elements: new Map<number, HierarchyElement>(),
  tree: {
    data: {} as Assembly,
    isLeaf: false,
    parent: -1,
    children: new Array<Tree>(),
  },
  hierarchyFilter: {
    searchTerm: '',
    showExcluded: false,
    showDisassembled: true,
  },
});

export const useHierarchyStore = create<HierarchyStore>()(
  subscribeWithSelector(
    devtools((set) => ({
      ...init(),
      rootIndex: 0,
      reset: () => {
        set(init());
      },
    }))
  )
);
