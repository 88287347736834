import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class IdParamDto {
  @ApiProperty({
    description: 'The id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;
}

export const IdParamSchema = generateClassValidationObject(IdParamDto);
