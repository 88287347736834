import { generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { ApiProperty } from '@assemblio/shared/decorators';
import { MetaDto, MetaSchema, PaginatedDto } from '../meta';
import { UserPopulatedDto, UserPopulatedSchema } from './populated-response.dto';
import * as Joi from 'joi';

export class PaginatedPopulatedUserDto extends PaginatedDto<UserPopulatedDto> {
  @joiSchemaProperty(Joi.array().items(UserPopulatedSchema).required())
  @ApiProperty({
    description: 'Users',
    isArray: true,
    type: [UserPopulatedDto],
    required: true,
  })
  override items!: UserPopulatedDto[];

  @joiSchemaProperty(MetaSchema.required())
  @ApiProperty({
    description: 'Pagination information',
    type: MetaDto,
    required: true,
  })
  override meta!: MetaDto;
}

export const PaginatedPopulatedUserSchema = generateClassValidationObject(PaginatedPopulatedUserDto);
