import { ArrowAnnotation, ImageAnnotation, ImageAnnotationFile, ShapeAnnotation } from '@assemblio/type/annotation';
// Todo: Refactor to actual DTOs

export class AnnotationArrowDto {
  id: string;
  type: 'arrow';
  meta: ArrowAnnotation;

  constructor(id: string, meta: ArrowAnnotation) {
    this.id = id;
    this.type = 'arrow';
    this.meta = meta;
  }
}

export class AnnotationRectDto {
  id: string;
  type: 'rect';
  meta: ShapeAnnotation;

  constructor(id: string, meta: ShapeAnnotation) {
    this.id = id;
    this.type = 'rect';
    this.meta = meta;
  }
}

export class AnnotationEllipseDto {
  id: string;
  type: 'ellipse';
  meta: ShapeAnnotation;

  constructor(id: string, meta: ShapeAnnotation) {
    this.id = id;
    this.type = 'ellipse';
    this.meta = meta;
  }
}

export class AnnotationImageDto {
  id: string;
  type: 'image';
  meta: ImageAnnotation;
  file?: ImageAnnotationFile;

  constructor(id: string, meta: ImageAnnotation) {
    this.id = id;
    this.type = 'image';
    this.meta = meta;
  }
}

export type AnnotationDto = AnnotationArrowDto | AnnotationRectDto | AnnotationEllipseDto | AnnotationImageDto;
