import { generateClassValidationObject, joiSchemaProperty, ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class PartGroupNameDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Part group name', required: true })
  name!: string;
}
export const PartGroupNameSchema = generateClassValidationObject(PartGroupNameDto);

export class PartNameDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Part name', required: true })
  name!: string;
}
export const PartNameSchema = generateClassValidationObject(PartNameDto);

export class PartOrGroupNameDto {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'id', required: true })
  id!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'name', required: true })
  name!: string;
}
export const PartOrGroupNameSchema = generateClassValidationObject(PartOrGroupNameDto);
