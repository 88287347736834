import { useState, useRef, useCallback, useEffect } from 'react';
import { Line } from '@react-three/drei';
import { useGesture } from '@use-gesture/react';
import { Line2 } from 'three-stdlib';
import { useThree } from '@react-three/fiber';
import {
  ImperativeModelController,
  MachineController,
  UIController,
  useModelStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { useSpatialQueries } from './hooks/useSpatialQueries';
import { Point2D } from './CoordinateSystems';
import * as CoordConversion from './CoordinateSystems';
import { Vector2, Vector3 } from 'three';

interface SelectionControlsProps {
  debug?: boolean;
}
export function SelectionControls({ debug }: SelectionControlsProps) {
  const lineRef = useRef<Line2>(null);
  const { size, camera, gl } = useThree();
  const [points, setPoints] = useState<Point2D[]>([]);
  const {mode: selectionMode, active} = useUIStore((state) => state.selection);
  const [startScreenPoint, setStartPoint] = useState<Point2D | null>(null);
  const [lastPoint, setLastPoint] = useState<Point2D | null>(null);
  const [currentlySelectedParts, setCurrentIntersected] = useState<number[]>([]);
  const scene = useModelStore((state) => state.model.scene);

  const { isMeshInsidePolygon, iterateHierarchy, isMeshOccluded } = useSpatialQueries();

  const screenToCNDC = useCallback(
    ({ x, y }: Point2D): Point2D => {
      return CoordConversion.screenToCNDC({ x, y }, size);
    },
    [size]
  );

  const screenToWorld = useCallback(
    ({ x, y }: Point2D) => {
      return CoordConversion.cndcToWorld({ x, y }, camera);
    },
    [camera]
  );

  const prevIntersectedRef = useRef<number[]>();

  useEffect(() => {
    if (debug) {
      if (prevIntersectedRef.current) {
        prevIntersectedRef.current.forEach((id) => {
          ImperativeModelController.setColor(id, 0x808080);
        });
      }

      currentlySelectedParts.forEach((id) => {
        ImperativeModelController.setColor(id, 0x00ff00);
      });
    }

    MachineController.selectParts(currentlySelectedParts);
    prevIntersectedRef.current = currentlySelectedParts;
  }, [currentlySelectedParts, debug]);

  const collectIntersections = () => {
    const intersectedGLTFIds: Array<number> = [];
    const visibleMeshes = Array.from(iterateHierarchy(scene, true));

    for (const child of visibleMeshes) {
      const isInside = isMeshInsidePolygon(child, points) && !isMeshOccluded(child, visibleMeshes);
      if (isInside) {
        const closestGLTFId = ImperativeModelController.findClosestGltfIndex(child);
        if (closestGLTFId !== undefined) intersectedGLTFIds.push(closestGLTFId);
      }
    }
    return intersectedGLTFIds;
  };

  const deltaVec = useRef(new Vector2());
  const previousDelta = useRef(new Vector2());
  useGesture(
    {
      onDragStart: ({ xy: [x, y] }) => {
        if (!active) return;

        const currentCoordinates = screenToCNDC({ x, y });

        setStartPoint(currentCoordinates);
        setLastPoint(currentCoordinates);
        setPoints([currentCoordinates]);
      },

      onDrag: ({ xy: [x, y] }) => {
        if (!active || !startScreenPoint || !lastPoint) return;

        const currentCoordinates = screenToCNDC({ x, y });
        if (selectionMode === 'box') {
          const boxPoints = [
            startScreenPoint,
            { x: currentCoordinates.x, y: startScreenPoint.y },
            currentCoordinates,
            { x: startScreenPoint.x, y: currentCoordinates.y },
            startScreenPoint,
          ];

          setPoints(boxPoints);
        } else {
          const appendLastPoint = () => {
            setPoints((prevPoints) => {
              const updatedPoints = [...prevPoints];
              updatedPoints[updatedPoints.length - 1] = currentCoordinates;
              updatedPoints.push(updatedPoints[0]);

              return updatedPoints;
            });

            setLastPoint(currentCoordinates);
          };
          const replaceLastPoint = () => {
            setPoints((prevPoints) => {
              const updatedPoints = [...prevPoints];
              updatedPoints[updatedPoints.length - 2] = currentCoordinates;
              return updatedPoints;
            });

            setLastPoint(currentCoordinates);
          };
          deltaVec.current.set(currentCoordinates.x - lastPoint.x, currentCoordinates.y - lastPoint.y);

          const segmentLength = deltaVec.current.length();

          if (segmentLength > 0.03) {
            if (lastPoint && points.length > 3) {
              const lastControlPoint = points[points.length - 3];
              previousDelta.current.set(lastPoint.x - lastControlPoint.x, lastPoint.y - lastControlPoint.y);

              const dot = deltaVec.current.normalize().dot(previousDelta.current.normalize());
              if (dot > 0.95) {
                replaceLastPoint();
              } else {
                appendLastPoint();
              }
            } else {
              appendLastPoint();
            }
          }
        }
      },

      onDragEnd: () => {
        if (!active || !startScreenPoint || !lastPoint) return;

        setPoints([]);
        setStartPoint(null);
        UIController.toggleSelection(false);

        const intersections = collectIntersections();
        if (intersections.length > 0) {
          setCurrentIntersected(intersections);
        }
      },
    },
    {
      target: gl.domElement,
      eventOptions: { passive: false },
    }
  );

  return points.length > 1 ? (
    <Line ref={lineRef} color={0xf59c1b} lineWidth={2} points={points.map((p) => screenToWorld(p))} />
  ) : null;
}
