import {
  useHasFeatureFlag,
  useCreateSyncProfile,
  useDeleteSyncProfile,
  useEditSyncProfile,
} from '../../../../../hooks';
import { FeatureFlag } from '@assemblio/type/feature-flag';
import { SyncProfileRelationDto } from '@assemblio/shared/next-types';
import { useModalContext } from '@assemblio/frontend/components';
import { IconRefresh, IconTableOptions, IconUnlink } from '@tabler/icons-react';
import { ProductAction, ProductActionHookInput } from '../types/productActions.types';
import { InstructionState } from '@assemblio/type/instruction';

interface SyncActionHookInput extends ProductActionHookInput {
  syncProfiles?: SyncProfileRelationDto[];
}

export const useProductSyncActions = ({ product, userState, syncProfiles }: SyncActionHookInput) => {
  const variantsEnabled = useHasFeatureFlag(FeatureFlag.Variants);
  const editableProfiles = syncProfiles?.filter((profile) => profile.from.id === product?.id);
  const { openModal } = useModalContext();
  const { onDeleteSyncProfile } = useDeleteSyncProfile();
  const { onCreateSyncProfile } = useCreateSyncProfile();
  const { onUpdateSyncProfile } = useEditSyncProfile();
  const { isOwner, isViewer } = userState;

  const showUnsyncProductAction = syncProfiles?.some((profile) => profile.from.id !== product?.id);

  const handleEditSyncProfile = () => {
    openModal('edit-sync-profile', {
      syncRelations: editableProfiles,
      onUpdateSyncProfile,
    });
  };

  const openCreateSyncProfileModal = () => {
    if (!product) return;
    openModal('create-sync-profile-modal', {
      product,
      onCreateSyncProfile,
    });
  };

  const openDeleteSyncProfileModal = () => {
    if (!product) return;
    openModal('delete-sync-profile-modal', {
      product,
      syncProfiles,
      onDeleteSyncProfile,
    });
  };

  const actions: ProductAction[] = [
    {
      label: 'Create sync profile',
      Icon: IconRefresh,
      onClick: openCreateSyncProfileModal,
      allowedUserRoles: ['editor', 'manager'],
      allowedProductStates: [InstructionState.DRAFT, InstructionState.REQUEST_CHANGE],
      show: isOwner,
    },
    {
      label: 'Edit sync profile',
      Icon: IconTableOptions,
      onClick: handleEditSyncProfile,
      show: isOwner && editableProfiles && editableProfiles.length > 0,
    },
    {
      label: 'Unsync product',
      Icon: IconUnlink,
      onClick: openDeleteSyncProfileModal,
      show: isOwner && !isViewer && showUnsyncProductAction,
    },
  ];

  const filteredActions = isViewer ? [] : actions.filter((action) => action.show !== false);

  return {
    productActions: variantsEnabled ? filteredActions : [],
  };
};
