import { useMousePosition } from '@assemblio/frontend/hooks';
import { Dialog } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { PropsWithChildren, useEffect, useState } from 'react';

export interface ContextMenuProps {
  opened: boolean;
  onClose?: () => void;
}
export const ContextMenu = (props: PropsWithChildren<ContextMenuProps>) => {
  const {getMousePosition} = useMousePosition();
  const [initialized, setInitialized] = useState(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const ref = useClickOutside(() => props.onClose && props.onClose());

  useEffect(() => {
    if (props.opened && !initialized) {
      setPosition({ ...getMousePosition() });
      setInitialized(true);
    }
    if (!props.opened) {
      setInitialized(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opened, initialized]);

  if(!props.opened){
    return null;
  }

  return (
    <Dialog
      ref={ref}
      position={{
        top: `${position.y}px`,
        left: `${position.x}px`,
      }}
      opened={props.opened && initialized}
      transitionProps={{ duration: 0 }}
    >
      {props.children}
    </Dialog>
  );
};
