import { Button, Group } from '@mantine/core';
import { useState } from 'react';
import { useLinkResourceToStep } from '@assemblio/frontend/data-access';
import { useProjectStore, useUIStore } from '@assemblio/frontend/stores';
import { notifications } from '@mantine/notifications';
import { IconCirclePlusFilled } from '@tabler/icons-react';
import { ResourceSelect } from './ResourceSelect';

export const AddResourceInput = () => {
  const stepId = useUIStore((state) => state.selectedStep?.id);
  const [resourceIdToAdd, setResourceIdToAdd] = useState<string | undefined>(undefined);

  const linkMutation = useLinkResourceToStep();

  const handleAddResource = () => {
    if (!resourceIdToAdd || !stepId) return;
    linkMutation.mutate(
      {
        resourceId: resourceIdToAdd,
        stepId,
        productId: useProjectStore.getState().instructionId,
      },
      {
        onSuccess: () => {
          notifications.show({ message: 'Resource added to step' });
          setResourceIdToAdd(undefined);
        },
      }
    );
  };

  return (
    <Group w={'100%'} gap={0}>
      <ResourceSelect selectedResourceId={resourceIdToAdd} onSubmitResource={setResourceIdToAdd} />
      <Button
        leftSection={<IconCirclePlusFilled />}
        onClick={handleAddResource}
        ml={'auto'}
        w={'calc(30% - var(--mantine-spacing-sm)'}
        variant={'primary'}
        disabled={!resourceIdToAdd}
      >
        Add
      </Button>
    </Group>
  );
};
