import { generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class CreatorDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'User Id', required: true })
  id!: string;

  @joiSchemaProperty(
    Joi.string()
      .email({ tlds: { allow: false } })
      .required()
  )
  @ApiProperty({ description: 'User Email', required: true })
  email!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'First name', required: true })
  firstName!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Last name', required: true })
  lastName!: string;

  @joiSchemaProperty(Joi.string())
  @ApiProperty({ description: 'Full name', required: false })
  fullName?: string;
}

export const CreatorSchema = generateClassValidationObject(CreatorDto);
