import { IconCaretDown, IconCaretRight, IconGroup } from '@assemblio/design-system';
import { MachineController, UIController } from '@assemblio/frontend/stores';
import { Assembly } from '@assemblio/type/input';
import { Box, Collapse, Stack } from '@mantine/core';
import cx from 'clsx';
import { useMemo } from 'react';
import { HierarchyLeaf } from './HierarchyLeaf';
import { LightWeightIcon } from './LightWeightIcon';
import ToggableTextInput from './ToggableTextInput';
import classes from './TreeItem.module.scss';
import { TreeItemActions } from './TreeItemActions';
import { hierarchyEqualityFunction, useHierarchyStore } from './hierarchy.store';
import { Tree } from '../types/hierarchy.types';

interface Props {
  tree: Tree;
}

export const HierarchyBranch = ({ tree }: Props) => {
  const assembly = tree.data as Assembly;
  const childElements = useMemo(
    () =>
      tree.children.map((child) => {
        return child.isLeaf ? (
          <HierarchyLeaf key={'leaf-' + child.data.id} tree={child} />
        ) : (
          <HierarchyBranch key={'branch-' + child.data.id} tree={child} />
        );
      }),
    [tree.children]
  );
  const branchData = useHierarchyStore((state) => state.elements.get(assembly.gltfIndex), hierarchyEqualityFunction);
  if (!branchData) return null;
  const { selected, expanded, visible, transparent, excluded, name, filtered } = branchData;

  const select = (assemblyGltfIndex: number) => {
    MachineController.selectTransition(assemblyGltfIndex, true);
  };

  const expand = (assemblyGltfIndex: number) => {
    if (UIController.isAssemblyExpanded(assemblyGltfIndex)) {
      UIController.collapseAssembly(assemblyGltfIndex);
    } else {
      UIController.expandAssembly(assemblyGltfIndex);
    }
  };

  return (
    <Box ml={'10px'} style={{ display: filtered ? 'none' : '' }}>
      <div
        key={`assembly-${assembly.id}`}
        className={cx(classes.hierarchy_branch, classes.itemWrapper, {
          [classes.selected]: selected,
          [classes.selected__branch]: expanded,
        })}
        data-content={name}
        onClick={() => select(assembly.gltfIndex)}
        onContextMenu={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          UIController.setAssemblyContext(assembly.gltfIndex);
        }}
      >
        <LightWeightIcon color={'var(--text-tertiary)'} onClick={() => expand(assembly.gltfIndex)}>
          {expanded ? <IconCaretDown /> : <IconCaretRight />}
        </LightWeightIcon>
        <LightWeightIcon color={'var(--text-primary)'}>
          <IconGroup />
        </LightWeightIcon>

        <div className={classes.mid}>
          <ToggableTextInput
            elementId={assembly.id}
            isLeaf={false}
            gltfIndex={assembly.gltfIndex}
            name={name}
            visible={visible}
            excluded={excluded}
          />
        </div>
        {!excluded && (
          <div
            className={cx(classes.rhs, {
              [classes.rhs__selected]: selected || visible,
            })}
          >
            <TreeItemActions isLeaf={false} assembly={assembly} visible={visible} transparent={transparent} />
          </div>
        )}
      </div>
      <Collapse className={classes.collapse} in={expanded}>
        <Stack gap={'0'}>{childElements}</Stack>
      </Collapse>
    </Box>
  );
};
