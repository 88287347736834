import { InstructionSequence } from '@assemblio/shared/next-types';
import { PatchState } from './types/undo-redo.types';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { AnnotationDto } from '@assemblio/shared/dtos';
import { StepGroup } from '@assemblio/type/step';

export type SelectedStepGroup = {
  id: string;
  index: number;
};

export interface Sequence {
  id: string;
  selectedSequenceId: string;
  selectedStepGroup?: {
    id: string;
    index: number;
  };
  name: string;
  annotations: AnnotationDto[];
  stepGroups: StepGroup[];
  sequences: InstructionSequence[];
}

export interface SequenceStore extends Sequence, PatchState {
  reset: () => void;
}

const init = (): Sequence & PatchState => {
  return {
    patchState: new Map(),
    currentVersion: 0,
    sequences: [],
    annotations: [],
    selectedSequenceId: '',
    selectedStepGroup: undefined,
    id: '',
    name: '',
    stepGroups: [],
  };
};

export const useSequenceStore = create<SequenceStore>()(
  subscribeWithSelector(
    devtools(
      (set) => ({
        ...init(),
        reset: () => {
          set(init());
        },
      }),
      { name: 'Sequence Store' }
    )
  )
);
