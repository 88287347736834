import { Modal, Stack, Divider, Button, Text, Group } from '@mantine/core';
import { useEffect, useState } from 'react';
import produce from 'immer';
import { CheckboxWithHint } from './components/CheckBoxWIthHint';
import { SyncInstructionContent } from './components/SyncInstructionContent';
import { useExplorerRouteParams } from '../../hooks/ExplorerRouteParams.hook';
import { SyncProfileConfig, SyncProfileDto } from '@assemblio/shared/dtos';

interface ExecuteSyncModalProps {
  productId: string;
  productName: string;
  syncableProfiles: SyncProfileDto[];
  onSyncProduct: (
    baseVariantId: string,
    targetVariantId: string,
    config: SyncProfileConfig,
    projectId?: string
  ) => void;
  opened: boolean;
  onClose: () => void;
}

export const ExecuteSynchronisationModal = ({
  productId,
  productName,
  syncableProfiles,
  onSyncProduct,
  opened,
  onClose,
}: ExecuteSyncModalProps) => {
  const { projectId } = useExplorerRouteParams();

  const [baseVariant, setBaseVariant] = useState<SyncProfileDto>(syncableProfiles[0]);
  const [syncConfig, setSyncConfig] = useState({
    removeEmptySteps: false,
    removeEmptyGroups: false,
    duplicatePartColors: false,
    colorizeNewParts: false,
  });

  const commitSync = () => {
    if (!baseVariant) return;
    onSyncProduct(baseVariant.id, productId, syncConfig, projectId);
    onClose();
  };

  useEffect(() => {
    if (baseVariant?.config) {
      setSyncConfig(baseVariant?.config);
    }
  }, [baseVariant]);

  return (
    <Modal title="Execute synchronisation" size={'md'} opened={opened} onClose={onClose}>
      <Stack px={'lg'} pb={'sm'}>
        <SyncInstructionContent
          sourceProfiles={syncableProfiles}
          baseVariant={baseVariant}
          setBaseVariant={setBaseVariant}
          targetInstructionName={productName}
        />
        <Stack>
          <Text mb={'xs'}>Options:</Text>
          <CheckboxWithHint
            checked={syncConfig.duplicatePartColors}
            hintText={'Copy over all part colours from the base product'}
            onChange={() =>
              setSyncConfig(
                produce((draft) => {
                  draft.duplicatePartColors = !draft.duplicatePartColors;
                })
              )
            }
            label="Duplicate Part Colors"
          />
          <CheckboxWithHint
            checked={syncConfig.colorizeNewParts}
            hintText={'Apply a color to all new parts'}
            onChange={() =>
              setSyncConfig(
                produce((draft) => {
                  draft.colorizeNewParts = !draft.colorizeNewParts;
                })
              )
            }
            label="Colorize New Parts"
          />
          <CheckboxWithHint
            checked={syncConfig.removeEmptyGroups}
            hintText={'Remove empty step groups during synchronisation'}
            onChange={() =>
              setSyncConfig(
                produce((draft) => {
                  draft.removeEmptyGroups = !draft.removeEmptyGroups;
                })
              )
            }
            label="Remove Empty Groups"
          />
          <CheckboxWithHint
            checked={syncConfig.removeEmptySteps}
            hintText={'Remove empty steps during synchronisation'}
            onChange={() =>
              setSyncConfig(
                produce((draft) => {
                  draft.removeEmptySteps = !draft.removeEmptySteps;
                })
              )
            }
            label="Remove Empty Steps"
          />
        </Stack>
      </Stack>
      <Divider />
      <Group p={'lg'} justify="end">
        <Button variant="secondary">Cancel</Button>
        <Button disabled={!baseVariant} onClick={commitSync} variant={'primary'}>
          Synchronise
        </Button>
      </Group>
    </Modal>
  );
};
