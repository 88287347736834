import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class CreateStepGroupDto {
  @ApiProperty({ description: 'Id', required: false })
  @joiSchemaProperty(Joi.string().uuid())
  id?: string;

  @ApiProperty({ description: 'Name', required: true })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({ description: 'Seq Id', required: true })
  @joiSchemaProperty(Joi.string().uuid().required())
  instructionSequenceId!: string;

  @ApiProperty({ description: 'previous Group Id', required: false })
  @joiSchemaProperty(Joi.string().uuid().allow(null))
  previousStepGroupId?: string;
}

export const CreateStepGroupSchema = generateClassValidationObject(CreateStepGroupDto);
