import { FolderContentDto } from '@assemblio/shared/dtos';
import { useQueryClient } from '@tanstack/react-query';

export const useFolderDepth = (folderId?: string, queryKey?: string) => {
  const queryClient = useQueryClient();

  const currentFolderDepth = queryClient.getQueryData<FolderContentDto>(['folder', folderId, queryKey ?? null])?.depth;

  return currentFolderDepth || 0;
};
