export enum RoomEvents {
  Job = 'job',
  Product = 'product',
  User = 'user',
}

export enum SubscribeEvents {
  JoinRoom = 'join-room',
  LeaveRoom = 'leave-room',
  UserMessage = 'user-message',
}

export enum UserState {
  Active = 'active',
  Inactive = 'inactive',
}

export enum UserMessageType {
  SwitchSession = 'switch-session',
}
