import { useInstructionStateChange } from '@assemblio/frontend/data-access';
import { InstructionState } from '@assemblio/type/instruction';
import { notifications } from '@mantine/notifications';

export const useChangeProductState = () => {
  const changeStateMutation = useInstructionStateChange();

  const changeProductState = (productId: string, state: InstructionState) => {
    changeStateMutation.mutate(
      { instructionId: productId, state },
      {
        onSuccess: (_, values) => {
          notifications.show({
            id: 'change-state',
            message: `Product state changed to ${values.state}`,
            color: 'green',
          });
        },
      }
    );
  };

  return { changeProductState, isLoading: changeStateMutation.isLoading };
};
