import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class UnlinkResourceToStepDto {
  @ApiProperty({
    description: 'The resource to step id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({
    description: 'The step id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  stepId!: string;

  @ApiProperty({
    description: 'The product id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  productId!: string;
}

export const UnlinkResourceToStepSchema = generateClassValidationObject(UnlinkResourceToStepDto);
