import * as Joi from 'joi';

/**
 * create joi property validation for an array query string with specific enum values or RegExp string.
 * @param { Enum | string | 'uuid' } itemsValue
 * @example arrayQueryString(StateEnum)  // enum
 * @example arrayQueryString('[a-z]{10}')  // RegExp string
 * @example arrayQueryString('uuid')  // UUID
 */
export const arrayQueryString = <E extends string, K extends string>(
  itemsValue: { [key in K]: E } | string | 'uuid'
) => {
  let regexValue;
  if (typeof itemsValue !== 'string') regexValue = Object.values(itemsValue).join('|');
  else if (itemsValue === 'uuid') regexValue = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';
  else regexValue = itemsValue;

  return Joi.string()
    .regex(new RegExp(`^(${regexValue})+(,(${regexValue})+)*$`))
    .messages({
      'string.pattern.base': `'{#label}' with value '{#value}' must be valid value separated with ',' separator`,
    });
};
