import { OperationalResourceUnits } from '@assemblio/type/resources';
import { UpdateOperationalResourceDto } from '@assemblio/shared/dtos';
import { FormStatus } from '@mantine/form/lib/types';
import { UpdateDtoValue } from '../types/upsertOperationalResource.types';

export const filterUndefinedUnits = (unit: OperationalResourceUnits | undefined): unit is OperationalResourceUnits =>
  unit !== undefined;

export const getDirtyFields = (
  formValues: UpdateOperationalResourceDto,
  dirtyValues: FormStatus
): UpdateOperationalResourceDto => {
  const update: Partial<UpdateOperationalResourceDto> = {};
  for (const dirtyValuesKey in dirtyValues) {
    const key = dirtyValuesKey as keyof UpdateOperationalResourceDto;
    const value = formValues[key];
    if (value !== undefined) {
      update[key] = value as keyof UpdateDtoValue;
    }
  }
  return update;
};
