import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { ReviewStatus } from '@assemblio/type/revision';
import { UserDto } from '../user';

export class RevisionDto {
  @ApiProperty({
    description: 'The id of the instruction',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  instructionId!: string;

  @ApiProperty({
    description: 'The revision State',
    enum: ReviewStatus,
    required: true,
  })
  @joiSchemaProperty(Joi.string().valid(...Object.values(ReviewStatus)))
  state!: string;
  //
  // @ApiProperty({
  //   description: 'The reviewer',
  //   required: true,
  // })
  // @joiSchemaProperty(UserSchema)
  reviewer!: UserDto;
  //
  // @ApiProperty({
  //   description: 'The requestee',
  //   required: true,
  // })
  // @joiSchemaProperty(UserSchema)
  requestee!: UserDto;
}

export const RevisionSchema = generateClassValidationObject(RevisionDto);
