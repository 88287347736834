import produce from 'immer';
import { EventStore, useEventStore } from '../stores/EventsStore';
import {
  JobMessage,
  ProductMessage,
  RoomEvents,
  SocketEventsPayload,
  SubscribeEvents,
  UserMessage,
} from '@assemblio/type/socket';
import { RoomMessageDto } from '@assemblio/shared/dtos';

export namespace EventController {
  export const connectSocket = () => {
    useEventStore.getState().socket.connect();
  };

  export const setConnected = (isConnected: boolean) => {
    useEventStore.setState(
      produce<EventStore>((state) => {
        state.connected = isConnected;
      })
    );
  };

  export const subscribeRoom = <T extends RoomMessageDto>(
    roomProps: T,
    handler: (event: SocketEventsPayload<T['roomEvent']>) => void
  ) => {
    const socket = useEventStore.getState().socket;
    const room = roomProps.roomEvent;
    useEventStore.setState(
      produce<EventStore>((state) => {
        state.channels[room] += 1;
      })
    );
    socket.emit(SubscribeEvents.JoinRoom, roomProps);
    socket.on<RoomEvents>(room, (message: UserMessage | ProductMessage | JobMessage) => {
      handler(message as SocketEventsPayload<T['roomEvent']>);
    });
    console.debug('Subscribed to ws-room', room);
  };

  export const unsubscribeRoom = (room: RoomMessageDto) => {
    const socket = useEventStore.getState().socket;
    useEventStore.setState(
      produce<EventStore>((state) => {
        if (state.channels[room.roomEvent] === 0) {
          console.error('Trying to unsubscribe from a room that was not subscribed to', room.roomEvent);
          return;
        }
        state.channels[room.roomEvent] -= 1;
        if (state.channels[room.roomEvent] === 0) {
          console.debug('Unsubscribing from ws-room', room.roomEvent);
          state.socket.off(room.roomEvent);
        }
      })
    );
    socket.emit(SubscribeEvents.LeaveRoom, room);
  };

  export const disconnectSocket = () => {
    useEventStore.getState().socket.disconnect();
  };
}
