import { useStepGroupMove, useStepMove } from '@assemblio/frontend/data-access';
import { StepController, StepGroupController } from '@assemblio/frontend/stores';
import type { DraggableLocation, DropResult } from '@hello-pangea/dnd';
import { notifications } from '@mantine/notifications';
import { useCallback } from 'react';

export const DroppableType = {
  StepGroup: 'STEPGROUP',
  Step: 'STEP',
};

export const useStepDrop = () => {
  const moveStepGroupMutation = useStepGroupMove();
  const moveStepMutation = useStepMove();

  const moveStepGroup = useCallback(
    (source: DraggableLocation, destination: DraggableLocation) => {
      const canBeMoved = StepGroupController.canStepGroupBeMoved(source.index, destination.index);
      if (canBeMoved) {
        const { movedStepGroupId, newPrev } = StepGroupController.reorder(source.index, destination.index);

        if (movedStepGroupId && newPrev !== undefined) {
          moveStepGroupMutation.mutate(
            {
              id: movedStepGroupId,
              data: { newPrev },
            },
            {
              onError: () => {
                StepGroupController.reorder(destination.index, source.index);
              },
            }
          );
        }
      } else {
        notifications.show({
          id: 'step-group-move-forbidden',
          message:
            'Group cannot be moved to this position because dependent parts were found in the sequence. Please delete the steps with dependent parts first.',
          color: 'red',
        });
      }
    },
    [moveStepGroupMutation]
  );

  const moveStep = useCallback(
    (source: DraggableLocation, destination: DraggableLocation) => {
      const canBeMoved = StepController.canStepBeMoved(
        { groupId: source.droppableId, index: source.index },
        { groupId: destination.droppableId, index: destination.index }
      );

      if (canBeMoved) {
        const { movedStepId, newPrevStepId } = StepController.reorder(
          { groupId: source.droppableId, index: source.index },
          { groupId: destination.droppableId, index: destination.index }
        );

        if (movedStepId && newPrevStepId !== undefined) {
          moveStepMutation.mutate(
            {
              id: movedStepId,
              data: {
                newPrev: newPrevStepId,
                stepGroupId: destination.droppableId,
              },
            },
            {
              onError: () => {
                StepController.reorder(
                  { groupId: destination.droppableId, index: destination.index },
                  { groupId: source.droppableId, index: source.index }
                );
              },
            }
          );
        }
      } else {
        notifications.show({
          id: 'step-move-forbidden',
          message:
            'Step cannot be moved to this position because dependent parts were found in the sequence. Please delete the steps with dependent parts first.',
          color: 'red',
        });
      }
    },
    [moveStepMutation]
  );

  const onDragEnd = useCallback(
    (result: DropResult): void => {
      // dropped nowhere - can bail early
      if (!result.destination) {
        return;
      }

      const source: DraggableLocation = result.source;
      const destination: DraggableLocation = result.destination;

      // did not move anywhere - can bail early
      if (source.droppableId === destination.droppableId && source.index === destination.index) {
        return;
      }

      if (result.type === DroppableType.StepGroup) {
        moveStepGroup(source, destination);
      } else if (result.type === DroppableType.Step) {
        moveStep(source, destination);
      }
    },
    [moveStep, moveStepGroup]
  );

  return {
    onDragEnd,
  };
};
