import { BearerType } from '@assemblio/type/auth';

export const getAuthType = (envString?: string) =>
  envString?.split(',').map(
    (item) =>
      ({
        Auth0: BearerType.Auth0,
        Jwt: BearerType.Jwt,
      })[item.trim()] || BearerType.Jwt
  ) || [BearerType.Jwt];
