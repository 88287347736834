import {
  generateClassValidationObject,
  joiSchemaProperty,
} from '@assemblio/shared/decorators';
import { ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class AdminDownloadFileParamsDto {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({
    description: 'The file id',
    required: true,
  })
  id!: string;

  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({
    description: 'The instruction id',
    required: true,
  })
  instructionId!: string;
}

export const AdminDownloadFileParamsSchema = generateClassValidationObject(AdminDownloadFileParamsDto);
