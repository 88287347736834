import produce from 'immer';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { AnnotationState, AnnotationStore, ClipboardData, useAnnotationStore, useProjectStore } from '../stores';
import { AnnotationController } from './AnnotationController';
import { StepController } from '.';
import { ArrowAnnotation } from '@assemblio/type/annotation';

export namespace CopyPasteController {
  interface CopyResult {
    copied: boolean;
    errorMessage?: string;
  }

  export const clearClipboard = () => {
    useAnnotationStore.setState(
      produce<AnnotationStore>((state) => {
        state.clipboard = null;
      })
    );
  };

  export const copyAnnotation = (): CopyResult => {
    const originalStepId = StepController.getSelectedStep()?.id;

    if (!originalStepId) {
      return {
        copied: false,
        errorMessage: `Could not get current Step`,
      };
    }

    const instructionId = useProjectStore.getState().instructionId;
    const editingAnnotation = AnnotationController.getEditingAnnotation();

    if (!editingAnnotation) {
      return { copied: false, errorMessage: 'Could not copy annotation' };
    }

    const invalidTypes = ['image'];
    if (invalidTypes.includes(editingAnnotation.data.type)) {
      return {
        copied: false,
        errorMessage: `Cannot copy ${editingAnnotation.data.type} annotations`,
      };
    }

    const annotationCopy = _.cloneDeep(editingAnnotation.data);

    if (annotationCopy.type === 'arrow') {
      annotationCopy.meta = removeArrowAnchorReferences(annotationCopy.meta);
    }

    const copiedIds = new Map<string, Array<string>>([[originalStepId, [annotationCopy.id]]]);

    const newClipboardData: ClipboardData = {
      annotation: annotationCopy,
      context: 'annotation',
      instructionId: instructionId,
      copiedIds,
    };

    useAnnotationStore.setState(
      produce<AnnotationStore>((state) => {
        state.clipboard = newClipboardData;
      })
    );

    return {
      copied: true,
    };
  };

  export const pasteAnnotation = (targetStepId: string): AnnotationState | undefined => {
    const clipboard = useAnnotationStore.getState().clipboard;
    if (!clipboard || !clipboard.annotation) {
      console.error('No annotation in clipboard to paste.');
      return undefined;
    }

    const newId = uuidv4();

    useAnnotationStore.setState(
      produce<AnnotationStore>((state) => {
        // get annotations of targetStep
        const stepAnnotations = state.stepAnnotationMap.get(targetStepId) ?? new Set([]);

        const modifiedAnnotationData = modifyClipboardForAnnotationPositioning(
          newId,
          targetStepId,
          state.clipboard!,
          stepAnnotations
        );

        // Prepare the new annotation state with the modified data
        const newAnnotationState: AnnotationState = {
          visible: true,
          mode: 'display',
          data: { ...modifiedAnnotationData, id: newId },
          highlight: false,
        };

        // set mode to "display" for all annotations in targetStep before adding new annotation with mode "editing"
        state.annotationMap.forEach((annotationState) => {
          if (stepAnnotations.has(annotationState.data.id)) {
            annotationState.mode = 'display';
          }
        });

        // Add new annotation to annotationMap and stepAnnotationMap
        state.annotationMap.set(newId, newAnnotationState);
        stepAnnotations.add(newId);
        state.stepAnnotationMap.set(targetStepId, stepAnnotations);
      })
    );

    AnnotationController.editAnnotation(newId);
    AnnotationController.addAnnotationToStep(targetStepId, newId);

    return useAnnotationStore.getState().annotationMap.get(newId);
  };

  const modifyClipboardForAnnotationPositioning = (
    newAnnotationId: string,
    targetStepId: string,
    clipboard: ClipboardData,
    existingStepAnnotationIds: Set<string>
  ) => {
    let copyCount = 0;
    const annotation = _.cloneDeep(clipboard.annotation);
    const copiedIds = clipboard.copiedIds;

    const annotationIds = copiedIds.get(targetStepId);
    if (annotationIds) {
      // Set copy count to currently saved
      // length of annotations in clipboard
      copyCount = annotationIds.length;
      for (const id of annotationIds) {
        // Check if any annotations saved in the clipboard
        // where removed by the user
        if (!existingStepAnnotationIds.has(id)) {
          // If so start fresh from the index of the removed annotation
          copiedIds.set(targetStepId, annotationIds.slice(0, annotationIds.indexOf(id)));
          copyCount = annotationIds.indexOf(id);
          break;
        }
      }
      copiedIds.get(targetStepId)!.push(newAnnotationId);
    } else {
      // Annotation was pasted to a new Step
      copiedIds.set(targetStepId, [newAnnotationId]);
    }

    // Add some spacing between the copied arrow an the new one
    if (annotation.type === 'arrow') {
      annotation.meta.x = annotation.meta.x + 0.04 * copyCount;
      annotation.meta.tx = annotation.meta.tx + 0.04 * copyCount;
      annotation.meta.y = annotation.meta.y + 0.04 * copyCount;
      annotation.meta.ty = annotation.meta.ty + 0.04 * copyCount;
    } else if (annotation.type === 'ellipse' || annotation.type === 'rect') {
      annotation.meta.x = annotation.meta.x + 0.04 * copyCount;
      annotation.meta.y = annotation.meta.y + 0.04 * copyCount;
    }

    return annotation;
  };

  const removeArrowAnchorReferences = (arrowAnnotation: ArrowAnnotation) => {
    // All other types of heads don't make sense in a new step context
    arrowAnnotation.anchorHead.annotationId = null;
    arrowAnnotation.anchorHead.position = null;
    arrowAnnotation.anchorHead.type = 'relative';

    arrowAnnotation.anchorTail.annotationId = null;
    arrowAnnotation.anchorTail.position = null;
    arrowAnnotation.anchorTail.type = 'relative';

    return arrowAnnotation;
  };
}
