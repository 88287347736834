export enum ProjectContentSortProps {
  name = 'name',
  updatedAt = 'updatedAt',
  createdAt = 'createdAt',
  state = 'state',
}

// TODO: DELETE
export enum ProjectType {
  INSTRUCTION = 'instruction',
  SEQUENCED_INSTRUCTION = 'sequenced-instruction',
  INSTRUCTION_GROUP = 'instruction-group',
  PROJECT = 'project',
  EMPTY = 'empty',
}
