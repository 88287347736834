import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class ProjectResponseItemDto {
  @ApiProperty({
    description: 'The project id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The name of the project',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'The parent folder id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  folderId!: string;
}

export const ProjectResponseItemSchema = generateClassValidationObject(ProjectResponseItemDto);

export class QueryProjectResponseDto {
  @ApiProperty({
    description: 'The project items',
    required: true,
  })
  @joiSchemaProperty(Joi.array().items(ProjectResponseItemSchema.required()))
  items!: ProjectResponseItemDto[];

  @ApiProperty({
    description: 'The number of the projects',
    required: true,
  })
  @joiSchemaProperty(Joi.number().required())
  count!: number;
}

export const QueryProjectResponseSchema = generateClassValidationObject(QueryProjectResponseDto);
