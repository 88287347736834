import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { StepType } from '@assemblio/type/step';
import * as Joi from 'joi';
import { StepCameraSettingsDto, StepCameraSettingsSchema } from './step-props.dto';

export class StepNameDto {
  @ApiProperty({ description: 'Name', required: true })
  @joiSchemaProperty(Joi.string().required())
  name!: string;
}
export const StepNameSchema = generateClassValidationObject(StepNameDto);

export class StepMTMDto {
  @ApiProperty({ description: 'MTM', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  mtm!: number;
}
export const StepMTMSchema = generateClassValidationObject(StepMTMDto);

export class StepAnimationSpeedDto {
  @ApiProperty({ description: 'animation speed', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  animationSpeed!: number;
}
export const StepAnimationSpeedSchema = generateClassValidationObject(StepAnimationSpeedDto);

export class StepTypeDto {
  @ApiProperty({ description: 'type', required: true, enum: StepType })
  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(StepType))
      .required()
  )
  type!: StepType;
}
export const StepTypeSchema = generateClassValidationObject(StepTypeDto);

export class PatchStepCameraSettingsDto {
  @ApiProperty({ description: 'camera settings', required: true, type: StepCameraSettingsDto })
  @joiSchemaProperty(StepCameraSettingsSchema.required())
  cameraSettings!: StepCameraSettingsDto;
}
export const PatchStepCameraSettingsSchema = generateClassValidationObject(PatchStepCameraSettingsDto);

export class MoveStepDto {
  @ApiProperty({ description: 'new prev id', required: false })
  @joiSchemaProperty(Joi.string().uuid().required().allow(null))
  newPrev!: string | null;

  @ApiProperty({ description: 'Group Id', required: true })
  @joiSchemaProperty(Joi.string().uuid().required())
  stepGroupId!: string;
}
export const MoveStepSchema = generateClassValidationObject(MoveStepDto);
