import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class UpdateFolderDto {
  @ApiProperty({
    description: 'The name of the folder',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;
}

export const UpdateFolderSchema = generateClassValidationObject(UpdateFolderDto);
