import { useModalContext } from '@assemblio/frontend/components';
import { UppyProvider, useUppyUploader } from '@assemblio/frontend/hooks';
import { AppShell, SimpleGrid, useMantineTheme } from '@mantine/core';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import classes from './Explorer.module.scss';
import { Header, Sidebar } from './layout';
import { ExplorerTitleProvider } from './providers/ExplorerTitleProvider';

export const Explorer = () => {
  return (
    <UppyProvider>
      <ExplorerWithProviders />
    </UppyProvider>
  );
};

export const ExplorerWithProviders = () => {
  const theme = useMantineTheme();
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setCollapsed((collapsed) => !collapsed);
  };

  const { uploadFile, removeFile, uploadReady } = useUppyUploader();
  const { openModal } = useModalContext();

  const openCreateProductModal = () => {
    if (uploadFile) {
      openModal('create-product', {
        submitFileUpload: uploadFile,
        removeFile,
        uploadDisabled: !uploadReady,
      });
    }
  };

  return (
    <ExplorerTitleProvider>
      <AppShell
        layout={'alt'}
        navbar={{
          breakpoint: 'xs',
          width: collapsed ? theme.other.sidebarWidth_collapsed : theme.other.sidebarWidth,
        }}
        classNames={{
          root: classes.root,
          navbar: classes.navbar,
        }}
      >
        <Sidebar collapsed={collapsed} onToggleCollapse={handleToggleCollapse} />

        <AppShell.Main className={classes['content-container']}>
          <SimpleGrid verticalSpacing={0} className={classes.main_grid} h={'100%'} cols={1}>
            <Header onOpenCreateProductModal={openCreateProductModal} />

            <div className={classes.content_wrapper}>
              <Outlet />
            </div>
          </SimpleGrid>
        </AppShell.Main>
      </AppShell>
    </ExplorerTitleProvider>
  );
};
