import { generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class CreateTemporaryUserDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'tenant id', required: true })
  tenantId!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'role id', required: true })
  roleId!: string;
}

export const CreateTemporaryUserSchema = generateClassValidationObject(CreateTemporaryUserDto);
