import { InstructionEditorSettingsDto, ProjectSettingsDto } from '@assemblio/shared/dtos';
import { Projection } from '@assemblio/type/step';
import { produce } from 'immer';
import { numberToHex } from '../helper';
import { Settings, useSettingsStore } from '../stores/SettingsStore';
import { DrawMode, Grid, GridKeys, SelectionMode, Table, TableKeys } from '../stores/types/setting.types';

export namespace SettingsController {
  export const getSettingsForUpdate = () => {
    const viewport = useSettingsStore.getState().viewport;

    // Remove leading # and parse hex to number
    return {
      viewPortColor: parseInt(viewport.color.substring(1), 16),
      gridEnabled: viewport.grid.enabled,
      gridColor: parseInt(viewport.grid.color.substring(1), 16),
      gridThickness: viewport.grid.thickness,
      tableEnabled: viewport.table.enabled,
      tableColor: parseInt(viewport.table.color.substring(1), 16),
    } as InstructionEditorSettingsDto;
  };

  export const setEditorSettingsFromDto = (editorSettings: ProjectSettingsDto) => {
    SettingsController.setGridSettings({
      enabled: editorSettings.gridEnabled,
      color: numberToHex(editorSettings.gridColor),
      thickness: editorSettings.gridThickness,
    });
    SettingsController.setTableSettings({
      enabled: editorSettings.tableEnabled,
      color: numberToHex(editorSettings.tableColor),
    });
    SettingsController.setViewportColor(numberToHex(editorSettings.viewPortColor));
  };

  export const setSelectionMode = (selectionMode: SelectionMode): void => {
    useSettingsStore.setState(
      produce<Settings>((state: Settings) => {
        state.selectionMode = selectionMode;
      })
    );
  };

  export const setDrawMode = (drawMode: DrawMode) =>
    useSettingsStore.setState(
      produce<Settings>((state: Settings) => {
        state.viewport.drawMode = drawMode;
      })
    );

  export const setViewportColor = (color: string) =>
    useSettingsStore.setState(
      produce<Settings>((state: Settings) => {
        state.viewport.color = color;
      })
    );

  export const setGridSettings = (settings: Grid) =>
    useSettingsStore.setState(
      produce<Settings>((state: Settings) => {
        state.viewport.grid = settings;
      })
    );

  export const setGridSettingsByKey = (setting: GridKeys, value: number | boolean | string) =>
    useSettingsStore.setState(
      produce<Settings>((state: Settings) => {
        state.viewport.grid[setting] = value;
      })
    );

  export const setTableSettings = (settings: Table) =>
    useSettingsStore.setState(
      produce<Settings>((state: Settings) => {
        state.viewport.table = settings;
      })
    );

  export const setTableSettingsByKey = (setting: TableKeys, value: boolean | string) =>
    useSettingsStore.setState(
      produce<Settings>((state: Settings) => {
        state.viewport.table[setting] = value;
      })
    );

  export const setProjection = (projection: Projection) => {
    useSettingsStore.setState(
      produce<Settings>((state: Settings) => {
        state.viewport.camera.projection = projection;
      })
    );
  };
}
