export enum FeatureFlag {
  FileAnalyze = 'feature.file.analyze',
  Variants = 'feature.variants',
  JTFileType = 'feature.filetype.jt',
  WordExport = 'feature.word.export',
  BetaWordTemplateV2 = 'feature.word.template.v2',
  BetaReview = 'feature.beta.review',
  BetaResources = 'feature.beta.resources',
  EXPERIMENTAL_COVER_GENERATION = 'feature.experimental.cover.generation',
}
