import React from 'react';

export const IconOpacity = React.memo(() => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_114_1182"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="14"
        height="14"
      >
        <circle cx="8" cy="8" r="7" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_114_1182)">
        <rect x="6.5" y="7.5" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="7.6875" y="7.5" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="6.5" y="8.6875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="7.6875" y="8.6875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="6.5" y="2.75" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="7.6875" y="2.75" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="6.5" y="3.9375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="7.6875" y="3.9375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="6.5" y="12.25" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="7.6875" y="12.25" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="6.5" y="13.4375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="7.6875" y="13.4375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="6.5" y="0.375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="7.6875" y="0.375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="6.5" y="1.5625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="7.6875" y="1.5625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="6.5" y="9.875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="7.6875" y="9.875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="6.5" y="11.0625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="7.6875" y="11.0625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="6.5" y="5.125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="7.6875" y="5.125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="6.5" y="6.3125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="7.6875" y="6.3125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="6.5" y="14.625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="7.6875" y="14.625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="1.75" y="7.5" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="2.9375" y="7.5" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="1.75" y="8.6875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="2.9375" y="8.6875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="1.75" y="2.75" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="2.9375" y="2.75" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="1.75" y="3.9375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="2.9375" y="3.9375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="1.75" y="12.25" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="2.9375" y="12.25" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="1.75" y="13.4375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="2.9375" y="13.4375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="1.75" y="0.375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="2.9375" y="0.375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="1.75" y="1.5625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="2.9375" y="1.5625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="1.75" y="9.875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="2.9375" y="9.875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="1.75" y="11.0625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="2.9375" y="11.0625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="1.75" y="5.125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="2.9375" y="5.125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="1.75" y="6.3125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="2.9375" y="6.3125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="1.75" y="14.625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="2.9375" y="14.625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="11.25" y="7.5" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="12.4375" y="7.5" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="11.25" y="8.6875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="12.4375" y="8.6875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="11.25" y="2.75" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="12.4375" y="2.75" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="11.25" y="3.9375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="12.4375" y="3.9375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="11.25" y="12.25" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="12.4375" y="12.25" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="11.25" y="13.4375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="12.4375" y="13.4375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="11.25" y="0.375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="12.4375" y="0.375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="11.25" y="1.5625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="12.4375" y="1.5625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="11.25" y="9.875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="12.4375" y="9.875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="11.25" y="11.0625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="12.4375" y="11.0625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="11.25" y="5.125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="12.4375" y="5.125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="11.25" y="6.3125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="12.4375" y="6.3125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="11.25" y="14.625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="12.4375" y="14.625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="0.5625" y="7.5" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="0.5625" y="8.6875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="0.5625" y="2.75" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="0.5625" y="3.9375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="0.5625" y="12.25" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="0.5625" y="13.4375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="0.5625" y="0.375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="0.5625" y="1.5625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="0.5625" y="9.875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="0.5625" y="11.0625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="0.5625" y="5.125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="0.5625" y="6.3125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="0.5625" y="14.625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="8.875" y="7.5" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="10.0625" y="7.5" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="8.875" y="8.6875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="10.0625" y="8.6875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="8.875" y="2.75" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="10.0625" y="2.75" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="8.875" y="3.9375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="10.0625" y="3.9375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="8.875" y="12.25" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="10.0625" y="12.25" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="8.875" y="13.4375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="10.0625" y="13.4375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="8.875" y="0.375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="10.0625" y="0.375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="8.875" y="1.5625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="10.0625" y="1.5625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="8.875" y="9.875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="10.0625" y="9.875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="8.875" y="11.0625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="10.0625" y="11.0625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="8.875" y="5.125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="10.0625" y="5.125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="8.875" y="6.3125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="10.0625" y="6.3125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="8.875" y="14.625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="10.0625" y="14.625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="4.125" y="7.5" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="5.3125" y="7.5" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="4.125" y="8.6875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="5.3125" y="8.6875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="4.125" y="2.75" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="5.3125" y="2.75" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="4.125" y="3.9375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="5.3125" y="3.9375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="4.125" y="12.25" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="5.3125" y="12.25" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="4.125" y="13.4375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="5.3125" y="13.4375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="4.125" y="0.375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="5.3125" y="0.375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="4.125" y="1.5625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="5.3125" y="1.5625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="4.125" y="9.875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="5.3125" y="9.875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="4.125" y="11.0625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="5.3125" y="11.0625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="4.125" y="5.125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="5.3125" y="5.125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="4.125" y="6.3125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="5.3125" y="6.3125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="4.125" y="14.625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="5.3125" y="14.625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="13.625" y="7.5" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="14.8125" y="7.5" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="13.625" y="8.6875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="14.8125" y="8.6875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="13.625" y="2.75" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="14.8125" y="2.75" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="13.625" y="3.9375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="14.8125" y="3.9375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="13.625" y="12.25" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="14.8125" y="12.25" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="13.625" y="13.4375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="14.8125" y="13.4375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="13.625" y="0.375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="14.8125" y="0.375" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="13.625" y="1.5625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="14.8125" y="1.5625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="13.625" y="9.875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="14.8125" y="9.875" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="13.625" y="11.0625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="14.8125" y="11.0625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="13.625" y="5.125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="14.8125" y="5.125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="13.625" y="6.3125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect x="14.8125" y="6.3125" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="13.625" y="14.625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.6" />
        <rect x="14.8125" y="14.625" width="1.1875" height="1.1875" fill="#131925" fillOpacity="0.4" />
        <rect opacity="0.8" x="1" y="1" width="7" height="15" fill="currentColor" />
      </g>
    </svg>
  );
});
