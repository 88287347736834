import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { SyncProfileState } from '@assemblio/type/instruction';
import * as Joi from 'joi';

export class SyncProfileConfig {
  @joiSchemaProperty(Joi.array().items(Joi.string()))
  @ApiProperty({ description: 'config rules', required: true, type: [String] })
  triggerRules?: string[];

  @joiSchemaProperty(Joi.boolean().required())
  @ApiProperty({ description: 'remove empty steps?', required: true, type: Boolean })
  removeEmptySteps!: boolean;

  @joiSchemaProperty(Joi.boolean().required())
  @ApiProperty({ description: 'remove empty groups?', required: true, type: Boolean })
  removeEmptyGroups!: boolean;

  @joiSchemaProperty(Joi.boolean().required())
  @ApiProperty({ description: 'duplicate part colors?', required: true, type: Boolean })
  duplicatePartColors!: boolean;

  @joiSchemaProperty(Joi.boolean().required())
  @ApiProperty({ description: 'colorize new parts?', required: true, type: Boolean })
  colorizeNewParts!: boolean;
}

export const SyncProfileConfigSchema = generateClassValidationObject(SyncProfileConfig);

export class SyncProfileDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Profile Id', required: true })
  id!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Profile Name', required: true })
  name!: string;

  @joiSchemaProperty(SyncProfileConfigSchema)
  @ApiProperty({ description: 'Profile Name', required: false, type: SyncProfileConfig })
  config?: SyncProfileConfig;

  @joiSchemaProperty(Joi.string().valid(...Object.values(SyncProfileState)))
  @ApiProperty({ description: 'Profile Name', required: false, enum: SyncProfileState })
  status?: SyncProfileState;

  @joiSchemaProperty(Joi.date())
  @ApiProperty({ description: 'Last sync date', required: false })
  lastSyncedAt?: Date;
}

export const SyncProfileSchema = generateClassValidationObject(SyncProfileDto);
