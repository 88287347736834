import { Divider, Button, Modal, Stack, TextInput, Flex } from '@mantine/core';
import { ExplorerItemType } from '../../pages/assembly-plans/types/items.types';
import { FormEvent, useState } from 'react';
import { useCreateFolder } from './hooks/createFolder.hook';
import { useCreateProject } from './hooks/createProject.hook';
import { useForm } from '@mantine/form';
import classes from './CreateExplorerItemModal.module.scss';

interface CreateExplorerItemModalProps {
  opened: boolean;
  onClose: () => void;
  type: ExplorerItemType;
  parentFolderId?: string;
}

export const CreateExplorerItemModal = ({ opened, onClose, type, parentFolderId }: CreateExplorerItemModalProps) => {
  const [name, setName] = useState<string | undefined>(undefined);

  const { createFolder } = useCreateFolder();
  const { createProject } = useCreateProject();

  const form = useForm({
    initialValues: { name: '' },
    validate: {
      name: (value) => {
        if (value.trim().length < 3 || value.trim().length > 55) {
          return 'Name must be between 3 and 55 characters long';
        }
        return null;
      },
    },
  });

  const handleCancel = () => {
    setName(undefined);
    onClose();
  };

  //TODO: implement project creation

  const handleSubmitCreation = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (form.validate().hasErrors) {
      return;
    }
    if (type === ExplorerItemType.Folder) {
      createFolder(form.values.name, parentFolderId);
    } else {
      createProject(form.values.name);
    }
    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      title={`New ${type}`}
      classNames={{
        body: classes.customModal,
      }}
    >
      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmitCreation(e);
      }}>
        <Stack mb={'lg'} data-cy="ItemModal">
          <TextInput
            placeholder={name}
            {...form.getInputProps('name')}
            required
            px={'lg'}
            pb={'md'}
            data-cy="itemInput"
          />
          <Divider c={'border-secondary'} />
          <Flex justify="flex-end" gap={'md'} px={'lg'}>
            <Button onClick={handleCancel} variant={'secondary'}>
              Cancel
            </Button>
            <Button type={'submit'} disabled={!form.values.name} variant={'primary'} data-cy="createBtn">
              Create
            </Button>
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
};
