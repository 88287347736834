import { Text } from '@mantine/core';
import { Link } from 'react-router-dom';

interface BreadcrumbItemProps {
  name: string;
  navigationTarget?: string;
}

export const BreadcrumbItem = ({ name, navigationTarget }: BreadcrumbItemProps) => {
  return (
    <Text mx={'sm'} lineClamp={1} size={'md'}>
      <Link to={navigationTarget ?? ''}>{name}</Link>
    </Text>
  );
};
