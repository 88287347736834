import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class FolderIdParamDto {
  @ApiProperty({
    description: 'The id of a folder',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;
}

export const FolderIdParamSchema = generateClassValidationObject(FolderIdParamDto);
