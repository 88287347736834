import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import {
  OperationalResourceMainCategory,
  OperationalResourceSecondaryCategory,
  OperationalResourceUnits,
} from '@assemblio/type/resources';

export class UpdateOperationalResourceDto {
  @ApiProperty({
    description: 'The operational resource main category',
    required: false,
  })
  @joiSchemaProperty(Joi.string().valid(...Object.values(OperationalResourceMainCategory)))
  mainCategory?: OperationalResourceMainCategory;

  @ApiProperty({
    description: 'The operational resource sub category',
    required: false,
  })
  @joiSchemaProperty(Joi.string().valid(...Object.values(OperationalResourceSecondaryCategory)))
  secondaryCategory?: OperationalResourceSecondaryCategory;

  @ApiProperty({
    description: 'The operational resource name',
    required: false,
  })
  @joiSchemaProperty(Joi.string())
  name?: string;

  @ApiProperty({
    description: 'The operational resource descriptive text',
    required: false,
  })
  @joiSchemaProperty(Joi.string())
  text?: string;

  @ApiProperty({
    description: 'The operational resource unit of measurement',
    required: false,
  })
  @joiSchemaProperty(Joi.array().items(Joi.string().valid(...Object.values(OperationalResourceUnits))))
  allowedUnits?: OperationalResourceUnits[];

  @ApiProperty({
    description: 'The operational resource No.',
    required: false,
  })
  @joiSchemaProperty(Joi.string())
  resourceNo?: string;
}

export const UpdateOperationalResourceSchema = generateClassValidationObject(UpdateOperationalResourceDto);
