import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class FolderResponseItemDto {
  @ApiProperty({
    description: 'The Folder id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The name of the Folder',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;
}

export const FolderResponseItemSchema = generateClassValidationObject(FolderResponseItemDto);

export class QueryFolderResponseDto {
  @ApiProperty({
    description: 'The Folder items',
    required: true,
  })
  @joiSchemaProperty(Joi.array().items(FolderResponseItemSchema.required()))
  items!: FolderResponseItemDto[];

  @ApiProperty({
    description: 'The number of the Folders',
    required: true,
  })
  @joiSchemaProperty(Joi.number().required())
  count!: number;
}

export const QueryFolderResponseSchema = generateClassValidationObject(QueryFolderResponseDto);
