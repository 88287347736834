import { StepController } from '@assemblio/frontend/stores';
import { Step as StepDef } from '@assemblio/type/step';
import { Group, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { IconPackage } from '@tabler/icons-react';
import { memo, useEffect, useMemo, useRef } from 'react';
import { useStepDisplayState } from './Hooks/StepDisplayState';
import classes from './Styles/Step.module.scss';
import cx from 'clsx';

export interface StepProps {
  step: StepDef;
  onClick: (step: StepDef) => void;
}

const CompactStep = ({ step, onClick }: StepProps) => {
  const scrollTarget = useRef<HTMLDivElement>(null);
  const { selected, animated, highlighted } = useStepDisplayState(step);

  const enumeration = useMemo(() => StepController.getStepAssemblyOrderById(step), [step]);

  useEffect(() => {
    if (scrollTarget.current && (animated || (!animated && selected))) {
      scrollTarget.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [animated, selected, scrollTarget]);

  // Workaround until we use actual server ids
  if (step.id) {
    return (
      <div
        className={cx(classes.item, classes['item--compact'], {
          [classes['item--selected']]: selected,
          [classes['item--animated']]: animated,
        })}
        onClick={() => onClick(step)}
        ref={scrollTarget}
      >
        <div className={classes.header}>
          <Tooltip offset={10} multiline label={step.name} openDelay={500}>
            <Text
              size="md"
              variant={'medium'}
              className={cx(classes.stepNameCompact, {
                [classes['stepNameCompact--selected']]: selected,
              })}
              lineClamp={3}
            >
              {`${enumeration + 1}.\u00A0${step.name}`}
            </Text>
          </Tooltip>

          <Group gap={4} className={classes.headerRight}>
            {highlighted && (
              <Tooltip label="Step includes selected part">
                <ThemeIcon size="sm" className={classes.partIndicator}>
                  <IconPackage />
                </ThemeIcon>
              </Tooltip>
            )}
          </Group>
        </div>
      </div>
    );
  } else return null;
};

export default memo(CompactStep);
