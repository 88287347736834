import * as Joi from 'joi';
import { joiSchemaProperty, generateClassValidationObject, ApiProperty } from '@assemblio/shared/decorators';

export class CreateBrandingDTO {
  @ApiProperty({
    description: 'the tenant id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  tenantId!: string;
}

export const CreateBrandingSchema = generateClassValidationObject(CreateBrandingDTO);
