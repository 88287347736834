import { useSequenceStore } from '../../stores/SequenceStore';

export const getStepGroup = (groupId: string) => {
  return useSequenceStore.getState().stepGroups.find((group) => group.id === groupId);
};

export const getStepByIndex = (groupId: string, index: number) => {
  return getStepGroup(groupId)?.steps.at(index);
};

export const getGroupIndex = (groupId: string) => {
  return useSequenceStore.getState().stepGroups.findIndex((group) => group.id === groupId);
};

export const getGroupByIndex = (index: number) => {
  return useSequenceStore.getState().stepGroups.at(index);
};

export const getFirstStepGroup = () => {
  return useSequenceStore.getState().stepGroups.at(0);
};

export const getLastStepGroup = () => {
  return useSequenceStore.getState().stepGroups.at(-1);
};
