import { useRef, useEffect } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { BufferGeometry, Group, Intersection, Line, LineBasicMaterial, Object3D, Raycaster, Scene, Vector3 } from 'three';

type ViewableRaycastProps = {
  debug?: boolean;
  useFirstIntersection?: boolean;
}
export function useViewableRaycast( {debug, useFirstIntersection} : ViewableRaycastProps  ) {
  const raycaster = useRef(new Raycaster());
  const collectedRays = useRef<Line[]>([]);
  const rayGroupRef = useRef<Group | null>(null); // A debug group for visualizing rays in the scene

  const intersections : Array<Intersection> = []
  const { scene } = useThree();

  useEffect(() => {
    if(raycaster.current){
      raycaster.current.firstHitOnly = useFirstIntersection;
    }
  }, [useFirstIntersection, raycaster]);


  // Function to perform the raycast and optionally collect rays for visualization
  function intersectObjects(origin: Vector3, direction: Vector3, objects: Group | Array<Object3D> | Scene, recursive = true) {
    raycaster.current.set(origin, direction.normalize());
    intersections.length = 0;
    if(Array.isArray(objects)) {
      raycaster.current.intersectObjects(objects, recursive, intersections);
    }else if(objects instanceof Group) {
      raycaster.current.intersectObject(objects, recursive, intersections);
    }else {
      console.error('Objects must be an array or a group');
    }

    if (debug) {
      const minDistance = intersections?.length > 0 ? intersections.reduce((shortestRayLength, current) => {
        return current.distance < shortestRayLength ? current.distance : shortestRayLength;
      }, Infinity) : 1000;
      const line = createRayLine(origin, direction, minDistance);
      collectedRays.current.push(line);
      rayGroupRef.current?.add(line);
    }

    return intersections;
  }

  function resetRays() {
    collectedRays.current.forEach(line => {
      rayGroupRef.current?.remove(line);
    });
    collectedRays.current = [];
  }

  function createRayLine(origin: Vector3, direction: Vector3, length: number) {
    const points = [
      origin.clone(),
      origin.clone().add(direction.clone().multiplyScalar(length)),
    ];

    const geometry = new BufferGeometry().setFromPoints(points);
    const material = new LineBasicMaterial({ color: 'red' });
    return new Line(geometry, material);
  }

  useEffect(() => {
    if(debug){

      const rayGroup = new Group();
      rayGroupRef.current = rayGroup;
      scene.add(rayGroup);
    }

    return () => {
      if(rayGroupRef.current){

        scene.remove(rayGroupRef.current);
      }
    };
  }, [scene, debug]);

  return {
    intersectObjects,
    resetRays,
  };
}
