import { useProductExportJobQuery } from '@assemblio/frontend/data-access';
import { Divider } from '@mantine/core';
import { JobCard } from '../../../../../../../../components/JobCard/JobCard';
import { useExportJobWebSocketListener } from './hooks/jobWebSocket.listener';

interface ExportJobsListProps {
  productId: string | undefined;
  projectId: string | undefined;
}

export const ExportJobsList = ({ productId, projectId }: ExportJobsListProps) => {
  const { data: exportJobs, isLoading } = useProductExportJobQuery(productId);
  useExportJobWebSocketListener(productId, projectId, exportJobs?.length);
  const exportJobsIsEmpty = exportJobs && exportJobs.length === 0;

  if (isLoading || !exportJobs || exportJobsIsEmpty) return null;
  return (
    <>
      {exportJobs?.map((job) => <JobCard key={job.id} job={job} />)}
      <Divider />
    </>
  );
};
