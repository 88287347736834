import { getAccessToken } from '@assemblio/frontend/data-access';
import { useEffect, useState } from 'react';
import { ConfigService } from '@assemblio/frontend/stores';
import { BearerType } from '@assemblio/type/auth';
import { useAuth0 } from '@auth0/auth0-react';

export const Auth0Login = () => {
  const auth0 = useAuth0();
  const [loading, setLoading] = useState<boolean>(false);

  const config = ConfigService.getAuthConfig();

  useEffect(() => {
    if (!config.auth0) {
      setLoading(false);
      return;
    }
    if (!auth0.isLoading && auth0.isAuthenticated) getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0.isLoading, auth0.isAuthenticated]);

  const getToken = async () => {
    if (loading) return;
    setLoading(true);

    await getAccessToken(auth0).then((value) => {
      if (value.token) {
        setLoading(false);
      }
    });

    setLoading(false);
  };

  const shouldUseAuth0 = config.types.includes(BearerType.Auth0) && !loading;

  const onForceAuth0: () => void = () => {
    if (!auth0.isLoading) {
      auth0.loginWithRedirect({
        appState: {
          returnTo: '/explorer',
        },
      });
    }
  };

  const isAuth0Active = !auth0.isLoading;

  return {
    shouldUseAuth0,
    onForceAuth0,
    isAuth0Active,
  };
};
