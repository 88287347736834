import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { OperationalResourceUnits } from '@assemblio/type/resources';

export class StepResourceValueDto {
  @ApiProperty({
    description: 'The Amount',
    required: Number,
  })
  @joiSchemaProperty(Joi.number())
  amount?: number;

  @ApiProperty({
    description: 'The unit',
    required: false,
  })
  @joiSchemaProperty(Joi.string().valid(...Object.values(OperationalResourceUnits)))
  unit?: OperationalResourceUnits;

  @ApiProperty({
    description: 'Free text details',
    required: false,
  })
  @joiSchemaProperty(Joi.string())
  info?: string;
}

export const StepResourceValueSchema = generateClassValidationObject(StepResourceValueDto);
