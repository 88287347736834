import type { Transform as TransformInterface } from '@assemblio/type/3d';
import { QuaternionDto, QuaternionSchema } from './quaternion.dto';
import { VectorDto, VectorSchema } from './vector.dto';
import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';

export class TransformDto implements TransformInterface {
  @ApiProperty({ description: 'Position', required: true, type: VectorDto })
  @joiSchemaProperty(VectorSchema.required())
  position!: VectorDto;

  @ApiProperty({ description: 'Rotation', required: true, type: QuaternionDto })
  @joiSchemaProperty(QuaternionSchema.required())
  rotation!: QuaternionDto;
}
export const TransformSchema = generateClassValidationObject(TransformDto);

export class PivotTransformDto extends TransformDto {
  @ApiProperty({ description: 'Pivot', required: true, type: VectorDto })
  @joiSchemaProperty(VectorSchema.required())
  pivot!: VectorDto;
}
export const PivotTransformSchema = generateClassValidationObject(PivotTransformDto);
