import { Dropzone, FileRejection, FileWithPath } from '@mantine/dropzone';
import { Box, Button, Group, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import classes from './FileDropzone.module.scss';
import { IconCloudUpload, IconHelpHexagonFilled } from '@tabler/icons-react';
import { ErrorCode } from 'react-dropzone-esm';

interface FileDropzoneProps {
  acceptedFileTypes: string[];
  handleFileDrop: (files: FileWithPath[]) => void;
  buttonText?: string;
}

const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024;

export const FileDropzone = ({
  handleFileDrop,
  acceptedFileTypes,
  buttonText = 'Choose a file',
}: FileDropzoneProps) => {
  const [fileError, setFileError] = useState<ErrorCode | null>(null);
  const openRef = useRef<() => void>(null);

  const handleRejectedFiles = (fileRejections: FileRejection[]) => {
    const error = fileRejections[0].errors[0].code;
    const isErrorCode = Object.values(ErrorCode).includes(error as ErrorCode);
    if (isErrorCode) {
      setFileError(error as ErrorCode);
      return;
    }
  };

  const handleValidFileDrop = (files: FileWithPath[]) => {
    setFileError(null);
    handleFileDrop(files);
  };

  useEffect(() => {
    return () => {
      setFileError(null);
    };
  }, []);

  return (
    <Dropzone
      maxFiles={1}
      maxSize={MAX_FILE_SIZE}
      openRef={openRef}
      className={classes.dropzone}
      onReject={handleRejectedFiles}
      onDrop={handleValidFileDrop}
      useFsAccessApi
      accept={{
        'model/step': acceptedFileTypes,
        'application/p21': ['.p21'],
      }}
    >
      <Stack mih={209} justify="center" align="center" gap={'lg'} className={classes.container}>
        <IconCloudUpload size={40} color={'var(--border-quaternary-color)'} />
        <Box>
          <Text variant={'medium'} size={'lg'} c={'text-secondary'} inline>
            Upload your file
          </Text>
          <Box mt={'sm'} className={classes.dragDrop}>
            <Button px={'md'} py={'sm'}>
              {buttonText}
            </Button>
          </Box>
        </Box>
        <Group>
          <Text size={'sm'} c={'var(--text-primary)'}>
            Compatible Formats: {acceptedFileTypes.join(', ')}
          </Text>
          <Tooltip label={'Please contact us for additional formats'} openDelay={500}>
            <ThemeIcon variant={'transparent'} c={'text-tertiary'} size={'xs'}>
              <IconHelpHexagonFilled />
            </ThemeIcon>
          </Tooltip>
        </Group>
        {fileError && (
          <Text size={'sm'} c={'var(--text-alert)'}>
            {ERROR_MESSAGES[fileError]}
          </Text>
        )}
      </Stack>
    </Dropzone>
  );
};

type ErrorMap = {
  [key in ErrorCode]: string;
};

const ERROR_MESSAGES: ErrorMap = {
  [ErrorCode.FileInvalidType]: 'File type not supported',
  [ErrorCode.FileTooLarge]: 'File is too large',
  [ErrorCode.FileTooSmall]: 'File is too small',
  [ErrorCode.TooManyFiles]: 'Too many files',
};
