import { Button, Text, Title, Group, Divider, Stack, Modal, ActionIcon } from '@mantine/core';
import classes from './AdminSettingsModal.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconCircleXFilled } from '@tabler/icons-react';
import { useSidebarState } from '../../hooks';
import { useModalContext } from '@assemblio/frontend/components';
import { LayoutSettings } from './components/LayoutSettings/LayoutSettings';

interface AdminSettingsModalProps {
  opened: boolean;
  onClose: () => void;
}

export const AdminSettingsModal = ({ opened, onClose }: AdminSettingsModalProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { collapsed } = useSidebarState();

  const handleClose = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('administration');
    navigate({ search: `?${searchParams.toString()}` }, { replace: true });
    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      withCloseButton={false}
      radius={'lg'}
      data-collapsed={collapsed}
      centered
      classNames={{
        overlay: classes.modal__overlay,
        content: classes.modal__content,
        body: classes.modal__body,
        close: classes.modal_close,
      }}
    >
      <Stack h={'100%'} pt={'xl'} className={classes.content} px="xl">
        <ActionIcon variant={'transparent'} c={'text-primary'} className={classes.close} onClick={handleClose}>
          <IconCircleXFilled />
        </ActionIcon>
        <Stack gap={'lg'}>
          <Text variant={'medium'} size={'sm'} c={'text-secondary'}>
            Admin settings
          </Text>
          <Title order={1} mb="xs">
            Export Layout Settings
          </Title>
          <LayoutSettings />
        </Stack>
      </Stack>
    </Modal>
  );
};
