import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { SyncProfileState } from '@assemblio/type/instruction';
import * as Joi from 'joi';

class SyncProfileConfigV1 {
  @joiSchemaProperty(Joi.array().items(Joi.string()))
  @ApiProperty({ description: 'Trigger Rules', required: false })
  triggerRules?: string[];

  @joiSchemaProperty(Joi.boolean().required())
  @ApiProperty({ description: 'remove empty steps?', required: true })
  removeEmptySteps!: boolean;

  @joiSchemaProperty(Joi.boolean().required())
  @ApiProperty({ description: 'remove empty groups?', required: true })
  removeEmptyGroups!: boolean;

  @joiSchemaProperty(Joi.boolean().required())
  @ApiProperty({ description: 'duplicate part colors?', required: true })
  duplicatePartColors!: boolean;

  @joiSchemaProperty(Joi.boolean().required())
  @ApiProperty({ description: 'colorize new parts?', required: true })
  colorizeNewParts!: boolean;
}

class SyncProfileV1Dto {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'product id', required: true })
  id!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'product name', required: true })
  name!: string;
}

const SyncProfileConfigV1Schema = generateClassValidationObject(SyncProfileConfigV1);
const SyncProfileV1V1Schema = generateClassValidationObject(SyncProfileV1Dto);

export class SyncProfileRelationV1Dto {
  @joiSchemaProperty(SyncProfileV1V1Schema.required())
  @ApiProperty({ description: 'to product', required: true })
  to!: SyncProfileV1Dto;

  @joiSchemaProperty(SyncProfileV1V1Schema.required())
  @ApiProperty({ description: 'from product', required: true })
  from!: SyncProfileV1Dto;

  @joiSchemaProperty(SyncProfileConfigV1Schema)
  @ApiProperty({ description: 'config', required: false })
  config?: SyncProfileConfigV1;

  @joiSchemaProperty(Joi.date())
  @ApiProperty({ description: 'last synced date', required: false })
  lastSyncedAt?: Date;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(SyncProfileState))
      .required()
  )
  @ApiProperty({ description: 'last synced date', required: true })
  state!: SyncProfileState;
}

export const SyncProfileRelationV1Schema = generateClassValidationObject(SyncProfileRelationV1Dto);
