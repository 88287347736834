import { ScrollArea, Stack, Tabs } from '@mantine/core';
import { useRef, useState } from 'react';
import classes from './Sequencer.module.scss';
import { StepBoard } from './StepBoard/StepBoard';
import { SearchField } from '@assemblio/design-system';

export const Sequencer = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const viewport = useRef<HTMLDivElement>(null);

  // const scrollToBottom = () =>
  //   viewport?.current?.scrollTo({
  //     top: viewport.current.scrollHeight,
  //     behavior: 'smooth',
  //   });

  return (
    <Stack justify={'stretch'} h={'100%'} w={'100%'} data-cy="sequencerTab">
      <Tabs defaultValue="steps">
        <Tabs.List>
          <Tabs.Tab value="steps">Steps</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="steps">
          <div className={classes.stepList}>
            <SearchField
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.currentTarget.value)}
              placeholder="Search parts"
              type="search"
              classNames={{
                root: classes.inputRoot,
                input: classes.searchInput,
              }}
            />
            <ScrollArea
              data-cy="sequencer-scroll-list"
              type="scroll"
              offsetScrollbars
              pb={'xl'}
              className={classes.scrollArea}
              classNames={{
                viewport: classes.viewport,
              }}
              viewportRef={viewport}
            >
              <StepBoard searchTerm={searchTerm} />
            </ScrollArea>
          </div>
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
};
