import { Box, Image, Button, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { BrandingFileType } from '@assemblio/type/branding';
import { TitleWithInformation } from '../TitleWithInformation';
import classes from './LogoDisplay.module.scss';
import { UploadImageModal } from '../../modals/UploadImageModal';
import { notifications } from '@mantine/notifications';
import { useUploadBrandingFileMutation } from '@assemblio/frontend/data-access';

interface LogoDisplayProps {
  imageUrl: string | null;
  isLoading: boolean;
}
export const LogoDisplay = ({ imageUrl, isLoading }: LogoDisplayProps) => {
  const [openedUploadImageModal, { open: openUploadImageModal, close: closeUploadImageModal }] = useDisclosure(false);

  const uploadLogoMutation = useUploadBrandingFileMutation();

  const uploadLogo = (file: File | Blob | undefined) => {
    if (!file) return;

    const transformedFile = file instanceof Blob ? new File([file], 'logo.png', { type: 'image/png' }) : file;

    uploadLogoMutation.mutate(
      {
        type: BrandingFileType.logo,
        file: transformedFile,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'upload-logo-success',
            message: 'Uploaded new Logo',
            color: 'green',
          });
        },
        onError: () => {
          notifications.show({
            id: 'upload-logo-error',
            message: 'Logo could not be uploaded',
            color: 'red',
          });
        },
      }
    );
    closeUploadImageModal();
  };

  return (
    <Box maw={200}>
      <TitleWithInformation title={'Logo'} information={'Logo displayed in your exported Instructions.'} />
      <Box mb={'xs'} className={classes.layout__logo__container}>
        <LoadingOverlay visible={uploadLogoMutation.isLoading || isLoading} />
        <Image className={classes.layout__logo__image} h={'90%'} w={'90%'} m={'5%'} src={imageUrl} />
      </Box>
      <Button variant={'primary'} disabled={!imageUrl} fullWidth onClick={openUploadImageModal}>
        Change Logo
      </Button>
      {imageUrl && (
        <UploadImageModal
          imageUrl={imageUrl}
          onUploadImage={uploadLogo}
          opened={openedUploadImageModal}
          onClose={closeUploadImageModal}
        />
      )}
    </Box>
  );
};
