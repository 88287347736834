import { Input } from '@assemblio/type/input';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { PatchState } from './types/undo-redo.types';
import { InstructionState } from '@assemblio/type/instruction';

export interface ProjectStore extends Project, PatchState {
  selectedExplorerItem: string;
  highlightedInstructionId: string | undefined;
  navigatedToComposerFrom?: string;
  reset: () => void;
}

export interface Project {
  projectId: string;
  instructionId: string;
  name: string;
  owner: {
    id: string;
    lastName: string;
    firstName: string;
    email: string;
  };
  state: InstructionState;
  input: Input;
}

const initialState = {
  currentVersion: 0,
  patchState: new Map(),
  projectId: '',
  instructionId: '',
  name: '',
  selectedExplorerItem: '',
  highlightedInstructionId: undefined,
  owner: {
    id: '',
    lastName: '',
    firstName: '',
    email: '',
  },
  state: 'draft' as InstructionState,
  input: {} as Input,
};

export const useProjectStore = create<ProjectStore>()(
  subscribeWithSelector(
    devtools(
      (set) => ({
        ...initialState,
        reset: () => {
          set(initialState);
        },
      }),
      {
        name: 'Project Store',
      }
    )
  )
);
