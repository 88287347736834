import cx from 'clsx';
import { Box, Group, Text, Avatar } from '@mantine/core';
import classes from '../SelectUserModal.module.scss';
import { UserDto } from '@assemblio/shared/dtos';

interface UserCardProps {
  user: UserDto;
  isSelected: boolean;
  onSelect: (user: UserDto) => void;
}

export const UserCard = ({ user, isSelected, onSelect }: UserCardProps) => {
  return (
    <Box
      px="sm"
      py="sm"
      onClick={() => onSelect(user)}
      className={cx(classes.userCard, { [classes.selected]: isSelected })}
      style={{
        color: 'var(--text-primary)',
      }}
    >
      <Group gap={'sm'}>
        <Avatar name={user.fullName} color={'initials'} />
        <div>
          <Text c={'text-primary'}>
            {user.firstName} {user.lastName}
          </Text>
          <Text size="xs" c={'text-primary'}>
            {user.email}
          </Text>
        </div>
      </Group>
    </Box>
  );
};
