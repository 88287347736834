import { IconChangeUser, IconRenameFilled } from '@assemblio/design-system';
import { useEffect, useState } from 'react';
import { IconCopy, IconFileArrowRight, IconStar, IconStarFilled, IconTrashFilled } from '@tabler/icons-react';
import { useModalContext } from '@assemblio/frontend/components';
import { ProductAction, ProductActionHookInput } from '../types/productActions.types';
import { InstructionState } from '@assemblio/type/instruction';
import { ReviewStateSelection } from '../components/ReviewStateSelection/ReviewStateSelection';
import { useChangeProductState } from './useChangeProductState.hook';
import { useChangeProductOwner, useDeleteProduct, useRenameProduct, useToggleFavorite } from '../../../../../hooks';

export const useBaseProductAction = ({ product, userState }: ProductActionHookInput) => {
  const [isLoading, setIsLoading] = useState(false);

  const { isOwner, isViewer } = userState;

  const { openModal } = useModalContext();
  const { renameProduct, isLoading: isRenameMutationLoading } = useRenameProduct();
  const { deleteProduct, isLoading: isDeleteMutationLoading } = useDeleteProduct();
  const { changeProductOwner, isLoading: isChangeOwnerLoading } = useChangeProductOwner();
  const { toggleFavorite, isLoading: isFavoriteLoading } = useToggleFavorite();
  const { changeProductState } = useChangeProductState();

  const onChangeProductRevisionState = (newState: InstructionState) => {
    if (!product) return;
    changeProductState(product.id, newState);
  };

  const onDelete = () => {
    if (!product) return;
    deleteProduct(product.name, product.id, `/v2/explorer/project/${product.project.id}`);
  };

  const onRename = () => {
    if (!product) return;
    renameProduct(product.name, product.id);
  };

  const onToggleFavorite = () => {
    if (!product) return;
    toggleFavorite(product, product.project.id);
  };

  const openExportModal = () => {
    if (!product) return;
    openModal('export-modal', {
      instructionId: product.id,
      name: product.name,
    });
  };

  const actions: ProductAction[] = [
    {
      label: 'Rename',
      Icon: IconRenameFilled,
      onClick: onRename,
      show: isOwner && !isViewer,
      order: 2,
    },
    {
      component: <ReviewStateSelection onChangeState={onChangeProductRevisionState} state={product?.state} />,
      show: isOwner && !isViewer,
      order: 3,
    },
    {
      label: 'Duplicate',
      Icon: IconCopy,
      onClick: () => null,
      show: false,
    },

    {
      label: `${product?.isFavorite ? 'Remove from' : 'Add to'} Favorites`,
      Icon: product?.isFavorite ? IconStarFilled : IconStar,
      onClick: onToggleFavorite,
      order: 1,
    },
    {
      label: 'Export',
      Icon: IconFileArrowRight,
      onClick: openExportModal,
      disabled: !product?.hasSteps,
      order: 101,
    },
    {
      label: 'Change Owner',
      Icon: IconChangeUser,
      onClick: changeProductOwner,
      show: isOwner && !isViewer,
      order: 3,
    },
    {
      label: 'Delete',
      Icon: IconTrashFilled,
      onClick: onDelete,
      allowedProductStates: [InstructionState.DRAFT, InstructionState.REQUEST_CHANGE],
      show: isOwner && !isViewer,
      order: 102,
    },
  ];

  const filteredActions = actions.filter((action) => action.show !== false);

  useEffect(() => {
    setIsLoading(isRenameMutationLoading || isDeleteMutationLoading || isChangeOwnerLoading || isFavoriteLoading);
  }, [isRenameMutationLoading, isDeleteMutationLoading, isChangeOwnerLoading, isFavoriteLoading]);

  return { productActions: filteredActions, isLoading };
};
