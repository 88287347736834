import { TextDto } from '@assemblio/shared/dtos';
import { Transform } from '@assemblio/type/3d';
import { OrthographicCamera } from './camera.interface';

export enum StepType {
  assembly = 'assembly',
  alignment = 'alignment',
}

export interface Step {
  id: string;
  name: string;
  stepGroupId: string;
  type: StepType;
  playWithAbove: boolean;
  animationSpeed: number;
  mtm: number;
  cameraSettings: OrthographicCamera;
  data: StepData;
  annotations: Array<string>;
  text?: TextDto | null;
}

export interface StepData {
  path: Array<Transform>;
  parts: Array<PartInStep>;
}

export interface PartInStep {
  partGltfIndex: number;
  start: Transform;
  end: Transform;
}
