import { ColorInput, SimpleGrid, Skeleton, Stack, Text } from '@mantine/core';
import { TitleWithInformation } from '../TitleWithInformation';
import produce from 'immer';
import { BrandingColorsLabelMap, BrandingColorsType } from './types/brandingColors.types';

interface BrandingColorsProps {
  brandingColors: BrandingColorsType;
  setBrandingColors: React.Dispatch<React.SetStateAction<BrandingColorsType>>;
  isLoading: boolean;
}

const brandingColorsLabelMap: BrandingColorsLabelMap = {
  titleColor: 'Title',
  textColor: 'Text',
  headerColor: 'Header',
  footerColor: 'Footer',
};

export const BrandingColors = ({ setBrandingColors, brandingColors, isLoading }: BrandingColorsProps) => {
  return (
    <Stack gap={0} mb={'lg'}>
      <TitleWithInformation title={'Text Colors'} information={'Text colors used in your instructions.'} />
      <SimpleGrid cols={2}>
        {isLoading ? (
          <BrandingColorsSkeleton />
        ) : (
          Object.keys(brandingColorsLabelMap).map((color) => {
            const brandingColor = color as keyof BrandingColorsType;
            return (
              <ColorInput
                disabled
                size={'xs'}
                key={`branding-color-${color}`}
                label={<Text size={'sm'}>{brandingColorsLabelMap[brandingColor]}</Text>}
                value={brandingColors[brandingColor]}
                onChange={(value) => {
                  setBrandingColors(
                    produce<BrandingColorsType>((state) => {
                      state[brandingColor] = value;
                    })
                  );
                }}
              />
            );
          })
        )}
      </SimpleGrid>
    </Stack>
  );
};

const BrandingColorsSkeleton = () => {
  return Array.from({ length: 4 }).map((_element, index) => (
    <Skeleton key={`branding-color-skeleton-${index}`} h={'2rem'} />
  ));
};
