import { useEffect } from 'react';
import { EventController } from '@assemblio/frontend/stores';
import { useQueryClient } from '@tanstack/react-query';
import { ProductMessage, RoomEvents } from '@assemblio/type/socket';
import { ProductContentDto } from '@assemblio/shared/dtos';
import produce from 'immer';
import { InstructionState } from '@assemblio/type/instruction';

export const useProductWebsocketListener = (productId: string | undefined, projectId: string | undefined) => {
  const queryClient = useQueryClient();

  const handleProductUpdate = (event: ProductMessage) => {
    queryClient.setQueryData<ProductContentDto>(['product', productId], (data?: ProductContentDto) =>
      produce(data, (draft) => {
        if (!draft) return draft;
        draft.state = event.payload.state as InstructionState;
      })
    );
  };

  useEffect(() => {
    if (!productId || !projectId) return;
    EventController.subscribeRoom(
      {
        roomEvent: RoomEvents.Product,
        projectId,
        productId,
      },
      handleProductUpdate
    );

    return () => {
      EventController.unsubscribeRoom({
        roomEvent: RoomEvents.Product,
        projectId,
        productId,
      });
    };
  }, [productId, projectId]);
};
