import { InstructionState } from '@assemblio/type/instruction';
import { NotificationData } from '@mantine/notifications';

type StateErrorMapType = Partial<Record<InstructionState, NotificationData>>;

export const StateErrorMap: StateErrorMapType = {
  [InstructionState.INITIALIZING_FAILED]: {
    title: 'Could not open product',
    message: 'The product initialization failed',
    autoClose: 5000,
  },
  [InstructionState.PROCESSING_FAILED]: {
    title: 'Could not open product',
    message: 'The product processing failed.',
    autoClose: 5000,
  },
  [InstructionState.UPLOADING]: {
    title: 'Could not open product',
    message: 'The product is being uploaded.',
    autoClose: 5000,
    loading: true,
  },
};

export const PRODUCT_OPEN_ERROR_FALLBACK = {
  title: 'Could not open product',
  message: 'An error occurred while opening the product.',
  autoClose: 5000,
};
