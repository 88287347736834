import React from 'react';
import { Divider, ScrollArea } from '@mantine/core';
import { Mock_VersionInformation } from './types/utils.types';
import { VersionItem } from './components/VersionItem';
import classes from './VersionHistoryTab.module.scss';

interface VersionHistoryProps {
  versions: Mock_VersionInformation[];
}

export const VersionHistoryTab = ({ versions }: VersionHistoryProps) => {
  return (
    <ScrollArea style={{ height: 300 }}>
      <div className="versionTimeline">
        {versions.map((version, index) => (
          <>
            <VersionItem key={index} versionInfo={version} />
            <Divider className={classes.customDivider} my={'sm'} />
          </>
        ))}
      </div>
    </ScrollArea>
  );
};
