import { useRecentProductsQuery } from '@assemblio/frontend/data-access';
import { useExplorerTitle } from '../../hooks';
import { ProductsDisplay } from '../../components/ProductsDisplay/ProductsDisplay';
import { useSortingOptions } from '../../components';
import { ProductSortProps } from '@assemblio/type/product';
import { RECENT_QUERY_SORTING_OPTIONS } from './constants/recentQuerySortOptions';
import { useEffect, useMemo } from 'react';
import { QueryOrder } from '@assemblio/type/common';
import { ProductQueryDto } from '@assemblio/shared/dtos';
import { InstructionState } from '@assemblio/type/instruction';
import { useSearchParams } from 'react-router-dom';

export const RecentProducts = () => {
  const { sortingValue, handleSort } = useSortingOptions<ProductSortProps>(RECENT_QUERY_SORTING_OPTIONS);
  const [searchParams] = useSearchParams();

  const queryData: ProductQueryDto = useMemo((): ProductQueryDto => {
    return {
      orderBy: sortingValue?.queryValue,
      orderType: sortingValue?.ascending ? QueryOrder.ASC : QueryOrder.DESC,
      ownerIds: searchParams.get('owner') ?? undefined,
      states: (searchParams.get('state') as InstructionState) ?? undefined,
    };
  }, [sortingValue, searchParams]);

  const { data: products, isLoading, isRefetching, refetch } = useRecentProductsQuery(queryData);

  useExplorerTitle('Recently Opened', {
    title: {
      itemCount: products?.length,
    },
    noDefaultRightSection: true,
  });

  useEffect(() => {
    refetch();
  }, [sortingValue, searchParams]);

  return (
    <ProductsDisplay<ProductSortProps>
      products={products ?? []}
      isLoading={isLoading}
      isRefetching={isRefetching}
      sortingValue={sortingValue}
      onSort={handleSort}
      sortingOptions={RECENT_QUERY_SORTING_OPTIONS}
    />
  );
};
