import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class FolderSearchResponseItemDto {
  @ApiProperty({
    description: 'The id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The name of the item',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;
}

export const FolderSearchResponseItemSchema = generateClassValidationObject(FolderSearchResponseItemDto);

export class SearchInFolderResponseDto {
  @ApiProperty({
    description: 'The folder items',
    required: true,
  })
  @joiSchemaProperty(Joi.array().items(FolderSearchResponseItemSchema.required()))
  folders!: FolderSearchResponseItemDto[];

  @ApiProperty({
    description: 'The project items',
    required: true,
  })
  @joiSchemaProperty(Joi.array().items(FolderSearchResponseItemSchema.required()))
  projects!: FolderSearchResponseItemDto[];

  @ApiProperty({
    description: 'The product items',
    required: true,
  })
  @joiSchemaProperty(Joi.array().items(FolderSearchResponseItemSchema.required()))
  products!: FolderSearchResponseItemDto[];
}

export const SearchInFolderResponseSchema = generateClassValidationObject(SearchInFolderResponseDto);
