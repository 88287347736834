import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import {
  OperationalResourceMainCategory,
  OperationalResourceSecondaryCategory,
  OperationalResourceUnits,
} from '@assemblio/type/resources';
import { ProductOverviewDto, ProductOverviewSchema } from '../product';

export class ResourceDetailsDto {
  @ApiProperty({
    description: 'The operational resource id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({
    description: 'The operational resource main category',
    required: true,
  })
  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(OperationalResourceMainCategory))
      .required()
  )
  mainCategory!: OperationalResourceMainCategory;

  @ApiProperty({
    description: 'The operational resource sub category',
    required: false,
  })
  @joiSchemaProperty(Joi.string().valid(...Object.values(OperationalResourceSecondaryCategory)))
  secondaryCategory?: OperationalResourceSecondaryCategory;

  @ApiProperty({
    description: 'The operational resource name',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'The operational resource descriptive text',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  text!: string;

  @ApiProperty({
    description: 'The operational resource unit of measurement',
    required: false,
  })
  @joiSchemaProperty(Joi.array().items(Joi.string().valid(...Object.values(OperationalResourceUnits))))
  allowedUnits?: OperationalResourceUnits[];

  @ApiProperty({
    description: 'The operational resource No.',
    required: false,
  })
  @joiSchemaProperty(Joi.string())
  resourceNo?: string;

  @ApiProperty({
    description: 'The operational resource updated at',
    required: false,
  })
  @joiSchemaProperty(Joi.date())
  updatedAt?: Date;

  @ApiProperty({
    description: 'The operational resource created at',
    required: false,
  })
  @joiSchemaProperty(Joi.date())
  createdAt?: Date;

  @ApiProperty({
    description: 'The products that use this operational',
    required: false,
  })
  @joiSchemaProperty(Joi.array().items(ProductOverviewSchema))
  products!: ProductOverviewDto[];
}

export const ResourceDetailsSchema = generateClassValidationObject(ResourceDetailsDto);
