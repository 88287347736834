import {
  Button,
  colorsTuple,
  createTheme,
  DefaultMantineColor,
  Modal,
  virtualColor,
  Notification,
  Avatar,
  Menu,
  Select,
  Input,
  Tabs,
} from '@mantine/core';
import { ComposerColors } from './Composer.colors';
import { IconCircleCheckFilled, IconCircleXFilled } from '@tabler/icons-react';
import notificationClasses from './style-modules/Notifications.module.scss';
import avatarClasses from './style-modules/Avatar.module.scss';
import selectClasses from './style-modules/Select.module.scss';
import tabClasses from './style-modules/Tabs.module.scss';

const AVATAR_ALLOWED_COLORS: DefaultMantineColor[] = ['blue', 'yellow', 'green', 'magenta'];

export const composerTheme = createTheme({
  fontFamily: 'Satoshi, sans-serif',
  primaryColor: 'purple',
  cursorType: 'pointer',
  fontSizes: {
    xs: '11px',
    sm: '13px',
    md: '14px',
    lg: '16px',
    MD: '18px',
    XL: '32px',
  },
  lineHeights: {
    xs: '12px',
    sm: '16px',
    md: '24px',
    lg: '24px',
    xl: '24px',
    MD: '32px',
    XL: '40px',
  },
  headings: {
    fontFamily: 'Satoshi, sans-serif',
    fontWeight: '700',
    sizes: {
      h1: {
        fontSize: '32px',
        lineHeight: '40px',
      },
      h2: {
        fontSize: '21px',
        lineHeight: '32px',
      },
      h3: {
        fontSize: '18px',
        lineHeight: '32px',
      },
    },
  },
  spacing: { xs: '4px', sm: '8px', md: '12px', lg: '16px', xl: '24px' },
  radius: {
    xxs: '2px',
    xs: '4px',
    sm: '6px',
    md: '8px',
    lg: '10px',
    xl: '12px',
  },
  components: {
    Button: Button.extend({
      defaultProps: {
        radius: 'md',
      },
    }),
    Select: Select.extend({
      defaultProps: {},
      classNames: {
        root: selectClasses.root,
        input: selectClasses.input,
        label: selectClasses.label,
        option: selectClasses.option,
      },
    }),
    Input: Input.extend({
      defaultProps: {},
      classNames: {
        input: selectClasses.input,
      },
    }),
    MenuItem: Menu.Item.extend({}),
    Modal: Modal.extend({
      defaultProps: {
        centered: true,
        closeButtonProps: {
          icon: <IconCircleXFilled size={16} />,
          color: 'text-secondary',
        },
      },
    }),
    Avatar: Avatar.extend({
      defaultProps: {
        classNames: {
          root: avatarClasses.root,
        },
        allowedInitialsColors: AVATAR_ALLOWED_COLORS,
      },
    }),
    Tabs: Tabs.extend({
      defaultProps: {
        color: 'text-brand',
      },
      classNames: {
        root: tabClasses.tabContainer__root,
        list: tabClasses.tabContainer__list,
        panel: tabClasses.tabContainer__panel,
        tab: tabClasses.tabContainer__tab,
      },
    }),
    Notification: Notification.extend({
      defaultProps: {
        classNames: {
          root: notificationClasses.root,
          icon: notificationClasses.icon,
          description: notificationClasses.description,
        },
        closeButtonProps: {
          icon: <IconCircleXFilled size={16} color={'var(--text-secondary)'} />,
        },
        icon: <IconCircleCheckFilled size={16} />,
      },
    }),
  },
  colors: {
    black: colorsTuple('#0A0A0B'),
    'alpha-black': [...ComposerColors['alpha-black']],
    white: colorsTuple('#FFF'),
    'alpha-white': [...ComposerColors['alpha-white']],

    'neutral-dark-mode': [...ComposerColors['neutral-dark-mode']],
    'neutral-light-mode': [...ComposerColors['neutral-light-mode']],

    purple: [...ComposerColors.purple],
    'alpha-purple': [...ComposerColors['alpha-purple']],

    neutral: virtualColor({
      name: 'neutral',
      dark: 'neutral-dark-mode',
      light: 'neutral-light-mode',
    }),

    'green-400': colorsTuple('#13F8B9'),
    'green-500': colorsTuple('#18DAA4'),
    green: virtualColor({
      name: 'green',
      dark: 'green-500',
      light: 'green-400',
    }),

    'yellow-400': colorsTuple('#FEC633'),
    'yellow-500': colorsTuple('#FDB908'),
    yellow: virtualColor({
      name: 'yellow',
      dark: 'yellow-500',
      light: 'yellow-400',
    }),

    'blue-400': colorsTuple('#05CBFF'),
    'blue-500': colorsTuple('#00B1E0'),
    blue: virtualColor({
      name: 'blue',
      dark: 'blue-500',
      light: 'blue-400',
    }),

    'red-400': colorsTuple('#FF5C5C'),
    'red-500': colorsTuple('#FF3333'),
    red: virtualColor({
      name: 'red',
      dark: 'red-500',
      light: 'red-400',
    }),

    'orange-400': colorsTuple('#E69025'),
    'orange-500': colorsTuple('#FC4F04'),
    orange: virtualColor({
      name: 'orange',
      dark: 'orange-500',
      light: 'orange-400',
    }),

    'magenta-400': colorsTuple('#D402BF'),
    'magenta-500': colorsTuple('#B502A4'),
    magenta: virtualColor({
      name: 'magenta',
      dark: 'magenta-500',
      light: 'magenta-400',
    }),

    'true-blue-400': colorsTuple('#2B99FF'),
    'true-blue-500': colorsTuple('#007BED'),
    'accent-blue': virtualColor({
      name: 'accent-blue',
      dark: 'true-blue-500',
      light: 'true-blue-400',
    }),

    //   TEXT COLORS
    'text-primary-light': colorsTuple(ComposerColors['neutral-light-mode'][9]),
    'text-primary': virtualColor({
      name: 'text-primary',
      dark: 'white',
      light: 'text-primary-light',
    }),

    'text-secondary-light': colorsTuple(ComposerColors['alpha-black'][8]),
    'text-secondary-dark': colorsTuple(ComposerColors['alpha-white'][8]),
    'text-secondary': virtualColor({
      name: 'text-secondary',
      dark: 'text-secondary-dark',
      light: 'text-secondary-light',
    }),

    'text-tertiary-light': colorsTuple(ComposerColors['alpha-black'][6]),
    'text-tertiary-dark': colorsTuple(ComposerColors['alpha-white'][6]),
    'text-tertiary': virtualColor({
      name: 'text-tertiary',
      dark: 'text-tertiary-dark',
      light: 'text-tertiary-light',
    }),

    'text-disabled-light': colorsTuple(ComposerColors['alpha-black'][4]),
    'text-disabled-dark': colorsTuple(ComposerColors['alpha-white'][4]),
    'text-disabled': virtualColor({
      name: 'text-disabled',
      dark: 'text-disabled-dark',
      light: 'text-disabled-light',
    }),

    'text-constant': virtualColor({
      name: 'text-constant',
      dark: 'white',
      light: 'white',
    }),

    'text-constant-black': virtualColor({
      name: 'text-constant',
      dark: 'black',
      light: 'black',
    }),

    'text-brand-light': colorsTuple(ComposerColors.purple[5]),
    'text-brand-dark': colorsTuple(ComposerColors.purple[4]),
    'text-brand': virtualColor({
      name: 'text-brand',
      dark: 'text-brand-dark',
      light: 'text-brand-light',
    }),

    'text-alert': virtualColor({
      name: 'text-alert',
      dark: 'yellow-500',
      light: 'yellow-400',
    }),
  },

  shadows: {
    xl: '0px 4px 20px 0 #13192566;',
    lg: '0px 4px 20px 0 #13192566;',
    md: '0px 2px 2px 0 #1319251F;',
    sm: '0px 2px 7px 0 #1319251F, 0 5px 17px 0 #13192533;',
    xs: '0px 2px 7px 0 #1319251F, 0 5px 17px 0 #13192533;',
  },
  breakpoints: {
    xs: '48em',
    sm: '62em',
    md: '88em',
    lg: '100em',
    xl: '125em',
  },
  other: {
    topbarHeight: 60,
    navbarHeight: 40,
    explorerHeaderHeight: 100,
    sidebarWidth: 256,
    sidebarWidth_collapsed: 70,
    color: {
      brand: {
        purple: '#6B4092',
        yellow: '#F59C1B',
        blue: '#444697',
        pink: '#C22786',
      },
      editor: {
        light: '#392E3D',
        medium: '#2F2533',
        medium_light: '#5c565f',
        dark: '#251E29',
        white_10: '#FFFFFF1A',
        white_20: '#FFFFFF33',
        black_10: '#0000001A',
        black_20: '#00000033',
      },
      explorer: {
        card: '#272429',
        instruction: '#3f686e',
      },
    },
    v2: {
      explorerHeaderHeight: 70,
      sidebarWidth: 250,
      sidebarWidth_collapsed: 70,
      color: {
        base: {
          primary: '#6f3bed',
          card_shadow: '0px 2px 2px 0px rgba(10, 10, 11, 0.12)',
        },
        darkMode: {
          sidebar_bg: '#0a0a0b',
          content_bg: '#18191b',
          secondary: '#5a5d63',
          card: '#303236',
          text_primary: '#fff',
          border: '#414448',
        },
        lightMode: {
          sidebar_bg: '#fff',
          content_bg: '#fff',
          secondary: '#dcdfe5',
          card: '#fff',
          text_primary: '#1E2229',
          border: '#DCDFE5',
        },
      },
    },
  },
});
